import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { emptyObject } from '../../../utils/generic';
import { AppStateContext } from '../../../context/AppContext';
import { userInfoCardId } from '../../../constants';
import CardTemplate from '../../shared/CardTemplate';
import UserInfoWithData from './UserInfoWithData';

const UserInfoCard = () => {
    const { t } = useTranslation();
    const { user, security } = useContext(AppStateContext);

    return (
        <>
            <CardTemplate
                title={t('cardTitles$userInfo')}
                testId={userInfoCardId}
            >
                <UserInfoWithData user={user} />
            </CardTemplate>
            {!emptyObject(security) && (
                <CardTemplate title={t('cardTitles$securityInfo')}>
                    <UserInfoWithData security={security} />
                </CardTemplate>
            )}
        </>
    );
};

export default UserInfoCard;
