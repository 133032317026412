import React from 'react';
import { useHistory } from 'react-router-dom';
import { subTabsPaths, paths } from '../../../constants';
import { AccentLink } from '../../../styles/common';

const {
    helpDesk: { users },
} = subTabsPaths;
const { helpDesk } = paths;

const DeviceUser = ({ username }) => {
    const {
        location: { pathname },
    } = useHistory();
    if (
        !pathname ||
        !pathname.includes(helpDesk) ||
        pathname === `${users}/${username}`
    ) {
        return <span>{username}</span>;
    }
    return (
        <AccentLink to={{ pathname: `${users}/${username}` }}>
            {username}
        </AccentLink>
    );
};

export default DeviceUser;
