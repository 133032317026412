import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
    AppStateContext,
    AppDispatchContext,
} from '../../../context/AppContext';
import { paths } from '../../../constants';
import { NotificationDispatchContext } from './context';

const ActionLink = styled.a`
    font-size: 0.85em;
    margin-left: 6px;
    margin-right: 2px;
`;

const NotificationAction = ({ actions = [], serial, deviceType, subType }) => {
    const { user } = useContext(AppStateContext);
    const appDispatch = useContext(AppDispatchContext);
    const notificationDispatch = useContext(NotificationDispatchContext);
    const history = useHistory();
    const { t } = useTranslation();

    const {
        location: { pathname },
    } = history || {};
    const { username } = user;

    const generateActionLink = (action) => {
        const actionPaths = {
            deviceRenew: paths.update,
            deviceDisable: paths.disable,
            deviceEnable: paths.enable,
            deviceResyncOtp: paths.resyncOtp,
            deviceValidateDevice: paths.validateDeviceWithPin,
            deviceUnassign: paths.unassign,
            deviceRevoke: paths.revoke,
            deviceRecycle: paths.recycle,
            deviceEnroll: paths.credentialing,
            userResetSecurity: paths.security,
        };
        return actionPaths[action] || false;
    };

    const onClick = (action) => {
        const actionLink = generateActionLink(action);
        if (!actionLink) {
            return;
        }
        const device = {
            serial,
            type: deviceType,
            subType,
            user: username,
        };
        appDispatch({
            type: 'SET_DEVICE',
            device,
            goBackLink: pathname,
        });
        notificationDispatch({ type: 'CLOSE_DROPDOWN' });
        history.replace(actionLink);
    };

    const handleKeyPress = (event, action) => {
        const { keyCode } = event || {};
        event.stopPropagation();
        if (keyCode === 13) {
            onClick(action);
        }
    };

    return actions.map((action) => (
        <ActionLink
            key={`notification-action-${action}`}
            onClick={(e) => {
                // this prevents `Link` read `context.location` as undefined then read other properties from `context.location`. 
                e.preventDefault();
                onClick(action);
            }}
            tabIndex={0}
            onKeyUp={(e) => handleKeyPress(e, action)}
        >
            {t(`notifications$action$${action}`) || ''}
        </ActionLink>
    ));
};

NotificationAction.displayName = 'NotificationAction';

export default NotificationAction;
