import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Col, Row, FormGroup, Form } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import DeviceSelection from '../../elementLibrary/DeviceSelection';
import { AppDispatchContext, AppStateContext } from '../../../context/AppContext';
import {
    EnrollingDispatchContext,
    EnrollingStateContext,
} from '../../../context/EnrollingContext';
import {
    PortalContainer,
    CardHeader,
    StyledAlert,
    StyledRadioButton,
    StyledFormText,
} from '../../../styles/common';
import LoadingSpinner from '../../shared/LoadingSpinner';

const IssuanceTypeWithData = ({
    fieldset,
    handleRadioClick,
    isLoading,
    allowedDevices = {},
}) => {
    const { t } = useTranslation();
    const { user } = useContext(AppStateContext);
    const { username } = useContext(EnrollingStateContext);
    const appDispatch = useContext(AppDispatchContext);
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const displayFields = Object.keys(fieldset).filter(
        (key) => fieldset[key].display === true,
    );

    return (
        <PortalContainer>
            <Row>
                <Col>
                    <CardHeader>
                        {t('issuanceTypeCardLabels$stateless$h6')}
                    </CardHeader>
                </Col>
            </Row>
            <Form tag="fieldset" autoComplete="off">
                {displayFields.map(
                    (key) =>
                        fieldset[key].display && (
                            <FormGroup
                                className="form-check"
                                key={
                                    t(`issuanceTypeCardLabels$issuanceTypes$${key}`,) 
                                        ? t(`issuanceTypeCardLabels$issuanceTypes$${key}`,) 
                                        : key
                                }
                            >
                                <StyledRadioButton
                                    id={key}
                                    value={key}
                                    type="radio"
                                    name="issuance"
                                    onChange={handleRadioClick}
                                    checked={fieldset[key].selected}
                                    label={
                                        t(`issuanceTypeCardLabels$issuanceTypes$${key}`,) 
                                            ? t(`issuanceTypeCardLabels$issuanceTypes$${key}`,) 
                                            : key
                                    }
                                />
                                <StyledFormText $color={!fieldset[key].selected}>
                                    {t(
                                        `issuanceTypeCardLabels$issuanceTypes$${key}_desc`,
                                    )}
                                </StyledFormText>
                                {/* Todo: refactor when more rideo button have "dropdown" buttonTYpe besides insertedDevice  */}
                                {fieldset[key].buttonType === "dropdown" && (
                                    <DeviceSelection
                                        appDispatch={appDispatch}
                                        enrollingDispatch={enrollingDispatch}
                                        display={
                                            fieldset[key].selected
                                        }
                                        username={username}
                                        allowedDevices={allowedDevices}
                                        isHost={user.username === username}
                                    />
                                )}
                            </FormGroup>
                        ),
                )}
            </Form>
            {isLoading && <LoadingSpinner />}
            {!isLoading && displayFields.length === 0 && (
                <StyledAlert color="info">
                    {t('issuanceTypeNoneFoundDefault')}
                </StyledAlert>
            )}
        </PortalContainer>
    );
};

IssuanceTypeWithData.propTypes = {
    fieldset: PropTypes.objectOf(PropTypes.object).isRequired,
    handleRadioClick: PropTypes.func.isRequired,
};

export default IssuanceTypeWithData;
