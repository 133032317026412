import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { flowButtonsForwardId, flowButtonsBackwardId } from '../../constants';
import {
    PortalContainer,
    StyledNavIcon,
    AccentButton,
} from '../../styles/common';

const BackButton = styled(AccentButton)`
    margin-right: 0.5rem;
`;
const NextButton = styled(AccentButton)`
    margin-left: 0.5rem;
`;
const ButtonsContainer = styled(Row)`
    min-width: 500px;
    margin-left: 0px !important;
`;
const CustomIcon = styled(StyledNavIcon)`
    margin-right: 0px;
    margin-left: 10px;
`;

const NewFlowButtons = ({
    disableBack = false,
    disableNext = false,
    backCallback,
    nextCalback,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        const handleEnter = (e) => {
            if (e.keyCode === 13 && !disableNext) {
                nextCalback();
            }
        };
        document.addEventListener('keyup', handleEnter);
        return () => document.removeEventListener('keyup', handleEnter);
    },[]);

    return (
        <PortalContainer>
            <ButtonsContainer>
                <BackButton
                    aria-disabled={disableBack}
                    disabled={disableBack}
                    outline
                    color="primary"
                    onClick={backCallback}
                    size="sm"
                >
                    <span data-test-id={flowButtonsBackwardId}>
                        <StyledNavIcon icon="chevron-left" />
                        {`  ${t('flowButtonsElementLabels$back')}`}
                    </span>
                </BackButton>
                <NextButton
                    aria-disabled={disableNext}
                    disabled={disableNext}
                    outline
                    color="primary"
                    onClick={nextCalback}
                    size="sm"
                >
                    <span data-test-id={flowButtonsForwardId}>
                        {`${t('flowButtonsElementLabels$next')}  `}
                        <CustomIcon pad="start" icon="chevron-right" />
                    </span>
                </NextButton>
            </ButtonsContainer>
        </PortalContainer>
    );
};

NewFlowButtons.propTypes = {
    disableBack: PropTypes.bool,
    disableNext: PropTypes.bool,
    backCallback: PropTypes.func.isRequired,
    nextCalback: PropTypes.func.isRequired,
};

NewFlowButtons.defaultProps = {};

export default NewFlowButtons;
