import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import uniqueId from 'lodash.uniqueid';
import isEmpty from 'lodash.isempty';
import styled from 'styled-components';
import {
    PortalContainer,
    StyledAlert,
    StyledNavIcon,
    StyledDropdown,
    StyledDropdownToggle,
    StyledDropdownItem,
    StyledDropdownMenu,
    NoHoverLink,
} from '../../../../styles/common';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import SearchBar from '../../../elementLibrary/SearchBar';
import DevicesTable from '../../../elementLibrary/DevicesTable';
import { findDuplicates } from '../../../../utils/generic';
import {
    HelpDeskStateContext,
    HelpDeskDispatchContext,
} from '../../../../context/HelpDeskContext';
import {
    AppStateContext,
    AppDispatchContext,
} from '../../../../context/AppContext';
import {
    adminDeviceEndpoint,
    emptyDevicesLabelId,
    helpdeskDevicesQueryTableColumnsSettings,
} from '../../../../constants';
import { useSortColumn } from '../../../../utils/hooks';
import { helpDeskActionTypes } from '../../../../utils/mappings';

const CustomDropdown = styled(StyledDropdown)`
    display: inline-block !important;
`;

const CustomDropdownItem = styled(StyledDropdownItem)`
    .nav-link {
        ${(props) =>
        props['aria-disabled'] &&
            `color: #b1b1b1 !important;
        cursor: default !important;
        background-color: #fff !important;
        `}
    }
`;

const NumOfDevices = styled.p`
    padding-left: 1rem;
    padding-right: 0.5rem;
    display: inline-block;
`;

const LabelOfDevices = styled.div`
    display: inline-block;
    color: #6c757d;
`;

const ActionItem = ({ isEnabled, path, label }) => {
    const history = useHistory();
    const handleKeyPress = (event) => {
        const { keyCode } = event || {};
        if (isEnabled && (keyCode === 13 || keyCode === 32)) {
            history.push(path);
        }
    };
    return (
        <CustomDropdownItem
            aria-disabled={!isEnabled}
            disabled={!isEnabled}
            tabIndex={isEnabled ? 0 : -1}
            onKeyDown={handleKeyPress}
        >
            <NoHoverLink to={path} onClick={(e) => !isEnabled && e.preventDefault()} tabIndex={-1}>
                {label}
            </NoHoverLink>
        </CustomDropdownItem>
    );
};

const BulkActionBtn = ({ actions, bulkActions }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { t } = useTranslation();

    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const isEnabled = (action) => bulkActions.includes(action);

    return (
        <CustomDropdown
            isOpen={dropdownOpen}
            toggle={toggle}
            size="sm"
            a11y={false}
        >
            <StyledDropdownToggle caret>
                <StyledNavIcon icon="cog" />
                {t('helpDesk$bulkAction$label')}
            </StyledDropdownToggle>
            <StyledDropdownMenu>
                {actions &&
                    actions.map((action) => (
                        <ActionItem
                            action={action}
                            key={uniqueId('action_')}
                            label={t(helpDeskActionTypes[action].text)}
                            isEnabled={isEnabled(action)}
                            path={helpDeskActionTypes[action].href}
                        />
                    ))}
            </StyledDropdownMenu>
        </CustomDropdown>
    );
};

const DevicesHeader = ({ actions }) => {
    const { selectedRows, devicesDefaultValue, searchFilter } =
        useContext(HelpDeskStateContext);
    const {
        admin: { metadata },
        activeDevicesColumns,
    } = useContext(AppStateContext);
    const dispatch = useContext(HelpDeskDispatchContext);
    const appDispatch = useContext(AppDispatchContext);
    const { t } = useTranslation();

    const { devices } = metadata || {};
    const { filters } = devices || {};
    const numOfSelectedDevices = selectedRows && selectedRows.length;

    const sharedActions =
        !isEmpty(selectedRows) &&
        findDuplicates(...selectedRows.map((a) => a.actions));
    const bulkActions =
        actions &&
        actions.filter(
            (action) => sharedActions && sharedActions.includes(action),
        );
    const keywords = filters && filters.map((filter) => filter.name);

    const onLoading = (loading) =>
        dispatch({
            type: 'SET_SEARCH_LOADING',
            payload: loading,
        });
    const onSearchData = (data) => 
        dispatch({
            type: 'SET_SEARCH_DATA',
            payload: data,
        });
    const onClearData = () =>
        dispatch({
            type: 'RESET_SEARCH_DATA',
            payload: {},
        });
    const onFiltersChange = (filters) => {
        if (activeDevicesColumns !== undefined) {
            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const item = keys[i];
                activeDevicesColumns[item] = true;
            }
            appDispatch({
                type: 'SET_ACTIVE_DEVICES_COLUMNS',
                payload: activeDevicesColumns,
            })
        }
        dispatch({
            type: 'SET_SEARCH_FILTER',
            payload: filters,
        });
    }
    const onSelect = (defaultValues) =>
        dispatch({
            type: 'SAVE_DEVICES_DEFAULT_VALUE',
            payload: defaultValues,
        });

    return (
        <>
            <Col xs="6">
                <BulkActionBtn actions={actions} bulkActions={bulkActions} />
                {numOfSelectedDevices > 0 && (
                    <LabelOfDevices>
                        <NumOfDevices>{numOfSelectedDevices}</NumOfDevices>
                        {t('helpDesk$bulkAction$selectedDevices')}
                    </LabelOfDevices>
                )}
            </Col>
            <Col xs="5">
                <SearchBar
                    endpoint={adminDeviceEndpoint}
                    keywords={keywords}
                    onLoading={onLoading}
                    onSearchData={onSearchData}
                    onClearData={onClearData}
                    onFiltersChange={onFiltersChange}
                    onSelect={onSelect}
                    defaultValue={devicesDefaultValue}
                    searchFilter={searchFilter}
                />
            </Col>
        </>
    );
};

const Devices = () => {
    const {
        admin: { metadata },
        activeDevicesColumns,
        settings,
    } = useContext(AppStateContext);
    const defaultDeviceColumns =
        settings.defaultDeviceColumns === ''
            ? []
            : settings.defaultDeviceColumns.split(',');
    const { searchData, searchLoading } = useContext(HelpDeskStateContext);
    const dispatch = useContext(HelpDeskDispatchContext);
    const appDispatch = useContext(AppDispatchContext);
    const { t } = useTranslation();
    const [cleaning, setCleaning] = useState(true);

    const { devices: devicesMetadata } = metadata || {};
    const { bulkActions, columns } = devicesMetadata || {};

    useEffect(() => {
        if (isEmpty(searchData)) {
            setCleaning(false);
        }
    }, [searchData]);

    useEffect(() => {
        dispatch({ type: 'RESET_BULK_ACTIONS' });
        dispatch({
            type: 'RESET_SEARCH_DATA',
            payload: {},
        });
        return () => dispatch({ type: 'SET_SELECTED_ROWS', payload: [] });
    },[]);

    useSortColumn({
        data: searchData,
        columns,
        activeColumns: activeDevicesColumns,
    });

    const onSelectedRow = (value) =>
        value && dispatch({ type: 'SET_BULK_ACTIONS', payload: value });

    const onColumnsChange = (value) => {
        appDispatch({ type: 'SET_ACTIVE_DEVICES_COLUMNS', payload: value });
    };

    const getTableHeader = (settinsIconCol) => (
        <>
            <Row>
                <DevicesHeader actions={bulkActions} />
                {settinsIconCol}
            </Row>
            <Row>
                <Col>
                    {!searchLoading && isEmpty(searchData) && (
                        <StyledAlert
                            id={emptyDevicesLabelId}
                            color="info"
                            fade={false}
                        >
                            {t('helpDesk$devices$emptyDeviceTable')}
                        </StyledAlert>
                    )}
                </Col>
            </Row>
        </>
    );
    return (
        <>
            {metadata.devices.columns && !cleaning ? (
                <PortalContainer>
                    <DevicesTable
                        data={searchData}
                        columnsName={columns}
                        activeColumns={activeDevicesColumns}
                        onSelectedRow={onSelectedRow}
                        onColumnsChange={onColumnsChange}
                        selectedColumnsKey={
                            helpdeskDevicesQueryTableColumnsSettings
                        }
                        defaultColumns={defaultDeviceColumns}
                        getTableHeader={getTableHeader}
                        enableDeviceDetails
                    />

                    {searchLoading && <LoadingSpinner />}
                </PortalContainer>
            ) : (
                <LoadingSpinner />
            )}
        </>
    );
};

export default Devices;
