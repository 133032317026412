import React from 'react';
import { PropTypes } from 'prop-types';
import ResyncOtpWithData from './ResyncOtpWithData';

const ResyncOtp = ({ dispatch }) => {
    return <ResyncOtpWithData dispatch={dispatch} />;
};

ResyncOtp.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default ResyncOtp;
