import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CardTemplate from '../../shared/CardTemplate';
import { reportsCardId } from '../../../constants';
import { AppStateContext } from '../../../context/AppContext';
import { TabsStateContext } from '../../../context/TabsContext';
import {
    isAllowed,
    reportsAllowedPrivileges,
} from '../../../utils/authorization';
import {
    StyledAlert,
    PortalContainer,
    AccentButton,
} from '../../../styles/common';
import Inventory from './Inventory';

const ComponentsList = ({ activeIndex }) => {
    switch (activeIndex) {
        case 0:
            return <Inventory />;
        default:
            return null;
    }
};

const Reports = () => {
    const { user } = useContext(AppStateContext);
    const { activeIndex, tabs } = useContext(TabsStateContext);
    const { t } = useTranslation();

    const { title, subtitle } = tabs[activeIndex] || {};

    if (
        user &&
        user.privileges &&
        !isAllowed({
            allowedPermissions: reportsAllowedPrivileges,
            permissions: user.privileges,
        })
    ) {
        return (
            <CardTemplate
                title={t(title)}
                subtitle={t(subtitle)}
                testId={reportsCardId}
            >
                <PortalContainer>
                    <StyledAlert
                        id="invalid-assurance-alert"
                        color="warning"
                        fade={false}
                    >
                        {t('notAuthorized$higherAssuranceNecessary')}
                    </StyledAlert>
                    <a href={`https://${window.location.host}/operator`}>
                        <AccentButton outline color="primary" size="sm">
                            {t('notAuthorized$reloginBtn')}
                        </AccentButton>
                    </a>
                </PortalContainer>
            </CardTemplate>
        );
    }

    return (
        <CardTemplate
            title={t(title)}
            subtitle={t(subtitle)}
            testId={reportsCardId}
        >
            <ComponentsList activeIndex={activeIndex} />
        </CardTemplate>
    );
};

export default Reports;
