import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as Eye } from '../../images/eye.svg';

const StyledSecureEntryIcon = styled.div`
    height: 100%;
    cursor: pointer;
    & svg {
        font-size: 1.5em;
        font-weight: ${(props) => (props.isActive ? 'bold' : 'default')};
        margin-right: 10px;
        fill: ${({ isActive, theme }) =>
        isActive ? theme.colors.primary : '#c1c1c1'} !important;
        height: 100%;
    }
`;

const SecureEntryIcon = ({ onMouseDown, isActive, testId = 'sei-testid' }) => {
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    const release = isActive === true ? onMouseDown : () => {};
    return (
        <StyledSecureEntryIcon
            onMouseDown={onMouseDown}
            onMouseUp={release}
            onMouseOut={release}
            onBlur={release}
            id="secure-entry-icon"
            data-testid={testId}
            isActive={isActive}
        >
            <Eye />
        </StyledSecureEntryIcon>
    );
};

SecureEntryIcon.propTypes = {
    onMouseDown: PropTypes.func.isRequired,
    isActive: PropTypes.bool,
};

SecureEntryIcon.defaultProps = {
    isActive: false,
};

export default SecureEntryIcon;
