import React, { useRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { flowButtonsForwardId, flowButtonsBackwardId } from '../../constants';
import {
    PortalContainer,
    StyledNavIcon,
    AccentButton,
    StyledButton,
} from '../../styles/common';

const BackButton = styled(StyledButton)`
    margin-right: 0.5rem;
`;
const NextButton = styled(StyledButton)`
    margin-left: 0.5rem;
    margin-right: 0.5rem;
`;
const SkipButton = styled(AccentButton)`
    margin-left: 0.5rem;
`;
const ButtonsContainer = styled(Row)`
    min-width: 500px;
    margin-left: 0px !important;
`;
const CustomIcon = styled(StyledNavIcon)`
    margin-right: 0px;
    margin-left: 10px;
`;

const FlowButtons = ({
    mgmt,
    backButtonText = null,
    backButtonColor = 'primary',
    showBackButtonIcon = true,
}) => {
    const { t } = useTranslation();
    const mgmtRef = useRef(mgmt);
    mgmtRef.current = mgmt;

    const handleEnter = (e) => {
        const disabledNext =
            mgmtRef.current !== undefined &&
            mgmtRef.current.showNext !== undefined
                ? !mgmtRef.current.showNext
                : false;
        if (e.keyCode === 13 && !disabledNext && mgmtRef.current) {
            mgmtRef.current.next();
        }
    };

    useEffect(() => {
        document.addEventListener('keyup', handleEnter);
        return () => document.removeEventListener('keyup', handleEnter);
    },[]);

    const displayNext = !(mgmt !== undefined && mgmt.displayNext === false);

    return (
        <PortalContainer>
            <ButtonsContainer>
                <BackButton
                    aria-disabled={
                        mgmt !== undefined && mgmt.showBack !== undefined
                            ? !mgmt.showBack
                            : false
                    }
                    disabled={
                        mgmt !== undefined && mgmt.showBack !== undefined
                            ? !mgmt.showBack
                            : false
                    }
                    outline
                    color={backButtonColor}
                    onClick={mgmt ? mgmt.back : undefined}
                    size="sm"
                >
                    <span data-test-id={flowButtonsBackwardId}>
                        {showBackButtonIcon && (
                            <StyledNavIcon icon="chevron-left" />
                        )}
                        {`  ${
                            backButtonText || t('flowButtonsElementLabels$back')
                        }`}
                    </span>
                </BackButton>
                {displayNext && (
                    <NextButton
                        aria-disabled={
                            mgmt !== undefined && mgmt.showNext !== undefined
                                ? !mgmt.showNext
                                : false
                        }
                        disabled={
                            mgmt !== undefined && mgmt.showNext !== undefined
                                ? !mgmt.showNext
                                : false
                        }
                        outline
                        color="primary"
                        onClick={mgmt ? mgmt.next : undefined}
                        size="sm"
                    >
                        <span data-test-id={flowButtonsForwardId}>
                            {`${t('flowButtonsElementLabels$next')}  `}
                            <CustomIcon pad="start" icon="chevron-right" />
                        </span>
                    </NextButton>
                )}
                {mgmt && mgmt.skipEnabled && (
                    <SkipButton
                        outline
                        color="primary"
                        onClick={mgmt.clickSkip}
                        size="sm"
                    >
                        {`${t('flowButtonsElementLabels$skip')}`}
                        <CustomIcon pad="start" icon="chevron-right" />
                    </SkipButton>
                )}
            </ButtonsContainer>
        </PortalContainer>
    );
};

FlowButtons.propTypes = {
    mgmt: PropTypes.object,
};

FlowButtons.defaultProps = {
    mgmt: undefined,
};

export default FlowButtons;
