import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Progress } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FakeProgress from 'fake-progress';

const ProgressContainer = styled.div`
    text-align: left;
    display: block;
`;

const ProgressBar = styled(Progress)`
    .progress-bar {
        background-color: ${({ color, $transparent }) =>
        $transparent ? 'transparent' : color} !important;
    }
`;

const ProgressLabel = styled.p`
    padding-top: 10px;
`;

const ProgressTemplate = ({
    stage = '',
    isFake = false,
    isDone = false,
    gradient = false,
    timeout = 28250,
    loop = true,
    waitMessage = '',
}) => {
    const [stageCount, setStageCount] = useState(0);
    const [progressCount, setProgressCount] = useState(0);
    const [isAnimated, setIsAnimated] = useState(true);
    const isDoneRef = useRef(null);
    const { t } = useTranslation();

    const fakeProgress = useRef(new FakeProgress({
        timeConstant: timeout,
        autoStart: true,
    }));

    const timeoutRNG = (maxSec, minSec) =>
        Math.random() * (maxSec - minSec) + minSec;

    const makeColor = (value) => {
        const hue = ((1 - value / 120) * 120).toString(10);
        return `hsl(${120 - hue}, 60%, 40%)`;
    };

    const handleProgress = useCallback((p) => {
        if (loop && p >= 98 && p < 100) {
            setIsAnimated(false);
            fakeProgress.current.stop();
            fakeProgress.current.setProgress(0);
            setTimeout(() => {
                fakeProgress.current.start();
                setIsAnimated(true);
            }, 1000);
            return 0;
        }
        return fakeProgress.current.progress * 100;
    }, [loop]);

    useEffect(() => {
        const currentFakeProgress = fakeProgress.current;
        const progressInterval = () => {
            setInterval(() => {
                setProgressCount(handleProgress);
            }, 10);
        };
        
        isDoneRef.current = isDone;
        if(!isDoneRef.current) progressInterval()
        
        return () => {
            currentFakeProgress.end();
            isDoneRef.current  = true;
        };
    }, [isDone, loop, handleProgress]);

    useEffect(() => {
        const minSec = 1;
        const maxSec = 30;
        const timeout = timeoutRNG(minSec, maxSec) * 1000;
        const timer = setTimeout(() => {
            setStageCount(stageCount + 1);
        }, timeout);
        return () => clearTimeout(timer);
    }, [stageCount]);

    const colorValue = makeColor(progressCount);
    const showWaitMessage = isFake && waitMessage && progressCount >= 99.5;
    return (
        <ProgressContainer>
            <ProgressBar
                animated
                $transparent={!isAnimated}
                value={isFake ? progressCount : 100}
                color={gradient ? colorValue : 'primary'}
            />
            {stage && (
                <ProgressLabel>
                    {`${t('sharedLabels$progressTemplate$stage')} ${stage}`}
                </ProgressLabel>
            )}
            {showWaitMessage && <p>{waitMessage}</p>}
        </ProgressContainer>
    );
};

export default ProgressTemplate;
