import React, { useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { postResetPin } from '../../utils/walkways';
import ProgressTemplate from '../shared/ProgressTemplate';
import { AppDispatchContext, AppStateContext } from '../../context/AppContext';
import CardEnrollLayout from '../shared/CardEnrollLayout';
import { PROGRESS_BAR_DONE_DELAY } from '../../constants';
import { useDidMount } from '../../utils/hooks';

const ResetPin = ({ device, mgmt = null, reader }) => {
    const dispatch = useContext(AppDispatchContext);
    const { webpcscMode } = useContext(AppStateContext);
    const { t } = useTranslation();

    const [, setResult] = useState({});
    const [showNext, setShowNext] = useState(false);
    const [stage, setStage] = useState(t('deviceActionsStage$resetPin'));

    const setProgress = (hook, data) => {
        if (hook === 'stage') {
            setStage(data);
        }
    };

    const setResetResult = async (result) => {
        setResult(result);
        setShowNext(true);
        if (result.pinResetComplete === true) {
            setTimeout(mgmt.next, PROGRESS_BAR_DONE_DELAY);
        }
    };

    useDidMount(() => {
        postResetPin(
            setResetResult,
            setProgress,
            dispatch,
            {
                ...device,
                reader,
            },
            webpcscMode,
        );
    });

    return (
        <CardEnrollLayout
            cardTitle={t('cardTitles$resetPin')}
            title={t('updateProgress')}
            alert={t('enrollTemplateAlertDefault')}
            showNext={showNext}
            mgmt={mgmt}
        >
            <ProgressTemplate
                isFake
                isDone={showNext}
                stage={`${t('pivActionInitialState')} ${stage}`}
            />
        </CardEnrollLayout>
    );
};

ResetPin.propTypes = {
    mgmt: PropTypes.object,
    device: PropTypes.object.isRequired,
    reader: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

ResetPin.defaultProps = {
    mgmt: null,
};

export default ResetPin;
