import React from 'react';
import styled from 'styled-components';
import { icons } from '../../utils/mappings';

export const isSorting = (array) => {
    let sorting = false;
    array.map((elem) => {
        if (elem.sortedIndex > -1) {
            sorting = true;
        }
        return elem;
    });
    return sorting;
};

const StyledSortIcon = styled.img`
    height: ${({ size }) => size}px;
    width: ${({ hide }) => (hide ? '1px' : 'auto')};
    cursor: pointer;
    opacity: ${({ hide }) => (hide ? 0 : 1)};
    margin-left: ${({ hide }) => (hide ? 0 : 4)}px;
    transform: scale(0.8) !important;
`;

const SortIcon = ({
    size = 16,
    hide = false,
    isSorted = false,
    isSortedDesc = false,
}) => {
    let icon = 'sortInactive';
    if (isSorted) {
        icon = isSortedDesc ? 'sortDown' : 'sortUp';
    }
    return (
        <StyledSortIcon
            src={icons[icon]}
            hide={hide}
            size={size}
            alt="Sort column"
        />
    );
};

export default SortIcon;
