import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import uniqueId from 'lodash.uniqueid';
import { credetialIconMapping } from '../../../utils/mappings';
import { StyledIcon } from '../../../styles/common';

const CredentialIcon = styled(StyledIcon)`
    float: right;
    margin-top: 8px;
    margin-left: 3px;
    padding:1px;
    min-width:24px;
    min-height:24px
`;

const CredentialLogo = ({ credential }) => {
    const { t } = useTranslation();
    const icons = [];
    const { type } = credential || {};
    if (type && !icons.includes(type.toLowerCase())) {
        icons.push(type.toLowerCase());
    }

    return (
        <span>
            {icons.length > 0 &&
                icons.map(
                    (icon) =>
                        credetialIconMapping[icon] && (
                            <CredentialIcon
                                key={uniqueId(icon)}
                                alt={credetialIconMapping[icon]}
                                src={credetialIconMapping[icon]}
                                title={t(`mappingsLabels$credetialIconTooltip$${icon}`)}
                            />
                        ),
                )}
        </span>
    );
};

CredentialLogo.propTypes = {
    credential: PropTypes.object.isRequired,
};

CredentialLogo.defaultProps = {};

export default CredentialLogo;
