import React from 'react';
import { PropTypes } from 'prop-types';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
    PortalContainer,
    StyledNavIcon,
    StyledTableRow,
    StyledTableBody,
    StyledTableHeadRow,
    StyledTableHead,
    StyledAlert,
    AccentButton,
    StyledTable,
} from '../../../styles/common';

const LocalSettingsWithData = ({ tableValues, checkDiagnosis, showAlert }) => {
    const { t } = useTranslation();

    return (
        <PortalContainer>
            <Row>
                <Col>
                    <AccentButton
                        outline
                        color="primary"
                        size="sm"
                        onClick={checkDiagnosis}
                        id="settings-btn"
                    >
                        <StyledNavIcon icon="laptop" />
                        {t('localSettingsCardLabels$stateless$button')}
                    </AccentButton>
                </Col>
            </Row>
            {showAlert && (
                <StyledAlert color="success">
                    {t('localSettingsSuccessMsg')}
                </StyledAlert>
            )}
            <StyledTable hover borderless className="mTable">
                <StyledTableHead>
                    <StyledTableHeadRow>
                        <div style={{ width: '300px' }}>
                            {t(
                                'localSettingsCardLabels$stateless$columns$component',
                            )}
                        </div>
                        <div>
                            {t(
                                'localSettingsCardLabels$stateless$columns$version',
                            )}
                        </div>
                    </StyledTableHeadRow>
                </StyledTableHead>
                <StyledTableBody>
                    {tableValues.map((arr) => (
                        <StyledTableRow key={`settingsRow:${arr[0]}:${arr[1]}`}>
                            <div style={{ width: '300px' }}>
                                {arr[0]}
                            </div>
                            <div>{arr[1]}</div>
                        </StyledTableRow>
                    ))}
                </StyledTableBody>
            </StyledTable>
        </PortalContainer>
    );
};

LocalSettingsWithData.propTypes = {
    tableValues: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    showAlert: PropTypes.bool,
    checkDiagnosis: PropTypes.func.isRequired,
};

LocalSettingsWithData.defaultProps = {
    tableValues: [],
    showAlert: false,
};

export default LocalSettingsWithData;
