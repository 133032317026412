import React from 'react';
import { Pagination, PaginationItem, PaginationLink, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledNavIcon } from '../../styles/common';

const StyledPagination = styled(Pagination)`
    display: inline-block !important;
    .page-item:not(.disabled) > .page-link {
        color: ${({ theme }) => theme.colors.accent} !important;
    }
`;

const StyledPaginationItem = styled(PaginationItem)`
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    display: inline-flex;
    height: 32px !important;
`;

const StyledPaginationLink = styled(PaginationLink)`
    border-radius: 0.25rem !important;
    padding-right: 2px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
`;

const StyledPageInput = styled(Input)`
    display: inline-block;
    margin-left: 0.75rem;
    width: 60px !important;
    height: 32px !important;
    &:focus {
        box-shadow: none !important;
    }
`;

const StyledPageCount = styled.div`
    margin: 0 auto;
    padding-left: 0.5rem;
    align-self: center;
`;

const TablePagination = ({
    gotoPage = null,
    previousPage = () => {},
    nextPage = () => {},
    canPreviousPage = false,
    canNextPage = false,
    pageIndex = 0,
    pageCount = 0,
    totalCount = 0,
}) => {
    const { t } = useTranslation();
    return (
        <StyledPagination>
            {gotoPage && (
                <StyledPaginationItem
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                >
                    <StyledPaginationLink first>
                        <StyledNavIcon icon="angle-double-left" />
                    </StyledPaginationLink>
                </StyledPaginationItem>
            )}
            <StyledPaginationItem
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
            >
                <StyledPaginationLink previous>
                    <StyledNavIcon icon="angle-left" />
                </StyledPaginationLink>
            </StyledPaginationItem>
            <StyledPaginationItem
                onClick={() => nextPage()}
                disabled={!canNextPage}
            >
                <StyledPaginationLink next>
                    <StyledNavIcon icon="angle-right" />
                </StyledPaginationLink>
            </StyledPaginationItem>
            {gotoPage && (
                <StyledPaginationItem
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                >
                    <StyledPaginationLink last>
                        <StyledNavIcon icon="angle-double-right" />
                    </StyledPaginationLink>
                </StyledPaginationItem>
            )}
            <StyledPaginationItem>
                <StyledPageCount htmlFor="pageSize">
                    {t('tablePagination$page')}
                </StyledPageCount>
                {gotoPage ? (
                    <StyledPageInput
                        type="number"
                        aria-label="pageSize"
                        name="pageSize"
                        min="1"
                        max={pageCount}
                        placeholder={pageIndex + 1}
                        onChange={(e) => gotoPage(Number(e.target.value - 1))}
                    />
                ) : (
                    <StyledPageCount>{pageIndex + 1}</StyledPageCount>
                )}
                <StyledPageCount>
                    {t('tablePagination$pageCountOf')} {pageCount || 1} (
                    {totalCount} {t('tablePagination$entries')})
                </StyledPageCount>
            </StyledPaginationItem>
        </StyledPagination>
    );
};

export default TablePagination;
