import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import AssociateUserWithData from './AssociateUserWithData';
import { associateUserCardId } from '../../../constants';
import CardLayout from '../../shared/CardLayout';
import { useLazyFetchData, useDidMount } from '../../../utils/hooks';
import { EnrollingStateContext } from '../../../context/EnrollingContext';

const AssociateUser = ({ mgmt }) => {
    const { t } = useTranslation();
    const [showNext, setShowNext] = useState(false);
    const [validUser, setValidUser] = useState(null);
    const { form } = useContext(EnrollingStateContext);

    const [userCallback, { loading, data }] = useLazyFetchData({
        endpoint: `/delegation/associate`,
    });

    useDidMount(() => {
        const resetMgmtState = () => {
            if (mgmt !== undefined && mgmt.resetMgmtState !== undefined) {
                mgmt.resetMgmtState(0);
            }
        };
        resetMgmtState();
    });

    const checkValidUser = () => {
        userCallback({ user: form.values.userId });
    };

    useEffect(() => {
        if (showNext && data) {
            data.length ? mgmt.next() : setValidUser(false);
        }
    }, [data, showNext, mgmt]);

    useEffect(() => {
        setValidUser(null);
    }, [form.values.userId]);

    return (
        <CardLayout
            title={t('cardTitles$associateSupport')}
            subtitle={t('bannerLabels$delegations$info')}
            showNext={showNext && validUser !== false && !loading}
            showBack={false}
            mgmt={{ ...mgmt, next: checkValidUser }}
            testId={associateUserCardId}
        >
            {/* todo: get title from props */}
            <AssociateUserWithData
                validUser={validUser}
                setShowNext={setShowNext}
                showNext={showNext}
                loading={loading}
            />
        </CardLayout>
    );
};

export default AssociateUser;

AssociateUser.propTypes = {
    mgmt: PropTypes.object.isRequired,
};
