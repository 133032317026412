import { paths, helpDeskPaths, reportViewBase } from '../constants';

import usb from '../images/usb.svg';
import key from '../images/key.svg';
import mobile from '../images/mobile.svg';
import card from '../images/card.svg';
import cert from '../images/cert.svg';
import token from '../images/token.png';
import otp from '../images/otp.svg';
import u2f from '../images/u2f.png';
import push from '../images/push.svg';
import emailotp from '../images/emailotp.svg';
import smsotp from '../images/smsotp.svg';
import password from '../images/password.svg';
import tmppasswword from '../images/tmppasswword.svg';
import baseline_password from '../images/baseline_password.png';
import question_mark from '../images/question_mark.png';
import yubikeyPress from '../images/yubikey_press.png';
import unknown from '../images/unknown_credential.png';
import sort from '../images/sort.png';
import sortUp from '../images/sort_up.png';
import sortDown from '../images/sort_down.png';
import sortInactive from '../images/sort_inactive.png';
import laptop from '../images/round_laptop_windows_black_24dp.png';
import fido2 from '../images/fido-passkey.jpg';

export const icons = {
    sort,
    sortUp,
    sortDown,
    sortInactive,
};

export const copyBtnStatusMap = {
    failed: `failed`,
    copied: `copied`,
    copy: `copy`,
};

export const credetialIconMapping = {
    whfb: key,
    x509: cert,
    apn: push,
    mspush: push,
    totp: otp,
    hotp: otp,
    u2f,
    yubikeyPress,
    default: unknown,
    tmppasswword,
    emailotp,
    smsotp,
    password,
    fido2,
    pkirsa2048: cert
};

export const deviceSubTypes = {
    yubikey4: {
        label: 'mappingsLabels$deviceSubTypes$yubikey4$label',
    },
    yubikey5: {
        label: 'mappingsLabels$deviceSubTypes$yubikey5$label',
    },
    yubikey5_3: {
        label: 'mappingsLabels$deviceSubTypes$yubikey5_3$label',
    },
    yubikeyneo: {
        label: 'mappingsLabels$deviceSubTypes$yubikeyneo$label',
    },
    ocsv8: {
        label: 'mappingsLabels$deviceSubTypes$ocsv8$label',
    },
    IDPrime2_1: {
        label: 'mappingsLabels$deviceSubTypes$IDPrime2_1$label',
    },
    idprimemd830: {
        label: 'mappingsLabels$deviceSubTypes$idprimemd830$label',
    },
    idprimemd930: {
        label: 'mappingsLabels$deviceSubTypes$idprimemd930$label',
    },
    jcop: {
        label: 'mappingsLabels$deviceSubTypes$jcop$label',
    },
    jcop3: {
        label: 'mappingsLabels$deviceSubTypes$jcop3$label',
    },
    vsc: {
        label: 'mappingsLabels$deviceSubTypes$vsc$label',
    },
    hello: {
        label: 'mappingsLabels$deviceSubTypes$hello$label',
    },
    msauth: {
        label: 'mappingsLabels$deviceSubTypes$msauth$label',
    },
    feitian: {
        label: 'mappingsLabels$deviceSubTypes$feitian$label',
    },
};

export const deviceTypes = {
    smartcard: {
        credentialingIcons: [cert],
        imgLink: card,
        imgAlt: 'mappingsLabels$deviceTypes$smartcard$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$smartcard$tooltip',
        label: 'mappingsLabels$deviceTypes$smartcard$label',
    },
    windows_hello: {
        credentialingIcons: [key],
        imgLink: laptop,
        imgAlt: 'mappingsLabels$deviceTypes$hello$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$hello$tooltip',
        label: 'mappingsLabels$deviceTypes$hello$label',
    },
    apn: {
        credentialingIcons: [push, otp],
        imgLink: mobile,
        imgAlt: 'mappingsLabels$deviceTypes$apn$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$apn$tooltip',
        label: 'mappingsLabels$deviceTypes$apn$label',
    },
    yubikey: {
        credentialingIcons: [u2f, otp, cert],
        imgLink: usb,
        imgAlt: 'mappingsLabels$deviceTypes$yubikey$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$yubikey$tooltip',
        label: 'mappingsLabels$deviceTypes$yubikey$label',
    },
    totp: {
        credentialingIcons: [otp],
        imgLink: token,
        imgAlt: 'mappingsLabels$deviceTypes$totp$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$totp$tooltip',
        label: 'mappingsLabels$deviceTypes$totp$label',
    },
    hotp: {
        credentialingIcons: [otp],
        imgLink: token,
        imgAlt: 'mappingsLabels$deviceTypes$hotp$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$hotp$tooltip',
        label: 'mappingsLabels$deviceTypes$hotp$label',
    },
    mobileOtpToken: {
        credentialingIcons: [otp],
        imgLink: mobile,
        imgAlt: 'mappingsLabels$deviceTypes$mobileOtpToken$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$mobileOtpToken$tooltip',
        label: 'mappingsLabels$deviceTypes$mobileOtpToken$label',
    },
    unqualified_ctap: {
        credentialingIcons: [fido2],
        imgLink: usb,
        imgAlt: 'mappingsLabels$deviceTypes$unqualified_ctap$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$unqualified_ctap$tooltip',
        label: 'mappingsLabels$deviceTypes$unqualified_ctap$label',
    },
    windows_ctap: {
        credentialingIcons: [fido2],
        imgLink: laptop,
        imgAlt: 'mappingsLabels$deviceTypes$windows_ctap$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$windows_ctap$tooltip',
        label: 'mappingsLabels$deviceTypes$windows_ctap$label',
    }, unqualified_msauth: {
        credentialingIcons: [push],
        imgLink: mobile,
        imgAlt: 'mappingsLabels$deviceTypes$unqualified_msauth$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$unqualified_msauth$tooltip',
        label: 'mappingsLabels$deviceTypes$unqualified_msauth$label',
    }, android_msauth: {
        credentialingIcons: [push],
        imgLink: mobile,
        imgAlt: 'mappingsLabels$deviceTypes$android_msauth$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$android_msauth$tooltip',
        label: 'mappingsLabels$deviceTypes$android_msauth$label',
    }, ios_msauth: {
        credentialingIcons: [push],
        imgLink: mobile,
        imgAlt: 'mappingsLabels$deviceTypes$ios_msauth$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$ios_msauth$tooltip',
        label: 'mappingsLabels$deviceTypes$ios_msauth$label',
    },
    default: {
        credentialingIcons: [unknown],
        imgLink: key,
        imgAlt: 'mappingsLabels$deviceTypes$default$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$default$tooltip',
        label: 'mappingsLabels$deviceTypes$default$label',
    },
};

export const emergencyTypes = {
    tmpPwd: {
        credentialingIcons: [tmppasswword],
        imgLink: baseline_password,
        imgAlt: 'mappingsLabels$deviceTypes$default$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$default$tooltip',
        label: 'mappingsLabels$TmpPwd$default$label',
    },
    securityQuestion: {
        credentialingIcons: [unknown],
        imgLink: question_mark,
        imgAlt: 'mappingsLabels$deviceTypes$default$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$default$tooltip',
        label: 'mappingsLabels$securityQuestion$default$label',
    },
    password: {
        credentialingIcons: [unknown],
        imgLink: baseline_password,
        imgAlt: 'mappingsLabels$deviceTypes$default$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$default$tooltip',
        label: 'mappingsLabels$password$default$label',
    },
    smsotp: {
        credentialingIcons: [unknown],
        imgLink: otp,
        imgAlt: 'mappingsLabels$deviceTypes$default$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$default$tooltip',
        label: 'mappingsLabels$smsotp$default$label',
    },
    emailotp: {
        credentialingIcons: [unknown],
        imgLink: otp,
        imgAlt: 'mappingsLabels$deviceTypes$default$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$default$tooltip',
        label: 'mappingsLabels$emailotp$default$label',
    },
    tmppassword: {
        credentialingIcons: [unknown],
        imgLink: baseline_password,
        imgAlt: 'mappingsLabels$deviceTypes$default$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$default$tooltip',
        label: 'mappingsLabels$tmppassword$default$label',
    },
    totp: {
        credentialingIcons: [unknown],
        imgLink: otp,
        imgAlt: 'mappingsLabels$deviceTypes$default$imgAlt',
        tooltip: 'mappingsLabels$deviceTypes$default$tooltip',
        label: 'mappingsLabels$totp$default$label',
    }
};

export const actionTypes = {
    'CHANGE-PIN': {
        actionIcon: 'random',
        text: 'mappingsLabels$actionTypes$changePin$text',
        href: paths.changePin,
        effect: 'link',
    },
    details: {
        actionIcon: 'info-circle',
        text: 'mappingsLabels$actionTypes$details$text',
        effect: 'modal',
    },
    'PUK': {
        actionIcon: 'info-circle',
        text: 'mappingsLabels$actionTypes$retrievePuk$text',
        effect: 'modal',
    },
    'PUK_CHALLENGE': {
        actionIcon: 'info-circle',
        text: 'mappingsLabels$actionTypes$retrieveCR$text',
        effect: 'modal',
    },
    'SUSPENDED': {
        actionIcon: 'ban',
        text: 'mappingsLabels$actionTypes$suspend$text',
        href: paths.disable,
        effect: 'link',
    },
    'ACTIVE': {
        actionIcon: 'play',
        text: 'mappingsLabels$actionTypes$active$text',
        href: paths.enable,
        effect: 'link',
    },
    rename: {
        actionIcon: 'pencil-alt',
        text: 'mappingsLabels$actionTypes$rename$text',
        href: '',
        effect: 'link',
    },
    'RESET-PIN': {
        actionIcon: 'key',
        text: 'mappingsLabels$actionTypes$resetPin$text',
        href: paths.resetpin,
        effect: 'link',
    },
    'RENEWAL': {
        actionIcon: 'clock',
        text: 'mappingsLabels$actionTypes$update$text',
        href: paths.update,
        effect: 'link',
    },
    test: {
        actionIcon: 'lask',
        text: 'mappingsLabels$actionTypes$test$text',
        href: '',
        effect: 'link',
    },
    resyncOtp: {
        actionIcon: 'sync',
        text: 'mappingsLabels$actionTypes$resyncOtp$text',
        href: paths.resyncOtp,
        effect: 'link',
    },
    validateDeviceWithPin: {
        actionIcon: 'check',
        text: 'mappingsLabels$actionTypes$validateDeviceWithPin$text',
        href: paths.validateDeviceWithPin,
        effect: 'link',
    },
    validateDeviceWithoutPin: {
        actionIcon: 'check',
        text: 'mappingsLabels$actionTypes$validateDeviceWithoutPin$text',
        href: paths.validateDeviceWithoutPin,
        effect: 'link',
    },
    'REVOKED': {
        actionIcon: 'check',
        text: 'mappingsLabels$actionTypes$revoke$text',
        href: paths.revoke,
        effect: 'link',
    },
    unassign: {
        actionIcon: 'check',
        text: 'mappingsLabels$actionTypes$unassign$text',
        href: paths.unassign,
        effect: 'link',
    },
    resetCounter: {
        actionIcon: 'info-circle',
        text: 'mappingsLabels$actionTypes$resetCounter$text',
        href: paths.resetFailcounter,
        effect: 'link',
    },
};

export const kioskActionToDeviceAction = {
    enable: 'ACTIVE',
    disable: 'SUSPENDED',
    changePin: 'CHANGE-PIN',
    resetPin: 'RESET-PIN',
    update: 'RENEWAL'
}

export const helpDeskActionTypes = {
    'CHANGE-PIN': {
        text: 'mappingsLabels$actionTypes$changePin$text',
        href: helpDeskPaths.changePin,
        effect: 'link',
    },
    details: {
        text: 'mappingsLabels$actionTypes$details$text',
        effect: 'modal',
    },
    'PUK': {
        actionIcon: 'info-circle',
        text: 'mappingsLabels$actionTypes$retrievePuk$text',
        effect: 'modal',
    },
    'PUK_CHALLENGE': {
        actionIcon: 'info-circle',
        text: 'mappingsLabels$actionTypes$retrieveCR$text',
        effect: 'modal',
    },
    'SUSPENDED': {
        actionIcon: 'ban',
        text: 'mappingsLabels$actionTypes$suspend$text',
        href: helpDeskPaths.disable,
        effect: 'link',
    },
    'ACTIVE': {
        actionIcon: 'play',
        text: 'mappingsLabels$actionTypes$active$text',
        href: helpDeskPaths.enable,
        effect: 'link',
    },
    rename: {
        text: 'mappingsLabels$actionTypes$rename$text',
        href: helpDeskPaths.rename,
        effect: 'link',
    },
    'RESET-PIN': {
        text: 'mappingsLabels$actionTypes$resetPin$text',
        href: helpDeskPaths.resetpin,
        effect: 'link',
    },
    'RENEWAL': {
        text: 'mappingsLabels$actionTypes$update$text',
        href: helpDeskPaths.update,
        effect: 'link',
    },
    resyncOtp: {
        text: 'mappingsLabels$actionTypes$resyncOtp$text',
        href: helpDeskPaths.resyncOtp,
        effect: 'link',
    },
    validateDeviceWithPin: {
        text: 'mappingsLabels$actionTypes$validateDeviceWithPin$text',
        href: helpDeskPaths.validateDeviceWithPin,
        effect: 'link',
    },
    validateDeviceWithoutPin: {
        text: 'mappingsLabels$actionTypes$validateDeviceWithoutPin$text',
        href: helpDeskPaths.validateDeviceWithoutPin,
        effect: 'link',
    },
    'REVOKED': {
        text: 'mappingsLabels$actionTypes$revoke$text',
        href: helpDeskPaths.revoke,
        effect: 'link',
    },
    unassign: {
        text: 'mappingsLabels$actionTypes$unassign$text',
        href: helpDeskPaths.unassign,
        effect: 'link',
    },
    'RESET': {
        text: 'mappingsLabels$actionTypes$recycle$text',
        href: helpDeskPaths.recycle,
        effect: 'link',
    },
    addDevice: {
        text: 'mappingsLabels$actionTypes$addDevice$text',
        href: helpDeskPaths.addDevice,
        effect: 'link',
    },
    usersDetails: {
        text: 'mappingsLabels$actionTypes$details$text',
        href: helpDeskPaths.usersDetails,
        effect: 'link',
    },
    resetCounter: {
        actionIcon: 'info-circle',
        text: 'mappingsLabels$actionTypes$resetCounter$text',
        href: helpDeskPaths.resetFailcounter,
        effect: 'link',
    },
    generate: {
        text: 'helpDesk$action$generate',
    }
};

export const reportsActionTypes = {
    reportDownload: {
        actionIcon: 'info-circle',
        text: 'mappingsLabels$reportsActionTypes$download$text',
        effect: 'modal',
    },
    reportView: {
        text: 'mappingsLabels$reportsActionTypes$view$text',
        href: (reportName) => `${reportViewBase}/${reportName}`,
        effect: 'dynamicLink',
    },
    reportDelete: {
        text: 'mappingsLabels$reportsActionTypes$delete$text',
        effect: 'modal',
    },
};
export const credentialActionTypes = {
    'SUSPENDED': {
        text: 'mappingsLabels$credentialActionTypes$suspend$text',
        effect: 'api',
        apiType: 'PUT'
    },
    'ACTIVE': {
        text: 'mappingsLabels$credentialActionTypes$active$text',
        effect: 'api',
        apiType: 'PUT'
    },
    'REVOKED': {
        text: 'mappingsLabels$credentialActionTypes$revoke$text',
        effect: 'api',
        apiType: 'PUT'
    },
    'CHANGE-PIN': {
        text: 'mappingsLabels$actionTypes$changePin$text',
        href: paths.changePin,
        effect: 'link',
    },
    'RESET-PIN': {
        text: 'mappingsLabels$actionTypes$resetPin$text',
        href: helpDeskPaths.resetpin,
        effect: 'link',
    },
    resyncOtp: {
        text: 'mappingsLabels$actionTypes$resyncOtp$text',
        href: paths.resyncOtp,
        effect: 'link',
    },
    validateDeviceWithPin: {
        text: 'mappingsLabels$actionTypes$validateDeviceWithPin$text',
        href: paths.validateDeviceWithPin,
        effect: 'link',
    },
    validateDeviceWithoutPin: {
        text: 'mappingsLabels$actionTypes$validateDeviceWithoutPin$text',
        href: paths.validateDeviceWithoutPin,
        effect: 'link',
    },
    resetCounter: {
        text: 'mappingsLabels$actionTypes$resetCounter$text',
        href: paths.resetFailcounter,
        effect: 'link',
    },
};
export default deviceTypes;
