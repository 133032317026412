import React, { useEffect, useContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';
import { FormGroup, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import UpdateQuestionWithData from './UpdateQuestionWithData';
import { EnrollingDispatchContext } from '../../../context/EnrollingContext';
import { StyledNavIcon, PaddingButton } from '../../../styles/common';
import { useDidMount } from '../../../utils/hooks';

const questionPolicyDefault = {
    isEmpty: {
        regex: /^[a-zA-Z0-9].*$/,
        valid: false,
    },
};

const UpdateQuestion = ({
    registerForm,
    serverComponent,
    goToResetQuestion,
}) => {
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const { t } = useTranslation();
    const {
        details: { questions, serial },
    } = serverComponent;

    const initialState = {
        clearText: {},
        questionForm: {},
        validity: {},
    };
    for (let i = 0; i < questions.length; i++) {
        initialState.clearText[i] = false;
        initialState.validity[i] = {};
        initialState.questionForm[i] = { question: questions[i] };
    }

    const reducer = (state, action) => {
        let newForm;
        switch (action.type) {
            case 'SET_CLEAR_TEXT':
                return {
                    ...state,
                    clearText: {
                        ...state.clearText,
                        [action.questionNumber]:
                            !state.clearText[action.questionNumber],
                    },
                };
            case 'UPDATE_FORM':
                newForm = { ...state.questionForm };
                newForm[action.questionNumber][action.name] = action.value;
                return {
                    ...state,
                    questionForm: newForm,
                };
            case 'CHECK_VALIDITY': {
                const newValidity = {};
                for (
                    let i = 0;
                    i < Object.keys(state.questionForm).length;
                    i++
                ) {
                    newValidity[i] = {
                        answer: null,
                        confirmAnswer: null,
                    };
                    if (state.questionForm[i].confirmAnswer)
                        newValidity[i].confirmAnswer =
                            state.questionForm[i].answer ===
                            state.questionForm[i].confirmAnswer;
                    if (state.questionForm[i].answer)
                        newValidity[i].answer = questionPolicyDefault.isEmpty.regex.test(
                            state.questionForm[i].answer,
                        );
                }
                return {
                    ...state,
                    validity: newValidity,
                };
            }
            default:
                return { ...state };
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        dispatch({ type: 'CHECK_VALIDITY' });
    }, [state.questionForm]);

    const questionFormValidity =
        Object.values(state.validity).every(
            (value) =>
                ['answer', 'confirmAnswer'].every(
                    (name) => value[name] === null,
                ) ||
                ['answer', 'confirmAnswer'].every(
                    (name) => value[name] === true,
                ),
        ) &&
        Object.values(state.questionForm).some((value) =>
            ['answer', 'confirmAnswer'].some((name) => value[name]),
        );
    useEffect(() => {
        enrollingDispatch({
            type: 'SET_EXTRACT_VALUE',
            name: 'questionForm',
            value: questionFormValidity,
            validator: (value) => value,
        });
    }, [questionFormValidity, enrollingDispatch]);

    const updateDevice = () => {
        for (let i = 0; i < questions.length; i++) {
            ['question', 'answer', 'confirmAnswer'].forEach((name) => {
                enrollingDispatch({
                    type: 'ADD_TO_DEVICE',
                    name: `${name}${i}`,
                    value: state.questionForm[i]
                        ? state.questionForm[i][name]
                        : undefined,
                });
            });
        }
    };

    useDidMount(() => {
        registerForm({ questionForm: (value) => value });
        updateDevice();
        enrollingDispatch({
            type: 'ADD_TO_DEVICE',
            name: 'totalQuestions',
            value: questions.length,
        });
        enrollingDispatch({
            type: 'ADD_TO_DEVICE',
            name: 'questionSerial',
            value: serial,
        });
    });

    const clearTextSwitch = (questionNumber) => {
        dispatch({
            type: 'SET_CLEAR_TEXT',
            questionNumber,
        });
    };

    const updateForm = (value, name, questionNumber) => {
        dispatch({ type: 'UPDATE_FORM', value, name, questionNumber });
        enrollingDispatch({
            type: 'ADD_TO_DEVICE',
            name: `${name}${questionNumber}`,
            value,
        });
    };

    const questionComponents = [];
    for (let i = 0; i < questions.length; i++) {
        if (state.validity[i])
            questionComponents.push(
                <UpdateQuestionWithData
                    questions={questions}
                    questionNumber={i}
                    clearText={state.clearText[i]}
                    clearTextSwitch={() => clearTextSwitch(i)}
                    questionForm={state.questionForm}
                    updateForm={updateForm}
                    validity={state.validity}
                />,
            );
    }

    return (
        <>
            <FormGroup row>
                <Col xs>
                    <PaddingButton
                        onClick={goToResetQuestion}
                        outline
                        color="primary"
                        size="sm"
                    >
                        <StyledNavIcon icon="eraser" />
                        {t('formLabels$question$reset')}
                    </PaddingButton>
                </Col>
            </FormGroup>
            {questionComponents}
        </>
    );
};

UpdateQuestion.propTypes = {
    registerForm: PropTypes.func.isRequired,
    serverComponent: PropTypes.func.isRequired,
};

export default UpdateQuestion;
