import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { actionTypes } from '../../../utils/mappings';
import { AppDispatchContext } from '../../../context/AppContext';
import ActionButton from './ActionButton';

const ActionOption = ({ option, device, kioskAction }) => {
    const dispatch = useContext(AppDispatchContext);
    const { t } = useTranslation();
    const history = useHistory();

    if (!actionTypes[kioskAction]) {
        return null;
    }
    const title = t(actionTypes[kioskAction].text);
    const {
        location: { pathname },
    } = history || {};
    return (
        <ActionButton
            title={title}
            icon="chevron-right"
            id="kiosk-action-button"
            onClick={() => {
                dispatch({
                    type: 'SET_DEVICE',
                    device,
                    goBackLink: pathname,
                });
                history.push(option.href);
            }}
        />
    );
};

ActionOption.propTypes = {
    option: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    kioskAction: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
};

export default ActionOption;
