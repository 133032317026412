import React, { useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { postChangePinPiv } from '../../utils/walkways';
import {
    PROGRESS_BAR_DONE_DELAY,
    ucmsConstants,
} from '../../constants';
import ProgressTemplate from '../shared/ProgressTemplate';
import { AppDispatchContext, AppStateContext } from '../../context/AppContext';
import CardEnrollLayout from '../shared/CardEnrollLayout';
import { useDidMount } from '../../utils/hooks';

const ChangePinPiv = ({ title, mgmt, device, reader }) => {
    const dispatch = useContext(AppDispatchContext);
    const { webpcscMode } = useContext(AppStateContext);
    const { t } = useTranslation();
    const [showNext, setShowNext] = useState(false);

    const cardTitle = title || t('cardTitles$updateDevice');
    const setResult = async (json) => {
        setShowNext(true);
        if (json.stage === ucmsConstants.done && json.step === ucmsConstants.done) {
            setTimeout(mgmt.next, PROGRESS_BAR_DONE_DELAY);
        }
    };

    useDidMount(() => {
        const { oldPin, newPin, newPinForm, user } = device;
        let { ...data } = device;

        data = {
            ...device,
            oldPin,
            newPin,
            newPinForm,
            reader,
        };
        data.user = user || undefined;
        postChangePinPiv(setResult, undefined, dispatch, data, webpcscMode);
    });

    return (
        <CardEnrollLayout
            cardTitle={cardTitle}
            title={t('updateProgress')}
            alert={t('enrollTemplateAlertDefault')}
            showNext={showNext}
            mgmt={mgmt}
        >
            <ProgressTemplate
                isFake
                isDone={showNext}
                stage={t('deviceActionsStage$changePin')}
            />
        </CardEnrollLayout>
    );
};

ChangePinPiv.propTypes = {
    title: PropTypes.string.isRequired,
    mgmt: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    reader: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default ChangePinPiv;
