import React, { useContext, useEffect, useRef } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { library } from '@fortawesome/fontawesome-svg-core';
import styled, { ThemeProvider } from 'styled-components';
import isEmpty from 'lodash.isempty';
import * as log from 'loglevel';
import * as Sentry from '@sentry/browser';
import queryString from 'query-string';
import { AppStateContext, AppDispatchContext } from '../../context/AppContext';
import {
    BrandingDispatchContext,
    BrandingStateContext,
} from '../../context/BrandingContext';
import {
    paths,
    helpDeskPaths,
    REMAINING_TIME_BEFORE_IDLE_TIMEOUT_ALERT,
    subTabsPaths,
    reportsPaths,
    settingsEndpoint,
    KIOSK_MODE,
    USER_MODE,
    brandingJsonPath,
    redirectUrl,
    navInsightsId,
    navReportsId
} from '../../constants';
import {
    useFetchData,
    AUTHENTICATION_ERROR_CODES,
    useLocalStorage,
    useDidMount,
} from '../../utils/hooks';
import ComponentRoute from './ComponentRoute';
import GrowlerError from '../shared/GrowlerError';
import icons from '../../styles/icons';
import Header, { headerTabs } from './Header';
import Footer from './Footer';
import IdentitiesCard from '../cardLibrary/Identities';
import InsightsCard from '../cardLibrary/Insights';
import UserInfoCard from '../cardLibrary/UserInfo';
import LocalSettingsCard from '../cardLibrary/LocalSettings';
import NotAuthenticated from '../elementLibrary/NotAuthenticated';
import IdleTimeoutAlert from '../modalLibrary/IdleTimeoutAlert';
import CredentialTable from '../modalLibrary/CredentialTable';
import UserFeedback from '../modalLibrary/UserFeedback';
import Puk from '../modalLibrary/Puk';
import PukChallenge from '../modalLibrary/PukChallenge';
import PortalModal from '../elementLibrary/PortalModal';
import ResetFailcounter from '../walkwayLibrary/ResetFailcounter';
import Update from '../walkwayLibrary/Update';
import Disable from '../walkwayLibrary/Disable';
import ResetPin from '../walkwayLibrary/ResetPin';
import Credentialing from '../walkwayLibrary/Credentialing';
import Enable from '../walkwayLibrary/Enable';
import ChangePin from '../walkwayLibrary/ChangePin';
import ResyncOtp from '../walkwayLibrary/ResyncOtp';
import Delegation from '../walkwayLibrary/Delegation';
import ValidateDevice from '../walkwayLibrary/ValidateDevice';
import Revoke from '../walkwayLibrary/Revoke';
import Unassign from '../walkwayLibrary/Unassign';
import Recycle from '../walkwayLibrary/Recycle';
import UpdateSecurity from '../walkwayLibrary/UpdateSecurity';
import HelpDesk from '../tabLibrary/HelpDesk';
import UserDetails from '../tabLibrary/HelpDesk/Users/Details';
import Reports from '../tabLibrary/Reports';
import ExportReport from '../modalLibrary/ExportReport';
import SaveReport from '../modalLibrary/SaveReport';
import ReportView from '../tabLibrary/Reports/View';
import DeleteReport from '../modalLibrary/DeleteReport';
import LoadingSpinner from '../shared/LoadingSpinner';
import i18n from '../../i18n';
import SkipToMainContent from '../elementLibrary/SkipToMainContent';
import HelpInfoModal from '../modalLibrary/HelpInfoModal';
import PCSC from '../../utils/pcsc';
import HttpInterceptor from './HttpInterceptor'
import { ValidateWithoutPin } from '../walkwayLibrary/ValidateDevice/ValidateWithoutPin';

library.add(...icons);

const AppContainer = styled.div`
    text-align: center;
    min-height: 100vh;
    background-color: ${(props) => props.theme.colors.background};
    font-size: 14px;
    color: ${(props) => props.theme.typography.text};
    padding-bottom: 100px;
`;
const DebugAlert = styled(Alert)`
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 0px;
`;

const tabComponents = [
    {
        component: InsightsCard,
        path: paths.insights,
        key: 'insightsRoute',
    },
    {
        component: IdentitiesCard,
        path: paths.identities,
        key: 'identitiesRoute',
    },
    {
        component: Delegation,
        path: paths.delegations,
        key: 'delegationRoute',
        condition: (settings) => !!settings.delegation,
    },
    {
        component: HelpDesk,
        path: [
            paths.helpDesk,
            subTabsPaths.helpDesk.devices,
            subTabsPaths.helpDesk.users,
            subTabsPaths.helpDesk.scanner,
        ],
        key: 'helpDeskRoute',
    },
    {
        component: Reports,
        path: [
            paths.reports,
            subTabsPaths.reports.inventory,
            subTabsPaths.reports.devices,
        ],
        key: 'reportsRoute',
    },
];

const cardComponents = [
    ...tabComponents,
    {
        component: UserDetails,
        path: helpDeskPaths.usersDetails,
        key: 'userDetailsRoute',
    },
    {
        component: ReportView,
        path: reportsPaths.reportNew,
        key: 'reportNew',
    },
    {
        component: ReportView,
        path: reportsPaths.reportView,
        key: 'reportView',
    },
    {
        component: UpdateSecurity,
        path: paths.updateSecurity,
        key: 'updateSecurityRoute',
    },
    { component: UserInfoCard, path: paths.account, key: 'accountRoute' },
    {
        component: LocalSettingsCard,
        path: paths.settings,
        key: 'settingsRoute',
    },
    {
        component: Credentialing,
        path: [paths.credentialing, helpDeskPaths.addDevice],
        key: 'credentialingRoute',
    },
    {
        component: Update,
        path: [paths.update, helpDeskPaths.update],
        key: 'updateRoute',
    },
    {
        component: Enable,
        path: [paths.enable, helpDeskPaths.enable],
        key: 'enableRoute',
    },
    {
        component: Disable,
        path: [paths.disable, helpDeskPaths.disable],
        key: 'disableRoute',
    },
    {
        component: ChangePin,
        path: [paths.changePin, helpDeskPaths.changePin],
        key: 'changePinRoute',
    },
    {
        component: ResetPin,
        path: [paths.resetpin, helpDeskPaths.resetpin],
        key: 'resetPinRoute',
    },
    {
        component: ResyncOtp,
        path: [paths.resyncOtp, helpDeskPaths.resyncOtp],
        key: 'resyncOtpRoute',
    },
    {
        component: ValidateDevice,
        path: [paths.validateDeviceWithPin, helpDeskPaths.validateDeviceWithPin],
        key: 'validateDeviceRoute',
    },
    {
        component: ValidateWithoutPin,
        path: [paths.validateDeviceWithoutPin, helpDeskPaths.validateDeviceWithoutPin],
        key: 'validateDeviceRoute',
    },
    {
        component: Revoke,
        path: [paths.revoke, helpDeskPaths.revoke],
        key: 'revokeRoute',
    },
    {
        component: ResetFailcounter,
        path: [paths.resetFailcounter, helpDeskPaths.resetFailcounter],
        key: 'resetFailcounterRoute',
    },
    {
        component: Unassign,
        path: [paths.unassign, helpDeskPaths.unassign],
        key: 'unassignRoute',
    },
    {
        component: Recycle,
        path: [paths.recycle, helpDeskPaths.recycle],
        key: 'recycleRoute',
    },
];

const HomeComponent = () => {
    const { settings, user, viewMode } = useContext(AppStateContext);
    const { higherPrivileges } = user || {};
    const allowedTabs = headerTabs.filter((tab) =>
        tab.cond(settings, higherPrivileges, viewMode),
    );

    const {
        location: { pathname },
    } = window;

    if(allowedTabs.some(t => t.key === navReportsId) && (pathname === '/insights' || pathname === '/insights/')){
        allowedTabs.unshift({
            key: navInsightsId,
            href: paths.insights
        })
    }

    if (!isEmpty(allowedTabs)) {
        const homeIndex = tabComponents.findIndex(({ path }) => {
            const route = !Array.isArray(path) ? path : path[0];
            return allowedTabs[0].href.includes(route);
        });
        if (homeIndex > -1) {
            const Home = tabComponents[homeIndex].component;
            return <Home />;
        }
    }
    return null;
};

const App = () => {
    HttpInterceptor();
    const { t } = useTranslation();
    const { settings, authenticated, modal, user, viewMode } = useContext(AppStateContext);
    const dispatch = useContext(AppDispatchContext);
    const { theme } = useContext(BrandingStateContext);
    const brandingDispatch = useContext(BrandingDispatchContext);
    const [savedUrl, setVal, removeVal] = useLocalStorage({
        key: redirectUrl,
    });
    const history = useHistory();
    const { location } = history || {};
    const modalRef = useRef();
    modalRef.current = modal;
    PCSC.bindFirstReadyExtension(3000);
    const { higherPrivileges } = user || {};
    const allowedTabs = headerTabs.filter((tab) =>
        tab.cond(settings, higherPrivileges, viewMode),
    );
    useEffect(() => {
        if(allowedTabs.length > 0 && !allowedTabs.some(t => t.key === navReportsId) &&
            (location.pathname === '/insights' || location.pathname === '/insights/')){
            history.push("/")
        }
    }, [
        history,
        location.pathname,
        allowedTabs
    ]);

    useEffect(() => {
        const redirectPath = (p) => {
            const strArr = savedUrl.split('/');
            const pathExists = paths[p] && strArr[strArr.length - 1].includes(paths[p]);
            if (pathExists) {
                history.push(paths[p]);
            }
            return pathExists;
        };
        if (savedUrl && authenticated === true) {
            let find = false;
            Object.keys(paths).forEach(element => {
                if (!find && redirectPath(element)) {
                    find = true;
                }
            })
            removeVal();
        } else if (authenticated === false) {
            setVal(location.pathname);
        }
    }, [
        savedUrl,
        authenticated,
        history,
        location.pathname,
        removeVal,
        setVal,
    ]);

    useDidMount(() => {
        const handleBrandingJson = async () => {
            let data;
            try {
                const brandingJson = await fetch(brandingJsonPath);
                data = await brandingJson.json();
            } catch (e) {
                console.debug(
                    'Invalid branding.json. Branding set to default.',
                );
                return;
            }
            data.locales &&
                Object.keys(data.locales).forEach((language) => {
                    i18n.addResourceBundle(
                        language,
                        'translation',
                        data.locales[language],
                        true,
                        true,
                    );
                });
            brandingDispatch({ type: 'SET_THEME', theme: data.theme });
            brandingDispatch({
                type: 'SET_HELPLINK',
                helpLink: data.helpLink,
            });
            brandingDispatch({
                type: 'SET_DEFAULT_COLUMN_SETTINGS',
                columnSettings: data.columnSettings || {},
            });
            brandingDispatch({
                type: 'SET_KIOSK_MODE',
                payload: data.kiosk,
            });
        };
        handleBrandingJson();
    });

    useEffect(() => {
        let interval = false;
        if (settings.idleTimeout && !interval) {
            localStorage.setItem('uupIdleTimeoutLength', settings.idleTimeout);
            localStorage.setItem(
                'uupIdleTimeoutDate',
                Date.now() + settings.idleTimeout * 1000,
            );
            interval = setInterval(() => {
                if (
                    localStorage.getItem('uupIdleTimeoutDate') -
                        Date.now() -
                        1000 <=
                        REMAINING_TIME_BEFORE_IDLE_TIMEOUT_ALERT &&
                    modalRef.current.type !== 'IDLE_TIMEOUT_ALERT'
                ) {
                    dispatch({
                        type: 'SHOW_MODAL',
                        modal: {
                            type: 'IDLE_TIMEOUT_ALERT',
                        },
                    });
                }
            }, 1000);
        }

        if (!authenticated) {
            clearInterval(interval);
            interval = false;
        }

        return () => clearInterval(interval);
    }, [settings.idleTimeout, authenticated, dispatch]);

    useEffect(() => {
        const { mode, action, deviceId } = queryString.parse(location.search);
        if (mode === KIOSK_MODE) {
            dispatch({ type: 'SET_KIOSK_MODE', payload: { action, deviceId } });
        } else if (mode === USER_MODE) {
            dispatch({ type: 'SET_USER_MODE' });
        }
    }, [dispatch, location.search]);

    const [, authenticateCheckData, authenticateCheckError] = useFetchData({
        endpoint: settingsEndpoint,
        onSuccess: (data) => {
            dispatch({ type: 'SET_SETTINGS', settings: data });
        },
    });

    useEffect(() => {
        if (settings.analyticsDsn) {
            Sentry.init({ dsn: settings.analyticsDsn });
        }
    }, [settings.analyticsDsn]);

    if (AUTHENTICATION_ERROR_CODES.includes(authenticateCheckError)) {
        return <NotAuthenticated />;
    }

    if (!authenticateCheckData) return <LoadingSpinner />;
    return (
        <ThemeProvider theme={theme}>
            <AppContainer>
                <SkipToMainContent />
                <Header />
                {log.getLevel() !== log.levels.SILENT && (
                    <DebugAlert color="primary">
                        {t('debugModeLabel')}
                    </DebugAlert>
                )}
                <GrowlerError />
                <Switch>
                    {authenticated ? (
                        <ComponentRoute
                            exact
                            path="/"
                            component={HomeComponent}
                        />
                    ) : (
                        <NotAuthenticated />
                    )}
                    {cardComponents.map((enroll) => {
                        if (enroll.condition && !enroll.condition(settings)) {
                            return null;
                        }
                        return (
                            <ComponentRoute
                                exact
                                key={enroll.key}
                                name={enroll.key}
                                path={enroll.path}
                                component={enroll.component}
                            />
                        );
                    })}
                </Switch>
                <Footer />
                <PortalModal>
                    <CredentialTable modalId="CREDENTIAL_TABLE" />
                    <IdleTimeoutAlert modalId="IDLE_TIMEOUT_ALERT" />
                    <Puk modalId="PUK_TABLE" />
                    <PukChallenge modalId="PUK_CHALLENGE_TABLE" />
                    <ExportReport
                        type="devices"
                        modalId="EXPORT_REPORT_DEVICES"
                    />
                    <SaveReport
                        type="devices"
                        modalId="SAVE_CUSTOM_REPORT_DEVICES"
                    />
                    <DeleteReport modalId="DELETE_CUSTOM_REPORT" />
                    <UserFeedback modalId="USER_FEEDBACK" />
                    <HelpInfoModal modalId="HELP_INFO" />
                </PortalModal>
            </AppContainer>
        </ThemeProvider>
    );
};

export default App;
