import React from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import ReportsTableWithData from './ReportsTableWithData';
import ReportsAction from './ReportsAction';
import ReportsLink from './ReportsLink';
import { sortString } from '../../../utils/generic';

const cellTypeSelect = (name, data, t, rowId) => {
    switch (name) {
        case 'action':
            return <ReportsAction data={{ ...data, rowId }} />;
        case 'name':
            return <ReportsLink data={data} />;
        case 'createdDate':
        case 'updatedDate':
            return new Date(parseInt(data.createdDate, 10)).toLocaleString();
        default: {
            let displayedData = data[name];
            if (!displayedData && data.customAttributes) {
                const customAttribute =
                    data.customAttributes.find((attr) => attr.key === name) ||
                    {};
                displayedData = customAttribute.value;
            }
            if (Array.isArray(displayedData)) {
                return displayedData.join(', ') || ' ';
            }
            if (typeof displayedData === 'boolean') {
                return t(
                    `hideColumns$boolType$${displayedData ? 'true' : 'false'}`,
                );
            }
            return displayedData || ' ';
        }

    }
};

const createColumnsData = (columnsName, activeColumns, t) => {
    const parse = [];
    columnsName.forEach((name) => {
        parse.push({
            Header:
                t(`usersTable$column$${name}`) ||
                name[0].toUpperCase() + name.slice(1),
            accessor: name,
            Cell: ({ row }) => cellTypeSelect(name, row.original, t),
            isVisible: !!activeColumns[name],
            sortType: sortString,
        });
    });
    // Set actions column independently
    parse.push({
        Header: t(`reportsTable$column$action`),
        accessor: 'action',
        Cell: ({ row }) => cellTypeSelect('action', row.original, t, row.id),
        disableSortBy: true,
        isVisible: true,
    });
    return parse;
};

const ReportsTable = ({
    data,
    columnsName = [],
    activeColumns = {},
    selectedColumnsKey = '',
    hidePagination = false,
    onColumnsChange = () => {},
    hideCheckboxes = true,
}) => {
    const { t } = useTranslation();
    const columnsData = createColumnsData(columnsName, activeColumns, t);

    return (
        <ReportsTableWithData
            data={!isEmpty(data) ? data : []}
            columnsData={columnsData}
            columnsName={columnsName}
            hidePagination={hidePagination}
            hideCheckboxes={hideCheckboxes}
            selectedColumnsKey={selectedColumnsKey}
            onColumnsChange={onColumnsChange}
        />
    );
};

export default ReportsTable;
