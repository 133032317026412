import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { credetialIconMapping } from '../../../utils/mappings';
import {
    PortalContainer,
    CardHeader,
    StyledAlert,
} from '../../../styles/common';

const ResponsiveImage = styled.img`
    max-width: 100%;
    max-height: 100%;
`;
const ResponsiveImageContainer = styled(Container)`
    padding-top: 3%;
    padding-bottom: 3%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const EnrollU2fWithData = () => {
    const { t } = useTranslation();
    const { yubikeyPress } = credetialIconMapping;
    return (
        <PortalContainer>
            <Row>
                <Col sm="12">
                    <CardHeader>{t('issuanceProgress')}</CardHeader>
                </Col>
                <Col sm="12">
                    <StyledAlert color="info" fade={false}>
                        {t('enrollU2fCardLabels$stateless$alert')}
                    </StyledAlert>
                </Col>
            </Row>
            <ResponsiveImageContainer>
                <ResponsiveImage
                    src={yubikeyPress}
                    alt={t('enrollU2fCardLabels$stateless$imgAlt')}
                />
            </ResponsiveImageContainer>
        </PortalContainer>
    );
};

export default EnrollU2fWithData;
