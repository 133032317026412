import React from 'react';
import { useHistory } from 'react-router-dom';
import { paths, subTabsPaths } from '../../../constants';
import { AccentLink } from '../../../styles/common';

const {
    helpDesk: { users },
} = subTabsPaths;
const { helpDesk, reports } = paths;

const DeviceUser = ({ data }) => {
    const {
        location: { pathname },
    } = useHistory();
    if (
        !pathname ||
        !(pathname.includes(helpDesk) || pathname.includes(reports)) ||
        pathname === `${users}/${data.username}`
    ) {
        return <span>{data.username}</span>;
    }
    return (
        <AccentLink to={{ pathname: `${users}/${data.username}` }}>
            {data.username}
        </AccentLink>
    );
};

export default DeviceUser;
