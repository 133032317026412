import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { Dropdown } from 'reactstrap';
import uniqueId from 'lodash.uniqueid';
import {
    NoHoverLink,
    StyledAnchor,
    StyledActionTooltip,
    StyledActionDropdownToggle,
} from '../../styles/common';
import { AppDispatchContext } from '../../context/AppContext';
import { ReportsDispatchContext } from '../../context/ReportContext';

const DropdownOption = ({ component, reportData, text, href, effect }) => {
    const dispatch = useContext(AppDispatchContext);
    const reportsDispatch = useContext(ReportsDispatchContext);

    const showModal = (action) => {
        reportsDispatch({
            type: 'SET_CURRENT_REPORT',
            reportData,
        });
        switch (action) {
            case 'reportDownload':
                dispatch({
                    type: 'SHOW_MODAL',
                    reportData,
                    modal: {
                        type: 'DOWNLOAD_CUSTOM_REPORT',
                    },
                });
                break;
            case 'reportDelete':
                dispatch({
                    type: 'SHOW_MODAL',
                    reportData,
                    modal: {
                        type: 'DELETE_CUSTOM_REPORT',
                    },
                });
                break;
            default:
                break;
        }
    };

    if (!component) {
        return null;
    }

    return (
        <div
            key={uniqueId('dropdownItem_')}
            className="dropdown-item"
            id={`${reportData.name}_${text}`}
        >
            {effect === 'link' && (
                <NoHoverLink
                    to={{
                        pathname: href,
                    }}
                >
                    {component}
                </NoHoverLink>
            )}
            {effect === 'modal' && (
                <StyledAnchor onClick={() => showModal(text)}>
                    {component}
                </StyledAnchor>
            )}
            {effect === 'dynamicLink' && (
                <NoHoverLink
                    to={{
                        pathname: href(reportData.name),
                    }}
                >
                    {component}
                </NoHoverLink>
            )}
        </div>
    );
};

const DropdownContent = ({ reportData, text, options, disabled }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { location } = useHistory();

    const toggle = (event) => {
        if (event.target.closest(`#tooltip_${reportData.rowId}`)) return;
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <div id={`dropdown_${reportData.rowId}`}>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <StyledActionDropdownToggle
                    disabled={disabled}
                    color="link"
                    size="sm"
                    aria-label="Report Actions"
                >
                    {text}
                </StyledActionDropdownToggle>
            </Dropdown>
            <StyledActionTooltip
                id={`tooltip_${reportData.rowId}`}
                placement="auto"
                isOpen={dropdownOpen}
                target={`dropdown_${reportData.rowId}`}
                fade={false}
            >
                {options &&
                    options.map((option) => (
                        <DropdownOption
                            key={option.text}
                            {...option}
                            reportData={reportData}
                            location={location}
                        />
                    ))}
            </StyledActionTooltip>
        </div>
    );
};

DropdownContent.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    disabled: PropTypes.bool,
};

DropdownContent.defaultProps = {
    disabled: false
};

export default DropdownContent;
