import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Col } from 'reactstrap';
import { AppStateContext, AppDispatchContext } from '../../context/AppContext';
import {
    StyledInput,
    AccentButton,
    StyledFormGroup,
    StyledDefaultModalHeader,
    StyledDefaultModalBody,
    StyledDefaultModalFooter,
    StyledAlert,
    SpinnerPaddingRight,
} from '../../styles/common';

import { useDidMount, useHandleEnter, usePostData } from '../../utils/hooks';

import {
    ReportsStateContext,
    ReportsDispatchContext,
} from '../../context/ReportContext';

import { reportsGraphQLBody } from '../../utils/graphQL';

const ExportReport = () => {
    const [input, setInput] = useState('');
    const [downloading, setDownloading] = useState(false);
    const [downloaded, setDownloaded] = useState(false);
    const dispatch = useContext(AppDispatchContext);
    const {
        searchData,
        currentReport,
        currentSearchRules,
        sortBy,
        sortDir,
        searchResultsMetadata: {
            count: searchResultsCount,
            pi: { offset: piOffset, hasNext: piHasNext },
            ucms: { offset: ucmsOffset, hasNext: ucmsHasNext },
        },
    } = useContext(ReportsStateContext);
    const reportsDispatch = useContext(ReportsDispatchContext);
    const { activeDevicesColumns } = useContext(AppStateContext);
    const { t } = useTranslation();
    const inputRef = useRef();
    inputRef.current = input;

    useDidMount(() => {
        const reportName = currentReport.name || 'New Report';
        setInput(reportName);
    });

    const [searchCallback, { data: searchDataResponse }] = usePostData({
        graphQL: true,
    });


    const exportToCsv = () => {
        if (!searchData || !searchData.length) {
            return;
        }
        const processHeader = (row) => {
            let finalVal = '';
            Object.keys(row).forEach((col, index) => {
                if (activeDevicesColumns[col] !== true) return;
                const innerValue = col === null ? '' : col.toString();
                let result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0) result = `"${result}"`;
                if (index > 0) finalVal += ',';
                finalVal += result;
            });
            return `${finalVal}\n`;
        };

        const processRow = (row) => {
            let finalVal = '';
            Object.keys(row).forEach((col, index) => {
                if (activeDevicesColumns[col] !== true) return;
                const innerValue = row[col] === null ? '' : row[col].toString();
                let result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0) result = `"${result}"`;
                if (index > 0) finalVal += ',';
                finalVal += result;
            });
            return `${finalVal}\n`;
        };

        let csvFile = '';
        csvFile += processHeader(searchData[0]);
        for (let i = 0; i < searchData.length; i++) {
            csvFile += processRow(searchData[i]);
        }

        const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `${inputRef.current}.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const submit = () => {
        if (inputRef.current.length) setDownloading(true);
    };

    useEffect(() => {
        if (searchDataResponse) {
            const searchDataInfo =
                searchDataResponse &&
                searchDataResponse.data &&
                searchDataResponse.data.deviceInfo &&
                searchDataResponse.data.deviceInfo;
            if (searchDataInfo) {
                searchDataInfo.pageInfo &&
                    reportsDispatch({
                        type: 'SET_SEARCH_RESULTS_METADATA',
                        payload: searchDataInfo.pageInfo,
                    });
                searchDataInfo.devices &&
                    reportsDispatch({
                        type: 'APPEND_TO_SEARCH_DATA',
                        payload: searchDataInfo.devices,
                    });
            }
        }
    }, [searchDataResponse, reportsDispatch]);

    useEffect(() => {
        if (downloading) {
            if (piHasNext || ucmsHasNext) {
                searchCallback(
                    reportsGraphQLBody({
                        searchFilters: currentSearchRules,
                        sortBy,
                        sortDir,
                        limit: 50,
                        piOffset,
                        ucmsOffset,
                        searchUcms: ucmsHasNext,
                        searchPi: piHasNext,
                        fields: currentReport.updatedFields,
                    }),
                );
            } else {
                setDownloaded(true);
                exportToCsv();
            }
        }
    }, [downloading, searchData, piHasNext, ucmsHasNext]);

    useHandleEnter({ action: submit });

    return (
        <>
            <StyledDefaultModalHeader>
                {t(`modalLabels$exportReport$title`)}
            </StyledDefaultModalHeader>
            <StyledDefaultModalBody>
                <StyledAlert color="info">
                    {t(`modalLabels$exportReport$info`)}
                </StyledAlert>
                <StyledFormGroup row autoComplete="off">
                    <Col xs="3">
                        <Label for="reportTitle">
                            {t('modalLabels$exportReport$input')} :
                        </Label>
                    </Col>
                    <Col xs="6">
                        <StyledInput
                            name="reportTitle"
                            bsSize="sm"
                            value={input}
                            valid={input.length}
                            invalid={!input.length}
                            onChange={(e) => setInput(e.target.value)}
                        />
                    </Col>
                </StyledFormGroup>
                <StyledFormGroup row autoComplete="off">
                    <Col xs="3">{t('modalLabels$exportReport$filetype')} :</Col>
                    <Col xs="6">{t('modalLabels$exportReport$csv')}</Col>
                </StyledFormGroup>
                {downloading && (
                    <>
                        <StyledFormGroup row autoComplete="off">
                            <Col xs="3" />
                            <Col xs="6">
                                {`${
                                    downloaded
                                        ? t(
                                            'modalLabels$exportReport$downloaded',
                                        )
                                        : t(
                                            'modalLabels$exportReport$downloading',
                                        )
                                } ${
                                    searchData.length
                                }/${searchResultsCount} ${t(
                                    'modalLabels$exportReport$entries',
                                )}${downloaded ? '' : ' ...'}`}
                            </Col>
                        </StyledFormGroup>
                    </>
                )}
            </StyledDefaultModalBody>
            <StyledDefaultModalFooter>
                {!downloaded && (
                    <AccentButton
                        outline
                        color="primary"
                        size="sm"
                        onClick={submit}
                        disabled={!input.length || downloading}
                    >
                        {downloading ? (
                            <>
                                <SpinnerPaddingRight size="sm" />
                                {t('modalLabels$exportReport$downloading')}
                            </>
                        ) : (
                            t('modalLabels$exportReport$submit')
                        )}
                    </AccentButton>
                )}
                <AccentButton
                    outline
                    color="primary"
                    size="sm"
                    onClick={() => {
                        setDownloading(false);
                        dispatch({
                            type: 'CLEAR_MODAL',
                        });
                    }}
                >
                    {downloaded
                        ? t('modalElementLabels$details$modal$done')
                        : t('modalElementLabels$details$modal$cancel')}
                </AccentButton>
            </StyledDefaultModalFooter>
        </>
    );
};

export default ExportReport;
