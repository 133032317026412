import React from 'react';
import SearchBarWithData from './SearchBarWithData';
import LoadingSpinner from '../../shared/LoadingSpinner';

const SearchBar = ({
    endpoint,
    keywords,
    onLoading,
    onSearchData,
    onClearData,
    onFiltersChange,
    onSelect,
    searchFilter,
    defaultValue,
    graphQLBody,
    queryParams,
}) => {
    if (!keywords) {
        return <LoadingSpinner />;
    }
    return (
        <SearchBarWithData
            endpoint={endpoint}
            keywords={keywords}
            onLoading={onLoading}
            onSearchData={onSearchData}
            onClearData={onClearData}
            onFiltersChange={onFiltersChange}
            searchFilter={searchFilter}
            onSelect={onSelect}
            defaultValue={defaultValue}
            graphQLBody={graphQLBody}
            queryParams={queryParams}
        />
    );
};

export default SearchBar;
