import { apiPath } from '../constants';
import { errorFactory } from './errors';
import { updateIdleTimeoutDate, queryParamsToQueryString } from './generic';

const POST_TIMEOUT = 120000;

export const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const patchData = async ({
    endpoint,
    body,
    queryParams = {},
}) => {
    const queryString = queryParamsToQueryString(queryParams);
    const postEndpoint = `${apiPath}${endpoint}${queryString}`;
    const res = await Promise.race([
        fetch(postEndpoint, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
            },
            body: isJson(body) ? body : JSON.stringify(body),
        }),
        new Promise((_, reject) => {
            setTimeout(
                () =>
                    // eslint-disable-next-line implicit-arrow-linebreak
                    reject(errorFactory.timeout()),
                POST_TIMEOUT,
            );
        }),
    ]);
    updateIdleTimeoutDate();
    return res;
};
