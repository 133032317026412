import React from 'react';
import { PropTypes } from 'prop-types';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CardTemplate from './CardTemplate';
import { PortalContainer, StyledAlert } from '../../styles/common';

const ErrorLabel = styled.span`
    font-weight: bold;
    padding-right: 4px;
`;

const ErrorContainer = ({ issue, label }) => (
    <div>
        <ErrorLabel>{label}</ErrorLabel>
        <span>{issue}</span>
    </div>
);

ErrorContainer.propTypes = {
    issue: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

const ErrorMessage = ({ cause, solution, isLast }) => {
    const { t } = useTranslation();
    return (
        <div>
            <ErrorContainer
                label={t('sharedLabels$webpcscError$issue')}
                issue={cause}
            />
            <ErrorContainer
                label={t('sharedLabels$webpcscError$solution')}
                issue={solution || t('webpcscErrorSolutionDefault')}
            />
            {isLast && <hr />}
        </div>
    );
};

ErrorMessage.propTypes = {
    cause: PropTypes.string.isRequired,
    solution: PropTypes.string,
    isLast: PropTypes.bool.isRequired,
};

ErrorMessage.defaultProps = {
    solution: undefined
};

const WebpcscError = ({ error, children }) => {
    const { t } = useTranslation();
    let renderError = <>{t('errorDefault')}</>;
    const { diagnoses, msg } = error || {};

    if (diagnoses !== undefined && error.msg === undefined) {
        renderError = diagnoses.map((dialog, idx) => (
            <ErrorMessage
                key={`${dialog.cause}-${dialog.solution}`}
                cause={dialog.cause}
                solution={dialog.solution}
                isLast={idx !== diagnoses.length - 1}
            />
        ));
    } else if (msg !== undefined)
        renderError = (
            <ErrorContainer
                issue={msg}
                label={t('sharedLabels$webpcscError$issue')}
            />
        );
    return (
        <CardTemplate title={t('cardTitles$error')}>
            <PortalContainer>
                <Row>
                    <Col sm="12">
                        <StyledAlert color="danger">{renderError}</StyledAlert>
                    </Col>
                </Row>
                {children}
            </PortalContainer>
        </CardTemplate>
    );
};

WebpcscError.propTypes = {
    children: PropTypes.node,
    error: PropTypes.bool.isRequired,
};

WebpcscError.defaultProps = {
    children: null,
};

export default WebpcscError;
