import React, { useState, useEffect, useRef, useContext } from 'react';
import {
    Button,
    ModalBody,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    Label,
    Input,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import uniqueId from 'lodash.uniqueid';
import isEmpty from 'lodash.isempty';
import {  useLocalStorageState } from '../../utils/hooks';
import {
    BrandingStateContext,
} from '../../context/BrandingContext';
import {
    StyledModal,
    StyledModalHeader,
    StyledModalFooter,
} from '../../styles/common';
import { AppStateContext } from '../../context/AppContext';

const ResetDefaultLabel = styled.span`
    color: ${({ theme }) => theme.colors.accent};
    &:hover {
        opacity: 0.85;
        text-decoration: underline;
        cursor: pointer;
    }
    display: inline-blox;
`;

const SettingsIcon = styled(FontAwesomeIcon)`
    font-size: 1em;
    font-weight: ${({ active }) => (active ? 'bold' : 'default')};
    margin-right: 10px;
`;

const SettingsIconContainer = styled.div`
    padding: 12px;
    display: inline-block;
    cursor: pointer;
`;

const CancelBtn = styled(Button)`
    border-color: ${({ theme }) => theme.colors.accent} !important;
    color: ${({ theme }) => theme.colors.accent} !important;
    &:hover {
        color: white !important;
        background-color: ${({ theme }) => theme.colors.accent} !important;
    }
`;

const DoneBtn = styled(Button)`
    background-color: ${({ theme }) => theme.colors.accent} !important;
    border-color: ${({ theme }) => theme.colors.accent} !important;
    &:hover {
        opacity: 0.75 !important;
    }
`;

const StyledLabel = styled(Label)`
    color: ${({ theme }) => theme.typography.anchor} !important;
    text-decoration: none;
    font-size: 14px;
    padding-left: 24px;
    &:hover {
        color: ${({ theme }) => theme.typography.anchorHover} !important;
        text-decoration: none;
    }
    display: block;
`;

const StyledPaginationDropdown = styled(Dropdown)`
    button {
        background-color: ${({ theme }) => theme.colors.accent} !important;
        border-color: ${({ theme }) => theme.colors.accent} !important;
        &:hover {
            opacity: 0.85 !important;
        }
        &:focus {
            box-shadow: 0px 0px !important;
        }
    }
    div {
        button {
            background-color: transparent !important;
            &:hover {
                background-color: #f9f9f9 !important;
            }
            &:focus {
                color: ${({ theme }) => theme.colors.accent} !important;
            }
        }
    }
`;

const HorizontalDivider = styled.hr`
    opacity: 0;
`;

const StyledTitle = styled.span`
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 0;
    display: block;
`;

const parseColumns = (columns, defaultColumns = []) => {
    const a =
        defaultColumns === null || defaultColumns.length === 0
            ? columns.reduce(
                (o, key) => ({
                    ...o,
                    [key]: true,
                }),
                {},
            )
            : columns.reduce(
                (o, key) => ({
                    ...o,
                    [key]: defaultColumns.includes(key),
                }),
                {},
            );
    return a;
};

const HideColumnsSelector = ({
    columnsName = [],
    onSelect = () => {},
    value = [],
}) => {
    const { t } = useTranslation();

    if (!isEmpty(columnsName)) {
        return columnsName.map((column) => (
            <StyledLabel
                check
                key={uniqueId('column_')}
                onClick={() => onSelect(column)}
            >
                <Input type="checkbox" defaultChecked={value[column]} />
                {t(`hideColumns$option$${column}`) ||
                    column[0].toUpperCase() + column.slice(1)}
            </StyledLabel>
        ));
    }
    return null;
};

const PageCountDropdown = ({ onPageSizeChange, pageSize }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    const toggle = () => setIsOpen(!isOpen);

    const options = [10, 20, 50];

    return (
        <>
            <StyledTitle>
                {t('tableSettings$pageSize', { SIZE: pageSize })}
            </StyledTitle>
            <StyledPaginationDropdown isOpen={isOpen} toggle={toggle} size="sm">
                <DropdownToggle caret>{pageSize}</DropdownToggle>
                <DropdownMenu>
                    {options.map((option) => (
                        <DropdownItem
                            key={option}
                            onClick={() => onPageSizeChange(option)}
                        >
                            {option}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </StyledPaginationDropdown>
            <HorizontalDivider />
        </>
    );
};

const TableSettings = ({
    onPageSizeChange = () => {},
    onColumnsChange = () => {},
    selectedColumnsKey = '',
    columnsName = [],
    pageSize = 0,
    hidePagination = false,
    defaultColumns = null,
    enableLocalStorage = true,
}) => {
    const { user } = useContext(AppStateContext);
    const [parsedColumns, setParsedColumns] = useState(
        parseColumns(columnsName, defaultColumns),
    );
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const { columnSettings } = useContext(BrandingStateContext);
    const userSelectedColumnsKey = `${selectedColumnsKey}${user.username}`
    const [savedData, setSavedData] = useLocalStorageState(
        userSelectedColumnsKey,
        !!columnSettings && columnSettings[selectedColumnsKey] || parsedColumns,
        enableLocalStorage,
    );
    const [tmpValue, setTmpValue] = useState(savedData || {});
    const defaultPageSize = 10;
    useEffect(() => {
        if (selectedColumnsKey !== null) {
            const localDefault = localStorage.getItem(`${selectedColumnsKey  }_default`);
            if (defaultColumns !== null && localDefault !== JSON.stringify(defaultColumns)) {
                const localStorageColumns = JSON.parse(localDefault)
                const newDefaultCol = defaultColumns.filter((col) => {
                    if(localStorageColumns === null || !localStorageColumns.includes(col)) {
                        return true;
                    }
                    return false;
                })
                if (savedData) {
                    // combin savedData and defaultColumn
                    const newSavedData = {}
                    Object.keys(savedData).forEach((key) => {
                        if (newDefaultCol.includes(key)) {
                            newSavedData[key] = true;
                        } else {
                            newSavedData[key] = savedData[key];
                        }
                    })
                    setSavedData(newSavedData);
                    setParsedColumns(newSavedData);
                } else {
                    const parsedColumns = parseColumns(columnsName, defaultColumns);
                    setSavedData(parsedColumns);
                    setParsedColumns(parsedColumns);
                }
                localStorage.setItem(`${selectedColumnsKey  }_default`, JSON.stringify(defaultColumns));
            }
        }
    }, [defaultColumns, columnsName]);

    const toggle = () => setIsOpen(!isOpen);

    const onSelect = (column) => {
        savedData[column] = !savedData[column];
        setTmpValue({ ...savedData });
    };

    const savePreferences = () => {
        setSavedData({ ...tmpValue, pageSize });
        setIsOpen(false);
    };

    const resetPreferences = () => {
        const initialValue = parseColumns(columnsName, defaultColumns);
        const newSavedData = { ...initialValue, pageSize: defaultPageSize };
        setTmpValue(newSavedData);
        setSavedData(newSavedData);
        onPageSizeChange(defaultPageSize);
        setIsOpen(false);
    };

    const savePreferencesRef = useRef();
    savePreferencesRef.current = savePreferences;
    const isOpenRef = useRef();
    isOpenRef.current = isOpen;

    const handleEnter = (e) => {
        if (e.keyCode === 13 && isOpenRef.current) {
            savePreferencesRef.current();
        }
    };

    useEffect(() => {
        document.addEventListener('keyup', handleEnter);
        return () => document.removeEventListener('keyup', handleEnter);
    },[]);

    useEffect(() => {
        const resetVal =
            isEmpty(savedData) && !isEmpty(columnsName);
        if (resetVal) {
            setSavedData(parseColumns(columnsName));
        }
        if (!isEmpty(savedData)) {
            const { pageSize: savedPageSize } = savedData;
            if(savedPageSize !== undefined){
                onPageSizeChange(savedPageSize);
            } else {
                onPageSizeChange(defaultPageSize);
            }           
            onColumnsChange(savedData);
        }
    }, [columnsName, savedData]);

    return (
        <>
            <SettingsIconContainer onClick={toggle}>
                <SettingsIcon icon="cog" />
            </SettingsIconContainer>
            <StyledModal
                isOpen={isOpen}
                toggle={toggle}
                centered
                size="lg"
            >
                <StyledModalHeader>
                    {t('tableSettings$title')}
                </StyledModalHeader>
                <ModalBody>
                    {!hidePagination && (
                        <PageCountDropdown
                            onPageSizeChange={onPageSizeChange}
                            pageSize={pageSize}
                        />
                    )}
                    <StyledTitle>{t('hideColumns$title')}</StyledTitle>
                    <ResetDefaultLabel onClick={resetPreferences}>
                        {t('tableSettings$resetDefault')}
                    </ResetDefaultLabel>
                    <HideColumnsSelector
                        columnsName={columnsName}
                        onSelect={onSelect}
                        value={savedData}
                    />
                </ModalBody>
                <StyledModalFooter>
                    <DoneBtn size="sm" onClick={savePreferences}>
                        {t('tableSettings$btn$done')}
                    </DoneBtn>
                    <CancelBtn
                        outline
                        onClick={() => { 
                            toggle(); 
                            setSavedData(JSON.parse(localStorage.getItem(userSelectedColumnsKey))); 
                        }}
                        size="sm"
                    >
                        {t('tableSettings$btn$cancel')}
                    </CancelBtn>
                </StyledModalFooter>
            </StyledModal>
        </>
    );
};

export default TableSettings;
