import React, { useState, useEffect, useContext } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import { useHistory } from 'react-router-dom'; // eslint
import { StyledNavIcon } from '../../../styles/common';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { AppStateContext } from '../../../context/AppContext';
import { mapOrder } from '../../../utils/generic';
import {
    NotificationDispatchContext,
    NotificationStateContext,
} from './context';
import NotificationsList from './NotificationsList';
import { useLazyFetchData } from '../../../utils/hooks';
import { paths, subTabsPaths, homePath } from '../../../constants';

const priorityOrder = ['severe', 'critical', 'warn', 'info'];
const allowedPaths = [
    homePath,
    paths.account,
    paths.identities,
    paths.settings,
    paths.delegations,
    paths.helpDesk,
    paths.reports,
    subTabsPaths.helpDesk.devices,
    subTabsPaths.helpDesk.users,
    subTabsPaths.helpDesk.scanner,
    subTabsPaths.reports.inventory,
    subTabsPaths.reports.devices,
    subTabsPaths.reports.view,
];

const CustomIcon = styled(StyledNavIcon)`
    font-size: ${({ $isOpen }) => ($isOpen ? 14 : 20)}px;
    color: ${({ $isOpen, theme }) =>
        $isOpen ? theme.typography.text : theme.typography.dark};
    margin: 0 auto;
    &:hover {
        color: ${({ theme }) => theme.typography.dark};
    }
`;

const IconContainer = styled.div`
    width: 24px;
    height: 24px;
    background-color: ${({ isOpen }) => (isOpen ? '#d9d9d9' : 'inherit')};
    border-radius: 24px;
    padding-top: ${({ isOpen }) => (isOpen ? 0 : 3)}px;
    margin: 0 auto;
    position: relative;
`;

const NotificationTitle = styled(DropdownItem)`
    font-size: 1em;
    min-width: 500px;
    font-family: Poppins, san-serif !important;
    font-size: smaller !important;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    margin-top: 6px;
`;

const NotificationUnread = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    position: absolute;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 9px;
    left: 11px;
    top: -4px;
    height: 18px;
    width: ${({ circle }) => (circle ? 18 : 26)}px;
    @media only screen and (max-width: 768px) {
        right: 46.5%;
    }
`;

const CountLabel = styled.p`
    color: #fff;
    font-weight: 600;
    font-size: 0.8em;
`;

const StyledDropdown = styled(Dropdown)`
    margin-right: -4px;
    @media only screen and (max-width: 768px) {
        margin-right: 0px;
    }
`;

const NotificationMenu = styled(DropdownMenu)`
    max-width: 550px;
    max-height: 300px;
    overflow: auto;
    @media only screen and (max-width: 768px) {
        max-width: 100%;
    }
`;

const EmptyMessage = styled.p`
    padding-left: 24px;
    text-transform: initial;
    padding-top: 4px;
    font-weight: 400;
    font-size: 0.8125rem;
    text-align: left;
    opacity: 0.8;
    color: #333333;
`;

const NotificationsDropdown = () => {
    const [notifications, setNotifications] = useState([]);
    const [hideIcon, setHideIcon] = useState(false);
    const {
        user: { username },
    } = useContext(AppStateContext);
    const { isOpen, isUnread } = useContext(NotificationStateContext);
    const notificationDispatch = useContext(NotificationDispatchContext);
    const {
        location: { pathname },
    } = useHistory();
    const { t } = useTranslation();
    const [fetchCallback, { loading: isLoading }] = useLazyFetchData({
        endpoint: `/user/${username}/notifications`,
        onSuccess: (data) =>
            setNotifications(mapOrder(data, priorityOrder, 'priority')),
    });

    useEffect(() => {
        if (username) {
            fetchCallback();
        }
    }, [username, fetchCallback]);

    useEffect(() => {
        if (!isEmpty(notifications)) {
            notificationDispatch({ type: 'MARK_UNREAD' });
        }
    }, [notifications, notificationDispatch]);

    useEffect(() => {
        const path =
            homePath === pathname ? homePath : pathname.replace(/\/$/, '');
        setHideIcon(!allowedPaths.includes(path));
    }, [pathname]);

    const toggle = () =>
        notificationDispatch({
            type: isOpen ? 'CLOSE_DROPDOWN' : 'OPEN_DROPDOWN',
        });

    const count = notifications.length > 9 ? '9+' : notifications.length;
    const isCircle = count < 10;

    if (hideIcon) {
        return null;
    }
    return (
        <StyledDropdown
            nav
            inNavbar
            isOpen={isOpen}
            toggle={toggle}
            a11y={false}
        >
            <DropdownToggle nav aria-expanded={isOpen} aria-label='Notifications'>
                <IconContainer isOpen={isOpen}>
                    <CustomIcon
                        icon="bell"
                        $isOpen={isOpen}
                        aria-label={t('notifications$title')}
                    />
                    {isUnread && (
                        <NotificationUnread circle={isCircle}>
                            <CountLabel>{count}</CountLabel>
                        </NotificationUnread>
                    )}
                </IconContainer>
            </DropdownToggle>
            <NotificationMenu right>
                <NotificationTitle header>
                    {t('notifications$title')}
                </NotificationTitle>
                {isLoading && <LoadingSpinner />}
                {!isLoading && <NotificationsList items={notifications} />}
                {!isLoading && isEmpty(notifications) && (
                    <EmptyMessage>
                        {t('notifications$emptyMessage')}
                    </EmptyMessage>
                )}
            </NotificationMenu>
        </StyledDropdown>
    );
};

export default NotificationsDropdown;
