import React from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import TableCore from '../TableCore';
import EmergencyType from './EmergencyType';
import EmergencyInfoName from './EmergencyInfoName';
import TPAActionButton from './TPAActionButton';
import DeviceStatus from '../DevicesTable/DeviceStatus';
import { sortString } from '../../../utils/generic';
import { tableType } from '../../../constants';
import { emergencyTypes } from '../../../utils/mappings';

const cellTypeSelect = (name, data, t, revokeFailed, isRevoking, revokedDevice) => {
    switch (name) {
        case 'action':
            return (
                <TPAActionButton
                    onClick={data.onClick}
                    data={data}
                    revokeFailed={revokeFailed}
                    isRevoking={isRevoking}
                    revokedDevice={revokedDevice}
                />
            );
        case 'type':
            return <EmergencyType type={data.type} />;
        case 'label':
            return <EmergencyInfoName type={data.type} />;
        case 'status':
            return <DeviceStatus device={data} status={data[name]} />;
        default:
            if (Array.isArray(data[name])) {
                return data[name].join(', ') || ' ';
            }
            if (typeof data[name] === 'boolean') {
                return t(
                    `hideColumns$boolType$${data[name] ? 'true' : 'false'}`,
                );
            }
            return data[name] || ' ';
    }
};

const generateLabel = (data, t) => {
    const d = {...data}
    const { type } = d.original
    const { label } = emergencyTypes[type] || {};
    d.values['label'] = t(label) || t('unknownDevice');
    return d
}

let hideColumn = [];

const createColumnsData = ({
    columnsName,
    activeColumns,
    t,
    revokeFailed,
    isRevoking,
    revokedDevice,
}) => {
    const parse = [];
    hideColumn = [];
    columnsName.forEach((name) => {
        const headerName = () => {
            let res;
            if (name.indexOf('valid') !== -1) {
                res = t(`usersDetails$TPA$${name}`);
            } else {
                res = t(`devicesTable$column$${name}`);
            }

            if (!res) {
                res = name[0].toUpperCase() + name.slice(1);
            }
            return res;
        };
        parse.push({
            Header: headerName(),
            accessor: name,
            Cell: ({ row }) => cellTypeSelect(name, row.original, t),
            sortType: (rowA, rowB, columnId, desc) => {
                let a = {...rowA}
                let b = {...rowB}
                if(columnId === 'label'){
                    a = generateLabel(a, t)
                    b = generateLabel(b, t)
                }
                return sortString(a, b, columnId, desc)
            },
        });
        if (!activeColumns[name]) {
            hideColumn.push(name);
        }
    });

    // Set actions column independently
    parse.push({
        Header: t(`devicesTable$column$action`),
        accessor: 'action',
        Cell: ({ row }) =>
            cellTypeSelect('action', row.original, t, revokeFailed, isRevoking, revokedDevice),
        disableSortBy: true,
    });
    return parse;
};

const EmergencyInfoTable = ({
    revokeFailed,
    isRevoking,
    revokedDevice,
    data,
    columnsName = [],
    activeColumns = {},
    ...otherProps
}) => {
    const { t } = useTranslation();
    const columnsData = createColumnsData({
        data,
        columnsName,
        activeColumns,
        t,
        revokeFailed,
        isRevoking,
        revokedDevice,
    });

    const setWidthForStatusAndActionColumn = (column) => {
        if (column.id === 'status') {
            return {
                style: {
                    width: '9rem',
                },
            };
        }
        if (column.id === 'action') {
            return {
                style: {
                    width: '10rem',
                    flexGrow: 0,
                },
            };
        }
        return {};
    };
    return (
        <TableCore
            tableType={tableType.emergency}
            data={!isEmpty(data) ? data : []}
            columnsData={columnsData}
            hideColumn={hideColumn}
            setColumnStyle={setWidthForStatusAndActionColumn}
            columnsName={columnsName}
            {...otherProps}
        />
    );
};

export default EmergencyInfoTable;
