import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppDispatchContext, AppStateContext } from '../../context/AppContext';
import {
    rootPath,
    REMAINING_TIME_BEFORE_IDLE_TIMEOUT_ALERT,
    settingsEndpoint,
} from '../../constants';
import { useLazyFetchData, useHandleEnter } from '../../utils/hooks';
import {
    StyledDefaultModalHeader,
    StyledDefaultModalBody,
    StyledDefaultModalFooter,
    AccentButton,
} from '../../styles/common';

const IdleTimeoutAlert = () => {
    const appDispatch = useContext(AppDispatchContext);
    const { settings } = useContext(AppStateContext);
    const { t } = useTranslation();
    const [timeRemaining, setTimeRemaining] = useState();
    const [resetSessionCallback] = useLazyFetchData({
        endpoint: settingsEndpoint,
    });

    const logout = () => {
        document.getElementById("logout-form").submit();
    }

    const setTimeoutFromLocalStorage = () => {
        setTimeRemaining(
            Math.floor(
                (localStorage.getItem('uupIdleTimeoutDate') - Date.now()) /
                    1000,
            ),
        );
    };

    const clearModal = () => appDispatch({ type: 'CLEAR_MODAL' });

    const continueSession = () => {
        resetSessionCallback();
        setTimeout(() => clearModal());
    };

    useHandleEnter({ action: continueSession });

    useEffect(() => {
        let interval;

        setTimeoutFromLocalStorage();

        const handleInterval = () => {
            if (
                localStorage.getItem('uupIdleTimeoutDate') - Date.now() >
                REMAINING_TIME_BEFORE_IDLE_TIMEOUT_ALERT
            ) {
                resetSessionCallback();
                clearInterval(interval);
                appDispatch({ type: 'CLEAR_MODAL' });
            }
            setTimeoutFromLocalStorage();
        };

        interval = setInterval(handleInterval, 1000);

        appDispatch({
            type: 'SET_CUSTOM_MODAL_PROPERTIES',
            modal: {
                closeAction: resetSessionCallback,
            },
        });

        return () => {
            clearInterval(interval);
        };
    },[]);

    useEffect(() => {
        if (timeRemaining <= 0) {
            logout();
        }
    }, [timeRemaining, settings.logoutUrl]);

    return (
        <>
            <StyledDefaultModalHeader>
                {t(`modalLabels$idleTimeout$title`)}
            </StyledDefaultModalHeader>
            <StyledDefaultModalBody>
                <div>{t(`modalLabels$idleTimeout$body1`)}</div>
                <div>
                    {t(`modalLabels$idleTimeout$body2`)} <b>{timeRemaining}</b>.
                </div>
                <div>{t(`modalLabels$idleTimeout$body3`)}</div>
            </StyledDefaultModalBody>
            <StyledDefaultModalFooter>
                <form id="logout-form" action={`${rootPath}${settings.logoutUrl}`} method="POST" hidden="true">
                    <input 
                        type="submit" 
                        value={t('modalElementLabels$idletimeout$modal$logout')}
                    />
                </form>
                <AccentButton
                    outline
                    color="primary"
                    size="sm"
                    onClick={logout}
                >
                    {t('modalElementLabels$idletimeout$modal$logout')}
                </AccentButton>
                <AccentButton
                    outline
                    color="primary"
                    size="sm"
                    onClick={continueSession}
                >
                    {t('modalElementLabels$idletimeout$modal$continue')}
                </AccentButton>
            </StyledDefaultModalFooter>
        </>
    );
};

export default IdleTimeoutAlert;
