import React, { useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    ucmsConstants as UCMS,
    associatePivResetCardId,
    PROGRESS_BAR_DONE_DELAY,
} from '../../constants';
import { postAssociateAction } from '../../utils/walkways';
import ProgressTemplate from '../shared/ProgressTemplate';
import { AppDispatchContext, AppStateContext } from '../../context/AppContext';
import CardEnrollLayout from '../shared/CardEnrollLayout';
import { useDidMount } from '../../utils/hooks';

const RESET_PIV = 'reset';

const AssociatePivReset = ({ reader, associateUser, mgmt }) => {
    const { webpcscMode } = useContext(AppStateContext);
    const { t } = useTranslation();
    const [, setPivResult] = useState({});
    const [showNext, setShowNext] = useState(false);
    const [stage, setStage] = useState(t('pivActionInitialState'));
    const dispatch = useContext(AppDispatchContext);

    const setResetProgress = (hook, data) => {
        if (hook === UCMS.stage) {
            setStage(data);
        }
    };

    const setPivResetResult = async (json) => {
        setPivResult({ ...json });
        setShowNext(true);
        if (mgmt && json.step === UCMS.done) {
            setTimeout(mgmt.next, PROGRESS_BAR_DONE_DELAY);
        }
    };

    useDidMount(() => {
        postAssociateAction(
            setPivResetResult,
            setResetProgress,
            dispatch,
            {
                pin: mgmt.formValues.pinForm,
                user: associateUser,
                action: RESET_PIV,
                reader,
            },
            webpcscMode,
        );
    });

    return (
        <CardEnrollLayout
            cardTitle={t('cardTitles$associateSupport')}
            testId={associatePivResetCardId}
            title={t('resetProgress')}
            alert={t('enrollTemplateAlertDefault')}
            showNext={false}
            mgmt={mgmt}
        >
            <ProgressTemplate
                isFake
                isDone={showNext}
                stage={`${t('pivActionPrefix')} ${stage}`}
            />
        </CardEnrollLayout>
    );
};

AssociatePivReset.propTypes = {
    reader: PropTypes.object.isRequired,
    associateUser: PropTypes.string.isRequired,
    mgmt: PropTypes.object.isRequired,
};

export default AssociatePivReset;
