import PCSC from './pcsc';
import UCMS from './ucms';
import { deriveUrl, fetchJson, queryParamsToQueryString } from './generic';
import { errorFactory, declareError } from './errors';
import { rootPath, whfbPlaceholder } from '../constants';

export async function fetchInsertedCredentialFlow(
    setters,
    type,
    serial,
    queryParams,
    username,
) {
    let json = {};
    try {
        const queryString = queryParamsToQueryString(queryParams);
        json = await fetchJson(
            `${rootPath}/api/credentialing/insertedDevice/${username}/${type}/${serial}${queryString}`,
        );
        if (!Array.isArray(json)) {
            throw errorFactory.server(null, null, json);
        }
        setters.setCredentialFlow(json);
        setters.setWorkflow(json);
    } catch (error) {
        console.error('fetchInsertedCredentialFlow:', error);
        declareError(setters.dispatch, error);
    }
    return json;
}

export async function fetchNormalCredentialFlow(setters, username, option) {
    let json = {};
    try {
        json = await fetchJson(
            `${rootPath}/api/credentialing/${option}/${username}`,
        );
        if (!Array.isArray(json)) {
            throw errorFactory.server(null, null, json);
        }
        setters.setCredentialFlow(json);
        setters.setWorkflow(json);
    } catch (error) {
        console.error('fetchMobileCredentialFlow:', error);
        declareError(setters.dispatch, error);
    }
    return json;
}

export async function fetchOtpTokenCredentialFlow(setters, username) {
    let json = {};
    try {
        json = await fetchJson(
            `${rootPath}/api/credentialing/otpToken/${username}`,
        );
        if (!Array.isArray(json)) {
            throw errorFactory.server(null, null, json);
        }
        setters.setCredentialFlow(json);
        setters.setWorkflow(json);
    } catch (error) {
        console.error('fetchOtpCredentialFlow:', error);
        declareError(setters.dispatch, error);
    }
    return json;
}

export async function fetchMobileOtpTokenCredentialFlow(setters, username) {
    let json = {};
    try {
        json = await fetchJson(
            `${rootPath}/api/credentialing/mobileOtpToken/${username}`,
        );
        if (!Array.isArray(json)) {
            throw errorFactory.server(null, null, json);
        }
        setters.setCredentialFlow(json);
        setters.setWorkflow(json);
    } catch (error) {
        console.error('fetchmobileOtpCredentialFlow:', error);
        declareError(setters.dispatch, error);
    }
    return json;
}

export async function fetchCredentialingDevices(
    setCredentialingDevices,
    dispatch,
    username,
) {
    const TAG = 'fetchCredentialingDevices';
    try {
        const json = await fetchJson(
            `${rootPath}/api/credentialing/user/${username}`,
        );
        setCredentialingDevices(json);
    } catch (error) {
        console.error(TAG, ':', error);
        declareError(dispatch, error);
        return false;
    }
    return true;
}

export async function fetchCredentialingQuery(
    setCredentialingQuery,
    dispatch,
    identifiedDevices,
    username,
) {
    try {
        const json = await fetchJson(
            `${rootPath}/api/credentialing/insertedDevice/${username}/query`,
            identifiedDevices,
        );
        setCredentialingQuery(json);
    } catch (error) {
        console.error('fetchCredentialingQuery:', error);
        declareError(dispatch, error);
    }
}

export async function fetchEnrollU2fRequest(
    setEnrollU2fRequest,
    dispatch,
    register,
    deviceData,
    username,
) {
    try {
        const json = await fetchJson(
            `${rootPath}/api/credentialing/enroll/${username}/u2fEnroll`,
            deviceData,
        );
        setEnrollU2fRequest(json);
        register(json.request);
    } catch (error) {
        console.error('fetchEnrollU2fRequest:', error);
        declareError(dispatch, error);
    }
}

export async function postEnrollU2fRequest(
    setU2fResult,
    dispatch,
    u2fdata,
    username,
) {
    try {
        const json = await fetchJson(
            `${rootPath}/api/credentialing/enroll/${username}/u2fEnroll`,
            u2fdata,
        );
        setU2fResult(json);
    } catch (error) {
        console.error('postEnrollU2fRequest:', error);
        declareError(dispatch, error);
    }
}

export const postEnrollTPA = async (
    setTPAResult,
    dispatch,
    deviceData,
    username,
) => {
    try {
        const json = await fetchJson(
            `${rootPath}/api/credentialing/enroll/${username}/tmpPwdEnroll`,
            deviceData,
        );
        setTPAResult(json);
    } catch (error) {
        console.error('postEnrollOtpAssign:', error);
        declareError(dispatch, error);
    }
};

export async function postEnrollOtpAssign(
    setOtpResult,
    dispatch,
    deviceData,
    username,
) {
    try {
        const json = await fetchJson(
            `${rootPath}/api/credentialing/enroll/${username}/otpEnroll`,
            deviceData,
        );
        setOtpResult(json);
    } catch (error) {
        console.error('postEnrollOtpAssign:', error);
        declareError(dispatch, error);
    }
}

export async function postEnrollMobileOtpAssign(
    setOtpResult,
    dispatch,
    deviceData,
    username,
) {
    try {
        const json = await fetchJson(
            `${rootPath}/api/credentialing/enroll/${username}/mobileOtpEnroll`,
            deviceData,
        );
        setOtpResult(json);
    } catch (error) {
        console.error('postEnrollOtpAssign:', error);
        declareError(dispatch, error);
    }
}

export async function postEnrollQuestion(
    setOtpResult,
    dispatch,
    deviceData,
    username,
) {
    try {
        const json = await fetchJson(
            `${rootPath}/api/credentialing/enroll/${username}/questionEnroll`,
            deviceData,
        );
        setOtpResult(json);
    } catch (error) {
        console.error('postEnrollQuestion:', error);
        declareError(dispatch, error);
    }
}

export async function postValidateQuestion(
    setOtpResult,
    dispatch,
    challenges,
    username,
    serial
) {
    try {
        const json = await fetchJson(
            `${rootPath}/api/user/${username}/security/${serial}/validate`,
            { challenges },
        );
        setOtpResult(json);
    } catch (error) {
        console.error('postValidateQuestion:', error);
        declareError(dispatch, error);
    }
}

export async function postEnrollIdWallet(
    setIdWalletResult,
    dispatch,
    deviceData,
    username,
) {
    try {
        const json = await fetchJson(
            `${rootPath}/api/credentialing/enroll/${username}/idWalletEnroll`,
            deviceData,
        );
        setIdWalletResult(json);
    } catch (error) {
        console.error('postEnrollIdWallet:', error);
        declareError(dispatch, error);
    }
}

export const postFido2Enroll = async (
    issuanceType,
    setFido2Result,
    setProgress,
    dispatch,
    deviceData,
    username,
) => {
    try {
        const json = await fetchJson(
            `${rootPath}/api/credentialing/enroll/${username}/${issuanceType}`,
            { ...deviceData }, // this needs further discussion about if it's a fido2 specific device.
            'POST',
        );
        console.log('******************** Enrolling finished:: ', json);

        const result = await UCMS.apdu.run(
            null,
            json.process,
            json.session,
            setProgress,
            deriveUrl(`${rootPath}/api`),
        );

        setFido2Result(result);
    } catch (error) {
        console.error('postEnrollfido2:', error);
        declareError(dispatch, error);
    }
};

export async function postEnrollpiv(
    setPivResult,
    setProgress,
    dispatch,
    deviceData,
    username,
    webpcscMode,
) {
    try {
        const json = await fetchJson(
            `${rootPath}/api/credentialing/enroll/${username}/pivEnroll`,
            { ...deviceData },
        );
        let reader = null;
        let result = null;
        try {
            await PCSC.ready;
            if (deviceData.type !== whfbPlaceholder.subType) {
                switch (webpcscMode) {
                    case 'smart':
                        reader = await PCSC.connectWithFallback(deviceData.reader);
                        break;
                    case 'nonExclusive':
                        reader = await PCSC.connect(deviceData.reader, false);
                        break;
                    case 'exclusive':
                    default:
                        reader = await PCSC.connect(deviceData.reader, true);
                        break;
                }
            }
            result = await UCMS.apdu.run(
                reader,
                json.process,
                json.session,
                setProgress,
                deriveUrl(`${rootPath}/api`),
            );
        } catch (error) {
            console.error('postEnrollpiv error:', error);
            throw errorFactory.ucms(undefined, undefined, error);
        } finally {
            if (reader && deviceData.type !== whfbPlaceholder.subType) await reader.disconnect();
        }
        setPivResult(result);
    } catch (error) {
        console.error('postEnrollpiv:', error);
        declareError(dispatch, error);
    }
}
