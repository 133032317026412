import React from 'react';
import { DeviceDetailsButton as DetailsButton } from './styles';
import { ReactComponent as Article } from '../../../images/article.svg';

const DeviceDetailsButton = ({ onClick }) => (
    <DetailsButton type="button" onClick={onClick}>
        <Article />
    </DetailsButton>
);

export default DeviceDetailsButton;
