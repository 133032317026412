import styled from 'styled-components';

export const TableIcon = styled.img`
    transform: rotate(${({ isExpanded }) => (isExpanded ? '180deg' : '0deg')});
    transition-property: transform;
    transition-duration: 0.9s;
    transition-timing-function: cubic-bezier(0.77, 0.35, 0.46, 1.04);
`;

export const TableRowExpantionButton = styled.button`
    outline: none;
    border: none;
    background-color: transparent;
    align-self: center;
`;
