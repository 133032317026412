import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import isEmpty from 'lodash.isempty';
import { terminusCardId, paths, KIOSK_MODE } from '../../constants';
import CardTemplate from '../shared/CardTemplate';
import { BrandingStateContext } from '../../context/BrandingContext';
import { EnrollingDispatchContext } from '../../context/EnrollingContext';
import { PortalContainer, CardHeader, StyledAlert } from '../../styles/common';
import { AppStateContext, AppDispatchContext } from '../../context/AppContext';
import GoBackButton from '../shared/GoBackButton';
import { useDidMount } from '../../utils/hooks';

const BodyContainer = styled.div`
    margin-left: 0rem;
    margin-right: 0rem;
`;

const TerminusCard = ({ msg, title, errorMsg, info, path }) => {
    const { t } = useTranslation();
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const dispatch = useContext(AppDispatchContext);
    const { viewMode } = useContext(AppStateContext);
    const { kiosk } = useContext(BrandingStateContext);
    const isKiosk = viewMode === KIOSK_MODE;
    const message = errorMsg || msg || t('terminusDefault');
    const hideBackBtn =
        isKiosk && !isEmpty(kiosk) && kiosk.terminusSuccessButton === 'false';

    useDidMount(() => {
        enrollingDispatch({
            type: 'RESET_CONTEXT',
        });
    });

    const clearKioskAction = () => {
        if (!errorMsg) dispatch({ type: 'CLEAR_KIOSK_ACTION' });
    };
    return (
        <CardTemplate title={title} testId={terminusCardId}>
            <PortalContainer data-testid="terminus">
                <BodyContainer>
                    <CardHeader>
                        {t('terminusCardLabels$stateless$h6')}
                    </CardHeader>
                    <StyledAlert color={errorMsg ? 'danger' : 'success'}>
                        {message}
                    </StyledAlert>
                    {info !== null && info !== undefined
                        && (
                            <StyledAlert color='info'>
                                {t(info)}
                            </StyledAlert>
                        )
                    }
                    {!hideBackBtn && (
                        <GoBackButton onClick={clearKioskAction} path={path} />
                    )}
                </BodyContainer>
            </PortalContainer>
        </CardTemplate>
    );
};

TerminusCard.propTypes = {
    title: PropTypes.string,
    errorMsg: PropTypes.string,
    msg: PropTypes.string,
    path: PropTypes.string,
};

TerminusCard.defaultProps = {
    title: '',
    errorMsg: null,
    msg: null,
    path: paths.identities,
};

export default TerminusCard;
