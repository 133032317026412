import React, { useContext, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { AppStateContext, AppDispatchContext } from '../../context/AppContext';
import { useLazyFetchData } from '../../utils/hooks';
import { formatPukText } from '../../utils/stringUtil';
import LoadingSpinner from '../shared/LoadingSpinner';

const DeviceInfo = ({ type, serial, user, puk, cr}) => {
    const { t } = useTranslation();
    const listItems = [
        { value: type, label: 'modalElementLabels$details$columns$type' },
        { value: serial, label: 'modalElementLabels$details$columns$serial' },
        { value: user, label: 'modalElementLabels$details$columns$user' },
        { value: puk, label: 'modalElementLabels$details$columns$puk' },
        { value: cr, label: 'modalElementLabels$details$columns$cr' },
    ];

    return (
        <>
            {listItems.map((item) =>
                item.value ? (
                    <Row key={`${item.label}-${item.value}`}>
                        <Col xs="3">{`${t(item.label)} : `}</Col>
                        <Col xs="6">{item.value}</Col>
                    </Row>
                ) : null,
            )}
        </>
    );
};

const PukTable = ({ challenge }) => {
    const { device } = useContext(AppStateContext);
    const { serial, user, type } = device || {};
    const dispatch = useContext(AppDispatchContext);

    const [pukCallback, { loading, data, error }] = useLazyFetchData({
        endpoint: `/device/puk${challenge ? '-cr' : ''}/${type}/${serial}`,
    });

    useEffect(() => {
        pukCallback({
            user, // required by audit 
            challenge,
        });
    }, [user, challenge]);

    useEffect(() => {
        if (error) dispatch({ type: 'CLEAR_MODAL' });
    }, [error, dispatch]);

    return loading || !data ? (
        <LoadingSpinner />
    ) : (
        <DeviceInfo serial={serial} user={user} type={type} puk={!challenge && data.result} cr={challenge && formatPukText(data.result)} />
    );
};

PukTable.propTypes = {
    challenge: PropTypes.string,
};

PukTable.defaultProps = {
    challenge: null,
};
export default PukTable;
