import React, { createContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';

const initialState = {
    isUnread: false,
    isOpen: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'OPEN_DROPDOWN':
            return { ...state, isOpen: true };
        case 'CLOSE_DROPDOWN':
            return { ...state, isOpen: false };
        case 'MARK_UNREAD':
            return { ...state, isUnread: true };
        case 'MARK_READ':
            return { ...state, isUnread: false };
        default:
            return state;
    }
};

export const NotificationStateContext = createContext();
export const NotificationDispatchContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <NotificationStateContext.Provider value={state}>
            <NotificationDispatchContext.Provider value={dispatch}>
                {children}
            </NotificationDispatchContext.Provider>
        </NotificationStateContext.Provider>
    );
};

NotificationProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
