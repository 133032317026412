import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import i18next from 'i18next';
import TerminalError from '../shared/TerminalError';
import WebpcscError from '../shared/WebpcscError';
import { WEBPCSC_ERROR, homePath } from '../../constants';
import { StyledNavIcon, AccentButton } from '../../styles/common';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.handleEnter = this.handleEnter.bind(this);
    }

    state = {
        hasError: false,
        errorMsg: '',
    };

    handleEnter(e) {
        const { history } = this.props;
        const {
            location: { pathname },
        } = history || {};
        if (e.keyCode === 13 && (this.state.hasError || this.props.hasError)) {
            history.push(pathname || homePath);
        }
    }

    componentDidMount() {
        const { history } = this.props;
        history.listen((location, action) => {
            if (this.state.hasError || this.props.hasError) {
                this.setState({
                    hasError: false,
                    errorMsg: '',
                });
                this.props.appDispatch({ type: 'CLEAR_ERROR' });
            }
        });
        document.addEventListener('keyup', this.handleEnter);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleEnter);
    }

    componentDidCatch(error, info) {
        console.error('ErrorBoundary componentDidCatch error:', error);
        console.error('ErrorBoundary componentDidCatch info:', info);
        this.setState({
            hasError: true,
            errorMsg: i18next.t(`errorMsgs$${error.message}`),
        });
    }

    displayError = () => {
        const { errorType, errorMsg, history } = this.props;
        let Cmp = TerminalError;
        if (errorType === WEBPCSC_ERROR) {
            Cmp = WebpcscError;
        }
        const {
            location: { pathname },
        } = history || {};
        return (
            <Cmp error={this.state.hasError ? this.state.errorMsg : errorMsg}>
                <Row>
                    <Col sm="12">
                        <Link to={pathname || homePath}>
                            <AccentButton outline size="sm">
                                <StyledNavIcon icon="check" />
                                {i18next.t(
                                    'terminusCardLabels$stateless$errorOk',
                                )}
                            </AccentButton>
                        </Link>
                    </Col>
                </Row>
            </Cmp>
        );
    };

    render() {
        const hasError =
            this.state.hasError === true || this.props.hasError === true;
        return (
            <Fragment>
                {hasError ? this.displayError() : this.props.children}
            </Fragment>
        );
    }
}

export default withRouter(ErrorBoundary);
