import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    deviceTypes,
    deviceSubTypes,
} from '../../../utils/mappings';

const DeviceName = ({ device }) => {
    const { t } = useTranslation();

    const { type, subType, label } = device || {};
    const deviceMapping = {
        ...deviceTypes[type],
        ...deviceSubTypes[subType],
    };

    return (
        <span>
            {t(deviceMapping.label) || label|| t('unknownDevice')}
        </span>
    );
};

DeviceName.propTypes = {
    device: PropTypes.object.isRequired,
};

DeviceName.defaultProps = {};

export default DeviceName;
