import React, { useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Alert } from 'reactstrap';
import { AppDispatchContext } from '../../context/AppContext';

const DismissableAlert = ({ color, children }) => {
    const appDispatch = useContext(AppDispatchContext);
    const [visible, setVisible] = useState(true);

    const onDismiss = () => {
        setVisible(false);
        appDispatch({ type: 'CLEAR_ERROR' });
    };

    return (
        <Alert color={color} isOpen={visible} toggle={onDismiss}>
            {children}
        </Alert>
    );
};

DismissableAlert.propTypes = {
    color: PropTypes.string,
    children: PropTypes.node.isRequired,
};

DismissableAlert.defaultProps = {
    color: 'primary',
};

export default DismissableAlert;
