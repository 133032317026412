export const reportsGraphQLBody = ({
    searchFilters,
    sortBy,
    sortDir,
    limit = 50,
    piOffset = 0,
    ucmsOffset = 0,
    searchPi = true,
    searchUcms = true,
    fields = [
        'credId',
        'credType',
        'credStatus',
        'credProfile',
        'credIssuingCA',
        'credValidityStart',
        'credValidityEnd',
        'deviceId',
        'deviceType',
        'deviceStatus',
        'deviceCategory',
        'deviceProfile',
        'deviceWorkflow',
        'deviceAllocationDate',
        'deviceRevocationDate',
        'deviceRevocationReason',
        'deviceLastAccess',
        'userId',
        'userEmail',
        'userGroup',
        'userCreationDate',
    ],
}) => {
    const searchGroups = [{ mode: 'and', filters: [] }];
    searchFilters.forEach((filter, i) => {
        if (
            filter.logicalOperator &&
            filter.logicalOperator.toLowerCase() === 'or' &&
            i > 0
        ) {
            searchGroups.push({ mode: 'and', filters: [] });
        }
        searchGroups[searchGroups.length - 1].filters.push(filter);
    });
    const querySearchGroups = searchGroups.map(
        (sg) => `{
                mode: "${sg.mode}",
                filters: [
                    ${sg.filters.map((filter) => `{
                        name: "${filter.name}",
                        values: [${filter.values.map((fil) => `"${fil}"`).join(',')}]
                        ${filter.operation ? `operation: "${filter.operation}"` : ''}
                    }`,).join(',')}
                ]
        }`,
    );
    const query = `{
        deviceInfo(
            searchMode: "or"
            searchGroups: [${querySearchGroups}]
            sortBy: "${sortBy}"
            sortDir: "${sortDir}"
            pageInfo: {
                limit: ${limit}
                ${searchPi ? `pi: {
                  offset: ${piOffset}
                }` : ''}
                ${searchUcms ? `ucms: {
                  offset: ${ucmsOffset}
                }` : ''}
              }
        ){
            pageInfo {
                ${ucmsOffset === 0 && searchUcms && piOffset === 0 && searchPi ? 'count' : ''}
                limit
                ucms {
                    offset
                    ${ucmsOffset === 0 && searchUcms ? 'count' : ''}
                    hasNext
                }
                pi {
                    offset
                    ${piOffset === 0 && searchPi ? 'count' : ''}
                    hasNext
                }
            }
            devices {
                ${fields.join(' ')}
            }
        }
    }`;
    return {
        query,
    };
};
