import React, { useEffect, useState, useContext, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    enrollQuestionCardId,
    serverConstants as SERVER,
    PROGRESS_BAR_DONE_DELAY,
} from '../../constants';
import { postEnrollQuestion } from '../../utils/credentialing';
import ProgressTemplate from '../shared/ProgressTemplate';
import { AppDispatchContext } from '../../context/AppContext';
import CardEnrollLayout from '../shared/CardEnrollLayout';
import { EnrollingStateContext } from '../../context/EnrollingContext';

const EnrollQuestionCard = ({ mgmt = null }) => {
    const { device, username } = useContext(EnrollingStateContext);
    const { t } = useTranslation();
    const dispatch = useContext(AppDispatchContext);

    const [, setResult] = useState({});
    const [showNext, setShowNext] = useState(false);
    const usernameRef = useRef(null);

    useEffect(() => {
        if (usernameRef.current !== username) {
            usernameRef.current = username;

            const setOtpResult = async (json) => {
                setResult({ ...json });
                setShowNext(true);
                if (mgmt && json.result === SERVER.success) {
                    dispatch({
                        type: 'SET_SECURITY',
                        security: [{ 
                            enrolled: true,
                            type: "securityQuestion"
                        }],
                    });
                    setTimeout(mgmt.next, PROGRESS_BAR_DONE_DELAY);
                }
            };
            const requestData = {};
            for (let i = 0; i < device.totalQuestions; i++) {
                if (device[`answer${i}`])
                    requestData[device[`question${i}`]] = device[`answer${i}`];
            }
            postEnrollQuestion(
                setOtpResult,
                dispatch,
                { questions: requestData, serial: device.questionSerial },
                username,
            );
        }
    }, [username, device, dispatch, mgmt]);

    return (
        <CardEnrollLayout
            cardTitle={t('cardTitles$securityInfo')}
            testId={enrollQuestionCardId}
            title={t('securityProgress')}
            alert={t('enrollTemplateAlertDefault')}
            showBack={false}
            showNext={showNext}
            mgmt={mgmt}
        >
            <ProgressTemplate
                isFake
                isDone={showNext}
                stage={t('deviceActionStage$question')}
            />
        </CardEnrollLayout>
    );
};

EnrollQuestionCard.propTypes = {
    mgmt: PropTypes.object,
};

EnrollQuestionCard.defaultProps = {
    mgmt: null,
};

export default EnrollQuestionCard;
