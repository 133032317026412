import React, { useState, useRef, useContext } from 'react';
import { Col, Row, Input} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { AppStateContext, AppDispatchContext } from '../../../context/AppContext';
import { HelpDeskStateContext, HelpDeskDispatchContext } from '../../../context/HelpDeskContext';
import { useLazyPatchData } from '../../../utils/hooks';
import { queryParamsToQueryString } from '../../../utils/generic';

const StyledSaveIcon = styled(FontAwesomeIcon)`
`;
const StyledCancelTcon= styled(FontAwesomeIcon)`
    margin-left: 5px;
`;
const StyledLabelRow= styled(Row)`
    margin-right: 5px;
`;
const StyledLabelCol= styled(Col)`
    background: ${({ background }) => background };
    width: ${({ width }) => `${width}px` };
    min-height: 30px;
`;

const DeviceFriendlyName = ({ device }) => {
    const { user : loggedinUser, devices} = useContext(AppStateContext)
    const { searchData } = useContext(HelpDeskStateContext);
    const dispatch = useContext(AppDispatchContext);
    const helpdeskDispatch = useContext(HelpDeskDispatchContext);
    const [enableEditbBg, setEnableEditbBg] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const {type, subType, serial, user} = device
    const [friendlyName, setFriendlyName] = useState(device.friendlyName)
    const prevValue = useRef(friendlyName)
    const updateDeviceData = (data) => {
        return data.map(x => (x.serial === device.serial ? { ...x, friendlyName: prevValue.current } : x));
    }

    const handleSuccess = () => {
        console.log(`Successfully updated friendly name`);
        if(devices){
            dispatch({ type: 'SET_DEVICES', devices: updateDeviceData(devices)});
        }
        // update helpdesk table if searched/loaded
        if(searchData && searchData.length > 0){
            helpdeskDispatch({
                type: 'SET_SEARCH_DATA',
                payload: updateDeviceData(searchData),
            });
        }
    }

    const [patchCallback] = useLazyPatchData({
        onSuccess: handleSuccess
    });

    const handleSave = () => {
        prevValue.current = friendlyName
        const queryString = queryParamsToQueryString({subType});
        patchCallback({
            endpoint: `/device/update/${type}/${serial}${queryString}`,
            body: { friendlyName, user },
        });
        setEditMode(false)
    }

    const handleCancel = () => {
        setEditMode(false)
        setFriendlyName(prevValue.current)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter')  handleSave()    
        if (e.key === 'Escape') handleCancel()
    };

    // to be removed when friendly name is enabled for pi devcies.
    const piDevices = ["apn", "hotp", "totp", "mobileOtpToken"];

    const hasEditPrivilege = !piDevices.includes(type) && loggedinUser?.username?.toLowerCase() === user?.toLowerCase();

    const renderBackground = enableEditbBg ? 'white': undefined;

    return (
        <>
            {editMode ? (
                <StyledLabelRow>
                    <StyledLabelCol sm={8} width={300}>
                        <Input
                            name="friendlyName"
                            type="input"
                            bsSize="sm"
                            value={friendlyName}
                            maxLength={60}
                            onChange={(e) => setFriendlyName(e.target.value)}
                            onKeyDown={handleKeyDown}
                            autoFocus
                            data-testid="frnameInput"
                        />
                    </StyledLabelCol>
                    <Col sm={4} className="px-0">
                        <StyledSaveIcon icon="check-circle" onClick={() => handleSave()} data-testid="saveFrname" />
                        <StyledCancelTcon icon="times-circle" onClick={() => handleCancel()} />
                    </Col>
                </StyledLabelRow>
            )

                : (
                    <StyledLabelRow
                        onMouseEnter={()=> setEnableEditbBg(hasEditPrivilege)}
                        onMouseLeave={()=> setEnableEditbBg(false)}
                    >
                        <StyledLabelCol
                            onClick={() => setEditMode(hasEditPrivilege)}
                            background={renderBackground}
                            width={250}
                            data-testid="frnameLabel"
                        >
                            {friendlyName}
                        </StyledLabelCol>
                    </StyledLabelRow>
                )
            }

        </>
    );
};

export default DeviceFriendlyName;
