import React from 'react';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const StyledCheckPinIcon = styled(FontAwesomeIcon)`
    margin-left: -25px;
    margin-right: 0.5rem;
`;

const CheckPinIcon = ({ valid }) => {
    const icon = valid ? 'check-circle' : 'times-circle';
    const status = valid ? 'text-success' : 'text-danger';
    return <StyledCheckPinIcon className={status} icon={icon} size="sm" />;
};

CheckPinIcon.propTypes = {
    valid: PropTypes.bool.isRequired,
};

export default CheckPinIcon;
