import React, { useContext } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { AppStateContext } from '../../context/AppContext';
import { GROWLER_ERROR } from '../../constants';
import DismissableAlert from '../elementLibrary/DismissableAlert';
import { PortalContainer } from '../../styles/common';

const GrowlerError = () => {
    const { t } = useTranslation();
    const { hasError, errorType, errorMsg } = useContext(AppStateContext);
    if (!(hasError && errorType === GROWLER_ERROR)) {
        return null;
    }
    return (
        <PortalContainer>
            <Row>
                <Col sm="12">
                    {hasError && errorType === GROWLER_ERROR && (
                        <DismissableAlert color="danger">
                            {errorMsg || t('errorDefault')}
                        </DismissableAlert>
                    )}
                </Col>
            </Row>
        </PortalContainer>
    );
};

export default GrowlerError;
