import React, { useEffect, useState, useContext, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Countdown from 'react-countdown';
import {
    ucmsConstants as UCMS,
    enrollPivCardId,
    PROGRESS_BAR_DONE_DELAY,
    whfbPlaceholder,
    vscPlaceholder,
} from '../../constants';
import { postEnrollpiv } from '../../utils/credentialing';
import ProgressTemplate from '../shared/ProgressTemplate';
import { AppDispatchContext, AppStateContext } from '../../context/AppContext';
import CardEnrollLayout from '../shared/CardEnrollLayout';
import { EnrollingStateContext } from '../../context/EnrollingContext';

const EnrollPivCard = ({ mgmt }) => {
    const { reader, device, username } = useContext(EnrollingStateContext);
    const dispatch = useContext(AppDispatchContext);
    const { webpcscMode } = useContext(AppStateContext);
    const { t } = useTranslation();
    const [, setResult] = useState({});
    const [showNext, setShowNext] = useState(false);
    const [stage, setStage] = useState(t('pivActionInitialState'));
    const usernameRef = useRef(null);

    const setProgress = (hook, data) => {
        if (hook === 'stage') {
            setStage(data);
        }
    };

    useEffect(() => {
        if (usernameRef.current !== username) {
            usernameRef.current = username;

            const setPivResult = async (json) => {
                setResult({ ...json });
                setShowNext(true);
                if (mgmt && json.step === UCMS.done) {
                    if (reader.subType === vscPlaceholder.subType || reader.subType === whfbPlaceholder.subType) {
                        mgmt.setTerminusInfo('issuanceSuccessPostWindows');
                    } else {
                        mgmt.setTerminusInfo('issuanceSuccessPostInfo');
                    }
                    setTimeout(mgmt.next, PROGRESS_BAR_DONE_DELAY);
                }
            };
            let deviceData = {
                serial: reader.serial,
                device: reader.device,
                group: reader.groups[0],
                reader: reader.readerName,
                type: reader.subType || reader.type,
            };
            // WHFB does not have pin field in the payload, otherwise ucms returns error
            if (reader.subType !== whfbPlaceholder.subType) {
                deviceData = {
                    ...deviceData,
                    pin: device.pin,
                };
            }

            postEnrollpiv(
                setPivResult,
                setProgress,
                dispatch,
                deviceData,
                username,
                webpcscMode,
            );
        }
    }, [username, device, reader, dispatch, mgmt, webpcscMode]);

    const renderer = ({ seconds }) => {
        return <p>{t('instructionMessages$touchFeitian', { seconds })}</p>;
    };

    return (
        <CardEnrollLayout
            cardTitle={t('cardTitles$issueIdentity')}
            testId={enrollPivCardId}
            title={t('issuanceProgress')}
            alert={t('enrollTemplateAlertDefault')}
            showNext={showNext}
            mgmt={mgmt}
        >
            {/* Onlye feitian token required a countdown. */}
            {stage === 'reset_containers' && reader.subType === 'feitian' && <Countdown date={Date.now() + 30000} renderer={renderer} />}
            <ProgressTemplate
                isFake
                isDone={showNext}
                stage={`${t('pivActionPrefix')} ${stage}`}
            />
        </CardEnrollLayout>
    );
};

EnrollPivCard.propTypes = {
    mgmt: PropTypes.object,
};

EnrollPivCard.defaultProps = {
    mgmt: null,
};

export default EnrollPivCard;
