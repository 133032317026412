import React from 'react';
import { PropTypes } from 'prop-types';
import { Col, FormGroup, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { validateDeviceCardId } from '../../constants';
import CardLayout from '../shared/NewCardLayout';
import { PortalContainer, CardHeader, StyledAlert } from '../../styles/common';
import PassInput from '../formLibrary/Pass';
import { deviceTypes } from '../../utils/mappings';

const ValidateDevice = ({
    title,
    subtitle = '',
    dispatch,
    state: { disableBack, disableNext },
    device,
    withPin,
}) => {
    const { t } = useTranslation();
    const { serial, type, label } = device || {};

    const serialConfirmation = t('walkways$serialConfirmation', {
        SERIAL: serial,
        TYPE: label || t(type ? deviceTypes[type].label : 'unknownDevice'),
    });

    const setPass = (event) => {
        dispatch({ type: 'SET_PASS', payload: event });
    };

    return (
        <CardLayout
            title={title}
            id={validateDeviceCardId}
            dispatch={dispatch}
            disableBack={disableBack}
            disableNext={disableNext}
        >
            <PortalContainer>
                <Row>
                    <Col sm="12">
                        <CardHeader>
                            {subtitle || serialConfirmation}
                        </CardHeader>
                    </Col>
                </Row>
                <StyledAlert color="info">
                    {withPin? t('instructionMessages$validateWithPin') : t('instructionMessages$validateWithoutPin')} 
                </StyledAlert>
                <FormGroup autoComplete="off">
                    <PassInput setPass={setPass} withPin={withPin} />
                </FormGroup>
            </PortalContainer>
        </CardLayout>
    );
};

ValidateDevice.propTypes = {
    title: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.object,
};

export default ValidateDevice;
