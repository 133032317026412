import React, { useEffect, useState, useContext, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { postEnrollIdWallet } from '../../utils/credentialing';
import { AppDispatchContext } from '../../context/AppContext';
import CardEnrollLayout from '../shared/CardEnrollLayout';
import { EnrollingStateContext } from '../../context/EnrollingContext';
import { postData } from '../../utils/postData';
import {
    serverConstants,
    enrollApnCardId,
    walletIconId,
} from '../../constants';
import LoadingSpinner from '../shared/LoadingSpinner';

const WalletIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
const WalletIconImage = styled.img`
    height: 150px;
    width: 150px;
`;

const WalletIcon = ({ image, alt }) => (
    <WalletIconContainer>
        <WalletIconImage src={image} alt={alt} data-test-id={walletIconId} />
    </WalletIconContainer>
);

WalletIcon.propTypes = {
    image: PropTypes.string,
    alt: PropTypes.string,
};

WalletIcon.defaultProps = {
    image: '',
    alt: 'Wallet ID',
};

const EnrollIdWallet = ({ mgmt = null }) => {
    const { device } = useContext(EnrollingStateContext);
    const dispatch = useContext(AppDispatchContext);
    const { username } = useContext(EnrollingStateContext);
    const { t } = useTranslation();
    const [apnImage, setApnImage] = useState(null);
    const [showNext, setShowNext] = useState(false);

    const usernameRef = useRef(null);

    useEffect(() => {
        let checkApnEnroll;
        if (usernameRef.current !== username) {
            usernameRef.current = username;

            const apnPolling = ({ result, img, serial }) => {
                if (result === serverConstants.success && username) {
                    setApnImage(img);
                    const retryTimeout = 4000;
                    checkApnEnroll = setInterval(async () => {
                        const res = await postData({
                            endpoint: `/credentialing/idWallet/${username}/query`,
                            body: { serial },
                        });
                        const json = await res.json();
                        if (json.status === 'available') {
                            clearInterval(checkApnEnroll);
                            setShowNext(true);
                        }
                    }, retryTimeout);
                }
            };
            postEnrollIdWallet(
                apnPolling,
                dispatch,
                {
                    pin: device.pin,
                },
                username,
            );
        }

        return () => clearInterval(checkApnEnroll);
    }, [username, device.pin, dispatch]);

    return (
        <CardEnrollLayout
            id={postEnrollIdWallet}
            cardTitle={t('cardTitles$issueIdentity')}
            title={t('issuanceProgress')}
            testId={enrollApnCardId}
            alert={t('enrollIdWalletCardLabels$alert')}
            showNext={showNext}
            mgmt={mgmt}
        >
            {apnImage === null && <LoadingSpinner />}
            {apnImage && (
                <WalletIcon
                    image={apnImage}
                    alt={t('enrollIdWalletCardLabels$imgAlt')}
                />
            )}
        </CardEnrollLayout>
    );
};

EnrollIdWallet.propTypes = {
    mgmt: PropTypes.object,
};

EnrollIdWallet.defaultProps = {
    mgmt: null,
};

export default EnrollIdWallet;
