import React, { useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
    validateQuestionCardId,
    serverConstants as SERVER,
    PROGRESS_BAR_DONE_DELAY,
} from '../../constants';
import { postValidateQuestion } from '../../utils/credentialing';

import ProgressTemplate from '../shared/ProgressTemplate';
import { AppDispatchContext } from '../../context/AppContext';

import CardEnrollLayout from '../shared/CardEnrollLayout';
import { EnrollingStateContext } from '../../context/EnrollingContext';
import { useDidMount } from '../../utils/hooks';

const ValidateQuestionCard = ({ mgmt = null, deviceUser: user = '' }) => {
    const dispatch = useContext(AppDispatchContext);
    const { username,  userEntry: { components }, } = useContext(EnrollingStateContext);
    const { t } = useTranslation();
    const [showNext, setShowNext] = useState(false);

    const setOtpResult = async (json) => {
        setShowNext(true);
        if (mgmt && json.result === SERVER.success) {
            setTimeout(mgmt.next, PROGRESS_BAR_DONE_DELAY);
        }
    };

    useDidMount(() => {
        const { challenges } = mgmt;
        const serial = components[1].details.questions[0].serial || null;
        const pathUsername = user || username;
        postValidateQuestion(setOtpResult, dispatch, challenges, pathUsername, serial);
    });

    return (
        <CardEnrollLayout
            testId={validateQuestionCardId}
            cardTitle={t('cardTitles$securityInfo')}
            title={t('securityProgress')}
            alert={t('enrollTemplateAlertDefault')}
            showBack
            showNext={showNext}
            mgmt={mgmt}
        >
            <ProgressTemplate
                isFake
                isDone={showNext}
                stage={t('deviceActionStage$validateQuestion')}
            />
        </CardEnrollLayout>
    );
};

ValidateQuestionCard.propTypes = {
    mgmt: PropTypes.object,
    deviceUser: PropTypes.string,
};

ValidateQuestionCard.defaultProps = {
    mgmt: null,
    deviceUser: '',
};

export default ValidateQuestionCard;
