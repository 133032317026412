import React, { useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Col } from 'reactstrap';
import PukTable from '../elementLibrary/PukTable';
import {
    StyledInput,
    AccentButton,
    StyledFormGroup,
    StyledDefaultModalHeader,
    StyledDefaultModalBody,
    StyledDefaultModalFooter,
    StyledAlert,
} from '../../styles/common';
import { AppDispatchContext } from '../../context/AppContext';
import { useHandleEnter } from '../../utils/hooks';
import { formatPukText } from '../../utils/stringUtil';

const PukChallengeTable = () => {
    const [input, setInput] = useState('');
    const [challenge, setChallenge] = useState('');
    const dispatch = useContext(AppDispatchContext);
    const { t } = useTranslation();
    const inputRef = useRef();
    inputRef.current = input?.replace(/\s/g, "")?.trim();

    const validateInput = (input) =>  [16,32].includes(input?.replace(/\s/g, "")?.length)

    const submit = () => {
        if (validateInput(inputRef.current)) setChallenge(inputRef.current);
    };

    useHandleEnter({ action: submit });

    return (
        <>
            <StyledDefaultModalHeader>
                {t(`modalLabels$retrievePukChallenge$title`)}
            </StyledDefaultModalHeader>
            <StyledDefaultModalBody>
                <StyledAlert color="info">
                    {t(`modalLabels$retrievePukChallenge$info`)}
                </StyledAlert>
                <StyledFormGroup row autoComplete="off">
                    <Col xs="3">
                        <Label for="pukChallenge">
                            {t('modalLabels$retrievePukChallenge$input')} :
                        </Label>
                    </Col>
                    <Col xs="6">
                        <StyledInput
                            name="pukChallenge"
                            bsSize="sm"
                            value={input}
                            valid={validateInput(input)}
                            invalid={input && !validateInput(input)}
                            placeholder={t(
                                'modalLabels$retrievePukChallenge$placeholder',
                            )}
                            style={{width:'100%'}}
                            onChange={(e) => setInput(formatPukText(e.target.value))}
                            data-testid="pukCRInput"
                        />
                    </Col>
                </StyledFormGroup>
                {challenge && <PukTable challenge={challenge} />}
            </StyledDefaultModalBody>
            <StyledDefaultModalFooter>
                <AccentButton
                    outline
                    color="primary"
                    size="sm"
                    onClick={submit}
                    aria-disabled={!validateInput(input)}
                    disabled={!validateInput(input)}
                    data-testid="pukCRSubmit"
                >
                    {t('modalLabels$submit')}
                </AccentButton>
                <AccentButton
                    outline
                    color="primary"
                    size="sm"
                    onClick={() =>
                        dispatch({
                            type: 'CLEAR_MODAL',
                        })
                    }
                >
                    {t('modalLabels$close')}
                </AccentButton>
            </StyledDefaultModalFooter>
        </>
    );
};

export default PukChallengeTable;
