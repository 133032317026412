import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceStatusContainer } from '../../../styles/common';
import { activeColor, disabledColor } from '../../../constants';

const DeviceStatus = ({ device = {}, status = '' }) => {
    const { t } = useTranslation();
    const { serial } = device || {};

    const statusValue = status.toLowerCase();
    const color = {
        active: { ...activeColor },
        available: { ...activeColor },
        suspended: { ...disabledColor },
        revoked: { ...disabledColor },
        expired: { ...disabledColor },
        default: { ...disabledColor },
        notset: { ...disabledColor },
    };
    return (
        <DeviceStatusContainer
            id={`status_${serial}`}
            status={color[statusValue] || color.default}
        >
            {t(statusValue)}
        </DeviceStatusContainer>
    );
};

export default DeviceStatus;
