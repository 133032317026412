import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Devices from './Devices';
import Scanner from './Scanner';
import Users from './Users';
import CardTemplate from '../../shared/CardTemplate';
import { helpDeskCardId, subTabsPaths } from '../../../constants';
import { AppStateContext } from '../../../context/AppContext';
import { TabsStateContext, TabsDispatchContext } from '../../../context/TabsContext';
import {
    helpdeskAllowedPrivileges,
    isAllowed,
} from '../../../utils/authorization';
import {
    StyledAlert,
    PortalContainer,
    AccentButton,
} from '../../../styles/common';

const ComponentsList = ({ tab }) => {
    switch (tab) {
        case subTabsPaths.helpDesk.users:
            return <Users />;
        case subTabsPaths.helpDesk.devices:
            return <Devices />;
        case subTabsPaths.helpDesk.scanner:
            return <Scanner />;
        default:
            return null;
    }
};

const HelpDesk = () => {
    const { user } = useContext(AppStateContext);
    const { activeIndex, tabs } = useContext(TabsStateContext);
    const dispatch = useContext(TabsDispatchContext);
    const history = useHistory();
    const { location } = history || {};
    const { t } = useTranslation();

    const { title, subtitle, route } = tabs[activeIndex] || {};

    useEffect(() => {
        if (tabs.length > 0) {
            const index = tabs.findIndex((item) =>
                location.pathname.includes(item.route),
            );
            if(index > 0) dispatch({ type: 'SET_ACTIVE_INDEX', payload: index });
            
        } 
    }, [location, tabs]);

    if (
        user &&
        user.privileges &&
        !isAllowed({
            allowedPermissions: helpdeskAllowedPrivileges,
            permissions: user.privileges,
        })
    ) {
        return (
            <CardTemplate
                title={t(title)}
                subtitle={t(subtitle)}
                testId={helpDeskCardId}
            >
                <PortalContainer>
                    <StyledAlert
                        id="invalid-assurance-alert"
                        color="warning"
                        fade={false}
                    >
                        {t('notAuthorized$higherAssuranceNecessary')}
                    </StyledAlert>
                    <a href={`https://${window.location.host}/operator`}>
                        <AccentButton outline color="primary" size="sm">
                            {t('notAuthorized$reloginBtn')}
                        </AccentButton>
                    </a>
                </PortalContainer>
            </CardTemplate>
        );
    }

    return (
        <CardTemplate
            title={t(title)}
            subtitle={t(subtitle)}
            testId={helpDeskCardId}
        >
            <ComponentsList tab={route} />
        </CardTemplate>
    );
};

export default HelpDesk;
