import i18next from 'i18next';
import PCSC from './pcsc';
import { TERMINAL_ERROR, AC_AWAIT_MARKER, WEBPCSC_ERROR } from '../constants';

function emptyString(str) {
    return !str || str === 'null';
}

function formatError(code, msg, errorDefault) {
    let message = errorDefault;

    if (!emptyString(code) || !emptyString(msg)) {
        message += " (";

        if (!emptyString(msg)) {
            message += `Error Message: ${msg}`;
        }
        if (!emptyString(msg) && !emptyString(code)) {
            message += ", ";
        }
        if (!emptyString(code)) {
            message += `Error Code: ${code}`;
        }

        message += ")";
    }
    return message;
}

function errorGuard(key) {
    return i18next.t(`errorMsgs$${key}`) || null;
}

// https://stackoverflow.com/a/43595019
function ServerError(...args) {
    const instance = Reflect.construct(Error, args);
    Reflect.setPrototypeOf(instance, Reflect.getPrototypeOf(this));
    return instance;
}
ServerError.prototype = Object.create(Error.prototype, {
    constructor: {
        value: Error,
        enumerable: false,
        writable: true,
        configurable: true,
    },
});
Reflect.setPrototypeOf(ServerError, Error);

// https://stackoverflow.com/a/43595019
function TimeoutError(...args) {
    const instance = Reflect.construct(Error, args);
    Reflect.setPrototypeOf(instance, Reflect.getPrototypeOf(this));
    return instance;
}
TimeoutError.prototype = Object.create(Error.prototype, {
    constructor: {
        value: Error,
        enumerable: false,
        writable: true,
        configurable: true,
    },
});
Reflect.setPrototypeOf(TimeoutError, Error);

// https://stackoverflow.com/a/43595019
function UcmsError(...args) {
    const instance = Reflect.construct(Error, args);
    Reflect.setPrototypeOf(instance, Reflect.getPrototypeOf(this));
    return instance;
}
UcmsError.prototype = Object.create(Error.prototype, {
    constructor: {
        value: Error,
        enumerable: false,
        writable: true,
        configurable: true,
    },
});
Reflect.setPrototypeOf(UcmsError, Error);

// https://stackoverflow.com/a/43595019
function WebpcscError(...args) {
    const instance = Reflect.construct(Error, args);
    Reflect.setPrototypeOf(instance, Reflect.getPrototypeOf(this));
    return instance;
}
WebpcscError.prototype = Object.create(Error.prototype, {
    constructor: {
        value: Error,
        enumerable: false,
        writable: true,
        configurable: true,
    },
});
Reflect.setPrototypeOf(WebpcscError, Error);

// https://stackoverflow.com/a/43595019
function Err(...args) {
    const instance = Reflect.construct(Error, args);
    Reflect.setPrototypeOf(instance, Reflect.getPrototypeOf(this));
    return instance;
}
Err.prototype = Object.create(Error.prototype, {
    constructor: {
        value: Error,
        enumerable: false,
        writable: true,
        configurable: true,
    },
});
Reflect.setPrototypeOf(Err, Error);

const errorFactory = {
    err: (message = 'default', type = TERMINAL_ERROR, json = null) => {
        Err.prototype.type = type;
        Err.prototype.json = json;
        return new Err(message);
    },
    server: (message = 'serverDefault', type = TERMINAL_ERROR, json = null) => {
        ServerError.prototype.type = type;
        ServerError.prototype.json = json;
        return new ServerError(message);
    },
    timeout: (
        message = 'timeoutDefault',
        type = TERMINAL_ERROR,
        json = null,
    ) => {
        TimeoutError.prototype.type = type;
        TimeoutError.prototype.json = json;
        return new TimeoutError(message);
    },
    ucms: (message = 'ucmsDefault', type = TERMINAL_ERROR, json = null) => {
        UcmsError.prototype.type = type;
        UcmsError.prototype.json = json;
        return new UcmsError(message);
    },
    webpcsc: (
        message = 'webpcscDefault',
        type = WEBPCSC_ERROR,
        json = null,
    ) => {
        WebpcscError.prototype.type = type;
        WebpcscError.prototype.json = json;
        return new WebpcscError(message);
    },
};

function declareError(dispatch, e) {
    const TAG = 'declareError';
    let message = '';
    console.error(TAG, 'error:', e);

    if (e instanceof ServerError) {
        console.error(
            TAG,
            'instance of ServerError:',
            e instanceof ServerError,
        );
        console.error(TAG, 'error:', e.json);
        const obj = e.json;
        if (typeof obj.code === "string") {
            const codeArr = obj.code.split('.');
            message = errorGuard(codeArr[codeArr.length - 1] || obj.message);
        } else {
            message = errorGuard(obj.code || obj.message);
        }
        if (message === null) {
            message = formatError(
                obj.code,
                obj.message,
                i18next.t('errorMsgs$default'),
            );
        }
    } else if (e instanceof TimeoutError) {
        console.error(
            TAG,
            'instance of TimeoutError:',
            e instanceof TimeoutError,
        );
        message = errorGuard(e.message);
        if (message === null) {
            message = formatError(
                null,
                e.message,
                i18next.t('errorMsgs$default'),
            );
        }
    } else if (e instanceof WebpcscError) {
        console.error(
            TAG,
            'instance of WebpcscError:',
            e instanceof WebpcscError,
        );
        const { marker } = e.json || {};
        message = e.json;
        if (marker === AC_AWAIT_MARKER) {
            message.diagnoses = [
                {
                    cause: i18next.t('errorMsgs$ac_await_cause'),
                    solution: i18next.t('errorMsgs$ac_await_solution'),
                },
            ];
        }
    } else if (e instanceof UcmsError) {
        console.error(TAG, 'instance of UcmsError:', e instanceof UcmsError);
        const obj = e.json;
        const sw_msg = PCSC.sw(obj.code);
        message = sw_msg || errorGuard(obj.msg) || errorGuard(obj.code);
        if (message === null) {
            message = formatError(
                obj.code,
                obj.msg,
                i18next.t('errorMsgs$default'),
            );
        }
    } else if (e instanceof Err) {
        console.error(TAG, 'instance of Err:', e instanceof Err);
        message = errorGuard(e.message);
        if (message === null) {
            message = formatError(
                null,
                e.message,
                i18next.t('errorMsgs$default'),
            );
        }
    } else if (e instanceof Error) {
        console.error(TAG, 'instance of Error:', e instanceof Error);
        message = errorGuard(e.message);
        if (message === null) {
            message = formatError(
                null,
                e.message,
                i18next.t('errorMsgs$default'),
            );
        }
    } else {
        // This arm is where all errors thrown by pcsc.js (WebPCSC itself) will be handled.
        // WebPCSC errors are all objects containing "code" and "msg".
        console.error(TAG, 'Unknown error:', e);

        if (typeof e.code === "string") {
            const codeArr = e.code.split('.');
            message = errorGuard(codeArr[codeArr.length - 1] || e.message);
        } else {
            message = errorGuard(e.code || e.message);
        }
        // message = errorGuard(e.code || e.message);

        /**
         * Modern WebPCSC 1.x error codes generally follow the given format:
         *
         * <module>:<error or API name>[/<specific variant>]
         *
         * The module and error/API name make up the "generic" part of the error
         * code that is easy to localize.
         *
         * If the entire code (e.g. "whfb:ApiCall/0x1234ABCD") didn't have a
         * localization, then try again using just the first part
         * (e.g. "whfb:ApiCall").
         *
         * This, of course, only applies to errors coming out of WebPCSC.
         */
        if (message === null && e.code instanceof String) {
            const split = e.code.split("/");

            if (split.length > 1) {
                message = errorGuard(split[0]);
            }
        }

        if (message === null) {
            message = formatError(
                e.code,
                e.msg || e.message,
                i18next.t('errorMsgs$default'),
            );
        }
    }
    const errorType = e.type !== undefined ? e.type : TERMINAL_ERROR;
    dispatch({ type: 'ADD_ERROR', message, errorType });
    console.error(TAG, 'message:', message);
    console.error(TAG, 'type:', errorType);
}

export {
    errorFactory,
    declareError,
    ServerError,
    TimeoutError,
    UcmsError,
    WebpcscError,
    Err,
};
