import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { AccentButton, StyledModal } from '../../../../styles/common';
import { postData } from '../../../../utils/postData';
import { StyledHeader, StyledBody, StyledFooter } from './styles';

const RevokeSecurityQuestionModal = ({ isOpen, setIsOpen, revokeCallback }) => {
    const { username } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        const handleEnter = (e) => {
            if (e.keyCode === 13 && isOpen) {
                setIsOpen(false);
            }
        };
        document.addEventListener('keyup', handleEnter);
        return () => document.removeEventListener('keyup', handleEnter);
    }, []);

    const onResetSecurityQuestionClick = async () => {
        setIsOpen(false);
        const res = await postData({
            endpoint: `/user/${username}/security/reset`,
        });
        const json = await res.json();
        if (json.result === 'success') {
            revokeCallback();
        }
    };

    return (
        <StyledModal
            centered
            size="lg"
            isOpen={isOpen}
            toggle={() => setIsOpen(false)}
        >
            <StyledHeader>
                {t('usersDetails$securityQuestionTitle')}
            </StyledHeader>
            <StyledBody>{t('usersDetails$securityQuestionMessage')}</StyledBody>
            <StyledFooter>
                <AccentButton
                    outline
                    color="primary"
                    size="sm"
                    onClick={onResetSecurityQuestionClick}
                >
                    {t('modalElementLabels$modal$confirm')}
                </AccentButton>
                <AccentButton
                    outline
                    color="primary"
                    size="sm"
                    onClick={() => setIsOpen(false)}
                >
                    {t('modalElementLabels$modal$cancel')}
                </AccentButton>
            </StyledFooter>
        </StyledModal>
    );
};
export default RevokeSecurityQuestionModal;
