import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import uniqueId from 'lodash.uniqueid';
import {
    paths,
    userInfoRowContainerId,
    userInfoUpdateSecurityQuestionId,
    userInfoSecurityQuestionAlertWarning,
} from '../../../constants';
import {
    StyledNavIcon,
    StyledAlert,
    AccentButton,
} from '../../../styles/common';

const InfoContainer = styled(Container)`
    text-align: left;
`;
const UserInfoRow = styled(Row)`
    padding-top: 5px;
    padding-bottom: 5px;
`;

const SecurityInfoRows = ({ security }) => {
    const { t } = useTranslation();
    const question = security.find(element => element.type === 'securityQuestion');
    if (!question) {
        return null;
    }
    return (
        <InfoContainer data-test-id={userInfoRowContainerId}>
            <Row>
                <Col>
                    <Link
                        to={{
                            pathname: paths.updateSecurity,
                        }}
                    >
                        <AccentButton
                            outline
                            color="primary"
                            size="sm"
                            data-test-id={userInfoUpdateSecurityQuestionId}
                        >
                            <StyledNavIcon icon="edit" />
                            {t('userInfo$securityQuestionTitle')}
                        </AccentButton>
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col>
                    <StyledAlert
                        color={question.enrolled ? 'info' : 'warning'}
                        data-test-id={userInfoSecurityQuestionAlertWarning}
                    >
                        {question.enrolled
                            ? t('userInfo$securityQuestionCompleted')
                            : t('userInfo$securityQuestionPending')}
                    </StyledAlert>
                </Col>
            </Row>
        </InfoContainer>
    );
};

SecurityInfoRows.propTypes = {
    security: PropTypes.array.isRequired,
};

const UserInfoRows = ({ user }) => {
    const { t } = useTranslation();

    if (!user) {
        return null;
    }

    const attributes = {};
    attributes.fullName =
        [user.firstName, user.lastName].filter((name) => name).join(' ') || '';
    ['username', 'email', 'roles', 'groups'].forEach((attr) => {
        attributes[attr] = user[attr] || '';
    });
    user.customAttributes.forEach((attr) => {
        attributes[attr.key] = attr.value;
    });

    return (
        <InfoContainer data-test-id={userInfoRowContainerId}>
            {Object.keys(attributes).map((attr) => {
                let value;
                if (attributes[attr] instanceof Array) {
                    value = (
                        <Col>
                            {attributes[attr].map((value) => (
                                <Row key={value}>{value}</Row>
                            ))}
                        </Col>
                    );
                } else {
                    value = (
                        <Col>
                            <Row>{attributes[attr]}</Row>
                        </Col>
                    );
                }
                return (
                    <UserInfoRow key={uniqueId('userInfoRow_')}>
                        <Col xs="2">
                            {`${
                                t(`userInfo$attributes$${attr}`) ||
                                attr.charAt(0).toUpperCase() + attr.substring(1)
                            }:`}
                        </Col>
                        {value}
                    </UserInfoRow>
                );
            })}
        </InfoContainer>
    );
};

UserInfoRows.propTypes = {
    user: PropTypes.object.isRequired,
};

const UserInfoWithData = ({ user, security }) => {
    if (user !== undefined) {
        return <UserInfoRows user={user} />;
    }
    if (security !== undefined) {
        return <SecurityInfoRows security={security} />;
    }
    return null;
};

UserInfoWithData.propTypes = {
    user: PropTypes.object,
    security: PropTypes.array,
};

UserInfoWithData.defaultProps = {
    user: undefined,
    security: undefined,
};

export default UserInfoWithData;
