import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import ErrorBoundary from '../elementLibrary/ErrorBoundary';
import { AppStateContext, AppDispatchContext } from '../../context/AppContext';
import { usePageTitle } from '../../utils/hooks';

const CardContainer = styled.div`
    padding-left: 30px;
    padding-right: 30px;
`;

const CardComponentRoute = ({ component: Component, path, name }) => {
    const { device, hasError, errorType, errorMsg, errorStatus } = useContext(
        AppStateContext,
    );
    const appDispatch = useContext(AppDispatchContext);
    usePageTitle({ title: `pageTitle$${name}` });

    return (
        <main role="main">
            <CardContainer>
                <Route
                    exact
                    device={device}
                    path={path}
                    render={() => (
                        <ErrorBoundary
                            hasError={hasError}
                            errorType={errorType}
                            errorMsg={errorMsg}
                            errorStatus={errorStatus}
                            appDispatch={appDispatch}
                        >
                            <Component />
                        </ErrorBoundary>
                    )}
                />
            </CardContainer>
        </main>
    );
};

CardComponentRoute.propTypes = {
    path: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    component: PropTypes.elementType.isRequired,
};

export default CardComponentRoute;
