import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import UserId from '../formLibrary/UserId';
import {
    EnrollingDispatchContext,
    EnrollingStateContext,
} from '../../context/EnrollingContext';

const UserAssociate = ({
    nextShow,
    setShowNext,
    serverComponent,
    validUser,
    loading,
}) => {
    const { form } = useContext(EnrollingStateContext);
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const [registered, setRegistered] = useState({});

    useEffect(() => {
        return () => {
            enrollingDispatch({
                type: 'RESET_FORM',
            });
        };
    },[]);

    
    useEffect(() => {
        const validateForm = (state) => {
            const valid = Object.keys(state.registered).every((key) => {
                if (!(key in form.values)) {
                    return false;
                }
                const result = state.registered[key](form.values[key]);
                return result === true;
            });
            if (
                nextShow !== undefined &&
                setShowNext !== undefined &&
                valid !== nextShow
            ) {
                setShowNext(valid);
            }
        };
        validateForm({ registered });
    }, [form, registered, setShowNext, nextShow]);

    const registerForm = (forms) => {
        Object.keys(forms).forEach((name) => {
            if (name in registered) {
                throw Error(
                    `Naming conflict: ${name} form has already been registered`,
                );
            }
        });
        const newRegistered = {
            ...registered,
            ...forms,
        }
        setRegistered(newRegistered);
    };

    return (
        <UserId
            serverComponent={serverComponent}
            registerForm={registerForm}
            validUser={validUser}
            loading={loading}
        />
    );
};

UserAssociate.propTypes = {
    serverComponent: PropTypes.object,
    nextShow: PropTypes.bool,
    setShowNext: PropTypes.func.isRequired,
};

UserAssociate.defaultProps = {
    serverComponent: undefined,
    nextShow: true,
};

export default UserAssociate;
