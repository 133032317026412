import React, { useEffect, useState, useContext, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    enrollOtpCardId,
    serverConstants as SERVER,
    PROGRESS_BAR_DONE_DELAY,
} from '../../constants';
import { postEnrollOtpAssign } from '../../utils/credentialing';
import ProgressTemplate from '../shared/ProgressTemplate';
import { AppDispatchContext } from '../../context/AppContext';
import CardEnrollLayout from '../shared/CardEnrollLayout';
import { EnrollingStateContext } from '../../context/EnrollingContext';

const EnrollOtpCard = ({ mgmt }) => {
    const { reader, device, username } = useContext(EnrollingStateContext);
    const dispatch = useContext(AppDispatchContext);
    const { t } = useTranslation();
    const [, setResult] = useState({});
    const [showNext, setShowNext] = useState(false);
    const usernameRef = useRef(null);

    useEffect(() => {
        if (usernameRef.current !== username) {
            const setOtpResult = async (json) => {
                setResult({ ...json });
                setShowNext(true);
                if (mgmt && json.result === SERVER.success) {
                    setTimeout(mgmt.next, PROGRESS_BAR_DONE_DELAY);
                }
            };

            usernameRef.current = username;
            const otpData = {
                pin: device.pin,
                serial: reader && reader.serial ? reader.serial : device.serial,
            };
            if (reader) {
                otpData.type = reader.subType;
            }
            postEnrollOtpAssign(setOtpResult, dispatch, otpData, username);
        }
    }, [username, device.pin, device.serial, dispatch, reader, mgmt]);

    return (
        <CardEnrollLayout
            cardTitle={t('cardTitles$issueIdentity')}
            testId={enrollOtpCardId}
            title={t('issuanceProgress')}
            alert={t('enrollTemplateAlertDefault')}
            showNext={false}
            mgmt={mgmt}
        >
            <ProgressTemplate
                isFake
                isDone={showNext}
                stage={t('deviceActionsStage$otp')}
            />
        </CardEnrollLayout>
    );
};

EnrollOtpCard.propTypes = {
    mgmt: PropTypes.object,
};

EnrollOtpCard.defaultProps = {
    mgmt: null,
};

export default EnrollOtpCard;
