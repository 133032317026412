import React, { useState, useEffect, useContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';
import { useHistory, Link } from 'react-router-dom'; // eslint-disable-line import/no-extraneous-dependencies
import styled from 'styled-components';
import {
    DropdownMenu,
    DropdownItem,
    NavbarToggler,
    Container,
    Nav,
    DropdownToggle,
    Collapse,
    Navbar,
    UncontrolledDropdown,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Avatar from 'react-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    branding,
    paths,
    rootPath,
    homePath,
    scaffoldingLogoutLinkId,
    scaffoldingDropdownToggleId,
    userEndpoint,
    securityEndpoint,
    metadataEndpoint,
    KIOSK_MODE,
    navIdentitiesId,
    navDelegationId,
    navHelpDeskId,
    navDropdownHelpId,
    navDropdownSettingsId,
    navDropdownAccountId,
    navDropdownLogoutId,
    navReportsId,
    navUserFeedbackId,
} from '../../constants';
import { StyledNavItem, StyledNavLink } from '../../styles/common';
import { AppDispatchContext, AppStateContext } from '../../context/AppContext';
import { TabsDispatchContext } from '../../context/TabsContext';
import { useFetchData } from '../../utils/hooks';
import LoadingSpinner from '../shared/LoadingSpinner';
import {
    isAllowed,
    identitiesAllowedPrivileges,
    delegationAllowedPrivileges,
    helpdeskAllowedPrivileges,
    reportsAllowedPrivileges,
} from '../../utils/authorization';
import { BrandingStateContext } from '../../context/BrandingContext';
import TabNav from './TabNav';
import Notifications from '../elementLibrary/Notifications';
import help from '../../images/help.svg';

const StyledAvatar = styled(Avatar)`
    background-color: ${({ color }) => color} !important;
`;
const LogoutBtn = styled.a`
    &:hover {
        text-decoration: none;
        color: ${({ theme }) => theme.typography.light};
        background-color: ${({ theme }) => theme.colors.dark};
    }
    color: ${({ theme }) => theme.typography.light};
    background-color: ${({ theme }) => theme.colors.primary};
    padding: 0.75rem;
    padding-left: 0.85rem;
    padding-right: 0.85rem;
    border-radius: 0.25rem;
    display: flex;
    justify-content: space-around;
    min-width: 7.5rem;
    align-items: center;
`;
const HeaderSpan = styled.span`
    padding-left: 16px;
    padding-right: 4px;
    text-transform: none;
    letter-spacing: 0.06em;
`;
const HeaderImage = styled.img`
    max-height: 30px;
    padding-right: 0.25rem;
`;
const HeaderNavbar = styled(Navbar)`
    text-transform: uppercase;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 30px !important;
    padding-left: 30px !important;
    background-color: ${({ theme }) => theme.colors.foreground} !important;
    color: ${({ theme }) => theme.typography.text} !important;
    border-radius: 0;
    box-shadow: none;
    border: none;
    margin-bottom: 0;
    @media only screen and (min-width: ${({ expand }) => expand === 'lg' ? '992px' : '768px'}) {
        height: 76px;
    }
`;
const HeaderContainer = styled(Container)`
    padding-left: 15px !important;
    padding-right: 15px !important;
`;
const HeaderNavbarLink = styled(StyledNavLink).attrs({
    className: 'nav-link',
})`
    font-family: Poppins, san-serif !important;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.06em;
    color: ${({ theme, active }) =>
        active === 'true'
            ? theme.colors.primary
            : theme.typography.anchor} !important;
    &.nav-link:hover:focus:active {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.primary} !important;
    }
    :hover {
        color: ${({ theme }) => theme.colors.primary} !important;
    }
    @media only screen and (min-width: ${({ $breakPointSize }) => $breakPointSize === 'lg' ? '992px' : '768px'}) {
        padding-top: 32px !important;
        padding-bottom: 20px !important;
        padding-left: 26px !important;
        padding-right: 26px !important;
        border-bottom: ${({ active }) => (active === 'true' ? 3 : 0)}px solid;
        border-color: ${({ theme }) => theme.colors.primary};
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const HeaderLogo = styled.div`
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    padding-right: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    white-space: nowrap;
    text-transform: none;
`;
const HeaderUncontrolledDropdown = styled(UncontrolledDropdown)`
    @media only screen and (max-width: 768px) {
        padding-bottom: 8px;
    }
`;
const HeaderDropdownItem = styled(DropdownItem)`
    font-size: 14px;
    padding-left: 16px !important;
    font-weight: 300 !important;
    text-transform: none !important;
    color: ${({ theme }) => theme.typography.anchor} !important;
    &:hover {
        color: ${({ theme }) => theme.typography.anchorHover} !important;
        text-decoration: none !important;
    }
    &:focus {
        color: ${({ theme }) => theme.typography.anchorHover} !important;
        text-decoration: none !important;
    }
`;
const HeaderDropdownToggle = styled(DropdownToggle)`
    color: ${({ theme }) => theme.typography.anchor} !important;
    &:hover:focus {
        color: ${({ theme }) => theme.typography.anchorHover} !important;
        text-decoration: none !important;
    }
`;
const HeaderDropdownMenu = styled(DropdownMenu)`
    color: ${({ theme }) => theme.typography.anchor} !important;
    background-color: ${({ theme }) => theme.colors.foreground} !important;
    &:hover:focus {
        color: ${({ theme }) => theme.typography.anchorHover} !important;
        text-decoration: none !important;
    }
`;
const LinkStyled = styled(Link)`
    text-decoration: none !important;
    text-transform: none !important;
`;

const HelpLogo = styled.li`
    display: inline-block;
    margin-top: 0.5rem;
    width:24px;
    height:24px;
`;

export const headerTabs = [
    {
        key: navIdentitiesId,
        href: paths.identities,
        title: 'navLabels$identities',
        dispatch: { type: 'CLEAR_KIOSK_ACTION' },
        cond: (settings, permissions) =>
            isAllowed({
                allowedPermissions: identitiesAllowedPrivileges,
                permissions,
            }),
    },
    {
        key: navDelegationId,
        href: paths.delegations,
        title: 'navLabels$delegations',
        cond: (settings, permissions, viewMode) =>
            settings.delegation &&
            isAllowed({
                allowedPermissions: delegationAllowedPrivileges,
                permissions,
            }) &&
            viewMode !== KIOSK_MODE,
    },
    {
        key: navHelpDeskId,
        href: paths.helpDesk,
        title: 'navLabels$helpDesk',
        cond: (settings, permissions, viewMode) =>
            isAllowed({
                allowedPermissions: helpdeskAllowedPrivileges,
                permissions,
            }) && viewMode !== KIOSK_MODE,
    },
    {
        key: navReportsId,
        href: paths.reports,
        title: 'navLabels$reports',
        cond: (settings, permissions, viewMode) =>
            isAllowed({
                allowedPermissions: reportsAllowedPrivileges,
                permissions,
            }) && viewMode !== KIOSK_MODE,
    },
];

const navDropdownItems = [
    {
        key: navDropdownHelpId,
        title: 'navLabels$help',
        id: 'help-link',
        href: (settings) => settings.helpUrl,
        cond: (settings) => !!settings.helpUrl,
        extRoute: true,
    },
    {
        key: navDropdownSettingsId,
        title: 'navLabels$settings',
        id: 'settings-link',
        href: () => `${paths.settings}`,
        cond: () => true,
        noHover: true,
    },
    {
        key: navDropdownAccountId,
        title: 'navLabels$account',
        id: 'account-link',
        href: () => `${paths.account}`,
        cond: () => true,
    },
    {
        key: navUserFeedbackId,
        title: 'navLabels$userFeedback',
        id: 'user-feedback',
        cond: (settings) => !!settings.analyticsDsn,
        href: () => '',
        onClick: (dispatch) => {
            dispatch({
                type: 'SHOW_MODAL',
                modal: {
                    type: 'USER_FEEDBACK',
                },
            });
        },
    },
    {
        key: 'divider',
        type: 'DIVIDER',
        cond: () => true,
    },{
        key: navDropdownLogoutId,
        title: 'scaffoldingLabels$header$signOut',
        id: scaffoldingLogoutLinkId,
        href: () => '',
        cond: () => true,
        post: (settings) => `${rootPath}${settings.logoutUrl}`,
        extRoute: true,
    },
];

const NavDropdownItem = ({ item, settings, t, onClick, dispatch }) => {
    const onClickHandler = () => item.onClick && item.onClick(dispatch);
    if (item.type && item.type === 'DIVIDER') {
        return <DropdownItem divider />;
    }
    if (item.extRoute) {
        if (item.post) {
            return (
                <HeaderDropdownItem
                    id={item.id}
                    data-test-id={item.id}
                    href={item.href(settings)}
                >
                    <form action={item.post(settings)} method="POST">
                        <input
                            type="submit"
                            value={t(item.title)}
                            style={{background: 'none', border: 'none', padding: 0, font: 'inherit', color: 'inherit'}}
                        />
                    </form>
                </HeaderDropdownItem>

            );
        }
        if (item.href) {
            return (
                <HeaderDropdownItem
                    id={item.id}
                    data-test-id={item.id}
                    href={item.href(settings)}
                >
                    {t(item.title)}
                </HeaderDropdownItem>
            );
        }
    }
    return (
        <LinkStyled
            to={item.href(settings)}
            href={item.href(settings)}
            onClick={onClickHandler}
        >
            <HeaderDropdownItem
                onClick={onClick}
                id={item.id}
                data-test-id={item.id}
            >
                {t(item.title)}
            </HeaderDropdownItem>
        </LinkStyled>
    );
};

NavDropdownItem.propTypes = {
    item: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const NavbarTabs = ({
    activeHeaderTabs,
    breakPointSize,
    checkActive,
    onClick,
    translate,
}) => {
    const {
        location: { pathname },
    } = window;
    const isHome = pathname === '/user' || pathname === '/user/';
    const isInsights = pathname === '/user/insights' || pathname === '/user/insights/';
    const isActive = (idx, href) =>
        (
            (isInsights && idx === 0 && !activeHeaderTabs.some(t => t.key === navReportsId)) ||
            (isHome && idx === 0 ) ||
            checkActive(href, window.location.pathname)
        ).toString();
    return activeHeaderTabs.map((entry, idx) => (
        <StyledNavItem key={entry.key} active={isActive(idx, entry.href)}>
            <HeaderNavbarLink
                $breakPointSize={breakPointSize}
                id={entry.key}
                to={entry.href}
                active={isActive(idx, entry.href)}
                onClick={onClick}
            >
                {translate(entry.title)}
            </HeaderNavbarLink>
        </StyledNavItem>
    ));
};

NavbarTabs.propTypes = {
    translate: PropTypes.func.isRequired,
};

const Header = () => {
    const { user, settings, viewMode } = useContext(AppStateContext);
    const { theme, helpLink } = useContext(BrandingStateContext);
    const dispatch = useContext(AppDispatchContext);
    const tabsDispatch = useContext(TabsDispatchContext);
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const { location } = useHistory();
    const [showLogoutBtn, setShowLogoutBtn] = useState(false);
    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    useEffect(() => {
        setShowLogoutBtn(viewMode === KIOSK_MODE);
    }, [viewMode]);

    const [userLoading] = useFetchData({
        endpoint: userEndpoint,
        onSuccess: (data) =>
            dispatch({
                type: 'SET_USER',
                user: data,
            }),
    });
    const [securityLoading] = useFetchData({
        endpoint: securityEndpoint,
        onSuccess: (data) => dispatch({ type: 'SET_SECURITY', security: data }),
    });
    const [metadataLoading] = useFetchData({
        endpoint: metadataEndpoint,
        onSuccess: (data) => {
            dispatch({ type: 'SET_METADATA', metadata: data });
        },
    });
    const toggle = () => {
        setIsOpen(!isOpen);
    };
    const checkActive = (path, currPath) =>
        currPath.includes(path) ||
        (path === homePath &&
            (currPath === rootPath || currPath === `${rootPath}/`));
    const onClick = () => {
        tabsDispatch({ type: 'RESET_CONTEXT' });
        forceUpdate();
    };

    if (userLoading || securityLoading || metadataLoading) {
        return <LoadingSpinner />;
    }

    const firstName = user.firstName ? user.firstName : '';
    const lastName = user.lastName ? ` ${user.lastName}` : '';
    const { higherPrivileges } = user || {};

    const activeHeaderTabs = headerTabs.filter((entry) =>
        entry.cond(settings, higherPrivileges, viewMode),
    );
    const {location: { pathname },} = window;
    const isInsights = pathname === '/user/insights' || pathname === '/user/insights/';

    const breakPointSize = activeHeaderTabs.length < 4 ? 'md' : 'lg';
    return (
        <header role="banner">
            <HeaderNavbar
                light
                className="navigation-clean"
                expand={breakPointSize}
                aria-label="navigation-header"
                role="navigation"
            >
                <HeaderContainer>
                    <HeaderLogo>
                        <StyledNavLink
                            to={isInsights && activeHeaderTabs.some(t => t.key === navReportsId) ? "/insights":"/"}
                            onClick={() =>
                                dispatch({ type: 'CLEAR_KIOSK_ACTION' })
                            }
                        >
                            <HeaderImage
                                src={branding.navLogo.img}
                                alt={branding.navLogo.alt}
                            />
                            {t('scaffoldingLabels$header$portalTitle')}
                        </StyledNavLink>
                    </HeaderLogo>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav navbar aria-label="navigation-tabs">
                            <NavbarTabs
                                activeHeaderTabs={activeHeaderTabs}
                                breakPointSize={breakPointSize}
                                dispatch={dispatch}
                                translate={t}
                                checkActive={checkActive}
                                location={location}
                                onClick={onClick}
                            />
                        </Nav>
                        <Nav
                            className="ml-auto"
                            navbar
                            aria-label="navigation-dropdown"
                        >
                            {user && user.username && <Notifications />}
                            {helpLink.enable &&  (
                                <HelpLogo>
                                    <a href={helpLink.url} target="_blank" rel="noreferrer">
                                        <HeaderImage
                                            src={help}
                                            alt="help"
                                            title={helpLink.tooltip}
                                        />
                                    </a>
                                </HelpLogo>
                            )}
                            <HeaderUncontrolledDropdown nav inNavbar>
                                <HeaderDropdownToggle
                                    data-test-id={scaffoldingDropdownToggleId}
                                    nav
                                    caret
                                >
                                    <StyledAvatar
                                        textSizeRatio={2.33}
                                        size={24}
                                        round
                                        name={`${firstName}${lastName}`}
                                        color={theme.colors.primary}
                                    />
                                    <HeaderSpan>
                                        {`${firstName}${lastName}`}
                                    </HeaderSpan>
                                </HeaderDropdownToggle>
                                <HeaderDropdownMenu right>
                                    {navDropdownItems
                                        .filter((item) => item.cond(settings))
                                        .map((item) => (
                                            <NavDropdownItem
                                                onClick={onClick}
                                                key={item.key}
                                                item={item}
                                                settings={settings}
                                                permissions={higherPrivileges}
                                                t={t}
                                                dispatch={dispatch}
                                            />
                                        ))}
                                </HeaderDropdownMenu>
                            </HeaderUncontrolledDropdown>
                            {showLogoutBtn && (
                                <LogoutBtn>
                                    <form action={`${rootPath}${settings.logoutUrl}`} method="POST">
                                        <input
                                            type="submit"
                                            value={t('scaffoldingLabels$header$logout')}
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                                padding: 0,
                                                font: 'inherit',
                                                color: 'white',
                                                'text-transform': 'uppercase',
                                                display:'inline-block'
                                            }}
                                        />
                                    </form>
                                    <FontAwesomeIcon icon="sign-out-alt" style={{color:'white'}} />
                                </LogoutBtn>
                            )}
                        </Nav>
                    </Collapse>
                </HeaderContainer>
            </HeaderNavbar>
            <TabNav />
        </header>
    );
};

export default Header;


