import React from 'react';
import { Col, Row } from 'reactstrap';
import { PropTypes } from 'prop-types';
import CardTemplate from '../shared/CardTemplate';
import { PortalContainer, StyledAlert } from '../../styles/common';

const MessageCard = ({ title, msg, alertStyle, children }) => (
    <CardTemplate title={title}>
        <PortalContainer>
            <Row>
                <Col sm="12">
                    {msg && <StyledAlert color={alertStyle}>{msg}</StyledAlert>}
                </Col>
            </Row>
            {children}
        </PortalContainer>
    </CardTemplate>
);

MessageCard.propTypes = {
    title: PropTypes.string,
    alertStyle: PropTypes.string,
    msg: PropTypes.string,
    children: PropTypes.node,
};

MessageCard.defaultProps = {
    title: '',
    msg: '',
    alertStyle: 'info',
    children: null,
};

export default MessageCard;
