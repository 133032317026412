import React from 'react';
import TableRowAction from '../TableCore/TableRowAction/TableRowAction';
import DeviceType from './DeviceType';
import DeviceName from './DeviceName';
import DeviceFriendlyName from './DeviceFriendlyName';
import DeviceStatus from './DeviceStatus';
import CredentialLogo from './CredentialLogo';
import DeviceUser from './DeviceUser';
import { sortString, formatDate } from '../../../utils/generic';
import { TableRowExpantionColumn } from '../TableCore';
import DeviceDetailsButton from './DeviceDetailsButton';
import ActionOption from './ActionOption';
import { stringToHash } from '../../../utils/stringUtil';
import {
    deviceTypes,
    deviceSubTypes,
    actionTypes,
    helpDeskActionTypes,
    credentialActionTypes,
} from '../../../utils/mappings';
import { paths, credentialEndpoint } from '../../../constants';
import { TableCellHover } from './styles';

const getActionData = (actionType, pathname) => {
    const action = pathname.includes(paths.helpDesk)
        ? helpDeskActionTypes[actionType]
        : actionTypes[actionType];
    return action;
};

const dispatchDevice = ({ href, pathname, device, dispatch, history }) => {
    dispatch({
        type: 'SET_DEVICE',
        device,
        goBackLink: pathname,
    });
    history.push(href);
};

const dispatchModal = (type, device, dispatch) => {
    dispatch({
        type: 'SHOW_MODAL',
        device,
        modal: {
            type,
        },
    });
};

const showModal = (actionType, device, dispatch) => {
    switch (actionType) {
        // Todo: remove this after BE remove details action
        case 'details':
            dispatchModal('CREDENTIAL_TABLE', device, dispatch);
            break;
        case 'PUK':
            dispatchModal('PUK_TABLE', device, dispatch);
            break;
        case 'PUK_CHALLENGE':
            dispatchModal('PUK_CHALLENGE_TABLE', device, dispatch);
            break;
        default:
            break;
    }
};

const createActionData = ({ actions, pathname, device, dispatch, history }) => {
    const handleClick = (params) => {
        const { effect, href, actionType } = params;
        if (effect === 'link') {
            dispatchDevice({
                href,
                pathname,
                device,
                dispatch,
                history,
            });
        }
        if (effect === 'modal') {
            showModal(actionType, device, dispatch);
        }
    };

    const handleKeyUp = (params) => {
        const { event, actionType, effect, href } = params;
        const { keyCode } = event || {};
        event.preventDefault();
        event.stopPropagation();
        if (keyCode === 13 || keyCode === 32) {
            if (effect === 'link') {
                dispatchDevice({
                    href,
                    pathname,
                    device,
                    dispatch,
                    history,
                });
            } else if (effect === 'modal') {
                showModal(actionType, device, dispatch);
            }
        }
    };
    // create data array for each action
    const actionsData = actions
        .map((actionType) => {
            const actionData = getActionData(actionType, pathname);
            if (!actionData) {
                return null;
            }
            return {
                ...actionData,
                actionType,
                id: `${stringToHash(device.serial)}_${actionType}`,
                onClick: () => handleClick({ ...actionData, actionType }),
                onKeyUp: (e) =>
                    handleKeyUp({ event: e, actionType, ...actionData }),
            };
        })
        .filter((num) => num !== null);

    return actionsData;
};

const cellTypeSelect = (name, data, t) => {
    switch (name) {
        case 'type':
            return <DeviceType device={data} />;
        case 'label':
            return <DeviceName device={data} />;
        case 'friendlyName':
            return <DeviceFriendlyName device={data} />;
        case 'serial': {
            const serial = data.externalIdentifier ? data.externalIdentifier : data[name];
            // TODO: this is a temporary solution to hide the UUID identifier of FIDO2, MS Auth.
            return data.type === 'unqualified_ctap' || data.type === 'windows_ctap'
                || data.type === 'unqualified_msauth' || data.type === 'android_msauth'
                || data.type === 'ios_msauth'
                ? null : <TableCellHover>{serial}</TableCellHover>;
        }
        case 'legibleId': return  <TableCellHover>{data[name] || ''}</TableCellHover>;
        case 'credId':
        case 'deviceId':
            return data[name] || '';
        case 'status':
        case 'credStatus':
        case 'deviceStatus':
            return <DeviceStatus device={data} status={data[name]} />;
        case 'user':
            return <DeviceUser device={data} username={data[name]} />;
        default:
            if (Array.isArray(data[name])) {
                return data[name].join(', ') || ' ';
            }
            if (typeof data[name] === 'boolean') {
                return t(
                    `hideColumns$boolType$${data[name] ? 'true' : 'false'}`,
                );
            }
            return data[name] || ' ';
    }
};

const generateLabel = (data, t) => {
    const d = { ...data }
    const { type, subType, label } = d.original || {};
    const deviceMapping = {
        ...deviceTypes[type],
        ...deviceSubTypes[subType],
    };
    d.values.label = t(label) || t(deviceMapping.label) || t('unknownDevice');
    return d
}

export const createColumnsData = ({
    columnsName,
    actions,
    t,
    setClickedRowIndex,
    enableDeviceDetails,
    clickedRowIndex,
    pathname,
    dispatch,
    history,
    cardType = 'identities',
}) => {
    const columnsData = [];
    if (enableDeviceDetails) {
        columnsData.push({
            // Build our expander column
            id: 'expander', // Make sure it has an ID
            accessor: 'expander',
            // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
            // to build the toggle for expanding a row
            Cell: ({ row }) => {
                return (
                    <TableRowExpantionColumn
                        setClickedRowIndex={setClickedRowIndex}
                        row={row}
                        isExpanded={clickedRowIndex === row.index}
                    />
                );
            },
        });
    }

    columnsName.forEach((name) => {
        columnsData.push({
            Header:
            t(`devicesTable$column$${name}`) ||
            name[0].toUpperCase() + name.slice(1),
            accessor: name,
            Cell: ({ row }) => cellTypeSelect(name, row.original, t),
            sortType: (rowA, rowB, columnId, desc) => {
                let a = { ...rowA }
                let b = { ...rowB }
                if (columnId === 'label') {
                    a = generateLabel(a, t)
                    b = generateLabel(b, t)
                }
                return sortString(a, b, columnId, desc)
            },
        });
    });
    // Set actions column independently
    if (actions) {
        columnsData.push({
            Header: t(`devicesTable$column$action`),
            accessor: 'action',
            Cell: ({ row }) => {
                const { insertedActions, actions: rawActions } =
                    row.original || [];
                // Todo: update BE for removing details
                if (!rawActions) {
                    return <>{t(`actionWarning$${cardType}`)}</>;
                }
                const actions = rawActions
                    .filter((action) => action !== 'details')
                    .concat(insertedActions);
                // The data includes text, href, effect (modal or link) etc.
                const actionsData = createActionData({
                    actions,
                    pathname,
                    device: row.original,
                    dispatch,
                    history,
                });
                const deviceMapping = {
                    ...deviceTypes[row.original.type],
                    ...deviceSubTypes[row.original.subType],
                };
                const disabled =
                    deviceMapping.imgAlt === deviceTypes.default.imgAlt;

                // pass all props to action component
                return (
                    <TableRowAction
                        id={row.original.serial}
                        kioskComponent={(kioskAction) => (
                            <ActionOption
                                device={row.original}
                                option={actionTypes[kioskAction]}
                                kioskAction={kioskAction}
                            />
                        )}
                        disabled={disabled}
                        actionsData={actionsData}
                    />
                );
            },
            disableSortBy: true,
        });
    }
    return columnsData;
};

/** Device credential details table columnData */
const createCredentialActionData = ({
    actions,
    pathname,
    credential,
    dispatch,
    patchCallback,
    redirectionCallback,
    deviceUser
}) => {
    const fetchData = (actionType) => {
        const { effect, href } = credentialActionTypes[actionType];
        if (effect === 'api') {
            dispatch({
                type: 'ADD_LOADING_CREDENTIAL_ID',
                id: credential.identifier,
                goBackLink: pathname,
            });
            patchCallback({
                endpoint: `${credentialEndpoint}/${credential.type}/${credential.identifier}`,
                body: {
                    user: deviceUser,
                    status: actionType,
                    provider: credential.provider
                },
            });
        } else if (effect === 'link') {
            redirectionCallback(href, credential);
        }
    }
    // sub-function to handle onClick
    const handleClick = (params) => {
        const { actionType } = params;
        fetchData(actionType);

    };
    // sub-functions for handle onKeyUp
    const handleKeyUp = (params) => {
        const { event, actionType } = params;
        const { keyCode } = event || {};
        event.preventDefault();
        event.stopPropagation();
        if (keyCode === 13 || keyCode === 32) {
            fetchData(actionType);
        }
    };
    // create data array for each action
    const actionsData = actions
        .map((actionType) => {
            const actionData = credentialActionTypes[actionType];
            if (!actionData) {
                return null;
            }
            return {
                ...actionData,
                actionType,
                id: `${stringToHash(credential.identifier)}_${actionType}`,
                onClick: () => handleClick({ ...actionData, actionType }),
                onKeyUp: (event) =>
                    handleKeyUp({ event, actionType, ...actionData }),
            };
        })
        .filter((num) => num !== null);

    return actionsData;
};

const deviceDetailsColumnOrder = [
    'type',
    'name',
    'provider',
    'identifier',
    'created',
    'status',
    'data',
    'actions',
];
export const createRowDeviceDetails = ({
    columnsName,
    setDetailsModalData = () => { },
    showActionCol = false,
    pathname,
    dispatch,
    patchCallback,
    t,
    redirectionCallback,
    deviceUser
}) => {
    // create column defination
    const columnsData = [];
    deviceDetailsColumnOrder.forEach((name) => {
        if (columnsName.indexOf(name) !== -1) {
            columnsData.push({
                Header: t(`devicesDetailsTable$column$${name}`) || name,
                accessor: name,
                Cell: ({ row }) => {
                    const rowData = row.original;
                    if (name === 'identifier') {

                        // TODO: this is a temporary solution to hide the UUID identifier of FIDO2, WHFB and MS Authenticator.
                        if (rowData.type === 'fido2' || rowData.type === 'whfb' || rowData.type === 'mspush') {
                            return '';
                        }

                        return (
                            <TableCellHover>
                                {row.original.externalIdentifier ? row.original.externalIdentifier : row.original.identifier}
                            </TableCellHover>
                        );
                    }

                    if (name === 'type') {
                        return <CredentialLogo credential={rowData} />;
                    }

                    if (name === 'name') {
                        return t(`credentialLabels$type$${rowData.type?.toLowerCase()}`);
                    }

                    if (name === 'provider') {
                        return t(`credentialLabels$provider$${rowData.provider?.toLowerCase()}`);
                    }

                    if (name === 'created') {
                        return formatDate(rowData[name]);
                    }

                    if (name === 'status') {
                        return (
                            <DeviceStatus
                                device={rowData}
                                status={rowData[name]}
                            />
                        );
                    }

                    if (name === 'data') {
                        return (
                            <DeviceDetailsButton
                                onClick={() =>
                                    setDetailsModalData({
                                        identifier: rowData.identifier,
                                        type: rowData.type,
                                    })
                                }
                            />
                        );
                    }
                    if (name === 'actions' && showActionCol) {
                        const { actions } = row.original || [];
                        // Todo: how should the action perform? (like directly call api or integrate with walkway)
                        if (!actions) return null;
                        const actionsData = createCredentialActionData({
                            actions: [...actions],
                            pathname,
                            credential: row.original,
                            dispatch,
                            patchCallback,
                            redirectionCallback,
                            deviceUser
                        });

                        // pass all props to action component
                        return (
                            <TableRowAction
                                id={row.original.identifier}
                                kioskComponent={(kioskAction) => (
                                    <ActionOption
                                        device={row.original}
                                        option={actionTypes[kioskAction]}
                                        kioskAction={kioskAction}
                                    />
                                )}
                                actionsData={actionsData}
                            />
                        );
                    }
                    return rowData[name] || null;
                },
            });
        }
    });
    return columnsData;
};

export const donwloadCert = (str) => {
    if (str) {
        const element = document.createElement('a');
        element.setAttribute(
            'href',
            `data:text/plain;charset=utf-8,${encodeURIComponent(str)}`,
        );
        element.setAttribute('download', 'certificate.cer');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }
};
