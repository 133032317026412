import React from 'react';
import { useTranslation } from 'react-i18next';
import uniqueId from 'lodash.uniqueid';
import { StyledFlexbox, AccentIcon } from '../../../styles/common';
import { reportsActionTypes } from '../../../utils/mappings';
import DropdownContent from '../../shared/ReportsActionDropdownContent';

const ReportsAction = ({ data }) => {
    const { t } = useTranslation();

    const { actions } = data;
    const actionMapping =
        actions &&
        actions.map((actionType) => {
            const action = reportsActionTypes[actionType];
            if (action === undefined) {
                return { component: null };
            }
            return {
                component: (
                    <StyledFlexbox
                        key={uniqueId('identityDevice_')}
                        direction="row"
                        alignment="center"
                    >
                        {t(action.text)}
                    </StyledFlexbox>
                ),
                text: actionType,
                effect: action.effect,
                href: action.href,
            };
        });

    return (
        <DropdownContent
            reportData={data}
            text={<AccentIcon icon="cog" />}
            options={actionMapping}
        />
    );
};

export default ReportsAction;
