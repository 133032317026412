// npm dependencies
import React from 'react';
import i18next from 'i18next';
import { Alert } from 'reactstrap';
// data dependencies
import PCSC from '../../../utils/pcsc';
import UCMS from '../../../utils/ucms';
import { fetchDiagnosis } from '../../../utils/diagnosis';
import { deriveUrl, sleep } from '../../../utils/generic';
import { fetchCredentialingQuery } from '../../../utils/credentialing';
import {
    apiPath,
    ucmsConstants,
    buildDeviceDropdownDisplaySuccess,
    buildDeviceDropdownDisplayFailure,
    vscPlaceholder,
    whfbPlaceholder,
    atrCases
} from '../../../constants';
// visual dependencies
import DeviceSelectionWithData from './DeviceSelectionWithData';
import LoadingSpinner from '../../shared/LoadingSpinner';

/* *****************************************************************************************
  Warning: this file is a dragon. test carefully after any change.
    the main loop or engine of this component is the handlePcscOnChange function
    be careful while using state, with this component it is very easy
    for weird state changes to conflict or occur out of order
****************************************************************************************** */

const TAG = 'DeviceSelection'; // eslint-disable-line no-unused-vars

function deviceQuery(props, state, reader) {
    return reader.status === 'available'
        ? true
        : i18next.t(`errorMsgs$deviceSelection_status_${reader.status}`);
}

function displayOption(data) {
    console.debug(TAG, 'displayOption data:', data);
    return data.issuable === true
        ? buildDeviceDropdownDisplaySuccess(
              data,
              i18next.t('genericDeviceDropdownLabels$serialNo'),
          )
        : buildDeviceDropdownDisplayFailure(data);
}

class DeviceSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: '',
            readers: {},
            interval: null,
            syncing: false,
            operable: [],
            inoperable: [],
            loadingDevices: true,
            loadingDevicesError: undefined,
        };
    }

    async componentDidMount() {
        try {
            await this.pcscOnChange();
        } catch (err) {
            if (err.diagnoses && err.diagnoses.length) {
                this.setState({ loadingDevicesError: err.diagnoses[0].cause });
            } else
                this.setState({
                    loadingDevicesError: 'Unknown device loading error', // TODO
                });
        } finally {
            this.setState({ loadingDevices: false });
        }
    }

    componentWillUnmount() {
        if (this.state.interval) {
            this.state.interval.stop();
            this.setState({ ...this.state, interval: null });
        }
    }

    setReader = (value) => {
        console.debug(TAG, 'setting reader:', value);
        const empty =
            value === i18next.t('genericDeviceDropdownLabels$defaultOption') ||
            value === i18next.t('genericDeviceDropdownLabels$noDevicesOption');
        this.setState({
            ...this.state,
            selected: empty ? {} : value,
        });
        this.props.enrollingDispatch({
            type: 'SET_SELECTED_READER',
            reader: this.state.readers[value],
        });
    };

    setCredentialingQuery = (json) => {
        console.debug(TAG, 'setCredentialingQuery json:', json);
        const { readers } = this.state;
        Object.keys(readers).forEach((readerName) => {
            const reader = readers[readerName];
            json.forEach((device) => {
                if ((reader.type === device.subType || reader.subType === device.subType)
                    && (device.serial === reader.whfb_deviceId || device.serial === reader.serial)) {
                    readers[readerName] = {
                        ...reader,
                        ...device,
                        groups: device.groups,
                        changed: false,
                    };
                    if (device.serial !== vscPlaceholder.serial &&
                        device.serial !== whfbPlaceholder.serial) {
                        // -------- for vsc "null" CAN BE MADE CLEANER
                        readers[readerName].display = displayOption({
                            ...reader,
                            ...device,
                        });
                    }
                }
            });
        });
        this.setState({
            ...this.state,
            readers: {
                ...this.state.readers,
                ...readers,
            },
        });
        this.filterDevices(deviceQuery);
    };

    setDetectReaders = (added, modified) => {
        // eslint-disable-next-line max-len, no-return-assign, no-param-reassign
        added.forEach(
            (reader) =>
                (modified[reader] =
                    modified[reader] === undefined ? null : modified[reader]),
        );
        const readers = {};
        Object.keys(modified).forEach((reader) => {
            // Skip WHFB if user is not the host
            if (!reader.includes(whfbPlaceholder[0]) || this.props.isHost) {
                readers[reader] = {
                    ...[reader],
                    issuable: i18next.t(atrCases[modified[reader]]) || true,
                    atr: modified[reader],
                    readerName: reader,
                    label: reader,
                    changed: true,
                };
            }
        });
        this.setState({
            ...this.state,
            readers: {
                ...this.state.readers,
                ...readers,
            },
        });
    };

    // need to define other filtering methods
    filterDevices = (check) => {
        const { readers } = this.state;
        Object.keys(readers).forEach((readerName) => {
            const reader = readers[readerName];
            if (
                reader.issuable === true ||
                reader.issuable === i18next.t('errorMsgs$unissuable')
            ) {
                reader.issuable = check(this.props, this.state, reader);
            }
        });
        this.setState({
            ...this.state,
            ...readers,
        });
    };

    removeReaders = (removed) => {
        const { readers } = this.state;
        removed.forEach((readerName) => {
            delete readers[readerName];
        });
        this.setState({
            ...this.state,
            selected: removed.includes(this.state.selected)
                ? ''
                : this.state.selected,
            ...readers,
        });
        this.props.enrollingDispatch({
            type: 'SET_SELECTED_READER',
            reader: removed.includes(this.state.selected)
                ? null
                : this.state.readers[this.state.selected],
        });
    };

    handlePcscOnChange = async (added, removed, modified) => {
        this.setState({ ...this.state, syncing: true });
        if (removed.length > 0) {
            this.removeReaders(removed);
            // eslint-disable-next-line no-param-reassign
            removed.forEach((reader) => delete modified[reader]);
        }
        this.setDetectReaders(added, modified);
        await this.identifyDevices();
        await this.queryDevices();
        if (this.props.isHost) {
            // Skip the VSC and WHFB if it is not the host
            await this.handleVsc();
            await this.handleWhfb();
        }
        this.setState({
            ...this.state,
            syncing: false,
        });
    };

    handleVsc = async () => {
        const { readers } = this.state;
        // disable this code for now to show the placeholder all the time.
        // Enable it once we can differentiate the VSC created by us
        // if (
        //     Object.keys(readers).some(
        //         (reader) =>
        //             readers[reader].subType === vscPlaceholder.subType &&
        //             readers[reader].status === 'available',
        //     )
        // )
        //     return;
        if (await PCSC.supports(vscPlaceholder.subType)) {
            vscPlaceholder.display =
                i18next.t('mappingsLabels$deviceSubTypes$vsc$label') ||
                vscPlaceholder.display;
            this.setState({
                ...this.state,
                readers: {
                    ...this.state.readers,
                    vsc: vscPlaceholder,
                },
            });
            await fetchCredentialingQuery(
                this.setCredentialingQuery,
                this.props.appDispatch,
                [vscPlaceholder.device],
                this.props.username,
            );
        }
    };

    handleWhfb = async () => {
        const { readers } = this.state;
        if (
            Object.keys(readers).some(
                (reader) =>
                    readers[reader].subType === whfbPlaceholder.subType
            )
        ) {
            // If already detected available whfb device, this placeholder is not needed
            return;
        }

        if (await PCSC.supports(whfbPlaceholder.readerName)) {
            whfbPlaceholder.display =
                i18next.t('mappingsLabels$deviceSubTypes$whfb$label') ||
                whfbPlaceholder.display;
            this.setState({
                ...this.state,
                readers: {
                    ...this.state.readers,
                    whfb: whfbPlaceholder,
                },
            });
            await fetchCredentialingQuery(
                this.setCredentialingQuery,
                this.props.appDispatch,
                [whfbPlaceholder.device],
                this.props.username,
            );
        }
    };

    pcscOnChange = async () => {
        await fetchDiagnosis(this.props.appDispatch);
        await PCSC.ready;
        const interval = await PCSC.onChange(
            this.handlePcscOnChange,
            true,
            1500,
        );
        this.setState({
            ...this.state,
            interval,
        });
    };

    // open questions
    identifyDevices = async () => {
        // eslint-disable-line consistent-return
        const { readers } = this.state;
        await sleep(1000);
        for (const readerName of Object.keys(readers)) {
            // eslint-disable-line no-restricted-syntax, max-len
            if (readers[readerName].changed === false) {
                continue; // eslint-disable-line no-continue
            }
            let result = null;
            let reader = null;
            if (readers[readerName].issuable !== true) {
                continue; // eslint-disable-line no-continue
            }
            try {
                reader = await PCSC.connect(readerName); // eslint-disable-line no-await-in-loop
                var atr = readers[readerName].atr;
                // Hard coded the atr to 'axiad.whfb' for whfb
                if (readerName.includes('Windows Hello for Business')) {
                    atr = 'axiad.whfb';
                }
                result = await UCMS.apdu.run(
                    // eslint-disable-line no-await-in-loop
                    reader,
                    ucmsConstants.actions.identify,
                    atr,
                    () => {},
                    deriveUrl(apiPath),
                );
            } catch (error) {
                console.error(
                    TAG,
                    'device selection identifyDevices error:',
                    error,
                );
                if (error.msg === 'E_SHARING_VIOLATION') {
                    readers[readerName].issuable = i18next.t(
                        'errorMsgs$atr_inUse',
                    );
                } else if (error.msg === 'Unsupported card') {
                    readers[readerName].issuable = i18next.t(
                        'errorMsgs$unsupportedDevice',
                    );
                } else {
                    readers[readerName].issuable = i18next.t(
                        'errorMsgs$unidentifiable',
                    );
                }
            } finally {
                if (reader) await reader.disconnect(); // eslint-disable-line no-await-in-loop
            }
            let card = {};
            if (result !== null) {
                card = result.entries[0]?.result;
            }
            readers[readerName] = {
                ...readers[readerName],
                ...card,
                device: card,
                label: displayOption({ ...card, ...readers[readerName] }),
            };
        }
        this.setState({
            ...this.state,
            readers: {
                ...this.state.readers,
                ...readers,
            },
        });
    };

    queryDevices = async () => {
        const data = [];
        for (const readerName of Object.keys(this.state.readers)) {
            // eslint-disable-line no-restricted-syntax, max-len
            const reader = this.state.readers[readerName];
            if (
                reader.issuable === true &&
                reader.changed === true &&
                reader.device
            ) {
                // const device = {
                //     ...reader.device
                // };
                // if (device.type === whfbPlaceholder.subType) {
                //     device.serial = device.whfb_deviceId;
                // }
                data.push(reader.device);
            }
        }
        if (data.length > 0) {
            await fetchCredentialingQuery(
                this.setCredentialingQuery,
                this.props.appDispatch,
                data,
                this.props.username,
            );
        }
    };

    render() {
        const { display } = this.props;
        const {
            loadingDevices,
            loadingDevicesError,
            readers,
            syncing,
        } = this.state;

        if (loadingDevices) return <LoadingSpinner />;
        if (loadingDevicesError)
            return (
                <Alert color="danger">
                    {`Error loading devices (${loadingDevicesError})`}
                </Alert>
            );
        return (
            <DeviceSelectionWithData
                display={display}
                syncing={syncing}
                readers={readers}
                defaultOption={i18next.t(
                    'genericDeviceDropdownLabels$defaultOption',
                )}
                setReader={this.setReader}
            />
        );
    }
}

export default DeviceSelection;
