import React, { useState, useEffect, createRef } from 'react';
import uniqueId from 'lodash.uniqueid';
import styled from 'styled-components';
import { stringToHash } from '../../utils/stringUtil';
import {
    StyledAnchor,
    StyledActionTooltip,
    StyledActionDropdownToggle,
    ActionDropdown,
} from '../../styles/common';

const OptionContent = styled.div`
    &:active {
        color: inherit !important;
        text-decoration: none !important;
        background-color: transparent !important;
    }
`;

const DropdownOption = ({ component, onClick, onKeyUp, id }) => {
    if (!component) {
        return null;
    }
    return (
        <OptionContent
            key={uniqueId('dropdownItem_')}
            className="dropdown-item"
            id={id}
        >
            <StyledAnchor onClick={onClick} onKeyUp={onKeyUp} tabIndex={0}>
                {component}
            </StyledAnchor>
        </OptionContent>
    );
};

const DropdownContent = ({ id, text, options, disabled }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [keyPressed, setKeyPress] = useState(0);
    const tooltipRef = createRef();

    const toggle = (event) => {
        if (event.target.closest(`#tooltip_${stringToHash(id)}`))
            return;
        if (!dropdownOpen) setKeyPress(event.keyCode);
        setDropdownOpen(!dropdownOpen);
    };

    useEffect(() => {
        if ((keyPressed === 32 || keyPressed === 13) && dropdownOpen) {
            tooltipRef.current && tooltipRef.current.focus();
        }
    }, [dropdownOpen, keyPressed, tooltipRef]);

    return (
        <div
            style={{ display: 'flex' }}
            id={`dropdown_${stringToHash(id)}`}
        >
            <ActionDropdown
                isOpen={dropdownOpen}
                toggle={toggle}
                a11y
                aria-haspopup
            >
                <StyledActionDropdownToggle
                    disabled={disabled}
                    color="link"
                    size="sm"
                    aria-label="actions button"
                >
                    {text}
                </StyledActionDropdownToggle>
            </ActionDropdown>
            <StyledActionTooltip
                id={`tooltip_${stringToHash(id)}`}
                placement="auto"
                isOpen={dropdownOpen}
                target={`dropdown_${stringToHash(id)}`}
                fade={false}
                tabIndex={0}
                innerRef={tooltipRef}
            >
                {options &&
                    options.map((option) => (
                        <DropdownOption {...option} key={`${option.id}`} />
                    ))}
            </StyledActionTooltip>
        </div>
    );
};

export default DropdownContent;
