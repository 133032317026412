import moment from 'moment';
import * as log from 'loglevel';
import i18next from 'i18next';
import isEmpty from 'lodash.isempty';
import { errorFactory } from './errors';
import {
    loggerLevels,
    delegationConstants,
    SCRUB_KEYS,
    serverConstants,
    componentKeys,
} from '../constants';

const { ASSOCIATE_PIV_ENROLL, ASSOCIATE_PIV_UPDATE, FIDO2_ENROLL } = componentKeys;

const DELEGATE_DEVICE_NOT_LOCKED = delegationConstants.states.NOT_LOCKED;
const DELEGATE_DEVICE_LOCKED = delegationConstants.states.LOCKED;

// for ucms tunneling
export function deriveUrl(path) {
    return `${window.location.origin}${path}`;
}

export function getParentRoute(pathname) {
    const tokens = pathname.match(/[^\/]+\/?|\//g); // eslint-disable-line no-useless-escape
    return `${tokens[0]}${tokens[1] || ''}`;
}

export function capitalize(s) {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export function capitalizeWords(words) {
    const capWords = words
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    return capWords;
}

export function formatDate(date) {
    return date ? moment(date).format('MMM DD YYYY HH:mm:ss') : '';
}

export function emptyObject(obj) {
    return (
        !obj || (obj.constructor === Object && Object.keys(obj).length === 0)
    );
}

export async function sleep(stallTime = 1000) {
    await new Promise((resolve) => setTimeout(resolve, stallTime));
}

export function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function updateIdleTimeoutDate() {
    if (localStorage.getItem('uupIdleTimeoutLength')) {
        localStorage.setItem(
            'uupIdleTimeoutDate',
            Date.now() + localStorage.getItem('uupIdleTimeoutLength') * 1000,
        );
    }
}

export async function fetchJson(
    input,
    body = null,
    action = null,
    timeoutLimit = 120000,
    queryParams = {},
) {
    const meta = {
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    if (body !== null) {
        if (!isJson(body)) {
            meta.body = JSON.stringify(body);
        } else {
            meta.body = body;
        }
    }
    let method = action;
    if (method === null) {
        method = body === null ? 'GET' : 'POST';
    }
    meta.method = method;
    const queryString = queryParamsToQueryString(queryParams);
    const resp = await Promise.race([
        fetch(`${input}${queryString}`, meta),
        new Promise((_, reject) =>
            setTimeout(() => reject(errorFactory.timeout()), timeoutLimit),
        ),
    ]);
    updateIdleTimeoutDate();
    if (!resp.ok) {
        throw errorFactory.err(`${resp.statusText}`);
    }
    console.debug('Response:', resp);
    const json = await resp.json();
    if (json.result === serverConstants.failure) {
        throw errorFactory.server(null, null, json);
    }
    console.debug('Json:', json);
    return json;
}

export function queryParamsToQueryString(queryParams) {
    return queryParams && Object.keys(queryParams).length
        ? `?${Object.keys(queryParams)
            .filter((k) => !!queryParams[k])
            .map((k) => `${k}=${queryParams[k]}`)
            .join('&')}`
        : '';
}

export async function fetchTimeout(input, init, timeoutLimit = 120000) {
    return Promise.race([
        fetch(input, init),
        new Promise((_, reject) =>
            setTimeout(() => reject(errorFactory.timeout()), timeoutLimit),
        ),
    ]);
}

export function compareVersion(version, minVersion) {
    let ver = version;
    if (ver.includes('-')) {
        [ver] = ver.split('-');
    }
    const verArr = version.split('.');
    const minArr = minVersion.split('.');

    if (verArr[0] > minArr[0]) {
        return 1;
    } if (verArr[0] < minArr[0]) {
        return -1;
    }
    if (verArr[1] > minArr[1]) {
        return 1;
    } if (verArr[1] < minArr[1]) {
        return -1;
    }
    if (verArr[2] > minArr[2]) {
        return 1;
    } if (verArr[2] < minArr[2]) {
        return -1;
    }

    return 0;
}

export async function asyncForEach(iterable, func) {
    return Promise.all(iterable.map(func));
}

export function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
}

export function extractLoglevel(loglevel) {
    const { DEBUG, SILENT, TRACE, INFO, WARN, ERROR } = loggerLevels;
    const levels = [DEBUG, TRACE, INFO, WARN, ERROR];
    return levels.includes(loglevel) ? loglevel : SILENT;
}

// https://stackoverflow.com/questions/31728988/using-javascript-whats-the-quickest-way-to-recursively-remove-properties-and-va
// https://medium.com/@tkssharma/objects-in-javascript-object-assign-deep-copy-64106c9aefab
function cloneObject(obj, scrubKeys = []) {
    // benchmark: https://jsperf.com/deletion-speed
    const clone = {};
    Object.keys(obj).forEach((key) => {
        if (scrubKeys[key] !== true) {
            if (obj[key] !== null && typeof obj[key] === 'object') {
                clone[key] = cloneObject(obj[key]);
            } else {
                clone[key] = obj[key];
            }
        }
    });
    return clone;
}

export function scrubber(arg) {
    if (typeof arg !== 'object') {
        return arg;
    }
    return cloneObject(arg, SCRUB_KEYS);
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/rest_parameters
export function safelog(logger, ...args) {
    if (log.getLevel() < log.levels.SILENT) {
        logger(...args.map(scrubber));
    }
}

export function selectDelegateMessage(
    flowState,
    delegationOperation,
    messageObj,
) {
    const LOCKED = DELEGATE_DEVICE_LOCKED;
    const NOT_LOCKED = DELEGATE_DEVICE_NOT_LOCKED;
    if (delegationOperation === ASSOCIATE_PIV_ENROLL || delegationOperation === FIDO2_ENROLL) {
        return messageObj.replace;
    }
    if (flowState === LOCKED && delegationOperation === ASSOCIATE_PIV_UPDATE) {
        return messageObj.resetUpdate;
    }
    if (
        flowState === NOT_LOCKED &&
        delegationOperation === ASSOCIATE_PIV_UPDATE
    ) {
        return messageObj.update;
    }
    return messageObj.reset;
}

export const camelCaseToSentence = (text) => {
    const result = text.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
};

export const isArrayNotEmpty = (arr) =>
    typeof arr !== 'undefined' &&
    arr !== null &&
    arr.length !== null &&
    arr.length > 0;

export const findDuplicates = (...arrays) =>
    arrays.reduce((includ, current) =>
        Array.from(new Set(includ.filter((a) => current.includes(a)))),
    );

export const sortString = (rowA, rowB, columnId, desc) => {
    const sortRanks = ['', null, undefined];
    const a = rowA.values[columnId];
    const b = rowB.values[columnId];

    if (isEmpty(a) && isEmpty(b)) {
        const rankA = sortRanks.findIndex((r) => r === a);
        const rankB = sortRanks.findIndex((r) => r === b);
        const order = rankA - rankB;
        return desc ? -order : order;
    }
    if (isEmpty(a)) {
        return desc ? -1 : 1;
    }
    if (isEmpty(b)) {
        return desc ? 1 : -1;
    }
    return a > b ? 1 : -1;
};

export const renderCell = (cell) => {
    const { value } = cell;

    if (value !== null && (Array.isArray(value) ? !isEmpty(value) : true)) {
        return cell.render('Cell');
    }
    return null;

};

export const findKey = (object, predicate) => {
    let result;
    if (object === null) {
        return result;
    }
    Object.keys(object).some((key) => {
        const value = object[key];
        if (predicate(value, key, object)) {
            result = key;
            return true;
        }
        return false;
    });
    return result;
};

export const getSerialsFromBulk = (bulkDevices) => {
    if (isEmpty(bulkDevices)) {
        return null;
    }
    const serials = bulkDevices.map((devices) => devices.serial).join(', ');
    return `${i18next.t('walkways$multipleSerials')} ${serials}`;
};

export const mapOrder = (array, order, key) => {
    array.sort((a, b) => {
        const elA = a[key];
        const elB = b[key];
        return order.indexOf(elA) > order.indexOf(elB) ? 1 : -1;
    });
    return array;
};

export const changeColorLuminosity = (color = '#000', luminosity = 0.0) => {
    const l = luminosity || 0.0;
    let c = color.replace(/[^0-9a-f]/gi, '');
    if (c.length < 6) {
        // #000 -> #000000
        c = c[0] + c[0] + c[1] + c[1] + c[2] + c[2];
    }
    let p;
    let rgb = "#";
    const neg = l * 10 < 0 ? -1 : 1;
    for (let i = 0; i < 3; i += 1) {
        p = parseInt(c.substr(i * 2, 2), 16);
        p = Math.round(Math.min(Math.max(0, p + (neg) + (p * l)), 255)).toString(16);
        rgb += ("00" + p).substr(p.length); // eslint-disable-line prefer-template
    }
    return rgb;
};

export const darkerColor = (c) => changeColorLuminosity(c, -0.25);
export const ligtherColor = (c) => changeColorLuminosity(c, 0.25);

