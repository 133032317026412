import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userEntryDelegateCardId } from '../../../constants';
import UserEntryDelegateWithData from './UserEntryDelegateWithData';
import CardLayout from '../../shared/CardLayout';
import {
    EnrollingDispatchContext,
    EnrollingStateContext,
} from '../../../context/EnrollingContext';

const TAG = 'UserEntryDelegate';

export const validateForm = (registered, form) => {
    const valid = Object.keys(registered).every((key) => {
        if (!(key in form.values)) {
            return false;
        }
        const result = registered[key](form.values[key]);
        return result === true;
    });
    return valid;
};

const UserEntryDelegate = ({
    mgmt,
    delegationOperation,
    setCurrentPin,
    setCurrentAnswer,
    setPass,
    forgotPin,
}) => {
    const { t } = useTranslation();
    const { form } = useContext(EnrollingStateContext);
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const [registered, setRegistered] = useState({});
    const [showNext, setShowNext] = useState(false);
    const [skipEnabled, setSkipEnabled] = useState(false);
    const [skipClicked, setSkipClicked] = useState(false);



    useEffect(() => {
        return () => {
            enrollingDispatch({
                type: 'RESET_FORM',
            });
        };
    },[]);

    useEffect(() => {
        setShowNext(validateForm(registered, form));
    }, [form, registered]);

    const registerForm = (forms) => {
        console.debug(TAG, 'registerForm forms:', forms);
        console.debug(TAG, 'registerForm state:', { registered, showNext });

        setRegistered({
            ...registered,
            ...forms,
        });
    };

    const resetForm = () => {
        setRegistered({});
        setShowNext(false);
        enrollingDispatch({
            type: 'RESET_FORM',
        });
        if (forgotPin) {
            forgotPin();
        }
    };

    const next = () => {
        if (mgmt && validateForm(registered, form)) {
            mgmt.next();
        } else {
            console.error(TAG, 'form is not valid');
        }
    };

    return (
        <CardLayout
            title={t('cardTitles$userEntry')}
            showNext={showNext}
            mgmt={{
                ...mgmt,
                next,
            }}
            testId={userEntryDelegateCardId}
            skipEnabled={skipEnabled}
            clickSkip={() => {
                setSkipClicked(true);
                setSkipEnabled(false);
            }}
        >
            <UserEntryDelegateWithData
                mgmt={mgmt}
                delegationOperation={delegationOperation}
                setCurrentPin={setCurrentPin}
                setCurrentAnswer={setCurrentAnswer}
                setPass={setPass}
                registerForm={registerForm}
                forgotPin={resetForm}
                enableSkip={() => {
                    setSkipEnabled(true);
                    setSkipClicked(false);
                }}
                disableSkip={() => {
                    setSkipEnabled(false);
                    setSkipClicked(false);
                }}
                diableShowNext={() => setShowNext(false)}
                skipClicked={skipClicked}
            />
        </CardLayout>
    );
};

UserEntryDelegate.defaultProps = {
    mgmt: null,
    delegationOperation: '',
    setCurrentPin: null,
    setCurrentAnswer: null,
    setPass: null,
    forgotPin: null,
};

export default UserEntryDelegate;
