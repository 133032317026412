export const TARGET_SELF = 'self';
export const TARGET_ALL = 'all';
export const ACTION_ALL = 'all';
export const ACTION_DISABLE = 'deviceDisable';
export const ACTION_ENABLE = 'deviceEnable';
export const ACTION_REVOKE = 'deviceRevoke';
export const ACTION_RESET_PIN = 'deviceResetPin';
export const ACTION_UPDATE = 'deviceRenew';
export const ACTION_RESYNC = 'deviceResyncOtp';
export const ACTION_VALIDATE = 'deviceValidateOtp';
export const ACTION_ENROLL = 'deviceEnroll';
export const ACTION_ENROLL_PIN = 'deviceEnrollPin';
export const ACTION_RECYCLE = 'deviceRecycle';
export const ACTION_PUK = 'devicePuk';
export const ACTION_DEVICE_SEARCH = 'deviceSearch';
export const ACTION_USER_SEARCH = 'userSearch';
export const ACTION_USER_RESET_SECURITY = 'userResetSecurity';
export const ACTION_MY_CIRCLE = 'myCircle';
export const ACTION_REPORT_VIEW = 'reportView';
export const ACTION_REPORT_MODIFY = 'reportModify';

export const helpdeskUserResetPrivileges = [
    {
        scope: TARGET_ALL,
        privilege: ACTION_USER_RESET_SECURITY
    }
];

export const identitiesAllowedPrivileges = [
    {
        scope: TARGET_SELF,
        privilege: ACTION_DEVICE_SEARCH,
    },
];
export const delegationAllowedPrivileges = [
    {
        scope: TARGET_SELF,
        privilege: ACTION_MY_CIRCLE,
    },
];
export const helpdeskAllowedPrivileges = [
    {
        scope: TARGET_ALL,
        privilege: ACTION_DEVICE_SEARCH,
    },
    {
        scope: TARGET_ALL,
        privilege: ACTION_USER_SEARCH,
    },
];
export const reportsAllowedPrivileges = [
    {
        scope: TARGET_ALL,
        privilege: ACTION_REPORT_MODIFY,
    },
    {
        scope: TARGET_ALL,
        privilege: ACTION_REPORT_VIEW,
    },
];
export const reportsModifyPrivileges = [
    {
        scope: TARGET_ALL,
        privilege: ACTION_REPORT_MODIFY,
    },
];

export const deviceSearchPrivileges = [
    {
        scope: TARGET_ALL,
        privilege: ACTION_DEVICE_SEARCH,
    },
];

export const userSearchPrivileges = [
    {
        scope: TARGET_ALL,
        privilege: ACTION_USER_SEARCH,
    },
];

export const reportViewPrivileges = [
    {
        scope: TARGET_ALL,
        privilege: ACTION_REPORT_VIEW,
    },
];

export const reportCreatePrivileges = [
    {
        scope: TARGET_ALL,
        privilege: ACTION_REPORT_MODIFY,
    },
];

export const isAllowed = ({ allowedPermissions = [], permissions = [] }) => {
    return allowedPermissions.some((allowedPermission) =>
        permissions.some(
            (userPermission) =>
                (allowedPermission.privilege === undefined ||
                    userPermission.privilege === ACTION_ALL ||
                    userPermission.privilege === allowedPermission.privilege) &&
                (allowedPermission.scope === undefined ||
                    userPermission.scope === TARGET_ALL ||
                    userPermission.scope === allowedPermission.scope),
        ),
    );
};
