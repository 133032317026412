import React, { useReducer, createContext } from 'react';
import { PropTypes } from 'prop-types';

const initialState = {
    step: 0,
    form: {
        valid: false,
        dirty: false,
        formError: '',
        pristine: true,
        values: {},
        validators: {},
        errors: {},
    },
    device: null,
    reader: null,
    mobile: null,
    userEntry: {},
    credentialFlow: [],
    username: null,
};

const reducer = (state, action) => {
    let validity;
    let newPin;
    switch (action.type) {
        case 'SET_USERNAME':
            return { ...state, username: action.username };
        case 'SET_USER_ENTRY':
            return { ...state, userEntry: action.userEntry };
        case 'SET_QUESTION':
            return { ...state, question: action.question };
        case 'SET_PIN':
            return { 
                ...state, 
                device: {
                    ...state.device,
                    pin: action.pin,
                },
            };
        case 'SET_SERIAL':
            return { ...state, serial: action.serial };
        case 'SET_SELECTED_READER':
            return {
                ...state,
                reader: action.reader ? { ...action.reader } : null,
            };
        case 'SET_SELECTED_MOBILE':
            return {
                ...state,
                mobile: action.mobile ? { ...action.mobile } : null,
            };
        case 'SET_CREDENTIAL_FLOW': {
            const userEntry = action.credentialFlow.filter(
                (component) => component.type === 'userEntry',
            );
            return {
                ...state,
                credentialFlow: action.credentialFlow,
                userEntry: userEntry[0],
            };
        }
        case 'VALIDATE':
            validity = action.validator(action.value);
            if (validity === true) {
                return {
                    ...state,
                    form: {
                        validator: {
                            ...state.form.validator,
                            [action.name]: action.validator,
                        },
                        errors: {
                            ...state.form.errors,
                            [action.name]: false,
                        },
                        dirty: true,
                        pristine: false,
                        valid: true,
                    },
                };
            }
            return {
                ...this.state,
                form: {
                    dirty: true,
                    pristine: false,
                    valid: false,
                    validator: {
                        ...state.form.validator,
                        [action.name]: action.validator,
                    },
                    errors: {
                        ...state.form.errors,
                        [action.name]: validity,
                    },
                },
            };
        case 'SET_VALUE':
            validity = action.validator(action.value);
            if (validity === true) {
                return {
                    ...state,
                    form: {
                        values: {
                            ...state.form.values,
                            [action.name]: action.value,
                        },
                        validator: {
                            ...state.form.validator,
                            [action.name]: action.validator,
                        },
                        errors: { ...state.form.errors, [action.name]: false },
                        dirty: true,
                        pristine: false,
                        valid: true,
                    },
                };
            }
            return {
                ...state,
                form: {
                    dirty: true,
                    pristine: false,
                    valid: false,
                    validator: {
                        ...state.form.validator,
                        [action.name]: action.validator,
                    },
                    errors: {
                        ...state.form.errors,
                        [action.name]: true,
                    },
                    values: {
                        ...state.form.values,
                        [action.name]: action.value,
                    },
                },
            };
        case 'SET_EXTRACT_VALUE':
            validity = action.validator(action.value);
            if (validity === true) {
                return {
                    ...state,
                    form: {
                        values: {
                            ...state.form.values,
                            [action.name]: action.value,
                        },
                        validator: {
                            ...state.form.validator,
                            [action.name]: action.validator,
                        },
                        errors: { ...state.form.errors, [action.name]: false },
                        dirty: true,
                        pristine: false,
                        valid: true,
                    },
                    ...action.extra,
                };
            }
            return {
                ...state,
                form: {
                    dirty: true,
                    pristine: false,
                    valid: false,
                    validator: {
                        ...state.form.validator,
                        [action.name]: action.validator,
                    },
                    errors: { ...state.form.errors, [action.name]: true },
                    values: {
                        ...state.form.values,
                        [action.name]: action.value,
                    },
                },
                ...action.extra,
            };
        case 'ADD_TO_DEVICE':
            return {
                ...state,
                device: { ...state.device, [action.name]: action.value },
            };
        case 'REMOVE_FROM_DEVICE': {
            const newDevice = { ...state.device };
            delete newDevice[action.name];
            return {
                ...state,
                device: newDevice,
            };
        }
        case 'ADD_PIN':
            newPin = (state.device || {}).pin || [];
            newPin = newPin.filter((p) => p.name !== action.id);
            newPin.push({
                name: action.id,
                value: action.value,
                keyboardLayout:
                    action.id.includes('otp.slot')
                        ? action.keyboardLayout
                        : null,
            });
            return {
                ...state,
                device: {
                    ...state.device,
                    pin: newPin,
                },
            };
        case 'DELETE_PIN':
            newPin = (state.device || {}).pin || [];
            newPin = newPin.filter((p) => p.name !== action.id);
            return {
                ...state,
                device: {
                    ...state.device,
                    pin: newPin,
                },
            };
        case 'RESET_FORM':
            return {
                ...state,
                form: {
                    valid: false,
                    dirty: false,
                    formError: '',
                    pristine: true,
                    values: {},
                    validators: {},
                    errors: {},
                },
            };
        case 'RESET_DEVICE':
            return { ...state, device: { pin: '', serial: '' } };
        case 'RESET_FLOWS':
            return {
                ...state,
                step: 0,
                userEntry: {},
                credentialFlow: [],
                device: null,
                reader: null,
                mobile: null,
                username: null,
                form: {
                    valid: false,
                    dirty: false,
                    formError: '',
                    pristine: true,
                    values: {},
                    validators: {},
                    errors: {},
                },
            };
        case 'RESET_CONTEXT':
            return { ...initialState };
        default:
            return state;
    }
};

export const EnrollingStateContext = createContext();
export const EnrollingDispatchContext = createContext();

export const EnrollingProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <EnrollingStateContext.Provider value={state}>
            <EnrollingDispatchContext.Provider value={dispatch}>
                {children}
            </EnrollingDispatchContext.Provider>
        </EnrollingStateContext.Provider>
    );
};

EnrollingProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
