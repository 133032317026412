import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import styled from 'styled-components';
import Pass from '../../formLibrary/Pass';
import UserId from '../../formLibrary/UserId';
import Serial from '../../formLibrary/Serial';
import CreatePin from '../../formLibrary/CreatePin';
import CurrentPin from '../../formLibrary/CurrentPin';
import CreateQuestion from '../../formLibrary/CreateQuestion';
import UpdateQuestion from '../../formLibrary/UpdateQuestion';
import CurrentQuestion from '../../formLibrary/CurrentQuestion';
import AutoSuggest from '../../formLibrary/AutoSuggest';
import { EnrollingStateContext } from '../../../context/EnrollingContext';
import {
    PortalContainer,
    CardHeader,
    StyledAlert,
} from '../../../styles/common';
import { deviceTypes, deviceSubTypes } from '../../../utils/mappings';

const UserEntryBody = styled.div`
    padding-bottom: 12px;
`;

const displayFormComponent = (properties, components) => {
    const formComponents = {
        pin: (
            {
                registerForm,
                enableSkip,
                disableSkip,
                skipClicked,
                mgmt,
                diableShowNext,
            },
            component,
        ) => (
            <CreatePin
                registerForm={registerForm}
                serverComponent={component}
                enableSkip={enableSkip}
                disableSkip={disableSkip}
                skipClicked={skipClicked}
                diableShowNext={diableShowNext}
                mgmt={mgmt}
            />
        ),
        pass: ({ registerForm, setPass }, component) => (
            <Pass
                registerForm={registerForm}
                setPass={setPass}
                serverComponent={component}
            />
        ),
        serial: ({ registerForm }, component) => (
            <Serial registerForm={registerForm} serverComponent={component} />
        ),
        userid: ({ registerForm }, component) => (
            <UserId registerForm={registerForm} serverComponent={component} />
        ),
        AutoSuggest: ({ registerForm }, component) => (
            <AutoSuggest
                registerForm={registerForm}
                serverComponent={component}
            />
        ),
        currentPin: ({ registerForm, setCurrentPin, forgotPin }, component) => (
            <CurrentPin
                registerForm={registerForm}
                setCurrentPin={setCurrentPin}
                forgotPin={forgotPin}
                serverComponent={component}
            />
        ),
        question: ({ registerForm }, component) => (
            <CreateQuestion
                registerForm={registerForm}
                serverComponent={component}
            />
        ),
        updateQuestion: ({ registerForm, goToResetQuestion }, component) => (
            <UpdateQuestion
                registerForm={registerForm}
                serverComponent={component}
                goToResetQuestion={goToResetQuestion}
            />
        ),
        currentQuestion: ({ registerForm, setCurrentAnswer }, component) => (
            <CurrentQuestion
                setCurrentAnswer={setCurrentAnswer}
                registerForm={registerForm}
                serverComponent={component}
            />
        ),
        instruction: (
            {}, // eslint-disable-line no-empty-pattern
            component,
        ) => (
            <div>
                { 
                    component?.details?.warning && 
                    (
                        <StyledAlert color="warning">
                            {i18next.t(
                                `instructionMessages$${component.details.warning}`,
                            )}
                        </StyledAlert>
                    )
                }
                <StyledAlert color="info">
                    {i18next.t(
                        `instructionMessages$${component.details.message}$${component.details.extra}`,
                    ) ||
                        i18next.t(
                            `instructionMessages$${component.details.message}`,
                        )}
                </StyledAlert>
            </div>
        )
    };

    return components.map((component) => {
        if (
            component.state === undefined ||
            component.state === properties.mgmt.flowState
        ) {
            return React.cloneElement(
                formComponents[component.type](properties, component), {
                    key: component.type,
                }
            );
        }
        return '';
    });
};

const UserEntryWithData = ({
    mgmt,
    registerForm,
    setPass,
    setCurrentPin,
    forgotPin,
    setCurrentAnswer,
    device,
    goToResetQuestion,
    enableSkip = () => {},
    disableSkip = () => {},
    skipClicked = false,
    diableShowNext = () => {},
}) => {
    const { t } = useTranslation();
    const {
        userEntry: { components },
    } = useContext(EnrollingStateContext);

    const { serial, type, subType, label } = device || {};
    const deviceSubTypeLabel =
        deviceSubTypes[subType] && deviceSubTypes[subType].label;
    const deviceTypeLabel = deviceTypes[type] && deviceTypes[type].label;
    const subtitle = device
        ? t('walkways$serialConfirmation', {
            SERIAL: serial,
            TYPE:
                  label ||
                  t(deviceSubTypeLabel) ||
                  t(deviceTypeLabel) ||
                  t('unknownDevice'),
        })
        : t('userEntryLabels$stateless$h6');

    return (
        <PortalContainer>
            <CardHeader>{subtitle}</CardHeader>
            <UserEntryBody>
                {components &&
                    displayFormComponent(
                        {
                            registerForm,
                            setPass,
                            setCurrentPin,
                            forgotPin,
                            setCurrentAnswer,
                            mgmt,
                            goToResetQuestion,
                            enableSkip,
                            disableSkip,
                            skipClicked,
                            diableShowNext,
                        },
                        components,
                    )}
            </UserEntryBody>
        </PortalContainer>
    );
};

UserEntryWithData.propTypes = {
    mgmt: PropTypes.object,
    registerForm: PropTypes.func,
    setPass: PropTypes.func,
    setCurrentPin: PropTypes.func,
    forgotPin: PropTypes.func,
    setCurrentAnswer: PropTypes.func,
};

UserEntryWithData.defaultProps = {
    mgmt: null,
    registerForm: null,
    setPass: null,
    setCurrentPin: null,
    forgotPin: null,
    setCurrentAnswer: null,
};

export default UserEntryWithData;
