import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PROGRESS_BAR_DONE_DELAY, vscPlaceholder } from '../../constants';
import CardTemplate from '../shared/CardTemplate';
import ProgressTemplate from '../shared/ProgressTemplate';
import EnrollTemplate from '../shared/EnrollTemplate';
import FlowButtons from '../elementLibrary/FlowButtons';
import VscCreationSelection from '../elementLibrary/VscCreationSelection';

const AssociateEnrollVscCard = ({
    mgmt = null,
    associateUser,
    setReader,
    reader,
}) => {
    const { t } = useTranslation();
    const [showNext, setShowNext] = useState(false);

    useEffect(() => {
        if (
            reader &&
            reader.serial !== vscPlaceholder.serial &&
            associateUser
        ) {
            setShowNext(true);
            setTimeout(mgmt.next, PROGRESS_BAR_DONE_DELAY);
        }
    }, [reader, associateUser, mgmt]);

    return (
        <CardTemplate title={t('cardTitles$issueIdentity')} noFade>
            <EnrollTemplate
                title={t('issuanceProgress')}
                alert={t('enrollTemplateAlertDefault')}
            >
                <ProgressTemplate
                    isFake
                    isDone={showNext}
                    stage={t('deviceActionsStage$vsc')}
                />
            </EnrollTemplate>
            <FlowButtons
                mgmt={{
                    next: mgmt ? mgmt.next : '',
                    showNext,
                    back: mgmt ? mgmt.back : '',
                    showBack: false,
                }}
            />
            <VscCreationSelection
                setAssociateReader={setReader}
                username={associateUser}
            />
        </CardTemplate>
    );
};

AssociateEnrollVscCard.propTypes = {
    mgmt: PropTypes.object,
};

AssociateEnrollVscCard.defaultProps = {
    mgmt: null,
};

export default AssociateEnrollVscCard;
