import React, { useContext, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import {
    PortalContainer,
    StyledAlertMarginTop,
} from '../../../../styles/common';

import DevicesTable from '../../../elementLibrary/DevicesTable';
import {
    AppStateContext,
    AppDispatchContext,
} from '../../../../context/AppContext';
import { HelpDeskStateContext } from '../../../../context/HelpDeskContext';
import {
    emptyDevicesLabelId,
    helpdeskScannerQueryTableColumnsSettings,
} from '../../../../constants';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import { useDidMount } from '../../../../utils/hooks';

const ScannerWithData = ({ syncing }) => {
    const {
        admin: { metadata },
        activeDevicesColumns,
        settings,
    } = useContext(AppStateContext);
    const defaultDeviceColumns =
        settings.defaultDeviceColumns === ''
            ? []
            : settings.defaultDeviceColumns.split(',');
    const { searchData } = useContext(HelpDeskStateContext);
    const dispatch = useContext(AppDispatchContext);
    const { t } = useTranslation();

    const { devices: devicesMetadata } = metadata || {};
    const { columns } = devicesMetadata || {};
    const [cleaning, setCleaning] = useState(true);

    useEffect(() => {
        if (isEmpty(searchData)) {
            setCleaning(false);
        }
    }, [searchData]);

    useDidMount(() => {
        dispatch({
            type: 'RESET_SEARCH_DATA',
            payload: {},
        });
    });

    const onColumnsChange = (value) => {
        dispatch({ type: 'SET_ACTIVE_DEVICES_COLUMNS', payload: value });
    };

    return (
        <PortalContainer>
            <StyledAlertMarginTop
                id={emptyDevicesLabelId}
                color="info"
                fade={false}
            >
                {t('helpDesk$scanner$emptyDeviceTable')}
            </StyledAlertMarginTop>
            {!cleaning ? 
                (
                    <DevicesTable
                        hideCheckboxes
                        enableDeviceDetails
                        hidePagination
                        data={searchData}
                        columnsName={columns}
                        activeColumns={activeDevicesColumns}
                        defaultColumns={defaultDeviceColumns}
                        onColumnsChange={onColumnsChange}
                        selectedColumnsKey={helpdeskScannerQueryTableColumnsSettings}
                        cardType='scanner'
                    />
                ) : null
            }
            {syncing ? (<LoadingSpinner />) : null}
        </PortalContainer>
    );
};

ScannerWithData.propTypes = {
    syncing: PropTypes.bool,
};

ScannerWithData.defaultProps = {
    syncing: false,
};

export default ScannerWithData;
