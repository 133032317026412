import React, { useContext } from 'react';
import { Col, Row } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import ProgressTemplate from '../../shared/ProgressTemplate';
import { PortalContainer, StyledAlert } from '../../../styles/common';
import { AppStateContext } from '../../../context/AppContext';
import { vscPlaceholder, whfbPlaceholder } from '../../../constants';

const Stateless = ({ connected, children }) => {
    const { t } = useTranslation();
    const { device } = useContext(AppStateContext);
    const label =
        device && 
        (device.subType === vscPlaceholder.subType || device.subType === whfbPlaceholder.subType)
            ? t('deviceDetectionVsc')
            : t('DeviceDetectionDefault');
    return (
        <PortalContainer data-testid="device-detection">
            <Row>
                <Col sm="12">
                    <StyledAlert color={connected ? 'success' : 'info'}>
                        {label}
                    </StyledAlert>
                </Col>
            </Row>
            <ProgressTemplate />
            {children}
        </PortalContainer>
    );
};

Stateless.propTypes = {
    connected: PropTypes.bool,
    children: PropTypes.node,
};

Stateless.defaultProps = {
    children: null,
    connected: false,
};

export default Stateless;
