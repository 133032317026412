import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import clone from 'lodash.clone';

const StepWizard = ({ step, children, title, dispatch, state }) => {
    const stepComponents = useMemo(() => {
        const ret = {};

        React.Children.forEach(children, (child) => {
            const { stepId } = child.props;
            if (!stepId || typeof stepId !== 'string') {
                throw new Error(
                    `StepWizard: component '${child.type.name}' has \
                    a stepId="${stepId}", all children of StepWizard \
                    must have a stepId prop that is a valid string`,
                );
            }
            const newChild = clone(child);
            newChild.props = { ...child.props, dispatch, state, title };
            ret[child.props.stepId] = newChild;
        });
        return ret;
    }, [children, title, dispatch, state]);

    try {
        return stepComponents[step];
    } catch (err) {
        throw new Error(`StepWizard: ${step} is likely an invalid step`);
    }
};

StepWizard.propTypes = {
    children: PropTypes.node.isRequired,
    step: PropTypes.string.isRequired,
};

export default StepWizard;

// USAGE
// const STEP_1 = 'STEP_1';
// const STEP_2 = 'STEP_2';
// const STEP_3 = 'STEP_3';
// const STEP_4 = 'STEP_4';
// const STEP_5 = 'STEP_5';

// const Page = ({ stepId }) =>
//     console.log('page render: ', stepId) || <div>test{stepId}</div>;

// return (
//     <StepWizard step={STEP_3} >
//         <Page stepId={STEP_1} />
//         <Page stepId={STEP_2} />
//         <Page stepId={STEP_3} />
//         <Page stepId={STEP_4} />
//         <Page stepId={STEP_5} />
//     </StepWizard>
// );
