import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';
import { PropTypes } from 'prop-types';
import {
    PortalContainer,
    StyledFormGroup,
    SyncIcon,
    SelectInput,
} from '../../../styles/common';

const displayDeviceOption = (devices, readers) => {
    return devices.map((readerName) => {
        const reader = readers[readerName];
        return reader.issuable === true ? (
            <option
                key={`${reader.label}${reader.serial}`}
                value={reader.readerName}
                data-test-id={`${reader.serial}`}
            >
                {reader.display}
            </option>
        ) : (
            <option
                key={`${reader.readerName}`}
                disabled
                value={reader.readerName}
            >
                {reader.display
                    ? `${reader.display}`
                    : `${reader.readerName}: ${reader.issuable}`}
            </option>
        );
    });
};
const DeviceAssociateWithData = ({
    readers,
    defaultOption,
    display,
    setReader,
    syncing,
}) => {
    // MOCK --------------------- REMOVE WHEN REAL PCSC
    // Object.keys(readers).forEach((reader) => {
    //     if (readers[reader].readerName === 'vsc') {
    //         readers[reader].issuable = true;
    //         readers[reader].status = 'available';
    //     }
    // });
    // MOCK
    const { t } = useTranslation();
    const operable = Object.keys(readers).filter(
        (readerName) =>
            readers[readerName].issuable === true &&
            readers[readerName].display,
    );
    const inoperable = Object.keys(readers).filter(
        (readerName) =>
            readers[readerName].issuable !== true &&
            (readers[readerName].display || readers[readerName].readerName) &&
            !(readers[readerName].status === 'assigned' ||
                readers[readerName].status === 'otherAssigned'),
    );
    const assignedDevices = Object.keys(readers).filter(
        (readerName) =>
            readers[readerName].status === 'otherAssigned' &&
            (readers[readerName].display ||
                (readers[readerName].issuable !== true &&
                    readers[readerName].readerName)),
    );
    const coworkerDevices = Object.keys(readers).filter(
        (readerName) =>
            readers[readerName].status === 'assigned' &&
            (readers[readerName].display ||
                (readers[readerName].issuable !== true &&
                    readers[readerName].readerName)),
    );
    const newDevices = Object.keys(readers).filter(
        (readerName) =>
            readers[readerName].status === 'available' &&
            (readers[readerName].display ||
                (readers[readerName].issuable !== true &&
                    readers[readerName].readerName)),
    );
    return (
        <PortalContainer>
            <StyledFormGroup>
                <Col sm="9">
                    <SelectInput
                        disabled={!display}
                        type="select"
                        name="select"
                        aria-label="select"
                        onChange={(event) => {
                            setReader(event.target.value);
                        }}
                    >
                        <option>
                            {operable.length === 0 &&
                            inoperable.length === 0 &&
                            assignedDevices.length === 0 &&
                            coworkerDevices.length === 0 &&
                            newDevices.length === 0
                                ? t(
                                    'genericDeviceDropdownLabels$noDevicesOption',
                                )
                                : defaultOption}
                        </option>
                        {coworkerDevices.length > 0 && (
                            <optgroup label={t('deviceSelection$coworker')}>
                                {displayDeviceOption(coworkerDevices, readers)}
                            </optgroup>
                        )}
                        {assignedDevices.length > 0 && (
                            <optgroup label={t('deviceSelection$assigned')}>
                                {displayDeviceOption(assignedDevices, readers)}
                            </optgroup>
                        )}
                        {newDevices.length > 0 && (
                            <optgroup label={t('deviceSelection$available')}>
                                {displayDeviceOption(newDevices, readers)}
                            </optgroup>
                        )}
                        {inoperable.length > 0 && (
                            <optgroup label={t('deviceSelection$ineligible')}>
                                {displayDeviceOption(inoperable, readers)}
                            </optgroup>
                        )}
                    </SelectInput>
                </Col>
                <SyncIcon icon="sync" size="lg" spin $syncing={syncing} />
            </StyledFormGroup>
        </PortalContainer>
    );
};

DeviceAssociateWithData.propTypes = {
    readers: PropTypes.object.isRequired,
    display: PropTypes.bool,
    setReader: PropTypes.func.isRequired,
    defaultOption: PropTypes.string.isRequired,
};

DeviceAssociateWithData.defaultProps = {
    display: false,
};

export default DeviceAssociateWithData;
