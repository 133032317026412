import React, { useReducer, createContext } from 'react';
import { PropTypes } from 'prop-types';
import i18next from 'i18next';

const displayQuery = (rules) => {
    const validRules = rules.filter((r) => r.values.length && r.values[0]);
    if (!validRules || !validRules.length || !validRules[0]) return '';
    const ruleTextArray = validRules.map(
        (r, i) =>
            `${
                i !== 0
                    ? `${(i18next.t(`searchBar$mode$${r.logicalOperator}`) ||
                          r.logicalOperator).toUpperCase()} `
                    : ''
            }${i18next.t(`searchBar$keyword$${r.name}`) || r.name} ${
                i18next.t(`searchBar$operation$${r.operation}`) || r.operation
            } "${r.values[0]}"`,
    );
    return `(${ruleTextArray.join(' ').split(' OR ').join(') OR (')})`;
};

const initialSearchResultsMetadata = {
    limit: 10,
    count: 0,
    ucms: {
        offset: 0,
        count: 0,
        hasNext: false,
    },
    pi: {
        offset: 0,
        count: 0,
        hasNext: false,
    },
};

const initialState = {
    rules: [],
    currentSearchRules: [],
    displayedQuery: '',
    reportsList: [],
    currentReport: {},
    triggerInventoryFetch: false,
    reportDevicesDefaultValue: [],
    searchFilter: {},
    searchData: [],
    searchLoading: false,
    sortBy: null,
    sortDir: null,
    searchResultsMetadata: initialSearchResultsMetadata,
};

const reducer = (state, action) => {
    let newRules = [...state.rules];
    switch (action.type) {
        case 'SET_SEARCH_FILTER':
            if (action.target === 'searchType') {
                if (action.value.type !== newRules[action.id].type) {
                    newRules[action.id].values = [''];
                }
                newRules[action.id].name = action.value.name;
                newRules[action.id].operation = action.value.operations[0];
                newRules[action.id].type = action.value.type;
                newRules[action.id].operations = action.value.operations;
            } else {
                newRules[action.id][action.target] = action.value;
            }
            return {
                ...state,
                rules: newRules,
                displayedQuery: displayQuery(newRules),
            };
        case 'INITIALIZE_RULES':
            newRules = [action.rule];
            return {
                ...state,
                rules: newRules,
                displayedQuery: displayQuery(newRules),
            };
        case 'ADD_RULE':
            newRules.splice(action.id + 1, 0, {
                ...newRules[action.id],
                values: [''],
            });
            return {
                ...state,
                rules: newRules,
                displayedQuery: displayQuery(newRules),
            };
        case 'REMOVE_RULE':
            newRules.splice(action.id, 1);
            if (action.id === 0) newRules[action.id].logicalOperator = 'and';
            return {
                ...state,
                rules: newRules,
                displayedQuery: displayQuery(newRules),
            };
        case 'LOAD_CUSTOM_REPORT':
            return {
                ...state,
                rules: action.report.filters,
                displayedQuery: displayQuery(action.report.filters),
            };
        case 'SET_CUSTOM_REPORTS_LIST':
            return {
                ...state,
                reportsList: action.reports,
            };
        case 'REMOVE_CUSTOM_REPORT': {
            const newReportsList = state.reportsList.filter(
                (report) => report.name !== action.reportName,
            );
            return {
                ...state,
                reportsList: newReportsList,
            };
        }
        case 'SET_CURRENT_REPORT':
            return {
                ...state,
                currentReport: action.reportData,
            };
        case 'TRIGGER_INVENTORY_FETCH':
            return {
                ...state,
                triggerInventoryFetch: action.payload,
            };
        case 'SET_CURRENT_REPORT_UPDATED_COLUMNS':
            return {
                ...state,
                currentReport: {
                    ...state.currentReport,
                    updatedFields: action.updatedFields,
                },
            };
        case 'SET_SEARCH_LOADING':
            return { ...state, searchLoading: action.payload };
        case 'APPEND_TO_SEARCH_DATA':
            return {
                ...state,
                searchData: state.searchData.concat(action.payload),
            };
        case 'SET_SEARCH_DATA':
            return {
                ...state,
                searchData: action.payload,
            };
        case 'RESET_SEARCH_DATA':
            return {
                ...state,
                searchData: [],
                searchFilter: {},
                searchResultsMetadata: initialSearchResultsMetadata,
            };
        case 'SAVE_REPORT_DEVICES_DEFAULT_VALUE':
            return {
                ...state,
                reportDevicesDefaultValue: action.payload,
            };
        case 'SET_SEARCH_RESULTS_METADATA':
            return {
                ...state,
                searchResultsMetadata: {
                    ...state.searchResultsMetadata,
                    ...action.payload,
                },
            };
        case 'SET_SORT':
            return {
                ...state,
                sortBy: action.sortBy,
                sortDir: action.sortDir,
            };
        case 'SET_CURRENT_SEARCH_RULES':
            return {
                ...state,
                currentSearchRules: action.rules,
            };
        default:
            return state;
    }
};
export const ReportsStateContext = createContext();
export const ReportsDispatchContext = createContext();

export const ReportsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <ReportsStateContext.Provider value={state}>
            <ReportsDispatchContext.Provider value={dispatch}>
                {children}
            </ReportsDispatchContext.Provider>
        </ReportsStateContext.Provider>
    );
};

ReportsProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
