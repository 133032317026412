import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import uniqueId from 'lodash.uniqueid';
import styled from 'styled-components';
import { formatDate } from '../../utils/generic';
import { AppStateContext, AppDispatchContext } from '../../context/AppContext';
import {
    StyledTableRow,
    StyledTable,
    StyledTableBody,
    StyledTableHead,
    StyledTableHeadRow,
    TableWrapper,
    DeviceStatusContainer,
    StyledDefaultModalHeader,
    StyledDefaultModalBody,
    StyledDefaultModalFooter,
    AccentButton,
} from '../../styles/common';
import { useHandleEnter } from '../../utils/hooks';
import { activeColor, disabledColor } from '../../constants';

const DetailsHeader = styled.th`
    text-transform: uppercase;
    font-weight: normal;
    vertical-align: middle;
    font-size: 14px;
    color: #171818;
`;

const DetailsCell = styled.td`
    font-size: 14px;
`;

const StyledInfo = styled.table`
    th {
        border: 0;
        font-weight: normal;
        min-width: 52px;
        text-align: left;
        padding-left: 1rem;
    }
    margin-bottom: 1rem;
    font-size: 14px;
`;

const CustomTableWrapper = styled(TableWrapper)`
    margin-top: 1rem !important;
`;

const DeviceInfo = ({ subType, serial, user }) => {
    const { t } = useTranslation();

    const typeValue =
        t(`mappingsLabels$deviceSubTypes$${subType}$label`) || subType;
    const listItems = [
        { value: typeValue, label: 'modalElementLabels$details$columns$type' },
        { value: serial, label: 'modalElementLabels$details$columns$serial' },
        { value: user, label: 'modalElementLabels$details$columns$user' },
    ];

    return (
        <StyledInfo>
            {listItems.map((item) => (
                <tr>
                    <th>{`${t(item.label)} : `}</th>
                    <th>{item.value}</th>
                </tr>
            ))}
        </StyledInfo>
    );
};

const Details = ({ data }) => {
    const { t } = useTranslation();
    return Object.keys(data).map((k) => (
        <Row key={uniqueId('deviceDetail_')}>
            <Col sm="12">
                {`${
                    t(`credentialLabels$extra$${k}`) ||
                    k.charAt(0).toUpperCase() + k.substring(1)
                }: ${data[k]}`}
            </Col>
        </Row>
    ));
};

const CredentialRow = ({ credentials, deviceData }) => {
    const { t } = useTranslation();
    if (!deviceData || !credentials) {
        return null;
    }
    return credentials.map((device) => {
        const { type, status, data = {} } = device;
        const { startValidity = '', endValidity = '', ...rest } = data;
        const statusValue = status.toLowerCase();
        const color = {
            active: { ...activeColor },
            available: { ...activeColor },
            suspended: { ...disabledColor },
            revoke_pending: { ...disabledColor },
            revoked: { ...disabledColor },
            expired: { ...disabledColor },
            default: { ...disabledColor },
        };
        const cells = [
            type && t(`credentialLabels$type$${type}`),
            status && (
                <DeviceStatusContainer
                    status={color[statusValue] || color.default}
                >
                    {t(statusValue)}
                </DeviceStatusContainer>
            ),
            startValidity && formatDate(new Date(startValidity)),
            endValidity && formatDate(new Date(endValidity)),
            <Details data={rest} />,
        ];

        return (
            <StyledTableRow key={uniqueId('deviceRow_')}>
                {cells.map((cell) => (
                    <DetailsCell>{cell}</DetailsCell>
                ))}
            </StyledTableRow>
        );
    });
};

const CredentialTable = () => {
    const { t } = useTranslation();
    const { device } = useContext(AppStateContext);
    const dispatch = useContext(AppDispatchContext);

    const { credentials, serial, user, subType } = device || {};

    const headerTitles = [
        'modalElementLabels$details$columns$credential',
        'modalElementLabels$details$columns$status',
        'modalElementLabels$details$columns$validStart',
        'modalElementLabels$details$columns$validEnd',
        'modalElementLabels$details$columns$details',
    ];

    const clearModal = () => dispatch({ type: 'CLEAR_MODAL' });
    useHandleEnter({ action: clearModal });

    return (
        <>
            <StyledDefaultModalHeader>
                {t(`modalLabels$details$title`)}
            </StyledDefaultModalHeader>
            <StyledDefaultModalBody>
                <DeviceInfo serial={serial} user={user} subType={subType} />
                <CustomTableWrapper>
                    <StyledTable hover borderless>
                        <StyledTableHead>
                            <StyledTableHeadRow>
                                {headerTitles.map((title) => (
                                    <DetailsHeader>{t(title)}</DetailsHeader>
                                ))}
                            </StyledTableHeadRow>
                        </StyledTableHead>
                        <StyledTableBody>
                            <CredentialRow
                                credentials={credentials}
                                deviceData={device}
                            />
                        </StyledTableBody>
                    </StyledTable>
                </CustomTableWrapper>
            </StyledDefaultModalBody>
            <StyledDefaultModalFooter>
                <AccentButton
                    outline
                    color="primary"
                    size="sm"
                    onClick={clearModal}
                >
                    {t('modalLabels$close')}
                </AccentButton>
            </StyledDefaultModalFooter>
        </>
    );
};

export default CredentialTable;
