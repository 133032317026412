import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import uniqueId from 'lodash.uniqueid';
import { useTranslation } from 'react-i18next';
import DropdownContent from '../../../shared/DeviceActionDropdownContent';
import {
    StyledFlexbox,
    DeviceActionButton,
    DeviceActionButtonWrapper,
    TableActionWrapper,
} from '../../../../styles/common';
import { ReactComponent as MoreHoriz } from '../../../../images/more_horiz.svg';
import { AppStateContext } from '../../../../context/AppContext';
import { KIOSK_MODE, infoColor } from '../../../../constants';

const TableRowAction = ({
    kioskComponent,
    id,
    actions,
    actionsData,
    disabled,
}) => {
    const { t } = useTranslation();
    const { loadingCredentialIds, viewMode, kioskAction } = useContext(AppStateContext);
    const isCredentialActionWorking = () =>
        loadingCredentialIds.some(loadingId => loadingId === id);

    if (disabled) {
        return null;
    }

    const buttonActions = actionsData
        .slice(0, 2)
        .map((data) => {
            const { actionType, color, text, onClick, onKeyUp } = data;
            const isLoading = isCredentialActionWorking();
            return (
                <DeviceActionButton
                    tabIndex={0}
                    status={color || infoColor}
                    key={actionType}
                    onClick={() => !isLoading && onClick && onClick()}
                    onKeyUp={(e) => !isLoading && onKeyUp && onKeyUp(e)}
                >
                    { isLoading? '...' : t(text)}
                </DeviceActionButton>
            );
        })
        .filter((component) => component !== null);

    const moreActions = actionsData.slice(2, actionsData.length).map((data) => {
        const { text, onClick, onKeyUp, id } = data;
        return {
            component: (
                <StyledFlexbox
                    key={uniqueId('identityDevice_')}
                    direction="row"
                    alignment="center"
                >
                    {t(text)}
                </StyledFlexbox>
            ),
            onClick: () => onClick && onClick(),
            onKeyUp: (e) => onKeyUp && onKeyUp(e),
            id,
        };
    });

    if (viewMode === KIOSK_MODE && kioskAction && kioskComponent) {
        if (actions.includes(kioskAction)) {
            return kioskComponent(kioskAction);
        }
        return null;
    }

    return (
        <TableActionWrapper>
            {buttonActions.length > 0 && (
                <DeviceActionButtonWrapper>
                    {buttonActions}
                </DeviceActionButtonWrapper>
            )}
            {moreActions.length > 0 && (
                <DropdownContent
                    id={id}
                    text={<MoreHoriz style={{ margin: 0 }} />}
                    options={moreActions}
                />
            )}
        </TableActionWrapper>
    );
};

TableRowAction.propTypes = {
    kioskComponent: PropTypes.func,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    actions: PropTypes.arrayOf(PropTypes.string),
    actionsData: PropTypes.arrayOf(PropTypes.object),
};

TableRowAction.defaultProps = {
    kioskComponent: undefined,
    id: 'rowAction',
    actions: [],
    actionsData: [],
    disabled: false,
};

export default TableRowAction;
