import React, { useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { postUpdatePiv } from '../../utils/walkways';
import {
    ucmsConstants as UCMS,
    PROGRESS_BAR_DONE_DELAY,
} from '../../constants';
import ProgressTemplate from '../shared/ProgressTemplate';
import { AppDispatchContext, AppStateContext } from '../../context/AppContext';
import CardEnrollLayout from '../shared/CardEnrollLayout';
import { useDidMount } from '../../utils/hooks';

const EnrollUpdatePivCard = ({ device, mgmt = null, reader }) => {
    const dispatch = useContext(AppDispatchContext);
    const { webpcscMode } = useContext(AppStateContext);
    const { t } = useTranslation();

    const [, setResult] = useState({});
    const [showNext, setShowNext] = useState(false);
    const [stage, setStage] = useState(t('pivActionInitialState'));

    useDidMount(() => {
        const setProgress = (hook, data) => {
            if (hook === 'stage') {
                setStage(data);
            }
        };

        const setPivResult = async (json) => {
            setResult({ ...json });
            setShowNext(true);
            if (json && mgmt && json.step === UCMS.done) {
                // ucms
                setTimeout(mgmt.next, PROGRESS_BAR_DONE_DELAY);
            }
        };

        postUpdatePiv(
            setPivResult,
            setProgress,
            dispatch,
            {
                ...device,
                reader,
            },
            webpcscMode,
        );
    });

    return (
        <CardEnrollLayout
            cardTitle={t('cardTitles$updateDevice')}
            title={t('updateProgress')}
            alert={t('enrollTemplateAlertDefault')}
            mgmt={mgmt}
            showNext={showNext}
        >
            <ProgressTemplate
                isFake
                isDone={showNext}
                stage={`${t('pivActionPrefix')} ${stage}`}
            />
        </CardEnrollLayout>
    );
};

EnrollUpdatePivCard.propTypes = {
    mgmt: PropTypes.object,
    device: PropTypes.object.isRequired,
    reader: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

EnrollUpdatePivCard.defaultProps = {
    mgmt: null,
};

export default EnrollUpdatePivCard;
