import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label } from 'reactstrap';
import { currentQuestionFormId } from '../../../constants';
import { StyledInput, StyledGap } from '../../../styles/common';
import { EnrollingDispatchContext } from '../../../context/EnrollingContext';
import SecureEntryIcon from '../../shared/SecureEntryIcon';

const CurrentQuestionWithData = ({
    validator,
    setCurrentAnswer,
    clearText,
    clearTextSwitch,
    question,
    questionNumber,
    questionLength,
}) => {
    const { t } = useTranslation();
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const { session, value: questionValue } = question;
    return (
        <div data-test-id={currentQuestionFormId}>
            <FormGroup row autoComplete="off">
                <Col xs="3">
                    <Label for="question">
                        {t('formLabels$currentQuestion', {
                            NUMBER: questionNumber + 1,
                        })}
                    </Label>
                </Col>
                <Col cs="4">{questionValue}</Col>
            </FormGroup>
            <FormGroup row autoComplete="off">
                <Col xs="3" />
                <Col xs="4">
                    <StyledInput
                        type={clearText ? 'text' : 'password'}
                        name="currentQuestion"
                        placeholder={t('formLabels$answer$placeholder')}
                        bsSize="sm"
                        onChange={(event) => {
                            enrollingDispatch({
                                type: 'SET_VALUE',
                                name: `currentQuestion${questionNumber}`,
                                value: event.target.value,
                                validator,
                            });
                            setCurrentAnswer(event.target.value, session);
                        }}
                    />
                </Col>
                <Col xs="0">
                    <SecureEntryIcon
                        isActive={clearText}
                        onMouseDown={clearTextSwitch}
                    />
                </Col>
            </FormGroup>
            {questionNumber !== questionLength - 1 && <StyledGap />}
        </div>
    );
};

CurrentQuestionWithData.propTypes = {
    clearText: PropTypes.bool.isRequired,
    clearTextSwitch: PropTypes.func.isRequired,
    setCurrentAnswer: PropTypes.func.isRequired,
    validator: PropTypes.func.isRequired,
    serverComponent: PropTypes.object,
};

export default CurrentQuestionWithData;
