import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Label, Col } from 'reactstrap';
import {
    StyledInput,
    AccentButton,
    StyledFormGroup,
    StyledDefaultModalHeader,
    StyledDefaultModalBody,
    StyledDefaultModalFooter,
    StyledAlert,
} from '../../styles/common';
import { AppDispatchContext } from '../../context/AppContext';
import { useHandleEnter, usePatchData, usePostData } from '../../utils/hooks';
import { ReportsStateContext, ReportsDispatchContext } from '../../context/ReportContext';
import { subTabsPaths } from '../../constants';

const {
    reports: { view },
} = subTabsPaths;

const SaveReport = () => {
    const dispatch = useContext(AppDispatchContext);
    const { rules, currentReport, reportsList } = useContext(ReportsStateContext);
    const reportsDispatch = useContext(ReportsDispatchContext);
    const { t } = useTranslation();
    const history = useHistory();
    const [input, setInput] = useState(currentReport.name || '');
    const inputRef = useRef();
    inputRef.current = input;
    const currentReportRef = useRef();
    currentReportRef.current = currentReport;
    const [updateCallback, { loading, data, error }] = usePatchData({
        endpoint: '/setting/config/reports',
    });

    const [createCallback, { loading: createLoading, data: createData, error: createError }] = usePostData({
        endpoint: '/setting/config/reports',
    });

    const saveReport = () => {
        if (loading || createLoading) return;
        const savedRules = rules.map((rule) => {
            const ruleWithoutTypeAndOperations = { ...rule };
            delete ruleWithoutTypeAndOperations.type;
            delete ruleWithoutTypeAndOperations.operations;
            return ruleWithoutTypeAndOperations;
        });
        if (currentReport.name === inputRef.current) {
            updateCallback({
                name: inputRef.current,
                filters: savedRules,
                fields: currentReport.updatedFields,
            });
        } else {
            createCallback({
                name: inputRef.current,
                filters: savedRules,
                fields: currentReport.updatedFields,
            });
        }
    };

    const submit = () => {
        if (inputRef.current.length) saveReport();
    };

    useEffect(() => {
        if (data || createData) {
            reportsDispatch({
                type: 'TRIGGER_INVENTORY_FETCH',
                payload: true,
            });
            history.push(`${view}/${input}`);
            dispatch({
                type: 'CLEAR_MODAL',
            });
        }
    }, [data, createData]);

    useEffect(() => {
        if (error || createError) {
            dispatch({
                type: 'CLEAR_MODAL',
            });
        }
    },[error, createError])

    useHandleEnter({ action: submit });

    const isNameConflict = () => {
        return currentReport.name !== inputRef.current && reportsList.some(i => inputRef.current === i.name);
    }

    return (
        <>
            <StyledDefaultModalHeader>
                {t(`modalLabels$saveReport$title`)}
            </StyledDefaultModalHeader>
            <StyledDefaultModalBody>
                <StyledAlert color="info">
                    {t(`modalLabels$saveReport$info`)}
                </StyledAlert>
                <StyledFormGroup row autoComplete="off">
                    <Col xs="3">
                        <Label for="reportTitle">
                            {t('modalLabels$saveReport$input')} :
                        </Label>
                    </Col>
                    <Col xs="6">
                        <StyledInput
                            name="reportTitle"
                            bsSize="sm"
                            value={input}
                            valid={!!input.length && !isNameConflict()}
                            invalid={!input.length || isNameConflict()}
                            onChange={(e) => setInput(e.target.value)}
                            disabled={loading || data || createLoading || createData}
                        />
                    </Col>
                </StyledFormGroup>
            </StyledDefaultModalBody>
            <StyledDefaultModalFooter>
                <AccentButton
                    outline
                    color="primary"
                    size="sm"
                    onClick={submit}
                    disabled={!input.length || loading || createLoading || isNameConflict()}
                >
                    {t('modalLabels$saveReport$submit')}
                </AccentButton>
                <AccentButton
                    outline
                    color="primary"
                    size="sm"
                    onClick={() =>
                        dispatch({
                            type: 'CLEAR_MODAL',
                        })
                    }
                >
                    {t('modalElementLabels$details$modal$button')}
                </AccentButton>
            </StyledDefaultModalFooter>
        </>
    );
};

export default SaveReport;
