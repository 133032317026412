import { fetchJson } from './generic';
import { declareError } from './errors';
import { rootPath } from '../constants';

export async function fetchScannerDevices(
    setScannerDevices,
    updatedReaders,
    dispatch,
    identifiedDevices,
) {
    try {
        const json = await fetchJson(
            `${rootPath}/api/device/scanner`,
            identifiedDevices,
        );
        setScannerDevices(json, updatedReaders);
    } catch (error) {
        console.error('fetchScannerQuery:', error);
        declareError(dispatch, error);
    }
}
