import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { AccentLink, CopyInfo } from '../../styles/common';
import { copyBtnStatusMap } from '../../utils/mappings';
import { ReactComponent as Copy } from '../../images/copy.svg';
import { ReactComponent as Check } from '../../images/check.svg';

const CopyButton = ({ value, iconWidth, ...otherProps }) => {
    const { t } = useTranslation();

    const [status, setStatus] = useState(copyBtnStatusMap.copy);

    const copyBtnContentMap = {
        failed: t`devicesDetailsTable$copy$failed`,
        copied: <Check width={iconWidth} />,
        copy: <Copy style={{ fill: '#666666' }} width={iconWidth} />,
    };

    const toggleCopyBtnStatus = (text) => {
        setStatus(text);
        setTimeout(() => {
            setStatus(copyBtnStatusMap.copy);
        }, 2500);
    };
    const copy = (value) => {
        if (!navigator || !navigator.clipboard) return;

        navigator.clipboard.writeText(value).then(
            () => {
                toggleCopyBtnStatus(copyBtnStatusMap.copied);
            },
            () => {
                toggleCopyBtnStatus(copyBtnStatusMap.failed);
            },
        );
    };

    if (navigator && navigator.clipboard) {
        if (status !== copyBtnStatusMap.copy) {
            return (
                <CopyInfo
                    failed={status === copyBtnStatusMap.failed}
                    {...otherProps}
                >
                    {copyBtnContentMap[status]}
                </CopyInfo>
            );
        }
        return (
            <AccentLink
                onClick={(e) => {
                    e.preventDefault();
                    copy(value);
                }}
                to=""
                {...otherProps}
            >
                {copyBtnContentMap[status]}
            </AccentLink>
        );
    }
    return null;
};

export default CopyButton;
