import React from 'react';

const { default: ValidateDevice } = require(".");

const ValidateWithoutPin = () => {
    return (
        <ValidateDevice 
            withPin={false}
        />
    );
}
export { ValidateWithoutPin };