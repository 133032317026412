import React from 'react';
import { PropTypes } from 'prop-types';
import { Col, FormGroup, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { resyncTypeCardId } from '../../constants';
import CardLayout from '../shared/NewCardLayout';
import { PortalContainer, CardHeader, StyledAlert } from '../../styles/common';
import ResyncOtpForm from '../formLibrary/ResyncOtp';
import { deviceTypes } from '../../utils/mappings';

const ResyncOtp = ({
    title,
    subtitle = '',
    dispatch,
    disableBack,
    disableNext,
    device,
}) => {
    const { t } = useTranslation();
    const { serial, type, label } = device || {};

    const serialConfirmation = t('walkways$serialConfirmation', {
        SERIAL: serial,
        TYPE: label || t(type ? deviceTypes[type].label : 'unknownDevice'),
    });

    return (
        <CardLayout
            title={title}
            id={resyncTypeCardId}
            dispatch={dispatch}
            disableBack={disableBack}
            disableNext={disableNext}
        >
            <PortalContainer>
                <Row>
                    <Col sm="12">
                        <CardHeader>
                            {subtitle || serialConfirmation}
                        </CardHeader>
                    </Col>
                </Row>
                <StyledAlert color="info">
                    {t('instructionMessages$resync')}
                </StyledAlert>
                <FormGroup autoComplete="off">
                    <ResyncOtpForm dispatch={dispatch} />
                </FormGroup>
            </PortalContainer>
        </CardLayout>
    );
};

ResyncOtp.propTypes = {
    title: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    disableBack: PropTypes.bool,
    disableNext: PropTypes.bool,
};

ResyncOtp.defaultProps = {
    disableBack: false,
    disableNext: false,
};

export default ResyncOtp;
