import PCSC from './pcsc';
import UCMS from './ucms';
import { errorFactory, declareError } from './errors';
import { fetchJson, deriveUrl, queryParamsToQueryString } from './generic';
import { rootPath, apiPath, whfbPlaceholder } from '../constants';

export async function fetchUpdateWalkway(
    setWorkflow,
    dispatch,
    deviceData,
    queryParams,
) {
    const { serial, type, subType } = deviceData;
    const queryString = queryParamsToQueryString({
        ...queryParams,
        subType
    });
    try {
        const json = await fetchJson(
            `${rootPath}/api/device/update/${type}/${serial}${queryString}`,
        );
        if (!Array.isArray(json)) {
            throw errorFactory.server(null, null, json);
        }
        setWorkflow(json);
        return true;
    } catch (error) {
        declareError(dispatch, error);
        return false;
    }
}

export async function fetchRecycleWalkway(setWorkflow, dispatch, deviceData) {
    const { serial, type, subType } = deviceData;
    const queryParams = subType ? { subType } : {};
    const queryString = queryParamsToQueryString(queryParams);
    try {
        const json = await fetchJson(
            `${rootPath}/api/device/update/${type}/${serial}${queryString}`,
            // `${rootPath}/api/device/recycle/${type}/${serial}${queryString}`,
        );
        if (!Array.isArray(json)) {
            throw errorFactory.server(null, null, json);
        }
        setWorkflow(json);
        return true;
    } catch (error) {
        declareError(dispatch, error);
        return false;
    }
}

export async function postUpdatePiv(
    setPivResult,
    setProgress,
    dispatch,
    deviceData,
    webpcscMode,
) {
    try {
        console.debug('postUpdatePiv deviceData:', deviceData);
        const { pin, subType, type, serial, user } = deviceData;
        const queryParams = subType ? { subType } : {};
        const json = await fetchJson(
            `${rootPath}/api/device/update/${type}/${serial}`,
            { pass: pin, user },
            undefined, undefined, queryParams,
        );
        console.debug('postUpdatePiv json:', json);
        let reader = null;
        let result = null;
        try {
            await PCSC.ready;
            if (deviceData.type !== whfbPlaceholder.type) {
                switch (webpcscMode) {
                    case 'smart':
                        reader = await PCSC.connectWithFallback(deviceData.reader);
                        break;
                    case 'nonExclusive':
                        reader = await PCSC.connect(deviceData.reader, false);
                        break;
                    case 'exclusive':
                    default:
                        reader = await PCSC.connect(deviceData.reader, true);
                        break;
                }
            }
            console.debug('postUpdatePiv reader:', reader);
            result = await UCMS.apdu.run(
                reader,
                json.process,
                json.session,
                setProgress,
                deriveUrl(`${rootPath}/api`),
            );
        } catch (error) {
            console.error('postUpdatePiv error:', error);
            declareError(
                dispatch,
                errorFactory.ucms(error.code, error.msg, error),
            );
            return false;
        } finally {
            if (reader && deviceData.type !== whfbPlaceholder.type) await reader.disconnect();
        }
        setPivResult(result);
        return true;
    } catch (error) {
        console.error('postUpdatePiv:', error);
        declareError(dispatch, error);
        return false;
    }
}

export async function postRecyclePiv(
    setPivResult,
    setProgress,
    dispatch,
    deviceData,
    webpcscMode,
) {
    try {
        console.debug('postRecyclePiv deviceData:', deviceData);
        const { serial, user, type, subType } = deviceData;
        const queryParams = subType ? { subType } : {};
        const json = await fetchJson(
            `${rootPath}/api/device/recycle/${type}/${serial}`,
            { user },
            undefined, undefined,
            queryParams
        );
        console.debug('postRecyclePiv json:', json);
        let reader = null;
        let result = null;
        try {
            await PCSC.ready;
            switch (webpcscMode) {
                case 'smart':
                    reader = await PCSC.connectWithFallback(deviceData.reader);
                    break;
                case 'nonExclusive':
                    reader = await PCSC.connect(deviceData.reader, false);
                    break;
                case 'exclusive':
                default:
                    reader = await PCSC.connect(deviceData.reader, true);
                    break;
            }
            console.debug('postRecyclePiv reader:', reader);
            result = await UCMS.apdu.run(
                reader,
                json.process,
                json.session,
                setProgress,
                deriveUrl(`${rootPath}/api`),
            );
        } catch (error) {
            console.error('postRecyclePiv error:', error);
            declareError(
                dispatch,
                errorFactory.ucms(error.code, error.msg, error),
            );
        } finally {
            if (reader) await reader.disconnect();
        }
        setPivResult(result);
        return true;
    } catch (error) {
        console.error('postRecyclePiv:', error);
        declareError(dispatch, error);
        return false;
    }
}

export async function fetchChangePin(setWorkflow, dispatch, deviceData) {
    const TAG = 'fetchChangePin';
    const { serial, subType, type } = deviceData;
    const queryParams = subType ? { subType } : {};

    try {
        const json = await fetchJson(
            `${rootPath}/api/device/changePin/${type}/${serial}`,
            undefined, undefined, undefined, queryParams,
        );
        console.debug(TAG, 'json:', json);
        if (!Array.isArray(json)) {
            console.error(TAG, 'body:', json);
            throw errorFactory.server(null, null, json);
        }
        setWorkflow(json);
        return true;
    } catch (error) {
        console.error(TAG, ':', error);
        declareError(dispatch, error);
        return false;
    }
}

export async function postChangePin(
    setResult,
    setProgress,
    dispatch,
    deviceData,
) {
    const TAG = 'postChangePin';
    const { pass, newPinForm, subType, type, serial, user } = deviceData;
    let reader;
    const queryParams = subType ? { subType } : {};
    try {
        const json = await fetchJson(
            `${rootPath}/api/device/changePin/${type}/${serial}`,
            { pass, pin: newPinForm, user },
            undefined, undefined, queryParams,
        );
        setResult(json);
    } catch (error) {
        console.error(TAG, ':', error);
        declareError(dispatch, error);
        return false;
    } finally {
        if (reader) reader.disconnect();
    }
    return true;
}

export async function postChangePinPiv(
    setResult,
    setProgress,
    dispatch,
    deviceData,
    webpcscMode,
) {
    const TAG = 'postChangePinPiv';
    console.debug(TAG, 'deviceData:', deviceData);
    try {
        const { oldPin, newPinForm, subType, type, serial, user } = deviceData;
        const pass = oldPin;
        const queryParams = subType ? { subType } : {};

        const json = await fetchJson(
            `${rootPath}/api/device/changePin/${type}/${serial}`,
            { pass, pin: newPinForm, user },
            undefined, undefined, queryParams,
        );

        let reader = null;
        try {
            await PCSC.ready;
            switch (webpcscMode) {
                case 'smart':
                    reader = await PCSC.connectWithFallback(deviceData.reader);
                    break;
                case 'nonExclusive':
                    reader = await PCSC.connect(deviceData.reader, false);
                    break;
                case 'exclusive':
                default:
                    reader = await PCSC.connect(deviceData.reader, true);
                    break;
            }
            // Let apdu start to change PIN
            const result = await UCMS.apdu.run(
                reader,
                json.process,
                json.session,
                setProgress,
                deriveUrl(`${apiPath}`),
            );
            setResult(result);
        } catch (error) {
            console.error(TAG, ':', error);
            declareError(
                dispatch,
                errorFactory.ucms(error.code, error.msg, error),
            );
        } finally {
            if (reader) await reader.disconnect();
        }
    } catch (error) {
        console.error(TAG, ':', error);
        declareError(dispatch, error);
        return false;
    }
    return true;
}

export async function postResetPin(
    setResult,
    setProgress,
    dispatch,
    deviceData,
    webpcscMode,
) {
    const TAG = 'postResetPin';
    console.debug(TAG, 'deviceData:', deviceData);
    try {
        const { pin, subType, type, serial } = deviceData;

        const { user } = deviceData || {};

        const queryParams = subType ? { subType } : {};

        const json = await fetchJson(
            `${rootPath}/api/device/resetPin/${type}/${serial}`,
            { pin, user },
            undefined, undefined, queryParams,
        );
        if (json.process !== 'reset-pin') {
            setResult({ ...json, pinResetComplete: true });
            return true;
        }

        let reader = null;
        let result = null;
        try {
            await PCSC.ready;
            switch (webpcscMode) {
                case 'smart':
                    reader = await PCSC.connectWithFallback(deviceData.reader);
                    break;
                case 'nonExclusive':
                    reader = await PCSC.connect(deviceData.reader, false);
                    break;
                case 'exclusive':
                default:
                    reader = await PCSC.connect(deviceData.reader, true);
                    break;
            }
            console.debug(TAG, 'reader:', reader);
            result = await UCMS.apdu.run(
                reader,
                json.process,
                json.session,
                setProgress,
                deriveUrl(`${rootPath}/api`),
            );
        } catch (error) {
            console.error(TAG, 'error:', error);
            throw errorFactory.ucms(undefined, undefined, error);
        } finally {
            if (reader) await reader.disconnect();
        }

        setResult({ ...result, pinResetComplete: true });
        return true;
    } catch (error) {
        console.error(TAG, ':', error);
        declareError(dispatch, error);
        return false;
    }
}

// delegation
// ---------------------------------------------------------------------------------------------------------
export async function fetchAssociateDeviceOptions(
    setDeviceOptions,
    addError,
    associateUser,
) {
    const TAG = 'fetchAssociateDeviceOptions';
    try {
        const json = await fetchJson(
            `${apiPath}/delegation/associate/${associateUser}`,
        );
        setDeviceOptions(json);
        return true;
    } catch (error) {
        console.error(TAG, ':', error);
        declareError(addError, error);
        return false;
    }
}

export async function fetchDelegationFlow(
    setWorkflow,
    dispatch,
    associateData,
    queryParams,
) {
    const TAG = 'fetchAssociateDeviceOptions';
    console.debug(TAG, 'associateData:', associateData);
    const { user, type, serial } = associateData;
    try {
        const queryString = queryParamsToQueryString(queryParams);
        const json = await fetchJson(
            `${apiPath}/delegation/associate/${user}/${type}/${serial}${queryString}`,
        );
        if (!Array.isArray(json)) {
            throw errorFactory.server(null, null, json);
        }
        setWorkflow(json);
        return true;
    } catch (error) {
        console.error(TAG, ':', error);
        declareError(dispatch, error);
        return false;
    }
}

export async function fetchNormalDelegationFlow(
    setWorkflow,
    dispatch,
    associateData,
    queryParams,
) {
    const TAG = 'fetchAssociateDeviceOptions';
    console.debug(TAG, 'associateData:', associateData);
    const { user, type } = associateData;
    try {
        const queryString = queryParamsToQueryString(queryParams);
        const json = await fetchJson(
            `${apiPath}/delegation/associate/${user}/${type}${queryString}`,
        );
        if (!Array.isArray(json)) {
            throw errorFactory.server(null, null, json);
        }
        setWorkflow(json);
        return true;
    } catch (error) {
        console.error(TAG, ':', error);
        declareError(dispatch, error);
        return false;
    }
}


// eslint-disable-next-line max-len
export async function fetchDeviceAssociateQuery(
    setter,
    dispatch,
    identifiedDevices,
    associateUser,
) {
    const TAG = 'fetchDeviceAssociateQuery';
    try {
        const json = await fetchJson(
            `${apiPath}/delegation/associate/${associateUser}`,
            identifiedDevices,
        );
        console.debug(TAG, 'json:', json);
        setter(json);
        return true;
    } catch (error) {
        console.error(TAG, ':', error);
        declareError(dispatch, error);
        return false;
    }
}

export async function postAssociateAction(
    setter,
    progress,
    dispatch,
    data,
    webpcscMode,
) {
    const TAG = 'postAssociateAction';
    try {
        console.debug(TAG, 'data:', data);
        const { user, reader, pin, pass, action } = data;

        const { device, groups, subType, type, serial, readerName } = reader;
        const requestPayload = { pin, pass, action };
        if (action === 'enrollPiv') {
            requestPayload.device = device;
            requestPayload.group = groups[0]; // eslint-disable-line
        }
        const queryParams = subType ? { subType } : {};
        const json = await fetchJson(
            `${apiPath}/delegation/associate/${user}/${type}/${serial}`,
            requestPayload,
            undefined, undefined,
            queryParams,
        );
        console.debug(TAG, 'json:', json);
        let conn = null;
        let result = null;
        try {
            await PCSC.ready;
            switch (webpcscMode) {
                case 'smart':
                    conn = await PCSC.connectWithFallback(readerName);
                    break;
                case 'nonExclusive':
                    conn = await PCSC.connect(readerName, false);
                    break;
                case 'exclusive':
                default:
                    conn = await PCSC.connect(readerName, true);
                    break;
            }
            console.debug(TAG, 'conn:', conn);
            result = await UCMS.apdu.run(
                conn,
                json.process,
                json.session,
                progress,
                deriveUrl(`${apiPath}`),
            );
        } catch (error) {
            console.error(TAG, 'error:', error);
            throw errorFactory.ucms(undefined, undefined, error);
        } finally {
            if (conn) await conn.disconnect();
        }
        setter(result);
    } catch (error) {
        console.error(TAG, 'error:', error);
        declareError(dispatch, error);
    }
}

export async function fetchAssociateEnrollU2fRequest(
    setEnrollU2fRequest,
    dispatch,
    register,
    deviceData,
    { user, subType, type, serial },
) {
    try {
        const queryParams = subType ? { subType } : {};
        const json = await fetchJson(
            `${apiPath}/delegation/associate/${user}/${type}/${serial}`,
            deviceData,
            undefined, undefined,
            queryParams,
        );
        setEnrollU2fRequest(json);
        register(json.request);
    } catch (error) {
        console.error('fetchAssociateEnrollU2fRequest:', error);
        declareError(dispatch, error);
    }
}

export async function postAssociateEnrollU2fRequest(
    setU2fResult,
    dispatch,
    u2fdata,
    { user, subType, type, serial },
) {
    try {
        const queryParams = subType ? { subType } : {};
        const json = await fetchJson(
            `${apiPath}/delegation/associate/${user}/${type}/${serial}`,
            u2fdata,
            undefined, undefined,
            queryParams,
        );
        setU2fResult(json);
    } catch (error) {
        console.error('postAssociateEnrollU2fRequest:', error);
        declareError(dispatch, error);
    }
}

export async function postResetFailCounter(
    setResult,
    dispatch,
    { user, subType, type, serial },
) {
    try {
        const queryParams = subType ? { subType } : {};
        const body = {
            user: user.username
        };
        const json = await fetchJson(
            `${apiPath}/device/resetFailCounter/${type}/${serial}`,
            body,
            undefined, undefined,
            queryParams,
        );
        setResult(json);
    } catch (error) {
        console.error('postResetFailCounterRequest:', error);
        declareError(dispatch, error);
    }
}

export async function postAssociateEnrollOtpAssign(
    setOtpResult,
    dispatch,
    deviceData,
    { user, subType, type, serial },
) {
    try {
        const queryParams = subType ? { subType } : {};
        const json = await fetchJson(
            `${apiPath}/delegation/associate/${user}/${type}/${serial}`,
            deviceData,
            undefined, undefined,
            queryParams,
        );
        setOtpResult(json);
    } catch (error) {
        console.error('postEnrollOtpAssign:', error);
        declareError(dispatch, error);
    }
}


export const postAssociateFido2Enroll = async (
    setFido2Result,
    setProgress,
    dispatch,
    deviceData,
    username,
) => {
    try {
        const json = await fetchJson(
            `${apiPath}/delegation/associate/${username}/fido2Enroll`,
            { ...deviceData }, // this needs further discussion about if it's a fido2 specific device.
            'POST',
        );
        console.log('******************** Enrolling finished:: ', json);

        const result = await UCMS.apdu.run(
            null,
            json.process,
            json.session,
            setProgress,
            deriveUrl(`${rootPath}/api`),
        );

        setFido2Result(result);
    } catch (error) {
        console.error('postEnrollfido2:', error);
        declareError(dispatch, error);
    }
};