import React from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import {
    PortalContainer,
    CardHeader,
    StyledAlert,
} from '../../../styles/common';
import UserAssociate from '../../elementLibrary/UserAssociate';

const StyledUserAssociate = styled(UserAssociate)`
    padding-top: 12px;
    padding-bottom: 12px;
`;

const AssociateUserWithData = ({
    showNext,
    setShowNext,
    validUser,
    loading,
}) => {
    const { t } = useTranslation();
    return (
        <PortalContainer>
            <CardHeader>{t('associateUserLabels$stateless$h6')}</CardHeader>
            <StyledAlert color="info">
                {t('instructionMessages$confirmCoworkerInformation')}
            </StyledAlert>
            <StyledUserAssociate
                nextShow={showNext}
                setShowNext={setShowNext}
                validUser={validUser}
                loading={loading}
            />
        </PortalContainer>
    );
};

AssociateUserWithData.propTypes = {
    showNext: PropTypes.bool.isRequired,
    setShowNext: PropTypes.func.isRequired,
};

export default AssociateUserWithData;
