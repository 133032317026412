import { Container, Row, Col, ModalBody } from 'reactstrap';
import styled from 'styled-components';
import {
    StyledModalHeader,
    StyledModalFooter,
} from '../../../../../styles/common';

const InfoContainer = styled(Container)`
    text-align: left;
`;

const ScrollInfoContainer = styled(InfoContainer)``;

const UserInfoRow = styled(Row)`
    padding-top: 5px;
    padding-bottom: 5px;
`;
const FullHeightCol = styled(Col)`
    height: inherit !important;
    @media only screen and (min-width: 768px) {
        padding-right: ${({ $reduceSpace }) =>
        $reduceSpace === 'right' ? 0 : -1} !important;
        padding-left: ${({ $reduceSpace }) =>
        $reduceSpace === 'left' ? 0 : -1} !important;
    }
`;
const UserInfoColName = styled(FullHeightCol)`
    display: inline-grid;
    grid-template-columns: auto auto;
`;

const UserInfoColValue = styled(UserInfoColName)`
    grid-column-gap: 20px;
`;
const DevicesTableWrapper = styled.div`
    text-align: left;
`;
const ButtonsWrapper = styled.div`
    align-self: flex-start;
`;

const UserLabel = styled.div`
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
    @media only screen and (min-width: 768px) {
        max-width: 130px !important;
    }
`;

const UserValue = styled.div`
    text-align: left;
    padding: 5px;
`;

const StyledSubtitle = styled.h6`
    padding: 0.25rem 1rem;
    color: #818181;
    margin: 0;
`;

const StyledHeader = styled(StyledModalHeader)`
    background-color: ${(props) => props.theme.colors.foreground};
    color: ${(props) => props.theme.typography.text};
    padding-bottom: 0px !important;
`;
const StyledBody = styled(ModalBody)`
    background-color: ${(props) => props.theme.colors.foreground};
    color: ${(props) => props.theme.typography.text};
    padding-bottom: 0px !important;
`;
const StyledFooter = styled(StyledModalFooter)`
    background-color: ${(props) => props.theme.colors.foreground};
    color: ${(props) => props.theme.typography.text};
    padding-bottom: 0px !important;
`;

const TPADetailsBody = styled.div`
    display: grid;
    grid-template-columns: minmax(100px, 1fr) 2fr;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 1px dotted #c9c9c9;
    border-bottom: 1px dotted #c9c9c9;
`;

const TPAContent = styled.div`
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
`;

const TPATitle = styled(TPAContent)`
    font-weight: bold;
`

const Title = styled.h1`
    font-weight: 600;
    font-size: 1.5rem;
    text-align: left;
    text-transform: capitalize;
`;

const CardTitleRow = styled(Row)`
    min-height: 3rem;
`;

export {
    InfoContainer,
    ScrollInfoContainer,
    UserInfoRow,
    FullHeightCol,
    UserInfoColName,
    UserInfoColValue,
    DevicesTableWrapper,
    ButtonsWrapper,
    UserLabel,
    UserValue,
    StyledHeader,
    StyledSubtitle,
    StyledBody,
    StyledFooter,
    TPADetailsBody,
    TPAContent,
    TPATitle,
    Title,
    CardTitleRow,
};
