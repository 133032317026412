import React from 'react';
import { PropTypes } from 'prop-types';

import CardTemplate from './CardTemplate';
import NewFlowButtons from '../elementLibrary/NewFlowButtons';

const NewCardLayout = ({
    children,
    title,
    id = null,
    dispatch,
    disableBack,
    disableNext,
}) => (
    <CardTemplate title={title} testId={id} noFade>
        {children}
        <NewFlowButtons
            disableBack={disableBack}
            disableNext={disableNext}
            backCallback={() => dispatch({ type: 'GO_BACK' })}
            nextCalback={() => dispatch({ type: 'GO_NEXT' })}
        />
    </CardTemplate>
);

NewCardLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]).isRequired,
    title: PropTypes.string.isRequired,
    dispatch: PropTypes.func,
    disableBack: PropTypes.bool,
    disableNext: PropTypes.bool,
    id: PropTypes.string.isRequired,
};

export default NewCardLayout;
