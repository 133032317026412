import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AccentButton,
    StyledDefaultModalHeader,
    StyledDefaultModalBody,
    StyledDefaultModalFooter,
} from '../../styles/common';
import { AppStateContext, AppDispatchContext } from '../../context/AppContext';
import { useHandleEnter } from '../../utils/hooks';

const HelpInfoModal = () => {
    const {
        modal: { title, component },
    } = useContext(AppStateContext);
    const dispatch = useContext(AppDispatchContext);
    const { t } = useTranslation();

    const close = () => dispatch({ type: 'CLEAR_MODAL' });
    useHandleEnter({ action: close });

    return (
        <>
            <StyledDefaultModalHeader>{title}</StyledDefaultModalHeader>
            <StyledDefaultModalBody>{component}</StyledDefaultModalBody>
            <StyledDefaultModalFooter>
                <AccentButton outline color="primary" size="sm" onClick={close} data-testid='closeBtn'>
                    {t('modalLabels$close')}
                </AccentButton>
            </StyledDefaultModalFooter>
        </>
    );
};

export default HelpInfoModal;
