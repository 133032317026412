import React, {
    useState,
    useContext,
    useEffect,
    useRef,
    forwardRef,
} from 'react';
import {
    useTable,
    useSortBy,
    useRowSelect,
    useRowState,
    usePagination,
    useFlexLayout,
} from 'react-table';
import uniqueId from 'lodash.uniqueid';
import isEmpty from 'lodash.isempty';
import styled from 'styled-components';
import { Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
    StyledTableRow,
    StyledTable,
    StyledCheckbox,
    StyledTableBody,
    StyledTableHead,
    StyledTableHeadRow,
    StyledColumnHeader,
    TableWrapper,
    TableHeaderRow,
    TableRowWrapper,
    TableCellWrapper,
} from '../../../styles/common';
import { HelpDeskDispatchContext } from '../../../context/HelpDeskContext';
import SortIcon from '../../shared/SortIcon';
import TablePagination from '../../shared/TablePagination';
import TableSettings from '../../shared/TableSettings';
import { renderCell } from '../../../utils/generic';
import { usePrevious } from '../../../utils/hooks';

const TableSettingsWrapper = styled.div`
    text-align: right;
`;

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <StyledCheckbox type="checkbox" ref={resolvedRef} {...rest} />;
});

function processDeviceType(data, t) {
    return data.map((row) => {
        const label = `mappingsLabels$deviceSubTypes$${row.deviceType}$label`;
        return { ...row, deviceType: t(label) ? t(label) : row.deviceType };
    });
}

const DEFAULT_COLUMN_SETTINGS = {
    // When using the useFlexLayout:
    minWidth: 30, // minWidth is only used as a limit for resizing
    maxWidth: 150, // maxWidth is only used as a limit for resizing
};

const TableCore = ({
    tableType = '',
    hideTableHeader = false,
    data = [{}],
    columnsData = [{}],
    columnsName = [''],
    hideCheckboxes = false,
    hidePagination = false,
    selectedColumnsKey = '',
    onSelectedRow = () => { },
    onColumnsChange = () => { },
    getTableHeader = null,
    defaultColumns,
    defaultColumnSettings: defaultColumn = DEFAULT_COLUMN_SETTINGS,
    enableLocalStorage = true,
    disableSkipPaging = false,
    lazyFetchMode = false,
    lazyFetchCanNext = false,
    lazyFetchPageNumber = null,
    rowDetails = null,
    lazyFetchPreviousPage = () => { },
    lazyFetchNextPage = () => { },
    lazyFetchPageSize = () => { },
    lazyFetchSetPageSize = () => { },
    lazyFetchPageCount = () => { },
    lazyFetchResultsCount = () => { },
    lazyFetchSort = () => { },
    hideColumn = [],
    setColumnStyle = () => { },
    onPageChange = () => { },
}) => {
    const [isCheckAllClicked, setIsCheckAllClicked] = useState(false);
    const dispatch = useContext(HelpDeskDispatchContext);
    const { t } = useTranslation();
    processDeviceType(data, t);
    const sortByLocalStorageKey = `${selectedColumnsKey}SortBy`;

    const {
        getTableProps,
        prepareRow,
        headers,
        rows,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { selectedRowIds, pageSize, pageIndex, sortBy },
        selectedFlatRows,
    } = useTable(
        {
            columns: columnsData,
            data,
            initialState: {
                pageIndex: 0,
                hiddenColumns: hideColumn,
                sortBy: !!localStorage.getItem(sortByLocalStorageKey)
                    ? JSON.parse(localStorage.getItem(sortByLocalStorageKey))
                    : [],
            },
            autoResetSelectRows: false,
            autoResetPage: false,
            manualSortBy: lazyFetchMode,
            defaultColumn,
        },
        useFlexLayout,
        useSortBy,
        usePagination,
        useRowSelect,
        useRowState,
        ({ columns }) => {
            if (!hideCheckboxes && columns) {
                columns.push((columns) => [
                    {
                        id: 'selection',
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <IndeterminateCheckbox
                                onClick={() => setIsCheckAllClicked(true)}
                                {...getToggleAllRowsSelectedProps()}
                            />
                        ),
                        Cell: ({ row: { getToggleRowSelectedProps } }) => (
                            <IndeterminateCheckbox
                                onClick={() => setIsCheckAllClicked(false)}
                                {...getToggleRowSelectedProps()}
                            />
                        ),
                    },
                    ...columns,
                ]);
            }
        },
    );
    const prevSelectedRowIds = usePrevious(selectedRowIds);
    const hasRowSelected = !isEmpty(prevSelectedRowIds) || !isEmpty(selectedRowIds);
    const pageIndexRef = useRef(null);
    const selectedRowIdsRef = useRef(null);
    const lazyFetchPageNumberRef = useRef(null);
    const sortByRef = useRef(null)

    useEffect(() => {
        if (pageIndex !== pageIndexRef.current) {
            pageIndexRef.current = pageIndex;
            onPageChange(pageIndex);
        }
    }, [pageIndex, onPageChange]);

    useEffect(() => {
        if (!!sortBy) {
            localStorage.setItem(sortByLocalStorageKey, JSON.stringify(sortBy));
        }
    }, [sortBy, sortByLocalStorageKey]);

    useEffect(() => {
        if (selectedRowIdsRef.current !== selectedRowIds) {
            selectedRowIdsRef.current = selectedRowIds;
            const selectedIds = Object.keys(selectedRowIds);
            const selectedRowsData = !isEmpty(selectedIds)
                ? selectedIds.map((x) => data[x]).filter((x) => x !== null && x !== undefined)
                : [];
            if (hasRowSelected) {
                onSelectedRow(selectedRowsData);
            }
        }
        // In Lazy mode, page might be updated before the data is fetch. 
        // This call force the page to be updated, after new data is updated.
        lazyUpdatePageNumber();
    }, [selectedRowIds, data, hasRowSelected, onSelectedRow]);

    useEffect(() => {
        if (isCheckAllClicked) {
            const pageRows = selectedFlatRows.filter((a) =>
                page.some((b) => a.id === b.id),
            );

            rows.forEach((row) => {
                prepareRow(row);
                if (!pageRows.includes(row) || !isEmpty(prevSelectedRowIds)) {
                    row.toggleRowSelected(false);
                }
            });
            setIsCheckAllClicked(false);
        }
    }, [page, selectedFlatRows, isCheckAllClicked, rows, prepareRow, prevSelectedRowIds]);

    const onPageSizeChange = (value) => {
        if (lazyFetchMode) {
            value && lazyFetchSetPageSize(value);
        } else {
            value && setPageSize(value);
        }
    };

    useEffect(() => {
        if (lazyFetchPageNumberRef.current !== lazyFetchPageNumber) {
            lazyUpdatePageNumber();
        }
    }, [lazyFetchPageNumber, lazyFetchMode, lazyFetchPageSize, gotoPage, setPageSize]);


    const lazyUpdatePageNumber = () => {
        if (!lazyFetchMode) {
            return;
        }
        lazyFetchPageNumberRef.current = lazyFetchPageNumber
        gotoPage(lazyFetchPageNumber);
        setPageSize(lazyFetchPageSize);
    }

    const totalCount = lazyFetchMode ? lazyFetchResultsCount : data.length;

    const DefaultTableSettings = (
        <Col
            style={{
                display: 'flex',
                justifyContent: 'flex-end',
            }}
        >
            <TableSettings
                onPageSizeChange={onPageSizeChange}
                onColumnsChange={onColumnsChange}
                selectedColumnsKey={selectedColumnsKey}
                columnsName={columnsName}
                pageSize={pageSize}
                hidePagination={hidePagination}
                defaultColumns={defaultColumns}
                enableLocalStorage={enableLocalStorage}
            />
        </Col>
    );
    const tableHeader = () => {
        // User pass getTableHeader as a function to set custom header.
        if (!!getTableHeader && typeof getTableHeader === 'function') {
            return getTableHeader(DefaultTableSettings);
        }
        // Ohterwise return default table settings.
        return DefaultTableSettings;
    };

    useEffect(() => {
        if (lazyFetchMode && sortByRef.current !== sortBy) {
            sortByRef.current = sortBy;
            lazyFetchSort(headers);
        }
    }, [sortBy, lazyFetchSort, lazyFetchMode, headers]);

    return (
        <>
            <TableHeaderRow $hideTableHeader={hideTableHeader}>
                {tableHeader()}
            </TableHeaderRow>

            <TableWrapper>
                <StyledTable hover borderless {...getTableProps()}>
                    <StyledTableHead>
                        <StyledTableHeadRow>
                            {headers.map(
                                (column) =>
                                    column.isVisible && (
                                        <div
                                            key={uniqueId(
                                                `${tableType}Header_`,
                                            )}
                                            id={`${tableType}Header_${column.id}`}
                                            name={column.id}
                                            {...column.getHeaderProps({
                                                ...column.getSortByToggleProps(),
                                                ...setColumnStyle(column),
                                            })}
                                        >
                                            <StyledColumnHeader>
                                                {column.render('Header')}
                                            </StyledColumnHeader>
                                            <SortIcon
                                                isSorted={column.isSorted}
                                                isSortedDesc={
                                                    column.isSortedDesc
                                                }
                                                hide={
                                                    column.id === 'action' ||
                                                    column.id === 'selection' ||
                                                    column.id === 'expander' ||
                                                    column.id === 'logo' ||
                                                    column.id === 'type'
                                                }
                                            />
                                        </div>
                                    ),
                            )}
                        </StyledTableHeadRow>
                    </StyledTableHead>
                    <StyledTableBody>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <TableRowWrapper key={row.index}>
                                    <StyledTableRow
                                        key={uniqueId(`${tableType}Row_`)}
                                        id={`${tableType}Row_${row.id}`}
                                        onClick={() =>
                                            dispatch({
                                                type: 'SET_HELPDESK_USER',
                                                payload: row.original.user,
                                            })
                                        }
                                        {...row.getRowProps()}
                                    >
                                        {row.cells.map((cell) => (
                                            <TableCellWrapper
                                                key={uniqueId(
                                                    `${tableType}Cell_`,
                                                )}
                                                id={`${tableType}Cell_${cell.column.id}-${row.id}`}
                                                {...cell.getCellProps({
                                                    ...setColumnStyle(
                                                        cell.column,
                                                    ),
                                                })}
                                            >
                                                {renderCell(cell)}
                                            </TableCellWrapper>
                                        ))}
                                    </StyledTableRow>

                                    {/* row details drop down area */}
                                    {!!rowDetails && rowDetails(row)}
                                </TableRowWrapper>
                            );
                        })}
                    </StyledTableBody>
                </StyledTable>
            </TableWrapper>
            <TableSettingsWrapper>
                {!hidePagination && totalCount > pageSize && (
                    <>
                        <TablePagination
                            gotoPage={!disableSkipPaging && gotoPage}
                            previousPage={
                                lazyFetchMode
                                    ? lazyFetchPreviousPage
                                    : previousPage
                            }
                            nextPage={
                                lazyFetchMode ? lazyFetchNextPage : nextPage
                            }
                            canPreviousPage={canPreviousPage}
                            canNextPage={
                                lazyFetchMode ? lazyFetchCanNext : canNextPage
                            }
                            pageIndex={pageIndex}
                            pageCount={
                                lazyFetchMode ? lazyFetchPageCount : pageCount
                            }
                            totalCount={totalCount}
                            disableSkipPaging={disableSkipPaging}
                        />
                    </>
                )}
            </TableSettingsWrapper>
        </>
    );
};

export default TableCore;
