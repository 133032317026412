import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label } from 'reactstrap';
import { MAX_RATING } from '../../constants';
import {
    StyledAlert,
    StyledInput,
    AccentButton,
    StyledFormGroup,
    StyledDefaultModalHeader,
    StyledDefaultModalBody,
    StyledDefaultModalFooter,
} from '../../styles/common';
import { AppStateContext, AppDispatchContext } from '../../context/AppContext';
import { sendUserFeedback } from '../../utils/sentry';
import StarRating from '../shared/StarRating';

const UserFeedback = () => {
    const { t } = useTranslation();
    const [message, setMessage] = useState('');
    const [rating, setRating] = useState(0);
    const [displayText, setDisplayText] = useState(t('modalLabels$userFeedback$message'));
    const [color, setColor] = useState('info');
    const { user, settings } = useContext(AppStateContext);
    const dispatch = useContext(AppDispatchContext);

    const { email, firstName, lastName } = user || {};
    const { analyticsDsn, analyticsUrl, analyticsOrg, analyticsProject } =
        settings || {};

    const close = () => dispatch({ type: 'CLEAR_MODAL' });
    const submit = async () => {
        if (rating === 0 || message === undefined || message === null || message === '') {
            setDisplayText(t('modalLabels$userFeedback$error'));
            setColor('danger');
        } else {
            const endpoint = `${analyticsUrl}/api/0/projects/${analyticsOrg}/${analyticsProject}/user-feedback/`;
            await sendUserFeedback({
                email,
                dsn: analyticsDsn,
                name: `${firstName} ${lastName}`,
                comments: message || 'N/A',
                rating,
                endpoint,
            });
            close();
        }
    };

    const handleEnter = (e) => {
        if (e && e.keyCode === 13) {
            submit();
        }
    };
    
    useEffect(() => {
        const feedBackmodalDiv = document.getElementsByClassName('modal-content')[0]
        feedBackmodalDiv?.addEventListener('keyup', handleEnter);
        return () => {
            feedBackmodalDiv?.removeEventListener('keyup', handleEnter);
        };
    }, [message, rating]);

    return (
        <>
            <StyledDefaultModalHeader>
                {t('modalLabels$userFeedback$title')}
            </StyledDefaultModalHeader>
            <StyledDefaultModalBody>
                <StyledAlert color={color}>
                    {displayText}
                </StyledAlert>
                <StyledFormGroup row autoComplete="off">
                    <Col sm="3">
                        <Label for="userFeedbackRating">
                            {t('modalLabels$userFeedback$rating')} :
                        </Label>
                    </Col>
                    <Col sm="9">
                        <StarRating
                            name="userFeedbackRating"
                            onClick={setRating}
                            numberOfStars={MAX_RATING}
                        />
                    </Col>
                    <Col sm="3">
                        <Label for="userFeedbackComment">
                            {t('modalLabels$userFeedback$comment')} :
                        </Label>
                    </Col>
                    <Col sm="9">
                        <StyledInput
                            name="userFeedbackComment"
                            bsSize="sm"
                            value={message}
                            placeholder={t('modalLabels$userFeedback$input')}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </Col>
                </StyledFormGroup>
            </StyledDefaultModalBody>
            <StyledDefaultModalFooter>
                <AccentButton
                    outline
                    color="primary"
                    size="sm"
                    onClick={submit}
                >
                    {t('modalLabels$submit')}
                </AccentButton>
                <AccentButton outline color="primary" size="sm" onClick={close}>
                    {t('modalLabels$close')}
                </AccentButton>
            </StyledDefaultModalFooter>
        </>
    );
};

export default UserFeedback;
