import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Label, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { userIdFormId } from '../../../constants';
import { StyledInput } from '../../../styles/common';
import { EnrollingDispatchContext } from '../../../context/EnrollingContext';

const UserIdWithData = ({ serverComponent, validator, validUser, loading }) => {
    const { t } = useTranslation();
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const form = 'userId';
    return (
        <div data-test-id={userIdFormId}>
            <FormGroup row autoComplete="off">
                <Col xs="3">
                    <Label for={form}>{t('formLabels$userId$formLabel')}</Label>
                </Col>
                <Col xs="4">
                    {serverComponent.details.value ? (
                        <div>{serverComponent.details.value}</div>
                    ) : (
                        <StyledInput
                            id={form}
                            name={form}
                            disabled={loading}
                            bsSize="sm"
                            placeholder={t('formLabels$userId$placeholder')}
                            valid={validUser === true}
                            invalid={validUser === false}
                            onChange={(event) => {
                                enrollingDispatch({
                                    type: 'SET_VALUE',
                                    name: form,
                                    value: event.target.value,
                                    validator,
                                });
                            }}
                        />
                    )}
                </Col>
            </FormGroup>
        </div>
    );
};

UserIdWithData.propTypes = {
    serverComponent: PropTypes.object,
    validator: PropTypes.func.isRequired,
};

UserIdWithData.defaultProps = {
    serverComponent: {
        details: {
            value: false,
        },
    },
};

export default UserIdWithData;
