import * as Sentry from '@sentry/browser';
import { errorFactory } from './errors';

const POST_TIMEOUT = 120000;

export const sendUserFeedback = async ({
    dsn,
    name,
    email,
    comments,
    endpoint,
    rating,
}) => {
    Sentry.setTag('rating', rating);
    Sentry.captureException(new Error('UserFeedback'));
    const eventId = Sentry.lastEventId();
    const body = JSON.stringify({ name, email, comments, event_id: eventId });
    await Promise.race([
        fetch(endpoint, {
            method: 'POST',
            headers: {
                Authorization: `DSN ${dsn}`,
                'Content-Type': 'application/json',
            },
            body,
        }),
        new Promise((_, reject) => {
            setTimeout(() => reject(errorFactory.timeout()), POST_TIMEOUT);
        }),
    ]);
};
