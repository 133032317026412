import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
    AccentButton,
    StyledAlert,
    StyledModal,
    StyledDefaultModalHeader,
    StyledDefaultModalBody,
    StyledDefaultModalFooter,
} from '../../../styles/common';

import {
    DeviceDetailsField,
    DeviceDetailsValue,
    DeviceDetailsTableWrapper,
    FailcountBadge,
} from './styles';

import { donwloadCert } from './utils';

import CopyButton from '../../shared/CopyButton';
import { credentialEndpoint, apiPath } from '../../../constants';

const DeviceDetailsModal = ({
    isOpen,
    setIsOpen,
    data: { type, identifier }
}) => {
    const [value, setValue] = useState(null);
    const [otherData, setOtherData] = useState({});
    const { t } = useTranslation();

    const setData = (credentialData) => {
        if (credentialData !== null) {
            setValue(credentialData.value);
            setOtherData(credentialData.data);
        }
    }

    useEffect(() => {
        async function fetchCredentialData() {
            await fetch(`${apiPath}${credentialEndpoint}/${type}/${identifier}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                timeout: 5000,
            })
                .then((res) => res.json())
                .then((d) => {
                    setData(d);
                })
                .catch((err) => console.error(err));
        }

        if (isOpen === true) {
            fetchCredentialData()
        }
    }, [isOpen, identifier, type]);

    useEffect(() => {
        const handleEnter = (e) => {
            if (e.keyCode === 13 && isOpen) {
                setIsOpen(false);
            }
        };
        document.addEventListener('keyup', handleEnter);
        return () => document.removeEventListener('keyup', handleEnter);
    },[]);

    const getGridKeyLocale = (key) => {
        // check if the field name is validity or not
        if (key.toLowerCase().indexOf('valid') !== -1) {
            const validityLabelType =
                key.toLowerCase().indexOf('start') !== -1
                    ? 'validStart'
                    : 'validEnd';
            return t(`modalElementLabels$details$columns$${validityLabelType}`);
        }
        return t(`credentialLabels$extra$${key}`) || key;
    };

    const createGrid = () => {
        const gridData = { ...otherData };
        let maxfail;
        if (gridData.maxfail != null) {
            maxfail = gridData.maxfail;
            delete gridData.maxfail;
        }
        const contents = Object.keys(gridData)
            .sort((a, b) => {
                const aVal = getGridKeyLocale(a);
                const bVal = getGridKeyLocale(b);
                return aVal.localeCompare(bVal);
            }).map((key) => {
                let value = gridData[key];
                if (key.toLocaleLowerCase().indexOf('validity') !== -1
                    || key.toLocaleLowerCase().indexOf('assignmentdate') !== -1
                    || key.toLocaleLowerCase().indexOf('lastauthenticated') !== -1
                    || key.toLocaleLowerCase().indexOf('lastsynchronization') !== -1
                    || key.toLocaleLowerCase().indexOf('createddate') !== -1) {
                    value = new Date(value).toUTCString();
                }
                const keyText = getGridKeyLocale(key);

                let valueContent = <DeviceDetailsValue>{value}</DeviceDetailsValue>;

                if (key === 'provider') {
                    valueContent = t(`credentialLabels$provider$${value.toLowerCase()}`) || value;
                }
                if (key === 'type') {
                    valueContent = t(`credentialLabels$type$${value.toLowerCase()}`) || value;
                }

                if (key === 'failcount') {
                    let color = 'warning';
                    if (value === 0) color = 'success';
                    if (value === maxfail) color = 'danger';
                    valueContent = (
                        <FailcountBadge color={color}>
                            {value} {t('credentialTable$details$of')} {maxfail}
                        </FailcountBadge>
                    );
                }
                return (
                    <>
                        <DeviceDetailsField>{keyText}:</DeviceDetailsField>
                        {valueContent}
                    </>
                );
            });

        // add copy button if browser support clipboard
        if (!!value && navigator && navigator.clipboard) {
            contents.push(
                <>
                    <DeviceDetailsField>value:</DeviceDetailsField>
                    <DeviceDetailsValue>
                        <CopyButton value={value} />
                    </DeviceDetailsValue>
                </>,
            );
        }
        return contents;
    };

    return (
        <StyledModal
            centered
            size="lg"
            isOpen={isOpen}
            toggle={() => setIsOpen(false)}
        >
            <StyledDefaultModalHeader>{t`credentialTable$details$info`}</StyledDefaultModalHeader>
            <StyledDefaultModalBody>
                <StyledAlert color="info">
                    <DeviceDetailsTableWrapper>
                        {createGrid()}
                    </DeviceDetailsTableWrapper>
                </StyledAlert>
            </StyledDefaultModalBody>
            <StyledDefaultModalFooter>
                {!!value && (
                    <AccentButton
                        outline
                        size="sm"
                        onClick={() => donwloadCert(value)}
                    >
                        {t('modalLabels$exportReport$submit')}
                    </AccentButton>
                )}
                <AccentButton
                    outline
                    color="primary"
                    size="sm"
                    onClick={() => setIsOpen(false)}
                >
                    {t('modalElementLabels$modal$cancel')}
                </AccentButton>
            </StyledDefaultModalFooter>
        </StyledModal>
    );
};
export default DeviceDetailsModal;
