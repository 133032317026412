import React, { useEffect, useState, useContext, useRef } from 'react';
import { Input, FormGroup, FormFeedback } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { postEnrollMobileOtpAssign } from '../../utils/credentialing';
import { AppDispatchContext } from '../../context/AppContext';
import CardEnrollLayout from '../shared/CardEnrollLayout';
import { EnrollingStateContext } from '../../context/EnrollingContext';
import { postData } from '../../utils/postData';
import {
    serverConstants,
    mobileOtpQRId,
    enrollMobileOtpCardId,
    revokeDeviceEndpoint,
    identitiesPath,
} from '../../constants';
import LoadingSpinner from '../shared/LoadingSpinner';

const QRContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
const QRImage = styled.img`
    height: 150px;
    width: 150px;
    margin: 1.5rem;
`;

const MOTPContentContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const StyledFormGroup = styled(FormGroup)`
    margin: auto 0;
    max-width: 30rem;
    min-width: 10rem;
    position: relative;
`;

const StyledInput = styled(Input)`
    letter-spacing: 0.6rem;
    margin-bottom: 1.5rem;
`;

const StyledMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const StyledMessageTitle = styled.h5``;
const StyledMessageSubtitle = styled.span`
    color: #6e6e6e;
    margin-bottom: 0.25rem;
`;
const StyledMessage = styled.div`
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #235b9d;
    position: absolute;
    bottom: 0;
`;

const StyledFormFeedback = styled(FormFeedback)`
    position: absolute;
    bottom: 0;
`;

const WalletIcon = ({ image, alt }) => (
    <QRContainer>
        <QRImage src={image} alt={alt} data-test-id={mobileOtpQRId} />
    </QRContainer>
);

WalletIcon.propTypes = {
    image: PropTypes.string,
    alt: PropTypes.string,
};

WalletIcon.defaultProps = {
    image: '',
    alt: 'Mobile OTP QR code',
};

const MOTP_STATUS = {
    verifying: 'verifying',
    invalid: 'invalid',
    valid: 'valid',
    failExceeded: 'failExceeded',
    null: null,
};

const EnrollMobileOtp = ({ mgmt = null }) => {
    const { device } = useContext(EnrollingStateContext);
    const dispatch = useContext(AppDispatchContext);
    const { username } = useContext(EnrollingStateContext);
    const { t } = useTranslation();
    const [qrImage, setQRImage] = useState(null);
    const [motpStatus, setMotpStatus] = useState(MOTP_STATUS.null);
    const tokenTypeRef = useRef();
    const serialRef = useRef();
    const otpLenRef = useRef();
    const successRef = useRef(false);
    const history = useHistory();

    const revokeMotp = () => {
        const request = {
            body: { user: username },
            endpoint: `${revokeDeviceEndpoint}/${tokenTypeRef.current}/${serialRef.current}`,
        };
        postData(request);
    };

    useEffect(() => {
        return () => {
            if (!successRef.current) {
                revokeMotp();
            }
        };
    }, []);

    useEffect(() => {
        const moptPolling = ({ result, img, details }) => {
            if (result === serverConstants.success && username) {
                // the order here is important as ref cannot trigger rerender.
                tokenTypeRef.current = details.tokenType;
                serialRef.current = details.serial;
                otpLenRef.current = details.otpLen;
                setQRImage(img);
            }
        };
        postEnrollMobileOtpAssign(
            moptPolling,
            dispatch,
            {
                pin: device.pin,
            },
            username,
        );
    }, [username]);

    const fetchMOTPValid = async (motp) => {
        setMotpStatus(MOTP_STATUS.verifying);
        const res = await postData({
            endpoint: `/device/validateDevice/mobileOtpToken/${serialRef.current}`,
            body: {
                pass: `${device.pin[0].value}${motp}`,
                user: username,
            },
        });
        const json = await res.json();
        if (json.result === 'success') {
            successRef.current = true;
            setMotpStatus(MOTP_STATUS.valid);
            mgmt.next();
        } else {
            successRef.current = false;

            if (json.message === 'validateFailExceeded') {
                setMotpStatus(MOTP_STATUS.failExceeded);
            } else {
                setMotpStatus(MOTP_STATUS.invalid);
            }
        }
    };

    const onChange = async (e) => {
        const motp = e.target.value;

        if (motp.length > otpLenRef.current * 1) {
            e.preventDefault();
            e.target.value = motp.substr(0, otpLenRef.current * 1);
        }

        if (motpStatus === MOTP_STATUS.invalid) {
            e.preventDefault();
            e.target.value = motp.substr(0, otpLenRef.current * 1);
            setMotpStatus(MOTP_STATUS.null);
        }

        // tokenDetail.otpLen is a integer String
        if (motp.length === otpLenRef.current * 1) {
            await fetchMOTPValid(motp);
        }
    };

    return (
        <CardEnrollLayout
            id={enrollMobileOtpCardId}
            cardTitle={t('cardTitles$mobileOtp')}
            title={t('issuanceProgress')}
            testId={enrollMobileOtpCardId}
            alert={t('enrollMobileOtpCardLabels$alert', {
                SERIAL: serialRef.current,
            })}
            flowButtonProps={{
                backButtonText: t('modalElementLabels$modal$cancel'),
                backButtonColor: 'danger',
                showBackButtonIcon: false,
            }}
            showBack
            showNext={false}
            displayNext={false}
            mgmt={{
                next: mgmt.next,
                back: () => {
                    history.push(identitiesPath);
                },
            }}
        >
            {qrImage === null && <LoadingSpinner />}
            {qrImage && (
                <MOTPContentContainer>
                    <WalletIcon
                        image={qrImage}
                        alt={t('enrollMobileOtpCardLabels$alert', {
                            SERIAL: serialRef.current,
                        })}
                    />
                    <StyledFormGroup>
                        <StyledMessageContainer>
                            <StyledMessageTitle>
                                {t('enrollMobileOtpCardInput$title')}
                            </StyledMessageTitle>
                            <StyledMessageSubtitle>
                                {t('enrollMobileOtpCardInput$subtitle', {
                                    OTPLEN: otpLenRef.current,
                                })}
                            </StyledMessageSubtitle>
                        </StyledMessageContainer>
                        <StyledInput
                            invalid={
                                motpStatus === MOTP_STATUS.invalid ||
                                motpStatus === MOTP_STATUS.failExceeded
                            }
                            valid={motpStatus === MOTP_STATUS.valid}
                            id="exampleNumber"
                            name="number"
                            type="number"
                            onChange={onChange}
                            readOnly={
                                motpStatus === MOTP_STATUS.valid ||
                                motpStatus === MOTP_STATUS.verifying
                            }
                        />
                        {(motpStatus === MOTP_STATUS.invalid ||
                            motpStatus === MOTP_STATUS.failExceeded) && (
                            <StyledFormFeedback invalid>
                                {motpStatus === MOTP_STATUS.invalid
                                    ? t('enrollMobileOtp&invalid')
                                    : t('enrollMobileOtp&failexceeded')}
                            </StyledFormFeedback>
                        )}
                        {motpStatus === MOTP_STATUS.verifying && (
                            <StyledMessage>
                                {t('enrollMobileOtp&verifying')}
                            </StyledMessage>
                        )}
                    </StyledFormGroup>
                </MOTPContentContainer>
            )}
        </CardEnrollLayout>
    );
};

EnrollMobileOtp.propTypes = {
    mgmt: PropTypes.object,
};

EnrollMobileOtp.defaultProps = {
    mgmt: null,
};

export default EnrollMobileOtp;
