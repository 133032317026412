import React, {
    useState,
    useContext,
    useEffect,
    useRef,
    useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    PortalContainer,
    StyledAlert,
    StyledNavIcon,
    StyledDropdown,
    StyledDropdownToggle,
    StyledDropdownItem,
    StyledDropdownMenu,
    NoHoverLink,
    StyledButtonMarginAfter,
    StyledQueryCard,
    StyledQueryCardBody,
    StyledRefreshReportsBtn,
    ColFlex,
    ColAlignCenter,
} from '../../../styles/common';
import LoadingSpinner from '../../shared/LoadingSpinner';
import DevicesTable from '../../elementLibrary/DevicesTable';
import {
    AppStateContext,
    AppDispatchContext,
} from '../../../context/AppContext';
import { emptyDevicesLabelId } from '../../../constants';
import {
    useLazyFetchData,
    usePostData,
} from '../../../utils/hooks';
import AdvancedSearchBar from '../../elementLibrary/AdvancedSearchBar';
import {
    ReportsDispatchContext,
    ReportsStateContext,
} from '../../../context/ReportContext';
import CardTemplate from '../../shared/CardTemplate';
import {
    isAllowed,
    reportsModifyPrivileges,
} from '../../../utils/authorization';
import { reportsGraphQLBody } from '../../../utils/graphQL';

const CustomDropdown = styled(StyledDropdown)`
    display: inline-block !important;
    padding-right: 10px;
`;

const CustomDropdownItem = styled(StyledDropdownItem)`
    .nav-link {
        ${({ disabled }) => disabled && 'color: #b1b1b1 !important;'}
    }
`;

const ExportDropdown = ({ searchLoading }) => {
    const { searchData } = useContext(ReportsStateContext);
    const appDispatch = useContext(AppDispatchContext);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { t } = useTranslation();
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const showExportModal = (e) => {
        e.preventDefault();
        if (!searchData || !searchData.length) {
            return;
        }
        appDispatch({
            type: 'SHOW_MODAL',
            modal: {
                type: 'EXPORT_REPORT_DEVICES',
                staticBackdrop: true,
            },
        });
    };

    return (
        <CustomDropdown isOpen={dropdownOpen} toggle={toggle} size="sm">
            <StyledDropdownToggle caret>
                <StyledNavIcon icon="download" />
                {t('searchBar$labels$export')}
            </StyledDropdownToggle>
            <StyledDropdownMenu>
                <CustomDropdownItem
                    disabled={
                        !searchData || !searchData.length || searchLoading
                    }
                >
                    <NoHoverLink to="" onClick={showExportModal}>
                        {t('searchBar$labels$csv')}
                    </NoHoverLink>
                </CustomDropdownItem>
            </StyledDropdownMenu>
        </CustomDropdown>
    );
};

const DevicesHeader = ({ onSearch, searchLoading }) => {
    const {
        admin: { metadata },
        user: { privileges },
    } = useContext(AppStateContext);
    const { deviceDetails } = metadata || {};
    const { filters } = deviceDetails || {};
    const appDispatch = useContext(AppDispatchContext);
    const { t } = useTranslation();
    const reportsDispatch = useContext(ReportsDispatchContext);
    const { rules, triggerInventoryFetch, displayedQuery } =
        useContext(ReportsStateContext);
    const modifySearchAllowed = isAllowed({
        allowedPermissions: reportsModifyPrivileges,
        permissions: privileges,
    });
    const [advancedSearchMode, setAdvancedSearchMode] =
        useState(modifySearchAllowed);
    const [blankValueIds, setBlankValueIds] = useState(null);

    const { reportName } = useParams();

    const setAdvancedSearchModeIfEditable = (mode) => {
        if (modifySearchAllowed || blankValueIds) {
            setAdvancedSearchMode(mode);
        } else {
            setAdvancedSearchMode(false);
        }
    };

    const [fetchReportsCallback, { loading }] = useLazyFetchData({
        endpoint: '/setting/config/reports',
        onSuccess: (data) => {
            if (data && reportName) {
                const loadedReport = data.find(
                    (report) => report.name === reportName,
                );
                if (!loadedReport) return;
                loadedReport.filters = loadedReport.filters
                    .map((loadedReportFilter) => {
                        const filterDef = filters.find(
                            (filterDef) =>
                                filterDef.name === loadedReportFilter.name,
                        );
                        if (!filterDef) return null;
                        return {
                            ...loadedReportFilter,
                            type: filters.find(
                                (filterDef) =>
                                    filterDef.name === loadedReportFilter.name,
                            ).type,
                            operations: filters.find(
                                (filterDef) =>
                                    filterDef.name === loadedReportFilter.name,
                            ).operations,
                        };
                    })
                    .filter((loadedReportFilter) => !!loadedReportFilter);
                const blankValues = [];
                loadedReport.filters &&
                    loadedReport.filters.forEach((fil, id) => {
                        if (!fil.values[0]) blankValues.push(id);
                    });
                if (blankValues.length) {
                    setBlankValueIds(blankValues);
                    setAdvancedSearchMode(true);
                } else {
                    setAdvancedSearchModeIfEditable(true);
                }
                reportsDispatch({
                    type: 'LOAD_CUSTOM_REPORT',
                    report: loadedReport,
                });
                reportsDispatch({
                    type: 'SET_CURRENT_REPORT',
                    reportData: {
                        ...loadedReport,
                        updatedFields: loadedReport.fields,
                    },
                });
            }
        },
    });

    useEffect(() => {
        if (triggerInventoryFetch && !loading) {
            fetchReportsCallback();
            reportsDispatch({
                type: 'TRIGGER_INVENTORY_FETCH',
                payload: false,
            });
        }
    }, [triggerInventoryFetch, loading, fetchReportsCallback, reportsDispatch]);

    useEffect(() => {
        if (!filters || !filters[0]) return;
        reportsDispatch({
            type: 'INITIALIZE_RULES',
            rule: {
                name: filters[0].name,
                logicalOperator: 'and',
                operation: filters[0].operations[0],
                operations: filters[0].operations,
                values: [''],
                type: filters[0].type,
            },
        });
        if (reportName) {
            fetchReportsCallback();
        } else {
            reportsDispatch({
                type: 'SET_CURRENT_REPORT',
                reportData: {
                    name: null,
                },
            });
        }
    }, [filters, reportName, fetchReportsCallback, reportsDispatch]);

    const onSaveReport = () => {
        appDispatch({
            type: 'SHOW_MODAL',
            modal: {
                type: 'SAVE_CUSTOM_REPORT_DEVICES',
            },
        });
    };
    const onLoading = (loading) =>
        reportsDispatch({
            type: 'SET_SEARCH_LOADING',
            payload: loading,
        });

    const valueValidator = (value) => {
        const invalidCharacters = ['*', '%'];
        return (
            !!value && !invalidCharacters.some((char) => value.includes(char))
        );
    };

    const validateAllValuesBeforeSearch = () =>
        !rules.some((r) => r.values.some((v) => !valueValidator(v)));

    const onClickSearch = () => {
        if (!validateAllValuesBeforeSearch()) return;
        onLoading(true);
        onSearch();
    };

    return (
        <Row>
            {!reportName || !loading ? (
                <>
                    <Col xs="3">
                        <ExportDropdown searchLoading={searchLoading} />
                        {modifySearchAllowed && (
                            <StyledButtonMarginAfter
                                outline
                                color="primary"
                                size="sm"
                                onClick={onSaveReport}
                            >
                                <StyledNavIcon icon="save" />
                                {t('searchBar$labels$save')}
                            </StyledButtonMarginAfter>
                        )}
                    </Col>
                    <ColFlex xs="9">
                        <StyledQueryCard>
                            <StyledQueryCardBody>
                                {displayedQuery}
                            </StyledQueryCardBody>
                        </StyledQueryCard>
                        <StyledRefreshReportsBtn
                            $last={!modifySearchAllowed}
                            onClick={onClickSearch}
                            disabled={searchLoading}
                        >
                            <FontAwesomeIcon icon="search" />
                        </StyledRefreshReportsBtn>
                        {(modifySearchAllowed || blankValueIds) && (
                            <StyledRefreshReportsBtn
                                $last
                                onClick={() =>
                                    setAdvancedSearchModeIfEditable(
                                        !advancedSearchMode,
                                    )
                                }
                            >
                                <FontAwesomeIcon
                                    icon={
                                        advancedSearchMode
                                            ? 'chevron-down'
                                            : 'chevron-up'
                                    }
                                />
                            </StyledRefreshReportsBtn>
                        )}
                    </ColFlex>
                    <Col xs="12">
                        {advancedSearchMode &&
                            (modifySearchAllowed || blankValueIds) && (
                            <AdvancedSearchBar
                                filters={filters}
                                modifySearchAllowed={modifySearchAllowed}
                                alwaysModifiableIds={blankValueIds}
                                valueValidator={valueValidator}
                            />
                        )}
                    </Col>
                </>
            ) : (
                <ColAlignCenter>
                    <LoadingSpinner />
                </ColAlignCenter>
            )}
        </Row>
    );
};

const ReportView = () => {
    const {
        admin: { metadata },
        activeDevicesColumns,
    } = useContext(AppStateContext);
    const appDispatch = useContext(AppDispatchContext);
    const reportsDispatch = useContext(ReportsDispatchContext);
    const {
        rules,
        currentSearchRules,
        searchData,
        currentReport,
        sortBy,
        sortDir,
        searchResultsMetadata: {
            count: searchResultsCount,
            pi: { offset: piOffset, hasNext: piHasNext },
            ucms: { offset: ucmsOffset, hasNext: ucmsHasNext },
        },
    } = useContext(ReportsStateContext);
    const { t } = useTranslation();

    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [canNext, setCanNext] = useState(false);

    const prevDisplayedColumnsRef = useRef([]);
    const prevSortByRef = useRef(sortBy);
    const prevSortDirRef = useRef(sortDir);
    const { reportName } = useParams();

    const { deviceDetails: reportDevicesMetadata } = metadata || {};
    const { columns } = reportDevicesMetadata || {};

    const onColumnsChange = (value) => {
        const updatedFields =
            value &&
            Object.keys(value)
                .filter((column) => value[column] === true)
                .map((column) => column);
        if (!updatedFields.includes(sortBy)) {
            reportsDispatch({
                type: 'SET_SORT',
                sortBy: updatedFields[0],
                sortDir: 'asc',
            });
        }
        reportsDispatch({
            type: 'SET_CURRENT_REPORT_UPDATED_COLUMNS',
            updatedFields,
        });
        appDispatch({ type: 'SET_ACTIVE_DEVICES_COLUMNS', payload: value });
    };

    const [
        searchCallback,
        { loading: searchLoading, data: searchDataResponse },
    ] = usePostData({
        graphQL: true,
    });

    const onClearData = useCallback(
        () =>
            reportsDispatch({
                type: 'RESET_SEARCH_DATA',
                payload: {},
            }),
        [reportsDispatch],
    );

    const setCurrentSearchRules = (rules) =>
        reportsDispatch({
            type: 'SET_CURRENT_SEARCH_RULES',
            rules,
        });

    const onSearch = () => {
        if (searchLoading) return;
        onClearData();
        searchCallback(
            reportsGraphQLBody({
                searchFilters: rules,
                sortBy,
                sortDir,
                limit: pageSize,
                piOffset: 0,
                ucmsOffset: 0,
                fields: currentReport.updatedFields,
            }),
        );
        const clonedRules = rules.map((rule) => ({ ...rule }));
        prevSortByRef.current = sortBy;
        prevSortDirRef.current = sortDir;
        setCurrentSearchRules(clonedRules);
        setPageNumber(0);
    };

    const onSearchCurrentRules = useCallback(
        ({ currentReport, currentSearchRules, pageSize }) => {
            onClearData();
            searchCallback(
                reportsGraphQLBody({
                    searchFilters: currentSearchRules,
                    sortBy,
                    sortDir,
                    limit: pageSize,
                    piOffset: 0,
                    ucmsOffset: 0,
                    fields: currentReport.updatedFields,
                }),
            );
            prevSortByRef.current = sortBy;
            prevSortDirRef.current = sortDir;
            setPageNumber(0);
        },
        [onClearData, searchCallback, sortBy, sortDir],
    );

    const lazyFetchCallback = useCallback(() => {
        searchCallback(
            reportsGraphQLBody({
                searchFilters: currentSearchRules,
                sortBy,
                sortDir,
                limit: pageSize,
                piOffset,
                ucmsOffset,
                searchUcms: ucmsHasNext,
                searchPi: piHasNext,
                fields: currentReport.updatedFields,
            }),
        );
    }, [
        currentReport.updatedFields,
        currentSearchRules,
        pageSize,
        piHasNext,
        piOffset,
        searchCallback,
        sortBy,
        sortDir,
        ucmsHasNext,
        ucmsOffset,
    ]);

    useEffect(() => {
        onClearData();
        return onClearData;
    },[]);

    useEffect(() => {
        if (searchDataResponse) {
            const searchDataInfo =
                searchDataResponse &&
                searchDataResponse.data &&
                searchDataResponse.data.deviceInfo &&
                searchDataResponse.data.deviceInfo;
            if (searchDataInfo) {
                if (searchDataInfo.pageInfo) {
                    reportsDispatch({
                        type: 'SET_SEARCH_RESULTS_METADATA',
                        payload: searchDataInfo.pageInfo,
                    });
                }

                if (
                    searchDataInfo.pageInfo &&
                    searchDataInfo.pageInfo.count &&
                    searchDataInfo.devices
                ) {
                    reportsDispatch({
                        type: 'SET_SEARCH_DATA',
                        payload: searchDataInfo.devices,
                    });
                } else if (searchDataInfo.devices) {
                    reportsDispatch({
                        type: 'APPEND_TO_SEARCH_DATA',
                        payload: searchDataInfo.devices,
                    });
                }
            }
        }
    }, [searchDataResponse, reportsDispatch]);

    useEffect(() => {
        if (
            searchData.length &&
            (sortBy !== prevSortByRef.current ||
                sortDir !== prevSortDirRef.current)
        ) {
            onSearchCurrentRules({
                currentReport,
                currentSearchRules,
                pageSize,
            });
        }
    }, [
        sortBy,
        sortDir,
        searchData,
        onSearchCurrentRules,
        currentReport,
        currentSearchRules,
        pageSize,
    ]);

    useEffect(() => {
        // only search if columns changed
        const currentFields = currentReport.updatedFields || [];
        const changedColumns =
            currentFields.some(
                (c) => !prevDisplayedColumnsRef.current.includes(c),
            ) ||
            prevDisplayedColumnsRef.current.some(
                (c) => !currentFields.includes(c),
            );
        if (searchData.length && changedColumns) {
            onSearchCurrentRules({
                currentReport,
                currentSearchRules,
                pageSize,
            });
        }
        prevDisplayedColumnsRef.current = currentFields;
    }, [
        currentReport.updatedFields,
        searchData.length,
        onSearchCurrentRules,
        currentReport,
        currentSearchRules,
        pageSize,
    ]);

    const nextPage = () => {
        if (!searchLoading) setPageNumber(Math.min(pageCount, pageNumber + 1));
    };

    const prevPage = () => {
        setPageNumber(Math.max(0, pageNumber - 1));
    };

    const sort = (headers) => {
        const sortedHeader = headers.find((h) => h.isSorted && h.isVisible);
        let newSortBy;
        let newSortDir;
        if (sortedHeader) {
            newSortBy = sortedHeader.id;
            newSortDir = sortedHeader.isSortedDesc ? 'desc' : 'asc';
        } else {
            newSortBy =
                currentReport && currentReport.updatedFields
                    ? currentReport.updatedFields[0]
                    : activeDevicesColumns[0];
            newSortDir = 'asc';
        }
        if (newSortBy && (sortBy !== newSortBy || sortDir !== newSortDir)) {
            reportsDispatch({
                type: 'SET_SORT',
                sortBy: newSortBy,
                sortDir: newSortDir,
            });
        }
    };

    const setPageSizeAndAdjustPageNumber = (pageSize) => {
        setPageSize(pageSize);
        setPageNumber(0);
    };

    const updatedCanNext =
        (pageNumber + 1) * pageSize < searchResultsCount &&
        (pageNumber + 1) * pageSize <= searchData.length;
    const shouldFetch =
        !updatedCanNext && (ucmsHasNext || piHasNext) && !searchLoading;
    useEffect(() => {
        if (shouldFetch) {
            lazyFetchCallback();
        }
    }, [lazyFetchCallback, shouldFetch]);

    useEffect(() => {
        setCanNext(updatedCanNext);
    }, [updatedCanNext]);

    useEffect(() => {
        if (searchResultsCount) {
            setPageCount(Math.floor(searchResultsCount / pageSize));
        }
    }, [pageSize, searchResultsCount]);

    return (
        <CardTemplate
            title={reportName || t('reports$create$title')}
            subtitle={reportName ? '' : t('reports$create$subtitle')}
            wideModeEnabled
        >
            <PortalContainer>
                <DevicesHeader
                    onSearch={onSearch}
                    searchLoading={searchLoading}
                />
                {!searchLoading && isEmpty(searchData) && (
                    <StyledAlert
                        id={emptyDevicesLabelId}
                        color="info"
                        fade={false}
                    >
                        {t('helpDesk$devices$emptyDeviceTable')}
                    </StyledAlert>
                )}
                {(reportName ? currentReport.name === reportName : true) &&
                    columns && (
                    <DevicesTable
                        data={searchData}
                        columnsName={columns}
                        activeColumns={activeDevicesColumns}
                        onColumnsChange={onColumnsChange}
                        selectedColumnsKey={`reportDevicesTableColumnsSettings${reportName || ''}`}
                        actions={false}
                        hideCheckboxes={true}
                        defaultColumns={
                            reportName ? currentReport.fields : null
                        }
                        defaultColumnSettings={{
                            minWidth: 30,
                            width: 220,
                        }}
                        enableLocalStorage={true}
                        disableSkipPaging={true}
                        lazyFetchMode={true}
                        lazyFetchCanNext={canNext}
                        lazyFetchPageNumber={pageNumber}
                        lazyFetchPreviousPage={prevPage}
                        lazyFetchNextPage={nextPage}
                        lazyFetchPageSize={pageSize}
                        lazyFetchSetPageSize={
                            setPageSizeAndAdjustPageNumber
                        }
                        lazyFetchPageCount={
                            searchResultsCount % pageSize === 0
                                ? pageCount
                                : pageCount + 1
                        }
                        lazyFetchResultsCount={searchResultsCount}
                        lazyFetchSort={sort}
                    />
                )}
                {searchLoading && <LoadingSpinner />}
            </PortalContainer>
        </CardTemplate>
    );
};

export default ReportView;
