/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import ProgressTemplate from '../shared/ProgressTemplate';
import CardEnrollLayout from '../shared/CardEnrollLayout';

const ResetFailCounterCard = ({ isDone }) => {
    const { t } = useTranslation();
    return (
        <CardEnrollLayout
            cardTitle={t('cardTitles$ResetFailcounter')}
            title={t('updateProgress')}
            alert={
                !isDone
                    ? t('enrollTemplateAlertDefault')
                    : t('resetCounterSuccessMsg')
            }
            showNext={false}
        >
            <ProgressTemplate
                isFake
                isDone={isDone}
                stage={`${t('ResetFailcounterState')}`}
            />
        </CardEnrollLayout>
    );
};

export default ResetFailCounterCard;
