
export const stringToHash = (string) => {
    let hash = 0;

    if (string.length === 0) return hash;

    for (let i = 0; i < string.length; i++) {
        const char = string.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash &= hash;
    }

    return hash;
};

export const formatPukText = (text) =>  text.replace(/[^\da-fA-F]/g, '').replace(/(.{4})/g, '$1 ')?.trim()?.toUpperCase()
