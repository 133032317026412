import React, { useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import {
    helpdeskUsersDevicesTableColumnsSettings,
    helpDeskPaths,
    devicesInfoCardId,
} from '../../../../constants';
import { useFetchData, useSortColumn } from '../../../../utils/hooks';

import DevicesTable from '../../../elementLibrary/DevicesTable';
import {
    AppStateContext,
    AppDispatchContext,
} from '../../../../context/AppContext';

import LoadingSpinner from '../../../shared/LoadingSpinner';
import CardTemplate from '../../../shared/CardTemplate';
import ActionButton from './ActionButton';

import { DevicesTableWrapper, Title } from './styles';

const DevicesRows = ({ userData }) => {
    const {
        admin: { metadata },
        activeDevicesColumns,
        settings,
        devices,
    } = useContext(AppStateContext);
    const defaultDeviceColumns =
        settings.defaultDeviceColumns === ''
            ? []
            : settings.defaultDeviceColumns.split(',');
    const dispatch = useContext(AppDispatchContext);
    const { username } = useParams();
    const { t } = useTranslation();
    const { location } = useHistory();

    const { columns } = (metadata || {}).devices || {};
    const { pathname } = location || {};

    const [devicesLoading] = useFetchData({
        endpoint: `/user/${username}/device`,
        onSuccess: (d) => {
            dispatch({ type: 'SET_DEVICES', devices: d });
        },
    });

    useSortColumn({
        data: devices,
        columns,
        activeColumns: activeDevicesColumns,
    });

    const onColumnsChange = (value) => {
        dispatch({ type: 'SET_ACTIVE_DEVICES_COLUMNS', payload: value });
    };
    const saveBackLink = () =>
        dispatch({ type: 'SET_GO_BACK_LINK', payload: pathname });

    if (devicesLoading || !devices) {
        return <LoadingSpinner />;
    }

    const getTableHeader = (settinsIconCol) => (
        <Row>
            <Col
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Title>{`${t('cardTitles$devices')}`}</Title>
                <ActionButton
                    style={{ margin: '0 0.75rem' }}
                    userData={userData}
                    path={helpDeskPaths.addDevice}
                    buttonText={t('identitiesCardLabels$stateless$button')}
                    id="add-identity"
                    onClick={saveBackLink}
                />
            </Col>
            <Col>{settinsIconCol}</Col>
        </Row>
    );

    return (
        <CardTemplate testId={devicesInfoCardId}>
            <DevicesTableWrapper>
                <DevicesTable
                    hideCheckboxes
                    data={devices}
                    columnsName={columns}
                    activeColumns={activeDevicesColumns}
                    onColumnsChange={onColumnsChange}
                    selectedColumnsKey={
                        helpdeskUsersDevicesTableColumnsSettings
                    }
                    defaultColumns={defaultDeviceColumns}
                    getTableHeader={getTableHeader}
                    enableDeviceDetails
                />
            </DevicesTableWrapper>
        </CardTemplate>
    );
};
export default DevicesRows;
