import React from 'react';
import { useTranslation } from 'react-i18next';
import { emergencyTypes } from '../../../utils/mappings';

const EmergencyInfoName = ({ type }) => {
    const { t } = useTranslation();

    const { label } = emergencyTypes[type] || {};
    return <span>{t(label) || t('unknownDevice')}</span>;
};

export default EmergencyInfoName;
