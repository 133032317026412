import React from 'react';
import { useTranslation } from 'react-i18next';
import { stringToHash } from '../../../utils/stringUtil';

const AssuranceAcknowledge = ({
    type
}) => {
    const { t } = useTranslation();
    
    const text = t(`assuranceAcknowledge$${type}`);
    return (
        <p>
            {text?.split("\\n").map((i,key,arr) => {
                if (i.includes("\\u")) {
                    const bullets = i.substring(2);

                    return (
                        <ul>
                            {bullets.split("\\l").map((bl) => {
                                return <li key={stringToHash(bl)}>{bl}</li>;
                            })}
                        </ul>
                    );
                }
                return (
                    <div key={stringToHash(i)}>
                        {i}
                        {(key < arr.length - 2) &&
                            <div><br /></div>
                        }
                    </div>
                );
            })}
        </p>
    );
}

export default AssuranceAcknowledge;