import React, {
    useEffect,
    useState,
    useContext,
    useCallback,
    useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    fetchCredentialingDevices,
    fetchInsertedCredentialFlow,
    fetchNormalCredentialFlow,
} from '../../../utils/credentialing';
import { emptyObject } from '../../../utils/generic';
import { issuanceTypeCardId } from '../../../constants';
import IssuanceTypeWithData from './IssuanceTypeWithData';
import { AppDispatchContext } from '../../../context/AppContext';
import CardLayout from '../../shared/CardLayout';
import {
    EnrollingDispatchContext,
    EnrollingStateContext,
} from '../../../context/EnrollingContext';
import { useDidMount } from '../../../utils/hooks';

export const parseFieldSet = (json, fieldset) => {
    let newFieldSet = {};
    // handle first time render
    if (fieldset === null) {
        Object.keys(json).forEach((key) => {
            newFieldSet[key] = {
                selected: false,
                display: true,
                ...json[key],
            };
        });
    } else {
        newFieldSet = { ...fieldset };
    }

    const firstOptionKey = Object.keys(json)[0];
    if (firstOptionKey) {
        newFieldSet[firstOptionKey].selected = true;
        Object.keys(newFieldSet).forEach((key) => {
            if (key !== firstOptionKey) {
                newFieldSet[key].selected = false;
            }
        });
    }
    return newFieldSet;
};

const IssuanceTypeCard = ({ mgmt, path }) => {
    const dispatch = useContext(AppDispatchContext);
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const { reader, username } = useContext(EnrollingStateContext);
    const { t } = useTranslation();
    const history = useHistory();
    const [fieldset, setFieldset] = useState(null);
    const [allowedDevices, setAllowedDevices] = useState({});
    const [showNext, setShowNext] = useState(false);
    const [nextClicked, setNextClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [assuranceAckType, setAssuranceAckType] = useState('insertedDevice');

    const lastUsername = useRef(null);

    const setDevicesCallback = useCallback(
        (json) => {
            setAllowedDevices(json);
            const newFieldSet = parseFieldSet(json, fieldset);
            setFieldset(newFieldSet);
            setIsLoading(false);
        },
        [fieldset],
    );

    const handleRadioClick = (proxy) => {
        const newFieldset = { ...fieldset };
        Object.keys(newFieldset).forEach((key) => {
            newFieldset[key].selected = false;
            if (key === proxy.target.value) {
                newFieldset[key].selected = true;
            }
        });
        setFieldset(newFieldset);
        setAssuranceAckType(proxy.target.value);
    };

    const back = async () => {
        history.push(path);
    };

    const next = async () => {
        setNextClicked(true);
        const setCredentialFlow = (credentialFlow) =>
            enrollingDispatch({
                type: 'SET_CREDENTIAL_FLOW',
                credentialFlow,
            });

        Object.keys(fieldset).forEach(async (key) => {
            const obj = fieldset[key];

            if (obj.selected) {
                // Todo: refactor when more rideo button have "dropdown" buttonType besides insertedDevice
                if (obj.buttonType === 'dropdown') {
                    if (mgmt && username) {
                        await fetchInsertedCredentialFlow(
                            {
                                setCredentialFlow,
                                setWorkflow: mgmt.setWorkflow,
                                dispatch,
                            },
                            reader.type,
                            reader.serial,
                            {
                                group: reader.groups && reader.groups[0],
                                subType: reader.subType,
                                prestate: reader.prestate,
                            },
                            username,
                        );
                        mgmt.next();
                    }
                } else {
                    enrollingDispatch({
                        type: 'SET_SELECTED_READER',
                        reader: {groups: obj.group && [obj.group]},
                    });
                    if (mgmt && username) {
                        await fetchNormalCredentialFlow(
                            {
                                setCredentialFlow,
                                setWorkflow: mgmt.setWorkflow,
                                dispatch,
                            },
                            username,
                            key,
                        );
                        mgmt.next();
                    }
                }
            }
        });
    };

    useDidMount(() => {
        if (mgmt !== undefined && mgmt.resetMgmtState !== undefined) {
            mgmt.resetMgmtState();
        }
        enrollingDispatch({
            type: 'SET_SELECTED_READER',
            reader: null,
        });
    });

    useEffect(() => {
        if (username && lastUsername.current !== username) {
            lastUsername.current = username;
            fetchCredentialingDevices(setDevicesCallback, dispatch, username);
        }
    }, [username, setDevicesCallback, dispatch]);

    useEffect(() => {
        const isRadioButtonSelected = () => {
            let res = false;
            Object.keys(fieldset).forEach((key) => {
                if (!res) {
                    const obj = fieldset[key];
                    const dropDownSelected =
                        obj.buttonType === 'dropdown' &&
                        obj.selected &&
                        !emptyObject(reader);
                    const normalSelected =
                        obj.buttonType === 'normal' && obj.selected;
                    if (normalSelected || dropDownSelected) {
                        res = true;
                    }
                }
            });
            return res;
        };
        const enableNext = () => {
            if (nextClicked === true || fieldset === null) {
                return false;
            }

            return isRadioButtonSelected();
        };
        setShowNext(enableNext());
    }, [nextClicked, reader, fieldset]);

    return (
        <CardLayout
            title={t('cardTitles$issueIdentity')}
            showNext={showNext}
            showBack
            mgmt={{
                ...mgmt,
                next,
                back,
            }}
            testId={issuanceTypeCardId}
            assuranceAckType={assuranceAckType}
        >
            {fieldset !== null && (
                <IssuanceTypeWithData
                    fieldset={fieldset}
                    handleRadioClick={handleRadioClick}
                    isLoading={isLoading}
                    allowedDevices={allowedDevices}
                />
            )}
        </CardLayout>
    );
};

IssuanceTypeCard.propTypes = {
    mgmt: PropTypes.object.isRequired,
};

export default IssuanceTypeCard;
