import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { mainContent } from '../../constants';

const StyledLink = styled.a`
    left: -999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
    color: ${({ theme }) => theme.colors.accent} !important;
    &:focus {
        left: 33.3%;
        top: 130px;
        width: 30%;
        height: auto;
        padding: 5px;
        outline: 2px solid ${({ theme }) => theme.colors.accent} !important;
        text-align: center;
        z-index: 999;
    }
    @media only screen and (max-width: 768px) {
        &:focus {
            top: 16px;
            left: 200px;
        }
    }
`;

const SkipToMainContent = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const {
        location: { pathname },
    } = history || {};

    const focusMainContent = (e) => {
        e.preventDefault();
        document.activeElement.blur();

        let element;
        const len = mainContent[pathname] && mainContent[pathname].length;
        for (let i = 0; i < len; i += 1) {
            element = document.getElementsByClassName(mainContent[pathname][i]);
        }
        if (element && element[0]) {
            element[0].focus();
        } else if (!len) {
            history.push('/');
        }
    };

    return (
        <aside>
            <StyledLink href="#main-content" onClick={focusMainContent}>
                {t('skipToMainContent')}
            </StyledLink>
        </aside>
    );
};

export default SkipToMainContent;
