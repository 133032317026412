import React, { useContext, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PCSC from '../../../utils/pcsc';
import UCMS from '../../../utils/ucms';
import { fetchDiagnosis } from '../../../utils/diagnosis';
import { deriveUrl } from '../../../utils/generic';
import {
    apiPath,
    ucmsConstants,
    buildDeviceDropdownDisplaySuccess,
    buildDeviceDropdownDisplayFailure,
    vscPlaceholder,
    atrCases
} from '../../../constants';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { AppDispatchContext } from '../../../context/AppContext';
import { EnrollingDispatchContext } from '../../../context/EnrollingContext';
import { fetchCredentialingQuery } from '../../../utils/credentialing';
import { declareError, errorFactory } from '../../../utils/errors.js';

const TAG = 'VscCreationSelection';

const VscCreationSelection = ({
    setAssociateReader = () => {},
    username = '',
}) => {
    const [readers, setReaders] = useState({});
    const [devices, setDevices] = useState({});
    const [, setSyncing] = useState(false);
    const [selected, setSelected] = useState('');
    const [pcscInterval, setPcscInterval] = useState(null);
    const [loadingDevices, setLoadingDevices] = useState(false);

    const readersRef = useRef(readers);
    readersRef.current = readers;
    const selectedRef = useRef(selected);
    selectedRef.current = selected;
    const devicesRef = useRef(devices);
    devicesRef.current = devices;
    const pcscIntervalRef = useRef(pcscInterval);
    pcscIntervalRef.current = pcscInterval;

    const appDispatch = useContext(AppDispatchContext);
    const enrollingDispatch = useContext(EnrollingDispatchContext);

    const { t } = useTranslation();

    const stopInterval = () => {
        if (pcscIntervalRef.current) {
            pcscIntervalRef.current.stop();
            setPcscInterval(null);
        }
    };

    const removeReaders = (removed, updatedReaders) => {
        const newUpdatedReaders = { ...updatedReaders };
        const updatedDevices = { ...devicesRef.current };
        removed.forEach((readerName) => {
            delete newUpdatedReaders[readerName];
            delete updatedDevices[readerName];
        });
        setReaders(newUpdatedReaders);
        setDevices(updatedDevices);
        setSelected(
            removed.includes(selectedRef.current) ? '' : selectedRef.current,
        );
        enrollingDispatch({
            type: 'SET_SELECTED_READER',
            reader: removed.includes(selectedRef.current)
                ? null
                : readers[selectedRef.current],
        });
        return newUpdatedReaders;
    };

    const setDetectReaders = async (added, modified, updatedReaders) => {
        const updatedModified = { ...modified };
        added.forEach(
            (reader) =>
                (updatedModified[reader] =
                    updatedModified[reader] === undefined ? null : updatedModified[reader]),
        );
        const modifiedReaders = {};
        Object.keys(updatedModified).forEach((readerName) => {
            modifiedReaders[readerName] = {
                ...[readerName],
                supported: t(atrCases[updatedModified[readerName]]) || true,
                atr: updatedModified[readerName],
                readerName,
                label: readerName,
                changed: true,
            };
            if (updatedModified[readerName] === null) {
                const updatedDevices = { ...devicesRef.current };
                delete updatedDevices[readerName];
                setDevices(updatedDevices);
            }
        });
        const newUpdatedReaders = {
            ...updatedReaders,
            ...modifiedReaders,
        };
        setReaders(newUpdatedReaders);
        return newUpdatedReaders;
    };

    const displayOption = (data) => {
        console.debug(TAG, 'displayOption data:', data);
        return data.supported === true
            ? buildDeviceDropdownDisplaySuccess(
                data,
                t('genericDeviceDropdownLabels$serialNo'),
            )
            : buildDeviceDropdownDisplayFailure(data);
    };

    const setSelectedVscReader = (updatedReaders) => {
        let match = false;
        Object.keys(updatedReaders).forEach(value => {
            if (
                !match &&
                updatedReaders[value].subType === vscPlaceholder.subType &&
                updatedReaders[value].status === 'available' &&
                (selectedRef.current === '' || selectedRef.current === value)
            ) {
                match = true;
                console.debug(TAG, 'setting reader:', value);
                const empty =
                    value === t('genericDeviceDropdownLabels$defaultOption') ||
                    value === t('genericDeviceDropdownLabels$noDevicesOption');
                setSelected(empty ? {} : value);
                setAssociateReader(updatedReaders[value]);
                enrollingDispatch({
                    type: 'SET_SELECTED_READER',
                    reader: updatedReaders[value],
                });
            }
        })
    };

    const setCredentialingQuery = (json) => {
        console.debug(TAG, 'setCredentialingQuery json:', json);
        const updatedReaders = { ...readersRef.current };
        const updatedDevices = { ...devicesRef.current };
        Object.keys(updatedReaders).forEach((readerName) => {
            const reader = updatedReaders[readerName];
            json.forEach((device) => {
                if (device.serial === reader.serial) {
                    updatedReaders[readerName] = {
                        ...reader,
                        ...device,
                        groups: device.groups,
                        display: displayOption({ ...reader, ...device }),
                        changed: false,
                    };
                    updatedDevices[readerName] = {
                        ...device,
                    };
                }
            });
        });
        setReaders(updatedReaders);
        setDevices(updatedDevices);
        setSelectedVscReader(updatedReaders);
        setSyncing(false);
    };

    const queryDevices = async (updatedReaders) => {
        const data = [];
        Object.keys(updatedReaders).forEach(readerName => {
            const reader = updatedReaders[readerName];
            if (
                reader.supported === true &&
                reader.changed === true &&
                reader.device
            ) {
                data.push(reader.device);
            }
        })
 
        if (data.length > 0 && username) {
            await fetchCredentialingQuery(
                setCredentialingQuery,
                appDispatch,
                data,
                username,
            );
        } else {
            setSyncing(false);
        }
    };

    const identifyDevices = async (updatedReaders) => {
        const newReaders = { ...updatedReaders };
        // eslint-disable-next-line no-restricted-syntax
        for (const readerName of Object.keys(newReaders)) {
            if (newReaders[readerName].changed === false) {
                continue;
            }
            let result = null;
            let reader = null;
            if (newReaders[readerName].supported !== true) {
                continue;
            }
            try {
                reader = await PCSC.connect(readerName);
                result = await UCMS.apdu.run(
                    reader,
                    ucmsConstants.actions.identify,
                    newReaders[readerName].atr,
                    () => {},
                    deriveUrl(apiPath),
                );
            } catch (error) {
                console.error(
                    TAG,
                    'device selection identifyDevices error:',
                    error,
                );
                if (error.msg === 'E_SHARING_VIOLATION') {
                    newReaders[readerName].supported = t(
                        'errorMsgs$atr_inUse',
                    );
                } else if (error.msg === 'Unsupported card') {
                    newReaders[readerName].supported = t(
                        'errorMsgs$unsupportedDevice',
                    );
                } else {
                    newReaders[readerName].supported = t(
                        'errorMsgs$unidentifiable',
                    );
                }
            } finally {
                if (reader) await reader.disconnect();
            }
            if (result === null) {
                continue;
            }
            const card = result.entries[0].result;
            newReaders[readerName] = {
                ...newReaders[readerName],
                ...card,
                device: card,
                label: displayOption({
                    ...card,
                    ...newReaders[readerName],
                }),
            };
        }
        setReaders(newReaders);
        return newReaders;
    };

    const handlePcscOnChange = async (added, removed, modified) => {
        const updatedModified = { ...modified };
        setSyncing(true);
        let updatedReaders = { ...readersRef.current };
        if (removed.length > 0) {
            updatedReaders = removeReaders(removed, updatedReaders);
            removed.forEach((reader) => delete updatedModified[reader]);
        }
        updatedReaders = await setDetectReaders(
            added,
            updatedModified,
            updatedReaders,
        );
        updatedReaders = await identifyDevices(updatedReaders);
        queryDevices(updatedReaders);
    };

    const pcscOnChange = async () => {
        await fetchDiagnosis(appDispatch);
        await PCSC.ready;
        const instanceId = await PCSC.createVirtualSmartCard(vscPlaceholder.subType);
        const instanceNum = parseInt(instanceId.substring(instanceId.lastIndexOf(" ")), 10);
        const readerList = await PCSC.list();
        for (let i = 0; i < readerList.length; i++ ) {
            const r = readerList[i];
            const readerNum = parseInt(r.substring(r.lastIndexOf(" ")), 10);
            if (readerNum === instanceNum) {
                setSelected(r);
            }
        }
        const interval = await PCSC.onChange(handlePcscOnChange, true, 1500);
        setPcscInterval(interval);
    };

    useEffect(() => {
        const onMount = async () => {
            try {
                await pcscOnChange();
            } catch (err) {
                const msg =
                    err.diagnoses && err.diagnoses.length
                        ? err.diagnoses[0].cause
                        : t('errorMsgs$vscFailCreation');
                declareError(appDispatch, errorFactory.err(msg));
            } finally {
                setLoadingDevices(false);
            }
        };
        onMount();

        return stopInterval;
    },[]);    

    if (loadingDevices) {
        return <LoadingSpinner />;
    }
    return null;
};

export default VscCreationSelection;
