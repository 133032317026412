import React from 'react';
import { PropTypes } from 'prop-types';
import { Card, Container, Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { TitleRow } from '../../styles/common';

const Title = styled.h3`
    font-weight: 400;
    text-align: left;
    font-size: 1rem;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 0.5rem;
`;

const CardBody = styled(Card)`
    text-align: left;
    border-left: 4px solid ${({ theme }) => theme.colors.accent};
`;

const CardNotification = ({ testId, title, icon, children }) => {
    return (
        <Container data-test-id={testId}>
            {title && (
                <TitleRow>
                    <Col>
                        <Title>{`${title} ${icon || ''}`}</Title>
                    </Col>
                </TitleRow>
            )}
            <Row>
                <Col>
                    <CardBody body>{children}</CardBody>
                </Col>
            </Row>
        </Container>
    );
};

CardNotification.propTypes = {
    testId: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
    children: PropTypes.node,
};

CardNotification.defaultProps = {
    testId: '',
    title: '',
    icon: '',
    children: null,
};

export default CardNotification;
