import React, { useContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { userEntryCardId } from '../../../constants';
import UserEntryWithData from './UserEntryWithData';
import CardLayout from '../../shared/CardLayout';
import {
    EnrollingDispatchContext,
    EnrollingStateContext,
} from '../../../context/EnrollingContext';
import { validateForm } from '../UserEntryDelegate';

const initialState = {
    registered: {},
    setShowNext: false,
    skipEnabled: false,
    skipClicked: false,
    showNext: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_SHOW_NEXT':
            return { ...state, showNext: action.payload };
        case 'SET_REGISTERED': {
            const forms = action.payload;
            return {
                ...state,
                registered: { ...state.registered, ...forms },
            };
        }
        case 'SET_SKIP_ENABLED':
            return {
                ...state,
                skipEnabled: action.payload,
                skipClicked: false,
            };
        case 'CLICK_SKIP':
            return {
                ...state,
                skipEnabled: false,
                skipClicked: true,
            };
        case 'RESET_FORM':
            return initialState;
        default:
            return { ...state };
    }
};

const UserEntryCard = ({
    mgmt,
    setPass,
    setCurrentPin,
    forgotPin,
    setCurrentAnswer,
    device,
    goToResetQuestion,
}) => {
    const { t } = useTranslation();
    const { form } = useContext(EnrollingStateContext);
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        return () => {
            enrollingDispatch({
                type: 'RESET_FORM',
            });
        };
    },[]);

    useEffect(() => {
        dispatch({ type: 'SET_SHOW_NEXT', payload: validateForm(state.registered, form) });
    }, [form, state.registered]);

    const registerForm = (forms) => {
        dispatch({ type: 'SET_REGISTERED', payload: forms });
    };

    const resetForm = () => {
        dispatch({ type: 'RESET_FORM' });
        enrollingDispatch({
            type: 'RESET_FORM',
        });
    };

    const next = () => {
        if (mgmt && validateForm(state.registered, form)) {
            resetForm();
            mgmt.next();
        }
    };

    const back = () => {
        resetForm();
        mgmt.back();
    };

    return (
        <CardLayout
            title={t('cardTitles$userEntry')}
            showNext={state.showNext}
            testId={userEntryCardId}
            showBack
            mgmt={{
                next,
                back,
            }}
            skipEnabled={state.skipEnabled}
            clickSkip={() => dispatch({ type: 'CLICK_SKIP' })}
        >
            <UserEntryWithData
                setPass={setPass}
                setCurrentPin={setCurrentPin}
                forgotPin={forgotPin}
                setCurrentAnswer={setCurrentAnswer}
                registerForm={registerForm}
                mgmt={mgmt}
                device={device}
                registered={state.registered}
                goToResetQuestion={goToResetQuestion}
                enableSkip={() =>
                    dispatch({ type: 'SET_SKIP_ENABLED', payload: true })
                }
                disableSkip={() =>
                    dispatch({ type: 'SET_SKIP_ENABLED', payload: false })
                }
                diableShowNext={() =>
                    dispatch({ type: 'SET_SHOW_NEXT', payload: false })
                }
                skipClicked={state.skipClicked}
            />
        </CardLayout>
    );
};

UserEntryCard.propTypes = {
    mgmt: PropTypes.object,
    setPass: PropTypes.func,
    setCurrentPin: PropTypes.func,
    forgotPin: PropTypes.func,
    setCurrentAnswer: PropTypes.func,
};

UserEntryCard.defaultProps = {
    mgmt: null,
    setPass: null,
    setCurrentPin: null,
    forgotPin: null,
    setCurrentAnswer: null,
};

export default UserEntryCard;
