import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { vscPlaceholder, PROGRESS_BAR_DONE_DELAY } from '../../constants';
import ProgressTemplate from '../shared/ProgressTemplate';
import { useDidMount } from '../../utils/hooks';

const CheckPivLocked = ({ reader, mgmt }) => {
    const [isDone, setIsDone] = useState(false);
    const { t } = useTranslation();

    useDidMount(() => {
        if (reader.serial === vscPlaceholder.serial) {
            mgmt.next();
        } else {
            setIsDone(true);
            setTimeout(mgmt.next, PROGRESS_BAR_DONE_DELAY);
        }
    });

    return (
        <ProgressTemplate
            stage={t('deviceActionsStage$checkLockedPiv')}
            isFake
            isDone={isDone}
        />
    );
};

CheckPivLocked.propTypes = {
    reader: PropTypes.object.isRequired,
    setFlowState: PropTypes.func,
    mgmt: PropTypes.object.isRequired,
};

export default CheckPivLocked;
