import React from 'react';
import { useTranslation } from 'react-i18next';
import { emergencyTypes } from '../../../utils/mappings';
import unknownCredential from '../../../images/lock.svg';
import { StyledImage } from '../../../styles/common';

const EmergencyType = ({ type }) => {
    const { t } = useTranslation();
    const deviceString = 'tpa';
    const imageTarget = `${deviceString}-img`;
    const { imgLink, imgAlt } = emergencyTypes[type] || {};
    const srcImg = imgLink || unknownCredential;
    const altImg = t(imgAlt || 'unknownDevice');

    return <StyledImage src={srcImg} id={imageTarget} alt={altImg} />;
};

export default EmergencyType;
