import React, { useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    ucmsConstants as UCMS,
    associatePivUpdateCardId,
    PROGRESS_BAR_DONE_DELAY,
} from '../../constants';
import { postAssociateAction } from '../../utils/walkways';
import ProgressTemplate from '../shared/ProgressTemplate';
import { AppDispatchContext, AppStateContext } from '../../context/AppContext';
import CardEnrollLayout from '../shared/CardEnrollLayout';
import { useDidMount } from '../../utils/hooks';

const UPDATE_PIV = 'update';

const AssociatePivReset = ({ mgmt, associateUser, reader }) => {
    const { webpcscMode } = useContext(AppStateContext);
    const { t } = useTranslation();
    const [, setPivResult] = useState({});
    const [showNext, setShowNext] = useState(false);
    const [stage, setStage] = useState(t('pivActionInitialState'));
    const dispatch = useContext(AppDispatchContext);

    const setUpdateProgress = (hook, data) => {
        if (hook === UCMS.stage) {
            setStage(data);
        }
    };

    const setPivUpdateResult = async (json) => {
        setPivResult({ ...json });
        setShowNext(true);
        if (mgmt && json.step === UCMS.done) {
            setTimeout(mgmt.next, PROGRESS_BAR_DONE_DELAY);
        }
    };

    useDidMount(() => {
        const { pinForm, pin, currentPin } = mgmt.formValues;
        postAssociateAction(
            setPivUpdateResult,
            setUpdateProgress,
            dispatch,
            {
                pin: pinForm,
                pass: pin || currentPin,
                user: associateUser,
                action: UPDATE_PIV,
                reader,
            },
            webpcscMode,
        );
    });

    return (
        <CardEnrollLayout
            cardTitle={t('cardTitles$associateSupport')}
            title={t('updateProgress')}
            alert={t('enrollTemplateAlertDefault')}
            testId={associatePivUpdateCardId}
            mgmt={mgmt}
            showNext={false}
        >
            <ProgressTemplate
                isFake
                isDone={showNext}
                stage={`${t('pivActionPrefix')} ${stage}`}
            />
        </CardEnrollLayout>
    );
};

AssociatePivReset.propTypes = {
    reader: PropTypes.object.isRequired,
    associateUser: PropTypes.string.isRequired,
    mgmt: PropTypes.object.isRequired,
};

export default AssociatePivReset;
