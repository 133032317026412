import React, { useContext } from 'react';
import { Col, Row, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { PortalContainer, SelectInput } from '../../styles/common';
import { AppDispatchContext, AppStateContext } from '../../context/AppContext';

const AdvancedSettings = () => {
    const { t } = useTranslation();
    const { webpcscMode } = useContext(AppStateContext);
    const dispatch = useContext(AppDispatchContext);

    const setWebPcscMode = (event) => {
        dispatch({
            type: 'SET_WEBPCSC_MODE',
            webpcscMode: event.target.value,
        });
    };

    return (
        <PortalContainer>
            <Row>
                <Col xs="2">
                    <Label>
                        {t('localSettingsCardLabels$webpcscModeSelect')}:
                    </Label>
                </Col>
                <Col xs="3">
                    <SelectInput
                        type="select"
                        name="select"
                        bsSize="sm"
                        value={webpcscMode}
                        onChange={setWebPcscMode}
                    >
                        <option value="smart">
                            {t('localSettingsCardLabels$smart')}
                        </option>
                        <option value="exclusive">
                            {t('localSettingsCardLabels$exclusive')}
                        </option>
                        <option value="nonExclusive">
                            {t('localSettingsCardLabels$nonExclusive')}
                        </option>
                    </SelectInput>
                </Col>
            </Row>
        </PortalContainer>
    );
};

export default AdvancedSettings;
