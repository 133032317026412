import React, { useContext, useState, useEffect, useRef } from 'react';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PCSC from '../../../../utils/pcsc';
import UCMS from '../../../../utils/ucms';
import { fetchDiagnosis } from '../../../../utils/diagnosis';
import { deriveUrl } from '../../../../utils/generic';
import {
    apiPath,
    ucmsConstants,
    buildDeviceDropdownDisplaySuccess,
    buildDeviceDropdownDisplayFailure,
    atrCases
} from '../../../../constants';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import { fetchScannerDevices } from '../../../../utils/helpdesk';
import {
    AppDispatchContext,
    AppStateContext,
} from '../../../../context/AppContext';
import { EnrollingDispatchContext } from '../../../../context/EnrollingContext';
import ScannerWithData from './ScannerWithData';
import { HelpDeskDispatchContext } from '../../../../context/HelpDeskContext';

const TAG = 'ScannerSelection';

const ScannerSelection = () => {
    const [readers, setReaders] = useState({});
    const [devices, setDevices] = useState({});
    const [selected, setSelected] = useState('');
    const [syncing, setSyncing] = useState(false);
    const [pcscInterval, setPcscInterval] = useState(null);
    const [loadingDevices, setLoadingDevices] = useState(false);
    const [loadingDevicesError, setLoadingDevicesError] = useState(undefined);

    const readersRef = useRef(readers);
    readersRef.current = readers;
    const selectedRef = useRef(selected);
    selectedRef.current = selected;
    const devicesRef = useRef(devices);
    devicesRef.current = devices;
    const pcscIntervalRef = useRef(pcscInterval);
    pcscIntervalRef.current = pcscInterval;

    const { user } = useContext(AppStateContext);
    const appDispatch = useContext(AppDispatchContext);
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const helpdeskDispatch = useContext(HelpDeskDispatchContext);

    const { t } = useTranslation();

    const removeReaders = (removed, updatedReaders) => {
        const newReaders = { ...updatedReaders };
        const updatedDevices = { ...devicesRef.current };
        removed.forEach((readerName) => {
            delete newReaders[readerName];
            delete updatedDevices[readerName];
        });
        setReaders(newReaders);
        setDevices(updatedDevices);
        setSelected(
            removed.includes(selectedRef.current) ? '' : selectedRef.current,
        );
        enrollingDispatch({
            type: 'SET_SELECTED_READER',
            reader: removed.includes(selectedRef.current)
                ? null
                : readers[selectedRef.current],
        });
        return newReaders;
    };

    const setDetectReaders = async (added, modified, updatedReaders) => {
        const updatedModified = { ...modified };
        added.forEach(
            // eslint-disable-next-line no-return-assign
            (reader) => 
                (updatedModified[reader] =
                    updatedModified[reader] === undefined ? null : updatedModified[reader]),
        );
        const modifiedReaders = {};
        Object.keys(updatedModified).forEach((readerName) => {
            modifiedReaders[readerName] = {
                ...[readerName],
                supported: t(atrCases[updatedModified[readerName]]) || true,
                atr: updatedModified[readerName],
                readerName,
                label: readerName,
                changed: true,
            };
            if (updatedModified[readerName] === null) {
                const updatedDevices = { ...devicesRef.current };
                delete updatedDevices[readerName];
                setDevices(updatedDevices);
            }
        });
        const newReaders = {
            ...updatedReaders,
            ...modifiedReaders,
        };
        setReaders(newReaders);
        return newReaders;
    };

    const displayOption = (data) => {
        console.debug(TAG, 'displayOption data:', data);
        return data.supported === true
            ? buildDeviceDropdownDisplaySuccess(
                data,
                t('genericDeviceDropdownLabels$serialNo'),
            )
            : buildDeviceDropdownDisplayFailure(data);
    };

    const identifyDevices = async (updatedReaders) => {
        const newReaders = { ...updatedReaders };
        // eslint-disable-next-line no-restricted-syntax
        for (const readerName of Object.keys(newReaders)) {
            if (newReaders[readerName].changed === false) {
                continue;
            }
            let result = null;
            let reader = null;
            if (newReaders[readerName].supported !== true) {
                continue;
            }
            try {
                reader = await PCSC.connect(readerName);
                let { atr } = newReaders[readerName];
                // Hard coded the atr to 'axiad.whfb' for whfb
                if (readerName.includes('Windows Hello for Business')) {
                    atr = 'axiad.whfb';
                }
                result = await UCMS.apdu.run(
                    reader,
                    ucmsConstants.actions.identify,
                    atr,
                    () => {},
                    deriveUrl(apiPath),
                );
            } catch (error) {
                console.error(
                    TAG,
                    'device selection identifyDevices error:',
                    error,
                );
                if (error.msg === 'E_SHARING_VIOLATION') {
                    newReaders[readerName].supported = t(
                        'errorMsgs$atr_inUse',
                    );
                } else if (error.msg === 'Unsupported card') {
                    newReaders[readerName].supported = t(
                        'errorMsgs$unsupportedDevice',
                    );
                } else {
                    newReaders[readerName].supported = t(
                        'errorMsgs$unidentifiable',
                    );
                }
            } finally {
                if (reader) await reader.disconnect();
            }
            if (result === null) {
                continue;
            }
            const card = result.entries[0].result;
            newReaders[readerName] = {
                ...newReaders[readerName],
                ...card,
                device: card,
                label: displayOption({
                    ...card,
                    ...newReaders[readerName],
                }),
            };
        }
        setReaders(newReaders);
        return newReaders;
    };

    const setScannerDevices = (json, updatedReaders) => {
        const newReaders = { ...updatedReaders };
        console.debug(TAG, 'setCredentialingQuery json:', json);
        const updatedDevices = { ...devicesRef.current };
        Object.keys(newReaders).forEach((readerName) => {
            const reader = newReaders[readerName];
            json.forEach((device) => {
                if (device.serial === reader.serial) {
                    newReaders[readerName] = {
                        ...reader,
                        ...device,
                        groups: device.groups,
                        display: displayOption({ ...reader, ...device }),
                        changed: false,
                    };
                    updatedDevices[readerName] = {
                        ...device,
                    };
                }
            });
        });
        setReaders(newReaders);
        setDevices(updatedDevices);
        setSyncing(false);
    };

    const queryDevices = async (updatedReaders) => {
        const data = [];
        Object.keys(updatedReaders).forEach( readerName => {
            const reader = updatedReaders[readerName];
            if (
                reader.supported === true &&
                reader.changed === true &&
                reader.device
            ) {
                data.push(reader.device);
            }
        })

        if (data.length > 0 && user) {
            await fetchScannerDevices(
                setScannerDevices,
                updatedReaders,
                appDispatch,
                data,
            );
        } else {
            setSyncing(false);
        }
    };

    const handlePcscOnChange = async (added, removed, modified) => {
        const updatedModified = { ...modified };
        setSyncing(true);
        let updatedReaders = { ...readersRef.current };
        if (removed.length > 0) {
            updatedReaders = removeReaders(removed, updatedReaders);
            removed.forEach((reader) => delete updatedModified[reader]);
        }
        updatedReaders = await setDetectReaders(
            added,
            updatedModified,
            updatedReaders,
        );
        updatedReaders = await identifyDevices(updatedReaders);
        queryDevices(updatedReaders);
    };

    const pcscOnChange = async () => {
        await fetchDiagnosis(appDispatch);
        await PCSC.ready;
        const interval = await PCSC.onChange(handlePcscOnChange, true, 1500);
        setPcscInterval(interval);
    };

    useEffect(() => {
        const onMount = async () => {
            try {
                await pcscOnChange();
            } catch (err) {
                if (err.diagnoses && err.diagnoses.length) {
                    setLoadingDevicesError(err.diagnoses[0].cause);
                } else if (err.code) {
                    // TODO: Use localization, possibly with errorGuard
                    setLoadingDevicesError(`${err.msg} ( ${err.code} )`);
                } else {
                    setLoadingDevicesError('Unknown device loading error');
                }
            } finally {
                setLoadingDevices(false);
            }
        };
        onMount();

        return () => {
            if (pcscIntervalRef.current) {
                pcscIntervalRef.current.stop();
                setPcscInterval(null);
            }
        };
    },[]);

    useEffect(() => {
        helpdeskDispatch({
            type: 'SET_SEARCH_DATA',
            payload: Object.values(devices),
        });
    }, [devices, helpdeskDispatch]);

    if (loadingDevices) return <LoadingSpinner />;
    if (loadingDevicesError)
        return (
            <Alert color="danger">
                {`Error loading devices (${loadingDevicesError})`}
            </Alert>
        );
    return (
        <ScannerWithData
            syncing={syncing}
            devices={devices}
            pcscInterval={pcscInterval}
        />
    );
};

export default ScannerSelection;
