import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import SerialWithData from './SerialWithData';
import { useDidMount } from '../../../utils/hooks';

const Serial = ({ registerForm }) => {
    const { t } = useTranslation();

    const validator = (value) => {
        if (value ) {
            return true;
        }
        return t('errorMsgs$serial');
    };

  
    useDidMount(() => {
        if (registerForm) {
            registerForm({
                serial: validator,
            });
        }  
    });

    return <SerialWithData validator={validator} />;
};

Serial.propTypes = {
    registerForm: PropTypes.func.isRequired,
};

export default Serial;
