import React, { useEffect, useContext, useReducer, useRef } from 'react';
import { PropTypes } from 'prop-types';
import CreateQuestionWithData from './CreateQuestionWithData';
import { EnrollingDispatchContext } from '../../../context/EnrollingContext';
import { useDidMount } from '../../../utils/hooks';

const questionPolicyDefault = {
    isEmpty: {
        regex: /^[a-zA-Z0-9].*$/,
        valid: false,
    },
};

const reducer = (questions) => (state, action) => {
    let newForm;
    let lastQuestionNumber;
    switch (action.type) {
        case 'SET_CLEAR_TEXT':
            return {
                ...state,
                clearText: {
                    ...state.clearText,
                    [action.questionNumber]:
                        !state.clearText[action.questionNumber],
                },
            };
        case 'ADD_QUESTION': {
            newForm = { ...state.questionForm };
            const initialQuestion = questions.find(
                (q) =>
                    !Object.values(state.questionForm).some(
                        (value) => value.question === q,
                    ),
            );
            lastQuestionNumber = Object.keys(newForm).length;
            newForm[lastQuestionNumber] = { question: initialQuestion };
            return {
                ...state,
                questionForm: newForm,
            };
        }
        case 'REMOVE_QUESTION': {
            const questionNumber = action.value;
            newForm = { ...state.questionForm };
            for (
                let i = questionNumber;
                i < Object.keys(state.questionForm).length;
                i++
            ) {
                newForm[i] = { ...newForm[i + 1] };
            }
            delete newForm[Object.keys(state.questionForm).length - 1];
            console.log(newForm);
            return {
                ...state,
                questionForm: newForm,
            };
        }
        case 'UPDATE_FORM':
            newForm = { ...state.questionForm };
            newForm[action.questionNumber][action.name] = action.value;
            return {
                ...state,
                questionForm: newForm,
            };
        case 'CHECK_VALIDITY': {
            const newValidity = {};
            for (let i = 0; i < Object.keys(state.questionForm).length; i++) {
                newValidity[i] = {
                    question: null,
                    answer: null,
                    confirmAnswer: null,
                };
                if (state.questionForm[i].confirmAnswer)
                    newValidity[i].confirmAnswer =
                        state.questionForm[i].answer ===
                        state.questionForm[i].confirmAnswer;
                if (state.questionForm[i].answer)
                    newValidity[i].answer =
                        questionPolicyDefault.isEmpty.regex.test(
                            state.questionForm[i].answer,
                        );
                if (state.questionForm[i].question)
                    newValidity[i].question =
                        questionPolicyDefault.isEmpty.regex.test(
                            state.questionForm[i].question,
                        ) &&
                        Object.values(state.questionForm).every(
                            (value, j) =>
                                i === j ||
                                value.question !==
                                    state.questionForm[i].question,
                        );
            }
            return {
                ...state,
                validity: newValidity,
            };
        }
        default:
            return { ...state };
    }
};

const CreateQuestion = ({ registerForm, serverComponent }) => {
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const {
        details: { minEnroll, maxEnroll, questions },
    } = serverComponent;

    const initialState = {
        clearText: {},
        questionForm: {},
        validity: {},
    };
    const maxQuestions = questions.length > 0 ? questions.length : maxEnroll;
    for (let i = 0; i < maxQuestions; i++) {
        initialState.clearText[i] = false;
        if (i < minEnroll) {
            initialState.validity[i] = {};
            initialState.questionForm[i] = { question: questions[i] };
        }
    }

    const [state, dispatch] = useReducer(reducer(questions), initialState);

    useEffect(() => {
        dispatch({ type: 'CHECK_VALIDITY' });
    }, [state.questionForm]);

    useEffect(() => {
        const validateAll = () =>
            Object.values(state.validity).every((value) =>
                ['answer', 'confirmAnswer', 'question'].every(
                    (name) => value[name] === true,
                ),
            );
        enrollingDispatch({
            type: 'SET_EXTRACT_VALUE',
            name: 'questionForm',
            value: validateAll(),
            validator: (value) => value,
        });
    }, [state.validity, enrollingDispatch]);

    const questionFormKeyLength = useRef(null);
    const formKeyLength = Object.keys(state.questionForm).length;
    useEffect(() => {
        if (questionFormKeyLength.current !== formKeyLength) {
            questionFormKeyLength.current = formKeyLength;
            const updateDevice = () => {
                for (let i = 0; i < maxQuestions; i++) {
                    ['question', 'answer', 'confirmAnswer'].forEach((name) => {
                        enrollingDispatch({
                            type: 'ADD_TO_DEVICE',
                            name: `${name}${i}`,
                            value: state.questionForm[i]
                                ? state.questionForm[i][name]
                                : undefined,
                        });
                    });
                }
            };
            updateDevice();
            enrollingDispatch({
                type: 'ADD_TO_DEVICE',
                name: 'totalQuestions',
                value: formKeyLength,
            });
        }
    }, [state.questionForm, enrollingDispatch, formKeyLength, maxQuestions]);

    useDidMount(() => {
        registerForm({ questionForm: (value) => value });
        enrollingDispatch({
            type: 'REMOVE_FROM_DEVICE',
            name: 'questionSerial',
        });
    });

    const clearTextSwitch = (questionNumber) => {
        dispatch({
            type: 'SET_CLEAR_TEXT',
            questionNumber,
        });
    };

    const addQuestion = () => {
        dispatch({
            type: 'ADD_QUESTION',
        });
    };

    const removeQuestion = (questionNumber) => {
        dispatch({
            type: 'REMOVE_QUESTION',
            value: questionNumber,
        });
    };

    const updateForm = (value, name, questionNumber) => {
        dispatch({ type: 'UPDATE_FORM', value, name, questionNumber });
        enrollingDispatch({
            type: 'ADD_TO_DEVICE',
            name: `${name}${questionNumber}`,
            value,
        });
    };

    const questionComponents = [];
    for (let i = 0; i < Object.keys(state.questionForm).length; i++) {
        if (state.validity[i])
            questionComponents.push(
                <CreateQuestionWithData
                    questions={questions}
                    questionNumber={i}
                    minEnroll={minEnroll}
                    maxQuestions={maxQuestions}
                    clearText={state.clearText[i]}
                    clearTextSwitch={() => clearTextSwitch(i)}
                    questionForm={state.questionForm}
                    updateForm={updateForm}
                    validity={state.validity}
                    removeQuestion={() => removeQuestion(i)}
                    addQuestion={addQuestion}
                />,
            );
    }

    return <>{questionComponents}</>;
};

CreateQuestion.propTypes = {
    registerForm: PropTypes.func.isRequired,
    serverComponent: PropTypes.object.isRequired,
};

export default CreateQuestion;
