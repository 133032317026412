import React, { useContext, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { postData } from '../../../utils/postData';
import { getSerialsFromBulk } from '../../../utils/generic';
import StepWizard from '../../shared/StepWizard';
import GoBackRedirect from '../../shared/GoBackRedirect';
import { DisableDeviceContext, DisableDeviceDispatchContext } from './context';
import SelectReason from '../../cardLibrary/SelectReason';
import PerformAction from '../../cardLibrary/PerformAction';
import {
    AppDispatchContext,
    AppStateContext,
} from '../../../context/AppContext';
import Status from '../../cardLibrary/Status';
import {
    serverConstants,
    paths,
    disableDeviceEndpoint,
    PROGRESS_BAR_DONE_DELAY,
} from '../../../constants';
import {
    HelpDeskStateContext,
    HelpDeskDispatchContext,
} from '../../../context/HelpDeskContext';

import { useLazyFetchData } from '../../../utils/hooks';
import LoadingSpinner from '../../shared/LoadingSpinner';
import ValidateQuestionCard from '../../cardLibrary/ValidateQuestion';
import UserEntryCard from '../../cardLibrary/UserEntry';
import { EnrollingDispatchContext } from '../../../context/EnrollingContext';
import EnrollQuestionCard from '../../cardLibrary/EnrollQuestion';

const USER_ENTRY = 'userEntry';
const QUESTION_VALIDATE = 'questionValidate';
const SELECT_REASON = 'selectReason';
const DEVICE_DISABLE = 'deviceDisable';
const STATUS = 'status';
const HOME = 'home';
const ENROLL_QUESTION = 'questionEnroll';

function reducer(state, action) {
    switch (action.type) {
        case 'DONE':
            return { ...state, step: 0 };
        case 'SET_WORKFLOW':
            return { ...state, workflow: action.workflow, step: action.step };
        case 'SET_CURRENT_ANSWER':
            return {
                ...state,
                challenges: {
                    ...state.challenges,
                    [action.session]: action.answer,
                },
            };
        case 'SET_REASON':
            return {
                ...state,
                reason: action.payload,
            };
        case 'ON_SUCCESS':
            return {
                ...state,
                statusMessage: 'disableSuccessMsg',
                errorMessage: false,
                loading: false,
                step: state.step + 1,
            };
        case 'ON_FAILURE':
            return {
                ...state,
                loading: false,
                statusMessage: action.payload,
                errorMessage: true,
                step: state.step + 1,
            };
        case 'GO_BACK':
            return {
                ...state,
                step: 0,
            };
        case 'GO_NEXT':
            return {
                ...state,
                step: state.step + 1,
            };
        case 'SET_LOADING_TRUE':
            return { ...state, loading: true };
        case 'PROGRESS_BAR_DONE':
            return { ...state, isDone: true };
        default:
            throw new Error('Disable Device Reducer: invalid action');
    }
}

const Disable = () => {
    const { device, goBackLink } = useContext(AppStateContext);
    const appDispatch = useContext(AppDispatchContext);
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const helpDeskDispatch = useContext(HelpDeskDispatchContext);
    const { bulkActionDevices } = useContext(HelpDeskStateContext);
    const [state, dispatch] = useReducer(reducer, {
        workflow: null,
        step: null,
        reason: 'lost',
        statusMessage: null,
        errorMessage: null,
        loading: false,
        isDone: false,
    });
    const history = useHistory();
    const { t } = useTranslation();

    const { location } = history || {};
    const { pathname } = location || {};
    const { serial, type, subType, user } = device || {};
    const isHelpDesk = pathname ? pathname.includes(paths.helpDesk) : false;
    const returnPath =
        goBackLink || (isHelpDesk ? paths.helpDesk : paths.identities);
    const serialsConfirmation = getSerialsFromBulk(bulkActionDevices);

    const setSteps = (res) => {
        const workflow = [{ type: HOME }, ...res, { type: STATUS }];
        dispatch({ type: 'SET_WORKFLOW', workflow, step: 1 });
    };

    const [fetchWalkway] = useLazyFetchData({
        endpoint: '/device/disable',
        onSuccess: (res) => {
            setSteps(res);
        },
    });

    useEffect(() => {
        enrollingDispatch({
            type: 'SET_USERNAME',
            username: user,
        });
    }, [user, enrollingDispatch]);

    useEffect(() => {
        const performAction = async () => {
            let endpoint;
            const queryParams = {};
            const body = {
                reason: state.reason,
            };
            if (bulkActionDevices.length !== 0) {
                body.devices = bulkActionDevices;
                endpoint = disableDeviceEndpoint;
            } else {
                body.user = user || "";
                endpoint = `${disableDeviceEndpoint}/${type}/${serial}`;
                queryParams.subType = subType;
            }
            const res = await postData({
                body,
                endpoint,
                appDispatch,
                queryParams
            });
            const content = await res.clone().text();
            if (content) {
                const json = await res.json();
                if (json.result === serverConstants.success) {
                    dispatch({ type: 'PROGRESS_BAR_DONE' });
                    setTimeout(
                        () => dispatch({ type: 'ON_SUCCESS', payload: {} }),
                        PROGRESS_BAR_DONE_DELAY,
                    );
                } else {
                    dispatch({ type: 'PROGRESS_BAR_DONE' });
                    setTimeout(
                        () =>
                            dispatch({
                                type: 'ON_FAILURE',
                                payload:
                                    t(`errorMsgs$${json.message}`) ||
                                    json.message,
                            }),
                        PROGRESS_BAR_DONE_DELAY,
                    );
                }
            } else {
                dispatch({ type: 'PROGRESS_BAR_DONE' });
                setTimeout(
                    () =>
                        dispatch({
                            type: 'ON_FAILURE',
                            payload: t('errorMsgs$serverDefault'),
                        }),
                    PROGRESS_BAR_DONE_DELAY,
                );
            }
        };
        if (state.workflow && state.step) {
            switch (state.workflow[state.step].type) {
                case USER_ENTRY:
                    enrollingDispatch({
                        type: 'SET_USER_ENTRY',
                        userEntry: state.workflow[state.step],
                    });
                    break;
                case DEVICE_DISABLE:
                    dispatch({
                        type: 'SET_LOADING_TRUE',
                    });
                    performAction();
                    break;
                default:
                    break;
            }
        }
    }, [
        state.step,
        state.reason,
        appDispatch,
        history,
        type,
        serial,
        bulkActionDevices,
        user,
        state.workflow,
        subType,
        t,
        enrollingDispatch,
    ]);

    useEffect(() => {
        if (device || isHelpDesk) {
            const queryParams = {};
            if (user) queryParams.user = user;
            fetchWalkway(queryParams);
        }
        return () => {
            helpDeskDispatch({ type: 'RESET_CONTEXT' });
            appDispatch({ type: 'RESET_FLOWS' });
            enrollingDispatch({ type: 'RESET_FLOWS' });
        };
    }, [device, isHelpDesk, user, fetchWalkway, helpDeskDispatch, appDispatch, enrollingDispatch]);

    if ((!serial || !type) && bulkActionDevices.length === 0) {
        history.push(returnPath);
        return null;
    }

    const components = {
        [HOME]: (
            <GoBackRedirect path={returnPath} stepId={HOME} history={history} />
        ),
        [ENROLL_QUESTION]: (
            <EnrollQuestionCard
                stepId={ENROLL_QUESTION}
                mgmt={{
                    next: () => dispatch({ type: 'GO_NEXT' }),
                    back: () => dispatch({ type: 'GO_BACK' }),
                }}
            />
        ),
        [USER_ENTRY]: (
            <UserEntryCard
                stepId={USER_ENTRY}
                setCurrentAnswer={(answer, session) =>
                    dispatch({ type: 'SET_CURRENT_ANSWER', answer, session })
                }
                mgmt={{
                    next: () => dispatch({ type: 'GO_NEXT' }),
                    back: () => dispatch({ type: 'GO_BACK' }),
                }}
            />
        ),
        [QUESTION_VALIDATE]: (
            <ValidateQuestionCard
                deviceUser={user}
                stepId={QUESTION_VALIDATE}
                mgmt={{
                    next: () => dispatch({ type: 'GO_NEXT' }),
                    back: () => dispatch({ type: 'GO_BACK' }),
                    challenges: state.challenges,
                }}
            />
        ),
        [SELECT_REASON]: (
            <SelectReason
                stepId={SELECT_REASON}
                device={device}
                instructions={t('instructionMessages$disable')}
                isHelpDesk={isHelpDesk}
                subtitle={serialsConfirmation}
            />
        ),
        [DEVICE_DISABLE]: (
            <PerformAction stepId={DEVICE_DISABLE} isDone={state.isDone} />
        ),
        [STATUS]: <Status path={returnPath} stepId={STATUS} />,
    };

    return state.workflow ? (
        <DisableDeviceContext.Provider value={state}>
            <DisableDeviceDispatchContext.Provider value={dispatch}>
                <StepWizard
                    step={state.workflow[state.step].type}
                    title={t('cardTitles$disableDevice')}
                    dispatch={dispatch}
                    state={state}
                >
                    {state.workflow.map((s) => components[s.type])}
                </StepWizard>
            </DisableDeviceDispatchContext.Provider>
        </DisableDeviceContext.Provider>
    ) : (
        <LoadingSpinner />
    );
};

export default Disable;
