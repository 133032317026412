import React, { useContext }  from 'react';
import { useTranslation } from 'react-i18next';
import {
    StyledDefaultModalHeader,
    StyledDefaultModalBody,
    StyledDefaultModalFooter,
    AccentButton,
    StyledAlert,
} from '../../styles/common';
import { AppDispatchContext } from '../../context/AppContext';
import PukTable from '../elementLibrary/PukTable';
import { useHandleEnter } from '../../utils/hooks';

const Puk = () => {
    const { t } = useTranslation();
    const dispatch = useContext(AppDispatchContext);

    const clearModal = () => dispatch({ type: 'CLEAR_MODAL' });
    useHandleEnter({ action: clearModal });

    return (
        <>
            <StyledDefaultModalHeader>
                {t(`modalLabels$retrievePuk$title`)}
            </StyledDefaultModalHeader>
            <StyledDefaultModalBody>
                <StyledAlert color="info">
                    {t(`modalLabels$retrievePuk$info`)}
                </StyledAlert>
                <PukTable />
            </StyledDefaultModalBody>
            <StyledDefaultModalFooter>
                <AccentButton
                    outline
                    color="primary"
                    size="sm"
                    onClick={clearModal}
                >
                    {t('modalLabels$close')}
                </AccentButton>
            </StyledDefaultModalFooter>
        </>
    );
};

export default Puk;
