import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faLaptop } from '@fortawesome/free-solid-svg-icons/faLaptop';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faThLarge } from '@fortawesome/free-solid-svg-icons/faThLarge';
import { faSuitcase } from '@fortawesome/free-solid-svg-icons/faSuitcase';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons/faUserAlt';
import { faRandom } from '@fortawesome/free-solid-svg-icons/faRandom';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faLifeRing } from '@fortawesome/free-solid-svg-icons/faLifeRing';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faPaintBrush } from '@fortawesome/free-solid-svg-icons/faPaintBrush';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons/faPlayCircle';
import { faRecycle } from '@fortawesome/free-solid-svg-icons/faRecycle';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown';
import { faColumns } from '@fortawesome/free-solid-svg-icons/faColumns';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faEraser } from '@fortawesome/free-solid-svg-icons/faEraser';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons/faAngleDoubleRight';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons/faAngleDoubleLeft';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { faExpandAlt } from '@fortawesome/free-solid-svg-icons/faExpandAlt';
import { faCompressAlt } from '@fortawesome/free-solid-svg-icons/faCompressAlt';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';

const icons = [
    faCheckSquare,
    faSquare,
    faEye,
    faEyeSlash,
    faTimesCircle,
    faCheckCircle,
    faLaptop,
    faCheck,
    faPlusCircle,
    faPencilAlt,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faThLarge,
    faSuitcase,
    faUserAlt,
    faRandom,
    faInfoCircle,
    faBan,
    faPlay,
    faCircle,
    faSync, // fa-refresh
    faCog, // fa-gear
    faUsers, // fa-group
    faLifeRing, // fa-support
    faArrowRight,
    faClock,
    faPaintBrush,
    faPlayCircle,
    faRecycle,
    faSearch,
    faSort,
    faSortUp,
    faSortDown,
    faColumns,
    faFilter,
    faTimes,
    faSignOutAlt,
    faEdit,
    faEraser,
    faAngleRight,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleDoubleLeft,
    faDownload,
    faSave,
    faMinus,
    faPlus,
    faMinusCircle,
    faExpandAlt,
    faCompressAlt,
    faStar,
    faBell,
    faQuestionCircle,
];

export default icons;
