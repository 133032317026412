import React, { useContext, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Nav, NavItem, NavLink, Navbar, Container } from 'reactstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import uniqueId from 'lodash.uniqueid';
import { AppStateContext } from '../../context/AppContext';
import {
    TabsStateContext,
    TabsDispatchContext,
} from '../../context/TabsContext';
import { paths, subTabsPaths } from '../../constants';
import {
    isAllowed,
    userSearchPrivileges,
    deviceSearchPrivileges,
    reportsAllowedPrivileges,
} from '../../utils/authorization';

const StyledNav = styled(Nav)`
    overflow: hidden;
    transition: all 0.75s ease-out;
    @media (max-width: 767px) {
        margin: 0 auto !important;
    }
`;
const StyledNavBar = styled(Navbar)`
    color: #fefefe !important;
    background-color: ${({ theme }) => theme.colors.dark} !important;
    min-height: 52px;
    padding-left: 30px !important;
    padding-right: 30px !important;
`;
const StyledNavItem = styled(NavItem)`
    letter-spacing: 0.06em;
`;
const StyledNavLink = styled(NavLink)`
    color: ${({ theme, active }) =>
        active ? theme.colors.primary : '#fefefe'} !important;
    font-family: Poppins, san-serif !important;
    font-size: smaller !important;
    font-weight: ${({ active }) => (active ? 'normal' : 'lighter')};
    text-decoration: none !important;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    padding-left: ${({ $firstLink }) =>
        $firstLink ? '0px' : '18px'} !important;
    @media (max-width: 767px) {
        padding-left: 18px !important;
    }
    padding-right: 18px !important;
    :hover {
        color: ${({ theme }) => theme.colors.primary} !important;
    }
`;

export const subTabsList = {
    helpDesk: [
        {
            route: subTabsPaths.helpDesk.users,
            label: 'tabNav$helpDesk$users',
            title: 'helpDesk$users$title',
            subtitle: 'helpDesk$users$subtitle',
            hide: (permissions) =>
                !isAllowed({
                    allowedPermissions: userSearchPrivileges,
                    permissions,
                }),
        },
        {
            route: subTabsPaths.helpDesk.devices,
            label: 'tabNav$helpDesk$devices',
            title: 'helpDesk$devices$title',
            subtitle: 'helpDesk$devices$subtitle',
            hide: (permissions) =>
                !isAllowed({
                    allowedPermissions: deviceSearchPrivileges,
                    permissions,
                }),
        },
        {
            route: subTabsPaths.helpDesk.scanner,
            label: 'tabNav$helpDesk$scanner',
            title: 'helpDesk$scanner$title',
            subtitle: 'helpDesk$scanner$subtitle',
            hide: (permissions) =>
                !isAllowed({
                    allowedPermissions: deviceSearchPrivileges,
                    permissions,
                }),
        },
    ],
    reports: [
        {
            route: subTabsPaths.reports.inventory,
            label: 'tabNav$reports$inventory',
            title: 'reports$inventory$title',
            subtitle: 'reports$inventory$subtitle',
            hide: (permissions) =>
                !isAllowed({
                    allowedPermissions: reportsAllowedPrivileges,
                    permissions,
                }),
        },
    ],
};

const RenderTabs = ({ tabs, onClick, activeTab }) => {
    const { t } = useTranslation();
    const handleOnKeyPress = (event, index, route) => {
        const { charCode } = event || {};
        if (charCode === 13 || charCode === 32) {
            onClick(index, route);
        }
    };
    return (
        <StyledNav navbar className="mr-auto">
            {tabs.map(({ label, route }, i) => (
                <StyledNavItem id={t(label)} key={uniqueId(label)}>
                    <StyledNavLink
                        $firstLink={i === 0}
                        active={activeTab === i}
                        onClick={() => onClick(i, route)}
                    >
                        <span
                            tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
                            onKeyPress={(e) => handleOnKeyPress(e, i, route)}
                        >
                            {t(label)}
                        </span>
                    </StyledNavLink>
                </StyledNavItem>
            ))}
        </StyledNav>
    );
};

const TabNav = () => {
    const { tabs, activeIndex, currentParent } = useContext(TabsStateContext);
    const dispatch = useContext(TabsDispatchContext);
    const { user } = useContext(AppStateContext);
    const history = useHistory();
    const { location } = history || {};
    const { higherPrivileges } = user || {};

    // Use ref to keep track on global variable. 
    // So the useEffect for location and the setTabs useCallback won't have exhoustive warning.
    const locationRef = useRef(location);
    const activeIndexRef = useRef(activeIndex);
    const currentParentRef = useRef(currentParent);
    const higherPrivilegesRef = useRef(higherPrivileges);

    useEffect(() => {
        locationRef.current = location;
        currentParentRef.current = currentParent;
        higherPrivilegesRef.current = higherPrivileges;
        activeIndexRef.current = activeIndex;
    }, [activeIndex, currentParent, higherPrivileges, location])


    const setTabs = useCallback((tabsArray, parent) => {
        const allowedTabs = tabsArray.filter(
            (tab) => !tab.hide(higherPrivilegesRef.current),
        );
        const index = allowedTabs.findIndex((item) =>
            locationRef.current.pathname.includes(item.route),
        );
        let newActiveIndex = index > 0 ? index : activeIndexRef.current;
        if (parent !== currentParentRef.current) newActiveIndex = 0;
        dispatch({
            type: 'SET_TABS_LIST',
            payload: {
                tabs: allowedTabs,
                activeIndex: newActiveIndex,
                currentParent: parent,
            },
        });
    }, [dispatch]);

    useEffect(() => {
        if (location.pathname.includes(paths.helpDesk)) {
            setTabs(subTabsList.helpDesk, 'helpdesk');
        } else if (location.pathname.includes(paths.reports)) {
            setTabs(subTabsList.reports, 'reports');
        } else {
            dispatch({ type: 'RESET_CONTEXT' });
        }
    }, [location, setTabs, dispatch]);

    const toggle = (tabIndex, route) => {
        if (activeIndex !== tabIndex) {
            dispatch({ type: 'SET_ACTIVE_INDEX', payload: tabIndex });
        }
        if (route) {
            history.push(route);
        }
    };

    return (
        <StyledNavBar
            className="navigation-clean"
            expand="xs"
            aria-label="tab-navigation"
            role="navigation"
        >
            <Container>
                <RenderTabs
                    tabs={tabs}
                    onClick={toggle}
                    activeTab={activeIndex}
                />
            </Container>
        </StyledNavBar>
    );
};

export default TabNav;
