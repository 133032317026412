import React, { useState, useContext, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import SearchBar from '../../../elementLibrary/SearchBar';
import UsersTable from '../../../elementLibrary/UsersTable';
import { PortalContainer, StyledAlert } from '../../../../styles/common';
import {
    adminUserEndpoint,
    emptyUsersLabelId,
    helpdeskUsersQueryTableColumnsSettings,
} from '../../../../constants';
import {
    HelpDeskStateContext,
    HelpDeskDispatchContext,
} from '../../../../context/HelpDeskContext';
import {
    AppStateContext,
    AppDispatchContext,
} from '../../../../context/AppContext';
import { useDidMount, useSortColumn } from '../../../../utils/hooks';

const UsersHeader = () => {
    const {
        admin: { metadata },
    } = useContext(AppStateContext);
    const { usersDefaultValue, searchFilter } =
        useContext(HelpDeskStateContext);
    const dispatch = useContext(HelpDeskDispatchContext);

    const { users } = metadata || {};
    const keywords = users ? users.filters.map((filter) => filter.name) : [];

    const onLoading = (loading) =>
        dispatch({
            type: 'SET_SEARCH_LOADING',
            payload: loading,
        });
    const onSearchData = (data) =>
        dispatch({
            type: 'SET_SEARCH_DATA',
            payload: data,
        });
    const onClearData = () =>
        dispatch({
            type: 'RESET_SEARCH_DATA',
            payload: {},
        });
    const onFiltersChange = (filters) =>
        dispatch({
            type: 'SET_SEARCH_FILTER',
            payload: filters,
        });
    const onSelect = (defaultValues) =>
        dispatch({
            type: 'SAVE_USERS_DEFAULT_VALUE',
            payload: defaultValues,
        });

    return (
        <>
            <Col xs="6" />
            <Col xs="5">
                <SearchBar
                    endpoint={adminUserEndpoint}
                    keywords={keywords}
                    onLoading={onLoading}
                    onSearchData={onSearchData}
                    onClearData={onClearData}
                    onFiltersChange={onFiltersChange}
                    onSelect={onSelect}
                    defaultValue={usersDefaultValue}
                    searchFilter={searchFilter}
                />
            </Col>
        </>
    );
};

const Users = () => {
    const {
        admin: { metadata },
        activeUsersColumns,
    } = useContext(AppStateContext);
    const { searchData, searchLoading } = useContext(HelpDeskStateContext);
    const dispatch = useContext(HelpDeskDispatchContext);
    const appDispatch = useContext(AppDispatchContext);
    const { t } = useTranslation();
    const [cleaning, setCleaning] = useState(true);
    const { users } = metadata || {};
    const columns = users ? users.columns : [];
    useSortColumn({
        data: searchData,
        columns,
        activeColumns: activeUsersColumns,
    });

    useEffect(() => {
        if (isEmpty(searchData)) {
            setCleaning(false);
        }
    }, [searchData]);

    useDidMount(() => {
        dispatch({
            type: 'RESET_SEARCH_DATA',
            payload: {},
        });
    });
    
    const onColumnsChange = (value) => {
        appDispatch({ type: 'SET_ACTIVE_USERS_COLUMNS', payload: value });
    };

    const getTableHeader = (settinsIconCol) => (
        <>
            <Row>
                <UsersHeader columnsName={columns} />
                {settinsIconCol}
            </Row>
            <Row>
                {!searchLoading && isEmpty(searchData) && (
                    <Col>
                        <StyledAlert
                            id={emptyUsersLabelId}
                            color="info"
                            fade={false}
                        >
                            {t('helpDesk$users$emptyTable')}
                        </StyledAlert>
                    </Col>
                )}
            </Row>
        </>
    );

    return (
        <>
            {metadata.users.columns  && !cleaning ? (
                <PortalContainer>
                    <UsersTable
                        data={searchData}
                        columnsName={columns}
                        activeColumns={activeUsersColumns}
                        onColumnsChange={onColumnsChange}
                        selectedColumnsKey={
                            helpdeskUsersQueryTableColumnsSettings
                        }
                        getTableHeader={getTableHeader}
                    />
                    {searchLoading && <LoadingSpinner />}
                </PortalContainer>
            ) : (
                <LoadingSpinner />
            )}
        </>
    );
};

export default Users;
