import React from 'react';
import { NotificationProvider } from './context';
import NotificationsDropdown from './NotificationsDropdown';

const Notifications = () => (
    <NotificationProvider>
        <NotificationsDropdown />
    </NotificationProvider>
);

export default Notifications;
