import i18next from 'i18next';
import { compareVersion } from './generic';
import PCSC from './pcsc';
import { MIN_VERSION_EXT, MIN_VERSION_APP } from '../constants';

const TAG = 'fetchDiagnosis';

export async function fetchDiagnosis() {
    try {
        const support = {
            os: ['Win32', 'Win64', 'MacIntel', 'Linux x86_64'],
            browser: { chrome: '55', /* firefox: "60", */ msedge: '16' },
        };
        await PCSC.diagnose(support);
        const ver = await PCSC.version();
        const ext = compareVersion(ver.ext, MIN_VERSION_EXT);
        const app = compareVersion(ver.app, MIN_VERSION_APP);
        console.debug('PCSC version', ver);
        console.debug('MIN_VERSION_EXT:', MIN_VERSION_EXT);
        console.debug('MIN_VERSION_APP:', MIN_VERSION_APP);
        console.debug('ext:', ext);
        console.debug('app:', app);
        /* eslint-disable no-throw-literal */
        if (ext === -1 && app === -1) {
            throw { msg: i18next.t('errorMsgs$ver_app_ext') };
        } else if (ext === -1) {
            throw { msg: i18next.t('errorMsgs$ver_ext') };
        } else if (app === -1) {
            throw { msg: i18next.t('errorMsgs$ver_app') };
        }
        /* eslint-enable no-throw-literal */
    } catch (error) {
        console.error(TAG, 'error:', error);
        console.error(TAG, 'error.marker:', error.marker);
        throw error;
    }
    return true;
}
