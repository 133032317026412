import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Col, Row, Label, Button } from 'reactstrap';
import styled from 'styled-components';
import { currentPinFormId, currentPinFormForgotId } from '../../../constants';
import { StyledInput, StyledFormGroup } from '../../../styles/common';
import { EnrollingDispatchContext } from '../../../context/EnrollingContext';
import SecureEntryIcon from '../../shared/SecureEntryIcon';

const StyledButton = styled(Button)`
    padding: 0px;
`;

const CurrentPinWithData = ({
    validator,
    setCurrentPin,
    clearText,
    clearTextSwitch,
    serverComponent,
    forgotPin,
}) => {
    const { t } = useTranslation();
    const enrollingDispatch = useContext(EnrollingDispatchContext);

    const name = 'currentPin';
    const forgotPinHandler = () => {
        enrollingDispatch({
            type: 'RESET_FORM',
        });
        forgotPin();
    };
    return (
        <div data-test-id={currentPinFormId}>
            <StyledFormGroup row autoComplete="off">
                <Col xs="3">
                    <Label for={name}>
                        {t('formLabels$currentPin$formLabel')}
                    </Label>
                </Col>
                <Col xs="4">
                    <StyledInput
                        type={clearText ? 'text' : 'password'}
                        name={name}
                        placeholder={t('formLabels$currentPin$placeholder')}
                        bsSize="sm"
                        onChange={(event) => {
                            enrollingDispatch({
                                type: 'SET_VALUE',
                                name,
                                value: event.target.value,
                                validator,
                            });
                            setCurrentPin(event.target.value);
                        }}
                    />
                </Col>
                <Col xs="0">
                    <SecureEntryIcon
                        isActive={clearText}
                        onMouseDown={clearTextSwitch}
                    />
                </Col>
            </StyledFormGroup>
            {serverComponent.details && serverComponent.details.reset ? (
                <Row>
                    <Col xs={{ size: 4, offset: 3 }}>
                        <StyledButton
                            color="link"
                            onClick={forgotPinHandler}
                            data-test-id={currentPinFormForgotId}
                        >
                            {t('formLabels$currentPin$forgetLabel')}
                        </StyledButton>
                    </Col>
                </Row>
            ) : (
                ''
            )}
        </div>
    );
};

const { object, func, bool } = PropTypes;

CurrentPinWithData.propTypes = {
    clearText: bool.isRequired,
    clearTextSwitch: func.isRequired,
    setCurrentPin: func.isRequired,
    validator: func.isRequired,
    forgotPin: func,
    serverComponent: object,
};

CurrentPinWithData.defaultProps = {
    serverComponent: {
        details: {
            reset: false,
        },
    },
    forgotPin: () => {
        throw Error('forgotPin was not set when passed to CurrentPin');
    },
};

export default CurrentPinWithData;
