import React, { useContext, useReducer, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TerminusCard from '../cardLibrary/Terminus';
import RecyclePiv from '../cardLibrary/RecyclePiv';
import MessageCard from '../cardLibrary/MessageCard';
import DeviceDetection from '../elementLibrary/DeviceDetection';
import FlowButtons from '../elementLibrary/FlowButtons';
import { AppDispatchContext, AppStateContext } from '../../context/AppContext';
import { EnrollingDispatchContext } from '../../context/EnrollingContext';
import { PaddingDivider } from '../../styles/common';
import { HelpDeskDispatchContext } from '../../context/HelpDeskContext';
import { paths, KIOSK_MODE } from '../../constants';
import DeviceNotFound from '../cardLibrary/DeviceNotFound';

const DETECTION = 'detection';
const PIV_RECYCLE = 'pivRecycle';
const TERMINUS = 'terminus';

function reducer(state, action) {
    switch (action.type) {
        case 'SET_RECYCLE_WORKFLOW':
            return {
                ...state,
                workflow: action.payload,
                fetching: false,
            };
        case 'SET_WORKFLOW':
            return {
                ...state,
                workflow: action.payload,
            };
        case 'SET_NEXT_STEP':
            return {
                ...state,
                step: state.step + 1,
            };
        case 'SET_BACK_STEP':
            return {
                ...state,
                step: state.step - 1,
            };
        case 'SET_DEVICE':
            return {
                ...state,
                device: action.payload,
            };
        case 'SET_READER':
            return {
                ...state,
                reader: action.payload,
            };
        case 'SET_DETECTED':
            return {
                ...state,
                detected: true,
            };
        default:
            return state;
    }
}

const Recycle = () => {
    const appDispatch = useContext(AppDispatchContext);
    const { errorMsg: errorMessage, device, goBackLink, viewMode } = useContext(
        AppStateContext,
    );
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const helpDeskDispatch = useContext(HelpDeskDispatchContext);
    const { t } = useTranslation();
    const history = useHistory();
    const [state, dispatch] = useReducer(reducer, {
        workflow: [
            {
                type: DETECTION,
            },
            {
                type: PIV_RECYCLE,
            },
            {
                type: TERMINUS,
            },
        ],
        step: 0,
        device,
        reader: '',
        detected: false,
    });

    const { location } = history || {};
    const { pathname } = location || {};
    const isHelpDesk = pathname ? pathname.includes(paths.helpDesk) : false;
    const returnPath =
        goBackLink || (isHelpDesk ? paths.helpDesk : paths.identities);
    const isKiosk = viewMode === KIOSK_MODE;

    const deviceNotFound = () =>
        dispatch({
            type: 'SET_RECYCLE_WORKFLOW',
            payload: [{ type: 'deviceNotFound' }],
        });

    const components = {
        /* eslint-disable react/prop-types */
        pivRecycle: ({ device, reader }, mgmt) => (
            <RecyclePiv device={device} reader={reader} mgmt={mgmt} />
        ),
        terminus: ({ title, errorMsg }) => (
            <TerminusCard
                title={title}
                errorMsg={errorMsg}
                msg={t('recycleSuccessMsg'.concat(isKiosk ? '$kiosk' : ''))}
                path={returnPath}
            />
        ),
        detection: ({ msg, title, alertStyle, device, setReader }, mgmt) => (
            <>
                <MessageCard
                    msg={msg}
                    title={title}
                    alertStyle={alertStyle}
                    mgmt={mgmt}
                >
                    <DeviceDetection
                        device={device}
                        mgmt={mgmt}
                        appDispatch={appDispatch}
                        enrollingDispatch={enrollingDispatch}
                        setReader={setReader}
                        handleNotFound={deviceNotFound}
                    />
                    <PaddingDivider />
                    <FlowButtons
                        mgmt={{
                            next: mgmt.next,
                            back: mgmt.back,
                            showBack: true,
                            showNext: false,
                        }}
                    />
                </MessageCard>
            </>
        ),
        deviceNotFound: ({ title }) => <DeviceNotFound title={title} />,
    };

    useEffect(() => {
        return () => {
            enrollingDispatch({ type: 'RESET_FLOWS' });
            helpDeskDispatch({ type: 'RESET_CONTEXT' });
            appDispatch({ type: 'RESET_FLOWS' });
        };
    },[]);

    const homeBack = () => {
        history.push(returnPath);
    };

    const next = () => {
        if (state.step < state.workflow.length) {
            dispatch({
                type: 'SET_NEXT_STEP',
            });
        }
    };

    const back = () => {
        if (state.step > 0) {
            dispatch({
                type: 'SET_BACK_STEP',
            });
        }
    };

    if (!device) {
        history.push(returnPath);
        return null;
    }
    return components[state.workflow[state.step].type](
        {
            // props
            errorMsg: errorMessage,
            title: t('cardTitles$recycleDevice'),
            device: state.device,
            setCurrentPin: (currentPin) =>
                dispatch({
                    type: 'SET_DEVICE',
                    payload: {
                        ...state.device,
                        pin: currentPin,
                        user: state.device.user ? state.device.user : undefined,
                    },
                }),
            setReader: (reader) =>
                dispatch({
                    type: 'SET_READER',
                    payload: reader,
                }),
            reader: state.reader,
        },
        {
            // state
            workflow: state.workflow,
            step: state.step,
            device: state.device,
            reader: state.reader,
            detected: state.detected,
            next,
            showNext: false,
            showBack: state.step === 0,
            back: state.step === 0 ? homeBack : back,
            setDetected: async () =>
                dispatch({
                    type: 'SET_DETECTED',
                }),
            homeBack,
        },
    );
};

export default Recycle;
