import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import CurrentPinWithData from './CurrentPinWithData';
import { useDidMount } from '../../../utils/hooks';

const CurrentPin = ({
    registerForm,
    setCurrentPin,
    serverComponent,
    forgotPin,
}) => {
    const [clearText, setClearText] = useState(false);

    const validator = (value) => (value ? true : '');

    useDidMount(() => {
        if (registerForm) {
            registerForm({
                currentPin: validator,
            });
        }
    });

    const clearTextSwitch = () => {
        setClearText(!clearText);
    };

    return (
        <CurrentPinWithData
            validator={validator}
            setCurrentPin={setCurrentPin}
            clearText={clearText}
            clearTextSwitch={clearTextSwitch}
            serverComponent={serverComponent}
            forgotPin={forgotPin}
        />
    );
};

const { func, object } = PropTypes;
CurrentPin.propTypes = {
    registerForm: func.isRequired,
    setCurrentPin: func.isRequired,
    serverComponent: object,
    forgotPin: func,
};
CurrentPin.defaultProps = {
    serverComponent: {
        details: {
            reset: false,
        },
    },
    forgotPin: () => {
        throw Error('forgotPin was not set when passed to CurrentPin');
    },
};

export default CurrentPin;
