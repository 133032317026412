// Copyright Axiad IDS Inc. 2019. All rights reserved.

const PCSC = (() => {
    const VERSION = "1.7.2";
    let   DEBUG = false;


    /**
     * Create an async Mutex that can optionally guard an Array or Object.
     *
     * Only one context can acquire the Mutex at a time.
     */
    function Mutex(value) {
        // The mutex starts out unlocked -- that is, it will be immediately
        // available for acquisition.
        let lastUnlocked = Promise.resolve();

        function acquire() {
            // Export the resolve function from a new Promise.
            //
            // This promise will be resolved when lastUnlocked is resolved, or
            // in other words when the last holder of this Mutex releases it.
            let acquireLock;
            let lockAcquired = new Promise((resolve) => {
                acquireLock = resolve;
            });

            // Export the resolve function from another new Promise.
            //
            // This promise will be resolved when the Mutex is released again.
            let release;
            let lockReleased = new Promise((resolve) => {
                release = resolve;
            });

            // When the last holder of this Mutex releases it, this caller will
            // receive ownership of it. When it's done, it will then relinquish
            // ownership to the next caller.
            //
            // Since JavaScript isn't multithreaded, this reassignment can be
            // considered atomic. Thus, since this is the only way to access
            // lastUnlocked, each caller will have to wait for the last cycle
            // of lock-then-unlock to complete, and a straight chain will form,
            // giving us the desired qualities of a mutex.
            lastUnlocked = lastUnlocked.then(() => {
                acquireLock({ release, value });

                return (lockReleased);
            });

            return (lockAcquired);
        }

        /**
         * Ensure that users of this Mutex have a hard time messing it up.
         */
        async function acquireThen(fn) {
            let { release, value } = await acquire();

            try {
                if (fn instanceof Promise) {
                    return (await fn);
                } else {
                    return await fn(value);
                }
            } finally {
                release();
            }
        }

        return { acquireThen };
    }


    // -------------------------------------------------------------------------
    // Adapter v2

    // Valid states:
    // - null: Unlocked
    // - undefined: Locked to Axiad Portal Extension 1.4.x or older
    // - "legacy": NOT A REAL STATE. Sentinel value found in Events ONLY.
    // - "<extension-id>": Locked to Axiad Portal Extension 1.5.x or newer
    let lockedExtensionId = null;

    function sendRaw(req) {
        if (lockedExtensionId === null) {
            return Promise.reject({ id: req.id, code: "UNEXPECTED_STATE", msg: "The Axiad Portal Extension was not ready to receive requests.", __donottouchthis_seriously_devnote: "lockedExtensionId was not initialized. Please await PCSC.bindFirstReadyExtension() before attempting to send requests, or report this as a bug." });
        }

        // Ensure that the Portal Extension(s) can identify which one this is
        // addressed to.
        //
        // Explicitly state that newer extensions are not being addressed. Older
        // extensions will ignore the field.
        req.extensionId = (lockedExtensionId === undefined)? "legacy" : lockedExtensionId;

        console.debug("[PC] >>", req);

        let p = new Promise((resolve, reject) => {
            // If talking to Axiad Portal Extension 1.4.x or older, use the
            // traditional event name.
            //
            // Otherwise, use the new event name to prevent older extensions
            // from picking up those events.
            let eventId = (lockedExtensionId === undefined)? "PCSC-in" : "PCSC-in-1.5";
            let event = new CustomEvent(eventId, { detail: JSON.stringify(req) });

            function onEventOut(event) {
                let res = JSON.parse(event.detail);

                // Ignore events from other extensions.
                if (res.extensionId !== lockedExtensionId) {
                    return;
                }

                let ok0 = (res.mid === undefined && res.id === undefined);
                let ok1 = (req.id  !== undefined && req.id === res.id);

                if (ok0 || ok1) {
                    console.debug("[PC] <<", res)

                    if (res.code === undefined)
                        resolve(res);
                    else
                        reject(res);
                    document.removeEventListener("PCSC-out", onEventOut);
                }
            }

            document.addEventListener("PCSC-out", onEventOut);
            document.dispatchEvent(event);
        })
        return timeout(p, 5 * 60000, { code: "TIMEOUT", msg: "Axiad Portal Extension failed to respond in a timely manner." });
    }

    /**
     * Reliably guard requests from being asynchronously sent.
     *
     * The binary in WebPCSC 1.x is not able to handle multiple requests at a
     * time. This guard just enforces that constraint early on.
     */
    let sendReq = (() => {
        let mutex = new Mutex({ id: 0 });

        return (req) => {
            return mutex.acquireThen((guarded) => {
                req.id = (guarded.id += 1);
                return sendRaw(req);
            });
        }
    })()

    // -------------------------------------------------------------------------

    const SW = {
        4: {
            "62a4": "Device locked",
            "62f5": "Default agent locked",
            "62f7": "Device holder locked",
            "6382": "Device key not supported",
            "6383": "Reader key not supported",
            "6384": "Plaintext transmission not supported",
            "6385": "Secured transmission not supported",
            "6386": "Volatile memory is not available",
            "6387": "Non-volatile memory is not available",
            "6388": "Key number not valid",
            "6389": "Key length not correct",
            "63c0": "PIN verification failed (no tries left)",
            "63c1": "PIN verification failed (1 try left)",
            "63c2": "PIN verification failed (2 tries left)",
            "63c3": "PIN verification failed (3 tries left)",
            "63c4": "PIN verification failed (4 tries left)",
            "63c5": "PIN verification failed (5 tries left)",
            "63c6": "PIN verification failed (6 tries left)",
            "63c7": "PIN verification failed (7 tries left)",
            "63c8": "PIN verification failed (8 tries left)",
            "63c9": "PIN verification failed (9 tries left)",
            "63ca": "PIN verification failed (10 tries left)",
            "63cb": "PIN verification failed (11 tries left)",
            "63cc": "PIN verification failed (12 tries left)",
            "63cd": "PIN verification failed (13 tries left)",
            "63ce": "PIN verification failed (14 tries left)",
            "63cf": "PIN verification failed (15 tries left)",
            "6401": "Command timeout. Immediate response required by the device",
            "6500": "Incorrect data",
            "6581": "Memory failure",
            "6600": "Error while receiving (timeout)",
            "6601": "Error while receiving (character parity error)",
            "6983": "Device is blocked.",
            "6984": "Device is blocked.",
            "6a80": "Issuance failed. This may be due to unsupported device or invalid PIN.",
        }
    }

    function b16e(b) {
        let A16 = "0123456789ABCDEF"
        let o = ""

        for (let i = 0; i < b.length; i++) {
            o += A16[(b.charCodeAt(i) & 0xf0) >> 4]
            o += A16[(b.charCodeAt(i) & 0x0f)]
        }

        return o
    }

    function stou(s) {
        let o = [];

        for (let c of s)
            o.push(c.charCodeAt(0));
        return o;
    }

    function timeout(prom, ms, e=new Error("Timed out.")) {
        let tm = new Promise((_, reject) => setTimeout(() => reject(e), ms));

        return Promise.race([prom, tm]);
    }

    // =========================================================================
    // API impl details

    // Detect the card type by comparing the input ATR to a known list.
    function detectSimpleCardType(atr)
    {
        const GEMALTO = [
            "3B7F96000080318065B084413DF6120FFE829000"
        ];
        const YUBIKEY_X = [ // e.g. NEO, 4_NANO, 5C, NANO_FIPS, ...
            // ...
        ];
        const WINDOWS_VSC = [
            "3B8D0180FBA000000397425446590401CF"
        ];

        if (GEMALTO.includes(atr))
            return ("gemalto");
        if (WINDOWS_VSC.includes(atr))
            return ("vsc");

        return ("generic");
    }

    // -------------------------------------------------------------------------
    // Default specialization

    const Generic = {
        async changePin(curr, next)
        {
            if (curr.length < 6 || curr.length > 8 || next.length < 6 || next.length > 8)
                throw { code: "CHANGE_PIN", msg: "Invalid PIN length." }

            let currRaw = stou(curr);
            let nextRaw = stou(next);

            while (currRaw.length < 8)
                currRaw.push(255);
            while (nextRaw.length < 8)
                nextRaw.push(255);

            let cmd = [ 0x00, 0x24, 0x00, 0x80 ];
            let data = currRaw.concat(nextRaw);
            let both = cmd.concat([data.length], data)
            let apdu = btoa(String.fromCharCode(...both));

            function onRes(msg) {
                if (msg.sw !== "9000")
                    throw { code: msg.sw, msg: sw(msg.sw) || "Unexpected SW!" };
            }

            await this.send("AKQEAAugAAADCAAAEAABAA==").then(onRes);
            await this.send(apdu).then(onRes);
        },

        async isLocked()
        {
            let ___ = await this.send("AKQEAAugAAADCAAAEAABAA==")
            let res = await this.send("ACAAgAA=")

            if (res.sw !== undefined) {
                let sw = res.sw.toLowerCase();

                if (["63c0", "6983", "6984"].includes(sw))
                    return true;
                if (["9000"].includes(sw) || sw.startsWith("63c"))
                    return false;
            }
            throw { code: "IS_LOCKED", msg: "Unexpected SW: " + res.sw }
        }
    };


    // -------------------------------------------------------------------------
    // Gemalto specialization

    const Gemalto = {
        async changePin(curr, next)
        {
            if (curr.length + next.length > 254)
                throw { code: "CHANGE_PIN", msg: "PINs exceeded combined maximum length." };

            let currRaw = stou(curr);
            let nextRaw = stou(next);

            let cmd = [ 0x80, 0x24, 0x00, 0x11 ];
            let data = [currRaw.length].concat(currRaw, nextRaw)
            let both = cmd.concat([data.length], data);
            let apdu = btoa(String.fromCharCode(...both));

            function check(msg) {
                if (msg.sw !== "9000")
                    throw { code: msg.sw, msg: sw(msg.sw) || "Unexpected SW!" };
            }

            await this.send("AKQEAA2gAAAAGIAAAAAGYkFR").then(check);
            await this.send(apdu).then(check);
        },

        async isLocked()
        {
            let ___ = await this.send("AKQEAA2gAAAAGIAAAAAGYkFR");
            let res = await this.send("ACAAEQA=");

            if (res.sw !== undefined) {
                let sw = res.sw.toLowerCase();

                if (["63c0", "6983", "6984"].includes(sw))
                    return true;
                if (["9000"].includes(sw) || sw.startsWith("63c"))
                    return false;
            }
            throw { code: "IS_LOCKED", msg: "Unexpected SW: " + res.sw }
        }
    };


    // -------------------------------------------------------------------------
    // Windows Virtual Smart Card specialization

    const WindowsVSC = {
        async changePin(curr, next)
        {
            if (curr.length < 8 || curr.length > 127 || next.length < 8 || next.length > 127)
                throw { code: "CHANGE_PIN", msg: "Invalid PIN length." }

            let currRaw = stou(curr);
            let nextRaw = stou(next);

            let cmd = [ 0x00, 0x24, 0x00, 0x80 ];
            let data = currRaw.concat(nextRaw);
            let both = cmd.concat([data.length], data)
            let apdu = btoa(String.fromCharCode(...both));

            function onRes(msg) {
                if (msg.sw !== "9000")
                    throw { code: msg.sw, msg: sw(msg.sw) || "Unexpected SW!" };
            }

            await this.send("AKQEAAmgAAADl0JURlkA").then(onRes);
            await this.send(apdu).then(onRes);
        }
    };

    // -------------------------------------------------------------------------
    // Windows Hello for Business Card specialization

    const WindowsWHFB = {
        async changePin()
        {
            // CMD to change the PIN for WHFB is 9024008000
            let cmd = [ 0x90, 0x24, 0x00, 0x80 ];
            let apdu = btoa(String.fromCharCode(...cmd));

            function onRes(msg) {
                if (msg.sw !== "9000")
                    throw { code: msg.sw, msg: sw(msg.sw) || "Unexpected SW!" };
            }

            await this.send(apdu).then(onRes);
        },
    };

    // =========================================================================
    // API

    // NOTE: When a method takes an optional card_type, it's a specialized
    //       helper. One can specify a particular implementation if so desired.
    //       By default, the most suitable known specialization will be chosen.
    function Reader(handle, detected_card_type) {

        return {
            // -----------------------------------------------------------------
            // Core functionality

            async info() /* -> {atr} */ {
                return sendReq({ op: "info", handle })
            },

            async send(apdu) /* -> {apdu, sw} */ {
                return sendReq({ op: "send", handle, apdu })
            },

            async disconnect() {
                if (handle === "intentionally-invalid") {
                    return;
                }
                return sendReq({ op: "disc", handle })
            },


            // -----------------------------------------------------------------
            // Helpers: Getters

            async bap() /* -> "bap" */ {
                let init = await this.send("AKQEAAA=")
                let bap = await this.send("gMoA7gA==")

                bap = atob(bap.apdu)

                return b16e(bap.substr(7, 3))
            },

            async cuid() /* -> "cuid" */ {
                let init = await this.send("AKQEAAA=")
                let cplc = await this.send("gMqffwA=")

                cplc = atob(cplc.apdu)

                if (cplc.length === 47)
                    cplc = cplc.slice(0, -2)
                if (cplc.length === 45)
                    cplc = cplc.slice(3)

                let fab = cplc.substr(0, 2)
                let typ = cplc.substr(2, 2)
                let bid = cplc.substr(16, 2)
                let ser = cplc.substr(12, 4)

                return b16e(fab + typ + bid + ser)
            },

            async isLocked(card_type = detected_card_type) /* -> Boolean, throws Error */ {
                if (card_type === "gemalto")
                    return await Gemalto.isLocked.call(this);
                else
                    return await Generic.isLocked.call(this);
            },

            cardType() /* -> String */ {
                return (detected_card_type);
            },

            // -----------------------------------------------------------------
            // Helpers: Actions

            async changePin(curr, next, card_type = detected_card_type) /* throws Error */ {
                if (card_type === "gemalto")
                    await Gemalto.changePin.call(this, curr, next);
                else if (card_type === "vsc")
                    await WindowsVSC.changePin.call(this, curr, next);
                else if (card_type === 'whfb')
                    await WindowsWHFB.changePin.call(this);
                else
                    await Generic.changePin.call(this, curr, next);
            },

            /* deprecated */ async changePivPin(curr, next) {
                await this.changePin(curr, next)
            }
        }
    }


    // Protected by extensionEvents.
    let extensionEventHandlers = [];
    let extensionEvents = new Mutex([]);
    let extensionStatus = new Mutex({});
    let extensionsCount = 0;


    /**
     * Append the event to the queue, or hand it off to all event handlers.
     *
     * If you're already holding extensionStatus, pass it into standIns.
     */
    let pushExtensionEvent = (event, standIns) => {
        let main = async (statuses) => {
            let { extensionId } = event;

            statuses[extensionId] = {
                seenAt: event.seenAt,
                status: event.status
            };

            return extensionEvents.acquireThen(async (events) => {
                if (extensionEventHandlers.length === 0) {
                    events.push(event);
                } else {
                    let [ handler ] = extensionEventHandlers.splice(0, 1);

                    try {
                        await handler(event);
                    } catch (e) {
                        console.error("An error occurred while handing an event", event, ":", e);
                    }
                }
            });
        };

        if (standIns !== undefined && "extensionStatus" in standIns) {
            return main(standIns.extensionStatus);
        } else {
            return extensionStatus.acquireThen(main);
        }
    };


    /**
     * Get the state of every Axiad Portal Extension instance detected passively
     * by the SDK.
     *
     * Known states as of WebPCSC 1.5, 2021-03-18:
     * - "present" -- Instance declared its presence, but is not yet available.
     * - "idle" -- Instance did not move from "present" in ~1.5s.
     * - "ready" -- Instance is ready to service requests.
     * - "unavailable" -- Instance refused service.
     */
    async function extensions()/* -> { "<id>": { state: String, ... } }*/ {
        return await extensionStatus.acquireThen((state) => {
            let copy = {};

            for (let key of Object.keys(state)) {
                copy[key] = { ...state[key] };
            }
            return (copy);
        });
    }


    /**
     * Get the next available extension-state update.
     *
     * Events produced by this function are Objects which contain a "status"
     * key:
     *
     * - "present": The extension has declared its presence.
     * - "idle": The extension did not transition from "present" quickly enough.
     *   It might be broken or waiting for user input.
     * - "ready": The extension is available and can be successfully bound.
     * - "unavailable": The extension has refused service and cannot be bound.
     *
     * All events also have a "seenAt" key which denotes when it occurred, and
     * an "extensionId" which denotes which extension the event is referring to.
     *
     * Note that extensions older than 1.5 will all identify as undefined.
     *
     * TODO: Track and expose canonical event history?
     */
    let nextExtensionEvent = (() => {
        let mutex = new Mutex();

        function nextExtensionEvent() {
            return new Promise((resolve, reject) => {
                extensionEvents.acquireThen(async (events) => {
                    if (events.length === 0) {
                        // Await the next event, which will supersede the
                        // queue and be given directly to this handler.
                        extensionEventHandlers.push((event) => {
                            resolve(event);
                        });
                    } else {
                        // Use an event from the front of the queue.
                        resolve(events.shift());
                    }
                });
            });
        }

        return () => mutex.acquireThen(() => nextExtensionEvent());
    })();


    /**
     * Lock communications to a specific extension ID, usually discovered by
     * calling nextExtensionEvent.
     *
     * This operation is mandatory.
     *
     * Legacy extensions all share an (advertised) extensionId of undefined.
     * This system cannot disambiguate between many Axiad Portal Extensions
     * older than 1.5. However, it *can* disambiguate between one legacy
     * extension and zero or more newer extensions.
     */
    function bindExtension(extensionId, explicitlyRebind = false) {
        if (lockedExtensionId !== null && !explicitlyRebind) {
            throw new Error("Attempted to call bindExtension when already bound!");
        }

        lockedExtensionId = extensionId;
    }


    /**
     * An easy manager over nextExtensionEvent and bindExtension that binds the
     * first ready extensionId.
     *
     * Resolves with the first extension ID to become ready.
     *
     * If no extensions were ready in time, may reject with an object containing
     * the number of extensions which have declared their presence.
     *
     * This blindly waits for the first "ready" event, and will not respect
     * future events that will indicate whether or not the extension is waiting
     * for user consent.
     *
     * Call this if you don't care which extension you get.
     */
    function bindFirstReadyExtension(timeout = 3000) {
        if (lockedExtensionId !== null) {
            return Promise.resolve(lockedExtensionId);
        }

        console.log("Binding first ready extension... (Timeout: %dms)", timeout);
        return new Promise(async (resolve, reject) => {
            setTimeout(() => reject({ extensionsCount }), timeout);

            while (true) {
                let event = await nextExtensionEvent();

                console.log(event)

                if (event.status === "ready") {
                    bindExtension(event.extensionId);
                    resolve(event.extensionId);
                    return;
                }
            }
        });
    }


    function onExtensionDetected(event) {
        let eventDetail = event.detail || {};
        let extensionId = eventDetail.extensionId;

        console.info("Axiad Portal Extension ID", extensionId, "is present.");
        extensionsCount += 1;
        pushExtensionEvent({
            seenAt: (new Date().valueOf()) / 1000,
            status: "present",
            extensionId
        });
    }


    function onExtensionAccepted(event) {
        let eventDetail = event.detail || {};
        let extensionId = eventDetail.extensionId;

        console.info("Axiad Portal Extension ID", extensionId, "is ready.");
        pushExtensionEvent({
            seenAt: (new Date().valueOf()) / 1000,
            status: "ready",
            extensionId
        });
    }


    function onExtensionRejected(event) {
        let eventDetail = event.detail || {};
        let extensionId = eventDetail.extensionId;

        console.warn("Axiad Portal Extension ID", extensionId, "refused service!");
        pushExtensionEvent({
            seenAt: (new Date().valueOf()) / 1000,
            status: "unavailable",
            extensionId
        });
    }


    document.addEventListener("PCSC", onExtensionDetected);
    document.addEventListener("PCSC-ok", onExtensionAccepted);
    document.addEventListener("PCSC-ko", onExtensionRejected);


    // Seconds before a "present" extension is transitioned to "idle".
    let extensionIdleTime = 1.5;

    /**
     * Prevent pending extensions from going idle for too long.
     */
    function eventWatchdog() {
        extensionStatus.acquireThen(async (extensionStatus) => {
            for (let extensionId of Object.keys(extensionStatus)) {
                // Using Object as a dictionary converts keys to Strings
                if (extensionId === "undefined") {
                    extensionId = undefined;
                }

                let { seenAt, status } = extensionStatus[extensionId];
                let now = (new Date().valueOf()) / 1000;

                if (status === "present" && now - seenAt > extensionIdleTime) {
                    // Mark extensions that have been "present" for 1.5 seconds
                    // with no further activity as "idle".
                    console.info("Axiad Portal Extension ID", extensionId, "is idle.");
                    await pushExtensionEvent({
                        seenAt: (new Date().valueOf()) / 1000,
                        status: "idle",
                        extensionId
                    }, { extensionStatus });
                }
            }

            setTimeout(eventWatchdog, 500);
        });
    }

    setTimeout(eventWatchdog, 500);


    /**
     * Shim to utilize the Electron-compatible transport for the usual events.
     *
     * Since the Electron side doesn't directly interact with the DOM, this end
     * creates all events on its behalf, including the signalling events for
     * present and ready.
     */
    if (window["Electron"] !== undefined) {
        document.dispatchEvent(new Event("PCSC"));

        let connected = window["Electron"].connect((msg) => {
            // Handler for every message coming from the extension
            document.dispatchEvent(new CustomEvent("PCSC-out", { detail: JSON.stringify(msg) }));
        }).then((connection) => {
            document.dispatchEvent(new Event("PCSC-ok"));

            // Handler for every message going to the extension
            document.addEventListener("PCSC-in", async (event) => {
                await connection.send(JSON.parse(event.detail));
            });
        }, (e) => {
            document.dispatchEvent(new Event("PCSC-ko"));
        });
    }

    async function list() /* -> ["reader1", ...] */ {
        return sendReq({ op: "list" }).then((res) => {
            return res.readers
        })
    }

    async function connect(name, exclusive = false) /* -> Reader */ {
        console.debug("Connecting to reader: \"" + name + "\"");

        if (name === "intentionally-invalid") {
            console.warn("Requested intentionally invalid Reader connection.");
            return Reader(name, undefined);
        }

        let handle = (await sendReq({ op: "conn", name, exclusive })).handle;
        let atr = (await sendReq({ op: "info", handle, purpose: "Autodetect card type" })).atr;
        let type = await detectSimpleCardType(atr);

        console.debug("Connected to reader: \"" + name + "\" (" + handle + ") with ATR: " + atr + " and specialization: " + type);

        return Reader(handle, type);
    }

    async function connectWithFallback(name, maxTries = 9) /* -> Reader */ {
        if (typeof maxTries !== "number") {
            throw new Error("Incorrect usage: connectWithFallback(name: string, maxTries: number)")
        }
        for (let i = 0; i < maxTries; i += 1) {
            try {
                return await this.connect(name, true)
            } catch (e) {
                if (e.code === "0x8010000B") {
                    console.warn("Another process has a lock on the requested reader; retrying soon... (" + (i + 1) + "/" + maxTries + ")")
                    await new Promise(resolve => setTimeout(resolve, 500))
                    continue
                }
                throw e
            }
        }
        console.warn("Falling back to shared mode...")
        return this.connect(name, false)
    }

    async function version() /* -> {app, ext, js} */ {
        return sendReq({ op: "qver" }).then(i => {
            return {
                js: VERSION,
                ext: i.ext,
                app: i.app
            }
        })
    }

    // -------------------------------------------------------------------------
    // Helpers

    /**
     * Detect a single new reader.
     */
    async function detect(delay = 1000) /* -> "reader" */ {
        let names = await list()
        let t = null

        return new Promise((resolve, reject) => {
            async function check() {
                let names2 = await list()
                let namesD = []

                for (let n of names2)
                    if (!names.includes(n))
                        namesD.push(n)

                if (namesD.length > 0) {
                    resolve(namesD[0])
                    clearInterval(t)
                    t = null
                }

                names = names2
            }

            t = setInterval(check, delay)
        })
    }

    /**
     * Detect any un/plugged readers.
     *
     * If deep == true, changes in reader ATR will also be tracked.
     *
     * fn: Function(added, removed[, modified, allReaders])
     */
    function onChange(fn, deep = false, delay = 1000) /* -> Interval */ {
        var rd1 = {}
        let firstRun = true;

        async function arrToObj(arr) {
            let o = {}

            for (let i = 0; i < arr.length; i++) {
                let atr = null

                if (deep) {
                    let rd = null

                    try {
                        rd = await connect(arr[i])
                        atr = await rd.info().then(i => i.atr)
                    } catch (e) {
                        if (["LOCK", "0x8010000B"].includes(e.code))
                            atr = "IN_USE";
                        else if (!["0x8010000C", "0x80100069"].includes(e.code))
                            atr = "ERROR"
                    } finally {
                        if (rd !== null)
                            rd.disconnect()
                    }
                }
                o[arr[i]] = atr;
            }
            return (o)
        }

        async function check() {
            let rd2 = null

            try {
                let ls = await list()
                rd2 = await arrToObj(ls)
            } catch (e) {
                throw e
            }


            let add = []
            let rem = []
            let mod = {}

            Object.keys(rd2).forEach((k) => {
                if (!rd1.hasOwnProperty(k))
                    add.push(k)
                if (rd2[k] != rd1[k])
                    mod[k] = rd2[k]
            })

            Object.keys(rd1).forEach((k) => {
                if (!rd2.hasOwnProperty(k))
                    rem.push(k)
                if (rd1[k] != rd2[k])
                    mod[k] = rd2[k]
            })

            if (add.length || rem.length || Object.keys(mod).length || firstRun) {
                firstRun = false;
                await fn(add, rem, mod, rd2)
            }

            rd1 = rd2
        }

        function createInst() {
            let onDone = new Promise((resolve) => resolve());
            let plsStop = false;

            function mgr() {
                if (!plsStop) {
                    onDone = check().finally(() => {
                        if (!plsStop) {
                            setTimeout(mgr, delay);
                        }
                    });
                }
            }

            return {
                start() {
                    setTimeout(mgr, delay);
                },

                stop() {
                    plsStop = true;
                    return onDone;
                }
            };
        }

        var instance = createInst();

        instance.start();

        return {
            start() {
                if (instance === null) {
                    instance = createInst();
                    instance.start();
                }
                return (this);
            },

            stop() {
                if (instance !== null) {
                    let o = instance.stop();

                    instance = null;
                    return o.then(() => this);
                }
                return new Promise((resolve) => resolve(this));
            },

            trigger() {
                return this.stop()
                    .then(check)
                    .then(this.start)
                    .then(() => this);
            }
        }
    }

    /**
     * Get a human-readable error message for a given sw1+sw2 byte pair.
     *
     * Ex: sw("62A4") -> "Card locked"
     */
    function sw(sw) /* -> "Error Message" */ {
        if (!(typeof sw === "string"))
            return null

        for (let i = 4; SW[i]; i--) {
            let key1 = sw.slice(0, i).toUpperCase()
            let key2 = sw.slice(0, i).toLowerCase()

            if (key1 in SW[i])
                return SW[i][key1]
            if (key2 in SW[i])
                return SW[i][key2]
        }
    }


    /**
     * Diagnose common issues with the PCSC system. Throw errors to signify these issues.
     *
     * - Throws { marker, diagnoses: [{ cause, solution }] } when an **expected** issue is detected.
     * - When marker === "AC_AWAIT", prompt the user to allow Axiad Portal Extension before trying diagnose() again.
     * - When diagnoses.length === 1, consider the diagnosis "as certain as possible."
     * - When solution === undefined, consider it to be "ask your admin/support for help."
     * - If no solution works for the user, tell them to fall back to their admin or tech support.
     *
     * TODO: Show minimum version of each browser when bowser check fails
     */
    let diagnose = (() => {
        const SUPPORT = {
            os: ["Win32", "Win64", "MacIntel"],
            browser: { chrome: "55", /*firefox: "60",*/ msedge: "16" }
        };

        const DIAG = {
            OS: [{ cause: "Unsupported operating system" }],
            BROWSER: [{ cause: "Unsupported browser", solution: "Switch to Google Chrome" }],
            NO_BRIDGE: [
                { cause: "Axiad Portal Extension is not installed", solution: "Install the Axiad Portal Extension" },
                { cause: "Axiad Portal Extension is not enabled", solution: "Enable the Axiad Portal Extension" },
                { cause: "On a non-HTTPS website" }
            ],
            AC_AWAIT: [{ cause: "Did not receive access to Axiad Portal Extension", solution: "Click \"ALLOW\" or \"ALWAYS\" when prompted" }],
            AC_DENIED: [
                { cause: "Access denied by user", solution: "Click \"ALLOW\" or \"ALWAYS\" when prompted" },
                { cause: "Access denied by administrator" }
            ],
            TIMEOUT: [
                { cause: "Axiad Portal Extension is not responding", solution: "Upgrade Axiad Portal Extension to 1.1.0 or greater" },
                { cause: "Axiad Portal Extension is not responding" }
            ],
            NO_HOST: [
                { cause: "Native app not installed", solution: "Run the WebPCSC installer" },
                { cause: "Invalid native manifest", solution: "Re-run the WebPCSC installer" }
            ],
            FORBIDDEN: [{ cause: "Native manifest does not list your Bridge", solution: "Re-run the WebPCSC installer" }],
            EXEC_FAIL: [{ cause: "Native app is corrupted", solution: "Re-run the WebPCSC installer" }],
            BAD_PROTO: [{ cause: "Native app is corrupted or invalid", solution: "Re-run the WebPCSC installer" }],
            CLOSED: [{ cause: "Native app is corrupted or invalid", solution: "Re-run the WebPCSC installer" }],
            NO_PCSC: [{ cause: "The PCSC service is offline" }],
            NO_PCSC_2: [{ cause: "The PCSC service is disabled", solution: "Enable the PCSC service" }]
        }

        if (navigator.platform.startsWith("Win"))
        {
            DIAG.NO_PCSC = [
                { cause: "The \"Smart Card\" service is offline", solution: "Insert a token or update to 1.2.0+" },
                { cause: "The \"Smart Card\" service is disabled", solution: "Enable the \"Smart Card\" service" }
            ]
            DIAG.NO_PCSC_2 = [{ cause: "The \"Smart Card\" service is disabled", solution: "Enable the \"Smart Card\" service" }]
            // DIAG.BROWSER[0].solution += ", Mozilla Firefox";
            DIAG.BROWSER[0].solution += " or Microsoft Edge";
        }

        if (!navigator.platform.startsWith("Win"))
        {
            DIAG.EXEC_FAIL.push({ cause: "Native app missing required permissions", solution: "chmod +x the native app" });
            // DIAG.BROWSER[0].solution += " or Mozilla Firefox";
        }

        DIAG.BROWSER[0].solution += ", or update your browser"

        function E(marker) {
            if (DIAG[marker] === undefined)
                throw new Error(marker + ": Not implemented!");
            return { marker, diagnoses: DIAG[marker] };
        }

        return async function diagnose(support=SUPPORT) {
            if (navigator.platform !== undefined) {
                if (!support.os.includes(navigator.platform))
                    throw E("OS");
            } else {
                console.warn("Cannot assert OS: navigator.platform is undefined");
            }

            if (window.bowser !== undefined) {
                if (!window.bowser.check(support.browser))
                    throw E("BROWSER");
            } else {
                console.warn("Cannot assert browser version: 'bowser' 1.x is unavailable");
            }

            // TODO: Update this to account for the new API
            //await timeout(present, 1000, E("NO_BRIDGE"));
            await new Promise(async (resolve, reject) => {
                  for (let i = 0; i < 9; i += 1) {
                       let event = await extensions();
                        console.log(event)
                        // throw exception if no extension id found inside event object
                        if(Object.keys(event).length === 0
                          || Object.values(event).filter((i) => i.status === 'ready').length === 0) {
                           setTimeout(() => reject(E("AC_AWAIT")), 1000);
                        } else if (Object.keys(event).length > 0) {
                                   resolve(event);
                                   return;
                        }
                  }
            });

            await timeout(sendReq({ "op": "qver" }), 7000).catch(e => {
                if (e.message === "Timed out.")
                {
                    console.debug("Faulting operation: qver (7000)");
                    throw E("TIMEOUT");
                }
                if (e.msg !== undefined)
                    console.error(`${e.msg} (${e.code})`);
                if (e.code === "NATIVE") {
                    if (e.msg === "Specified native messaging host not found.")
                        throw E("NO_HOST");
                    if (e.msg === "Native messaging host com.axiadids.pcsc is not registered.")
                        throw E("NO_HOST");
                    if (e.msg === "Access to the specified native messaging host is forbidden.")
                        throw E("FORBIDDEN");
                    if (e.msg === "Failed to start native messaging host.")
                        throw E("EXEC_FAIL");
                    if (e.msg === "Error when communicating with the native messaging host.")
                        throw E("BAD_PROTO");
                    if (e.msg === "Native host has exited.")
                        throw E("CLOSED");
                }
                if (e.code === "0x8010001D")
                    throw E("NO_PCSC");
                if (e.code === "NO_PCSC")
                    throw E("NO_PCSC_2");
                throw e;
            });

            await timeout(sendReq({ "op": "list" }), 7000).catch(e => {
                if (e.message === "Timed out.")
                {
                    console.debug("Faulting operation: list (7000)");
                    throw E("TIMEOUT");
                }
                if (e.msg !== undefined)
                    console.error(`${e.msg} (${e.code})`);
                if (e.code === "0x8010001D")
                    throw E("NO_PCSC");
                if (e.code === "NO_PCSC")
                    throw E("NO_PCSC_2");
                throw e;
            });
        }
    })();

    // =========================================================================
    // Axiad Virtual Smart Card Service

    /**
     * Request whether a feature is supported by all necessary components.
     *
     * Expects one of the following features:
     *
     * - "vsc" -- TPM-backed Virtual Smart Cards
     * - "whfb" -- Windows Hello for Business
     *
     * Returns true if the feature is supported, or false otherwise.
     */
    async function supports(feature) {
        let response;

        try {
            response = await sendReq({ op: "sfet", feature }).then(v => v, e => {
                // Fall back to "svsc" on WebPCSC 1.4.x
                if ((e.code === "???" || e.code === "CMD") && feature === "vsc") {
                    return sendReq({ op: "svsc" });
                } else {
                    throw (e);
                }
            });
        } catch (e) {
            // Error codes for "invalid operation" from the v1 extension and binary
            if (e.code == "???" || e.code == "CMD") {
                return (false);
            }

            // Error codes that denote an explicit but ignorable problem
            //
            // (Un-suppress these if useful in the future)
            if (e.code === "vsc:unsupported_os" || e.code === "vsc:not_installed") {
                return (false);
            }
            if (e.code === "HRESULT 0x80090030") {
                return (false);
            }
            if (e.code === "whfb:unsupported_os" || e.code.startsWith("whfb:not_available/")) {
                return (false);
            }

            throw e;
        }

        return (response.supported);
    }

    /**
     * Request whether a feature is supported by all necessary components.
     *
     * Expects one of the following features:
     *
     * - "vsc" -- TPM-backed Virtual Smart Cards
     * - "whfb" -- Windows Hello for Business
     *
     * Returns either a feature interface or null if not supported.
     */
    async function getFeature(feature) {
        if (await supports(feature)) {
            if (feature === "vsc") {
                return { create: createVirtualSmartCard };
            }
            if (feature === "whfb") {
                return { create: provisionWindowsHelloForBusiness };
            }
            throw {
                code: "sdk:bug",
                msg: "Unhandled feature '" + feature + "' in getFeature!"
            };
        }
        return (null);
    }


    /**
     * Attempt to create a TPM-backed Virtual Smart Card by having the binary
     * cooperate with the Axiad Virtual Smart Card Service.
     *
     * Returns the Instance ID string as from ITpmVirtualSmartCardManager::
     * CreateVirtualSmartCard.
     */
    async function createVirtualSmartCard() {
        let response = await sendReq({ op: "cvsc" });

        return (response.instanceId);
    }


    /**
     * Attempt to provision a Windows Hello for Business key to be used in future
     * operations.
     */
    async function provisionWindowsHelloForBusiness(replace = true) {
        await sendReq({ op: "cwhf", replace: replace });
    }


    // =========================================================================

    // Ensure older early-init scripts fail fast.
    if ("pcsc-present" in window || "pcsc-ready" in window) {
        throw new Error("This invocation of the SDK is outdated. Please find this error in the SDK for help.");

        // Use the below early-init script instead of your old one.
        /*
        window['axiad-portal-extension-events'] = [];

        let handler = (event) => window['axiad-portal-extension-events'].push(event);

        document.addEventListener('PCSC', handler);
        document.addEventListener('PCSC-ok', handler);
        document.addEventListener('PCSC-ko', handler);
        */
    }

    // Repeat events that were captured before the SDK was initialized.
    if ("axiad-portal-extension-events" in window) {
        for (let event of window["axiad-portal-extension-events"]) {
            if (event.type === "PCSC") {
                onExtensionDetected(event);
            } else if (event.type === "PCSC-ok") {
                onExtensionAccepted(event);
            } else if (event.type === "PCSC-ko") {
                onExtensionRejected(event);
            }
        }
    }


    return {
        extensions,
        nextExtensionEvent,
        bindExtension,
        bindFirstReadyExtension,

        connect,
        connectWithFallback,
        list,
        version,

        detect,
        onChange,
        sw,
        diagnose,

        supports,
        getFeature,

        // Deprecated
        createVirtualSmartCard,

        debug() {
            DEBUG = true;
        }
    }

})()

export default PCSC;