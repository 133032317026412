/* eslint-disable react/no-array-index-key */
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Col, Dropdown } from 'reactstrap';
import {
    PaddingButton,
    BoldCol,
    StyledButtonMarginBefore,
    StyledNavIcon,
    ColAlignRight,
    FullWidthSelectInput,
    DropdownToggleNoStyle,
    StyledDropdownMenuSelect,
    StyledDropdownItemSelect,
    FullWidthInput,
} from '../../../styles/common';
import { ReportsDispatchContext, ReportsStateContext } from '../../../context/ReportContext';

const AdvancedSearchBarWithData = ({
    filters = [],
    modifySearchAllowed = false,
    alwaysModifiableIds = null,
    valueValidator = () => true,
}) => {
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const searchBarDispatch = useContext(ReportsDispatchContext);
    const { rules } = useContext(ReportsStateContext);
    const { t } = useTranslation();

    const ruleRows = rules.map((r, id) => {
        const firstRow = id === 0;
        const multipleRows = rules.length > 1;
        const rType = rules[id] && rules[id].type;
        const rOperations = rules[id] && rules[id].operations;
        const defaultValues = filters.find((fil) => fil.name === r.name).values;
        return (
            <FormGroup
                key={`${id}-${r.name}-${r.logicalOperator}-${r.operation}`}
                row
                autoComplete="off"
            >
                <Col xs="3" />
                <Col xs="1">
                    {!firstRow && (
                        <FullWidthSelectInput
                            type="select"
                            bsSize="sm"
                            value={r.logicalOperator}
                            onChange={(e) =>
                                searchBarDispatch({
                                    type: 'SET_SEARCH_FILTER',
                                    id,
                                    target: 'logicalOperator',
                                    value: e.target.value,
                                })
                            }
                            disabled={!modifySearchAllowed}
                        >
                            {['and', 'or'].map((lop) => (
                                <option key={`${id}-${lop}`} value={lop}>
                                    {t(`searchBar$mode$${lop}`).toUpperCase() ||
                                        lop.toUpperCase()}
                                </option>
                            ))}
                        </FullWidthSelectInput>
                    )}
                </Col>
                <Col xs="2">
                    <FullWidthSelectInput
                        type="select"
                        bsSize="sm"
                        value={r.name}
                        onChange={(e) =>
                            searchBarDispatch({
                                type: 'SET_SEARCH_FILTER',
                                id,
                                target: 'searchType',
                                value: filters.find(
                                    (fil) => fil.name === e.target.value,
                                ),
                            })
                        }
                        disabled={!modifySearchAllowed}
                    >
                        {filters.map((fil) => (
                            <option key={`${id}-${fil.name}`} value={fil.name}>
                                {t(`searchBar$keyword$${fil.name}`) || fil.name}
                            </option>
                        ))}
                    </FullWidthSelectInput>
                </Col>
                <Col xs="2">
                    <FullWidthSelectInput
                        type="select"
                        bsSize="sm"
                        value={r.operation}
                        onChange={(e) =>
                            searchBarDispatch({
                                type: 'SET_SEARCH_FILTER',
                                id,
                                target: 'operation',
                                value: e.target.value,
                            })
                        }
                        disabled={!modifySearchAllowed}
                    >
                        {rOperations &&
                            rOperations.map((op) => (
                                <option key={`${id}-${op}`} value={op}>
                                    {t(`searchBar$operation$${op}`) || op}
                                </option>
                            ))}
                    </FullWidthSelectInput>
                </Col>
                <Col xs="2">
                    {modifySearchAllowed || alwaysModifiableIds.includes(id) ? (
                        <Dropdown
                            isOpen={id === openDropdownId}
                            toggle={() =>
                                setOpenDropdownId(
                                    id === openDropdownId ? null : id,
                                )
                            }
                        >
                            <DropdownToggleNoStyle>
                                <FullWidthInput
                                    bsSize="sm"
                                    type={
                                        rType === 'datetime' ? 'date' : 'text'
                                    }
                                    value={r.values[0]}
                                    valid={
                                        !!r.values[0] &&
                                        valueValidator(r.values[0])
                                    }
                                    invalid={
                                        !!r.values[0] &&
                                        !valueValidator(r.values[0])
                                    }
                                    list="valuesList"
                                    onChange={(e) =>
                                        searchBarDispatch({
                                            type: 'SET_SEARCH_FILTER',
                                            id,
                                            target: 'values',
                                            value: [e.target.value],
                                        })
                                    }
                                />
                            </DropdownToggleNoStyle>
                            {defaultValues && defaultValues.length > 0 && (
                                <StyledDropdownMenuSelect>
                                    {defaultValues &&
                                        defaultValues.map((dv) => (
                                            <StyledDropdownItemSelect
                                                key={`${id}-${dv}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    searchBarDispatch({
                                                        type: 'SET_SEARCH_FILTER',
                                                        id,
                                                        target: 'values',
                                                        value: [dv],
                                                    });
                                                    setOpenDropdownId(null);
                                                }}
                                            >
                                                {dv}
                                            </StyledDropdownItemSelect>
                                        ))}
                                </StyledDropdownMenuSelect>
                            )}
                        </Dropdown>
                    ) : (
                        r.values[0]
                    )}
                </Col>
                {modifySearchAllowed && (
                    <ColAlignRight xs="2">
                        <PaddingButton
                            outline
                            color="primary"
                            size="sm"
                            disabled={!multipleRows}
                            onClick={() =>
                                searchBarDispatch({ type: 'REMOVE_RULE', id })
                            }
                        >
                            <StyledNavIcon icon="minus-circle" />
                            {t('formLabels$question$delete')}
                        </PaddingButton>
                        <StyledButtonMarginBefore
                            outline
                            color="primary"
                            size="sm"
                            onClick={() =>
                                searchBarDispatch({ type: 'ADD_RULE', id })
                            }
                        >
                            <StyledNavIcon icon="plus-circle" />
                            {t('formLabels$question$add')}
                        </StyledButtonMarginBefore>
                    </ColAlignRight>
                )}
            </FormGroup>
        );
    });

    return (
        <>
            <FormGroup row>
                <Col xs="3" />
                <BoldCol xs="1">{t('searchBar$labels$mode')}</BoldCol>
                <BoldCol xs="2">{t('searchBar$labels$keyword')}</BoldCol>
                <BoldCol xs="2">{t('searchBar$labels$operation')}</BoldCol>
                <BoldCol xs="3">{t('searchBar$labels$value')}</BoldCol>
            </FormGroup>
            {ruleRows}
        </>
    );
};

export default AdvancedSearchBarWithData;
