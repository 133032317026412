import React, { useState } from 'react';
import { Col, Row, Progress, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { ResponsiveLine } from '@nivo/line'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import styled from 'styled-components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import CardTemplate from '../../shared/CardTemplate';
import { PortalContainer } from '../../../styles/common';



const InsightsEnrolledIcon = styled(FontAwesomeIcon)`
    font-size: 1em;
    margin-right: 10px;
    color:green;
`;

const InsightsNotEnrolledIcon = styled(FontAwesomeIcon)`
    font-size: 1em;
    margin-right: 10px;
    color:red;
`;

const InsightsDownloadIcon = styled(FontAwesomeIcon)`
    font-size: 1em;
`;
const InsightsInfoIcon = styled(FontAwesomeIcon)`
    font-size: 1em;
`;

export const InsightsEnrollmentText = styled.div`
    font-size:25px;
    font-weight:500;
`;

export const InsightsGraphText = styled(Col)`
    font-weight:500;
    font-size:15px;
    margin: 15px 0;
`;

export const InsightsGraphNumber = styled(Col)`
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:500;
`;

export const InsightsGraphActions = styled(Col)`
    display:flex;
    justify-content:flex-end;
    align-items:center;
    gap:10px;
    margin: 15px 0;
`;

export const StyledSpan = styled.span`
    margin: 5px;
`;

const toggleStyle = {
    lineHeight: '.5rem',
    fontSize: '10px',
    background: '#eef1f3',
    color: '#171818',
    border: 'none',
    borderRadius: '0px',
    padding: '5px'
}


const riskyAccountData = [
    {
        "id": "demo1",
        "color": "#ADD8E6",
        "data": [
            {
                "x": "Monday",
                "y": 151
            },
            {
                "x": "Tuesday",
                "y": 106
            },
            {
                "x": "Wednesday",
                "y": 34
            },
            {
                "x": "Thrusday",
                "y": 264
            },
            {
                "x": "Friday",
                "y": 275
            },
            {
                "x": "Saturday",
                "y": 44
            },
            {
                "x": "Sunday",
                "y": 6
            }
        ]
    }
]

const expiringCertData = [
    {
        "id": "demo2",
        "color": "#ADD8E6",
        "data": [
            {
                "x": "Monday",
                "y": 130
            },
            {
                "x": "Tuesday",
                "y": 20
            },
            {
                "x": "Wednesday",
                "y": 3
            },
            {
                "x": "Thrusday",
                "y": 106
            },
            {
                "x": "Friday",
                "y": 161
            },
            {
                "x": "Saturday",
                "y": 206
            },
            {
                "x": "Sunday",
                "y": 23
            }
        ]
    }
]

const theme = {
    axis: {
        domain: {
            line: {
                stroke: '#000',
                strokeWidth: 2,
            }
        }
    }
}

const generateGraph = (data: any, enableArea: boolean) => (
    <div style={{ height: "200px" }}>
        <ResponsiveLine
            data={data}
            margin={{ top: 50, right: 10, bottom: 50, left: 30 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 0,
                max: 'auto',
                stacked: true,
                reverse: false
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                format: () => '',
            }}
            axisLeft={{
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                format: () => '',
            }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            enableGridX={false}
            enableGridY={false}
            enableCrosshair={false}
            enablePoints={false}
            enableArea={enableArea}
            curve="monotoneX"
            theme={theme}
            colors={{ datum: 'color' }}
        />
    </div>
)

const InsightsCard = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch', gap: '50px' }}>
            <div>
                <CardTemplate roundedCorner width>
                    <PortalContainer style={{ padding: '0 30px' }}>
                        <Row style={{ height: '70px' }}>
                            <InsightsGraphText sm="11">
                                User Enrollment <InsightsInfoIcon icon={faInfoCircle as IconProp} />
                            </InsightsGraphText>
                            <InsightsGraphActions sm="1">
                                <InsightsDownloadIcon icon={faExternalLinkAlt as IconProp} />
                            </InsightsGraphActions>
                        </Row>


                        <Row style={{ height: '70px' }}>
                            <Col sm="2" style={{ fontSize: '25px', fontWeight: '500' }}>
                                90%
                            </Col>
                            <Col sm="10">
                                <Progress multi style={{ height: '1.5rem', marginBottom: '10px' }}>
                                    <Progress bar color="success" value="70" />
                                    <Progress bar color="danger" value="30" />
                                </Progress>
                                <div>
                                    <StyledSpan><InsightsEnrolledIcon icon="circle" /> Enrolled: 450</StyledSpan>
                                    <StyledSpan><InsightsNotEnrolledIcon icon="circle" /> Not Enrolled: 50</StyledSpan>
                                </div>

                            </Col>
                        </Row>

                        <Row style={{ marginTop: '30px' }}>
                            <InsightsGraphText sm="11">
                                Active Machines <InsightsInfoIcon icon={faInfoCircle as IconProp} />
                            </InsightsGraphText>
                            <InsightsGraphActions sm="1">
                                <InsightsDownloadIcon icon={faExternalLinkAlt as IconProp} />
                            </InsightsGraphActions>
                        </Row>
                        <Row>
                            <Col>
                                <InsightsEnrollmentText>
                                    279 Machine Certificates
                                </InsightsEnrollmentText>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '30px' }}>
                            <InsightsGraphText sm="11">
                                Subscriptions Remaining <InsightsInfoIcon icon={faInfoCircle as IconProp} />
                            </InsightsGraphText>
                            <InsightsGraphActions sm="1">
                                <InsightsDownloadIcon icon={faExternalLinkAlt as IconProp} />
                            </InsightsGraphActions>
                        </Row>

                        <Row>
                            <Col sm="3"><InsightsEnrollmentText>174</InsightsEnrollmentText></Col>
                            <Col sm="9"><InsightsEnrollmentText>MFA</InsightsEnrollmentText></Col>
                        </Row>
                        <Row>
                            <Col sm="3"><InsightsEnrollmentText>2</InsightsEnrollmentText></Col>
                            <Col sm="9"><InsightsEnrollmentText>CBA</InsightsEnrollmentText></Col>
                        </Row>
                        <Row>
                            <Col sm="3"><InsightsEnrollmentText>13.2k</InsightsEnrollmentText></Col>
                            <Col sm="9"><InsightsEnrollmentText>CBA+</InsightsEnrollmentText></Col>
                        </Row>
                        <Row>
                            <Col sm="3" style={{ color: 'red' }}><InsightsEnrollmentText>0</InsightsEnrollmentText></Col>
                            <Col sm="9"><InsightsEnrollmentText>PWL</InsightsEnrollmentText></Col>
                        </Row>
                        <Row>
                            <Col sm="3" style={{ color: 'red' }}><InsightsEnrollmentText>-10</InsightsEnrollmentText></Col>
                            <Col sm="9"><InsightsEnrollmentText>PWL+</InsightsEnrollmentText></Col>
                        </Row>

                    </PortalContainer>
                </CardTemplate>
            </div>

            <div>
                <CardTemplate roundedCorner width>
                    <PortalContainer style={{ padding: '0 30px' }}>
                        <Row>
                            <InsightsGraphText sm="9">
                                Risky Account <InsightsInfoIcon icon={faInfoCircle as IconProp} />
                            </InsightsGraphText>
                            <InsightsGraphActions sm="3">
                                <span>
                                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                        <DropdownToggle
                                            caret
                                            style={toggleStyle}
                                        >
                                            Last Month
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem>Last Week</DropdownItem>
                                            <DropdownItem>Last Year</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </span>
                                <span>
                                    <InsightsDownloadIcon icon={faExternalLinkAlt as IconProp} />
                                </span>
                            </InsightsGraphActions>
                        </Row>
                        <Row>
                            <InsightsGraphNumber sm="2">
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div style={{ fontSize: '25px' }}>6</div>
                                    <div style={{ fontSize: '15px' }}>Today</div>
                                </div>
                            </InsightsGraphNumber>
                            <Col sm="10">
                                {generateGraph(riskyAccountData, false)}
                            </Col>
                        </Row>
                        <Row>
                            <InsightsGraphText sm="9">
                                Expiring Certificate <InsightsInfoIcon icon={faInfoCircle as IconProp} />
                            </InsightsGraphText>
                            <InsightsGraphActions sm="3">
                                <span>
                                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                        <DropdownToggle
                                            caret
                                            style={toggleStyle}
                                        >
                                            Next Month
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem>Next Week</DropdownItem>
                                            <DropdownItem>Next Year</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </span>
                                <span>
                                    <InsightsDownloadIcon icon={faExternalLinkAlt as IconProp} />
                                </span>
                            </InsightsGraphActions>
                        </Row>
                        <Row>
                            <InsightsGraphNumber sm="2">
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div style={{ fontSize: '25px' }}>23</div>
                                    <div style={{ fontSize: '15px' }}>Upcoming</div>
                                </div>
                            </InsightsGraphNumber>
                            <Col sm="10">
                                {generateGraph(expiringCertData, true)}
                            </Col>
                        </Row>
                    </PortalContainer>
                </CardTemplate>
            </div>
        </div>
    );
}


export default InsightsCard;
