// npm dependencies
import React from 'react';
import i18next from 'i18next';
// data dependencies
import UCMS from '../../../utils/ucms';
import PCSC from '../../../utils/pcsc';
import { errorFactory, declareError } from '../../../utils/errors';
import { fetchDiagnosis } from '../../../utils/diagnosis';
import { deriveUrl, sleep } from '../../../utils/generic';
import {
    ucmsConstants,
    webpcscNoService,
    apiPath,
    vscPlaceholder,
    whfbPlaceholder,
    atrCases
} from '../../../constants';
// visual dependencies
import DeviceDetectionWithData from './DeviceDetectionWithData';

/* *****************************************************************************************
  Warning: this file is a dragon. test carefully after any change.
    the main loop or engine of this component is the handlePcscOnChange function
    be careful while using state, with this component it is very easy
    for weird state changes to conflict or occur out of order
****************************************************************************************** */

const TAG = 'DeviceDetection'; // eslint-disable-line no-unused-vars
class DeviceDetection extends React.Component {
    state = {
        connected: false,
        interval: false,
        syncing: false,
        readers: {},
    };

    componentDidMount() {
        this.pcscOnChange();
    }

    componentWillUnmount() {
        if (this.state.interval) {
            this.state.interval.stop();
            this.setState({ ...this.state, interval: null });
        }
    }

    setDetectReaders = (added, modified) => {
        // eslint-disable-next-line max-len, no-return-assign, no-param-reassign
        added.forEach(
            (reader) =>
                (modified[reader] =
                    modified[reader] === undefined ? null : modified[reader]),
        );
        const readers = {};
        Object.keys(modified).forEach((reader) => {
            readers[reader] = {
                ...[reader],
                issuable: i18next.t(atrCases[modified[reader]]) || true,
                atr: modified[reader],
                readerName: reader,
                label: reader,
                changed: true,
            };
        });
        this.setState({
            ...this.state,
            readers: {
                ...this.state.readers,
                ...readers,
            },
        });
    };

    pcscOnChange = async () => {
        try {
            await fetchDiagnosis(this.props.appDispatch);
            await PCSC.ready;
            const interval = await PCSC.onChange(
                this.handlePcscOnChange,
                true,
                1500,
            );
            this.setState({
                ...this.state,
                interval,
            });
        } catch (error) {
            console.error(
                TAG,
                'device selection identifyDevices error:',
                error,
            );
            console.error(TAG, 'PCSC.onChange:', error);
            declareError(
                this.props.appDispatch,
                errorFactory.err(`${webpcscNoService}_ERROR`),
            );
        }
    };

    handlePcscOnChange = async (added, removed, modified) => {
        this.setState({ ...this.state, syncing: true });
        if (removed.length > 0) {
            this.removeReaders(removed);
            // eslint-disable-next-line no-param-reassign
            removed.forEach((reader) => delete modified[reader]);
        }
        this.setDetectReaders(added, modified);
        console.debug(TAG, 'handlePcscOnChange detected state:', this.state);
        await this.identifyDevices();
        this.updateDisplay(this.state.readers, this.connected);
        this.handleVscDetection();
        this.handleWhfbDetection();
        this.setState({
            ...this.state,
            syncing: false,
        });
    };

    handleVscDetection = () => {
        const { subType } = this.props.device || {};
        if (
            subType === vscPlaceholder.subType &&
            this.props.mgmt.detected === false
        ) {
            this.props.handleNotFound();
        }
    };

    handleWhfbDetection = () => {
        const { subType } = this.props.device || {};
        if (
            subType === whfbPlaceholder.subType &&
            this.props.mgmt.detected === false
        ) {
            this.props.handleNotFound();
        }
    };

    removeReaders = (removed) => {
        const { readers } = this.state;

        this.updateDisplay(readers, this.disconnected);

        removed.forEach((readerName) => {
            delete readers[readerName];
        });
        this.setState({
            ...this.state,
            selected: removed.includes(this.state.selected)
                ? ''
                : this.state.selected,
            ...readers,
        });
        this.props.enrollingDispatch({
            type: 'SET_SELECTED_READER',
            reader: removed.includes(this.state.selected)
                ? null
                : this.state.readers[this.state.selected],
        });
    };

    identifyDevices = async () => {
        // eslint-disable-line consistent-return
        const { readers } = this.state;
        await sleep(1000);
        for (const readerName of Object.keys(readers)) {
            // eslint-disable-line no-restricted-syntax, max-len
            if (
                readers[readerName].changed === false ||
                readers[readerName].atr === null
            ) {
                continue; // eslint-disable-line no-continue
            }
            let result = null;
            let reader = null;
            try {
                console.debug(TAG, 'trying 0:', readerName);
                reader = await PCSC.connect(readerName); // eslint-disable-line no-await-in-loop
                var atr = readers[readerName].atr;
                // Hard coded the atr to 'axiad.whfb' for whfb
                if (readerName.includes('Windows Hello for Business')) {
                    atr = 'axiad.whfb';
                }
                result = await UCMS.apdu.run(
                    // eslint-disable-line no-await-in-loop
                    reader,
                    ucmsConstants.actions.identify,
                    atr,
                    () => {},
                    deriveUrl(apiPath),
                );
            } catch (error) {
                console.error(TAG, 'identifyDevices:', error, readerName);
                continue; // eslint-disable-line no-continue
            } finally {
                if (reader) await reader.disconnect(); // eslint-disable-line no-await-in-loop
            }
            if (result === null) {
                return null;
            }
            if (result !== null) {
                const card = result.entries[0].result;
                console.debug(TAG, 'identifyDevices:card:', card);
                readers[readerName] = {
                    ...readers[readerName],
                    ...card,
                    device: card,
                };
            }
        }
        this.setState({
            ...this.state,
            readers: {
                ...this.state.readers,
                ...readers,
            },
        });
    };

    connected = (readerName) => {
        this.props.setReader(readerName);
        this.setState({
            ...this.state,
            connected: true,
        });
        if (this.props.mgmt.detected === false) {
            this.props.mgmt.setDetected();
            setTimeout(() => {
                this.props.mgmt.next();
            }, 600);
        }
    };

    disconnected = () => {
        this.props.setReader('');
        this.setState({
            ...this.state,
            connected: false,
        });
    };

    updateDisplay = (readers, matchSetter) => {
        Object.keys(readers).forEach((readerName) => {
            if (this.matchDevice(readerName)) {
                matchSetter(readerName);
            }
        });
    };

    matchDevice(readerName) {
        console.debug(
            TAG,
            'matchDevice:state.readers[readerName]',
            this.state.readers[readerName],
        );
        const { device } = this.props;
        const reader = this.state.readers[readerName];

        /* *****************************************************************************************
      Warning: this will become a bug when we add a new ucms type or change how ucms types work
      add proper querying
    ****************************************************************************************** */

        let cardType = 'unidentified';
        console.debug(TAG, 'matchDevice:reader.type:', reader.type);
        if (reader.type) {
            console.debug(
                TAG,
                'matchDevice:reader.type.includes:',
                reader.type.includes('yubikey'),
            );
            if (reader.type.includes('yubico') || reader.type.includes('yubikey')) {
                cardType = 'yubikey';
            } else if (reader.type.includes(whfbPlaceholder.subType)) {
                cardType = whfbPlaceholder.type;
            } else {
                cardType = 'smartcard';
            }
        }
        return (
            (device.type === cardType || device.subType === reader.type) &&
            (device.serial === reader.serial 
                || (device.type === whfbPlaceholder.type && device.externalIdentifier === reader.serial))
        );
    }

    render() {
        const { children } = this.props;
        return (
            <DeviceDetectionWithData
                children={children}
                connected={this.state.connected}
            />
        );
    }
}

export default DeviceDetection;
