import React from 'react';
import { Link, generatePath } from 'react-router-dom';
import { StyledNavIcon, AccentButton } from '../../../../styles/common';

const ActionButton = ({
    path,
    userData,
    id,
    buttonText,
    color = 'primary',
    onClick,
    ...otherProps
}) => {
    if (!userData) {
        return null;
    }
    const { username } = userData;
    return (
        <Link
            to={{
                pathname: generatePath(path, {
                    username,
                }),
            }}
            onClick={onClick}
            {...otherProps}
        >
            {userData !== null && userData.status !== 'Disabled' && (
                <AccentButton
                    outline
                    size="sm"
                    id={id}
                    color={color}
                    style={{ margin: 0 }}
                >
                    <StyledNavIcon icon="plus-circle" />
                    {buttonText}
                </AccentButton>
            )}
        </Link>
    );
};

export default ActionButton;
