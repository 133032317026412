import React, { useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Countdown from 'react-countdown';
import {
    ucmsConstants as UCMS,
    PROGRESS_BAR_DONE_DELAY,
} from '../../constants';
import { postAssociateAction } from '../../utils/walkways';
import ProgressTemplate from '../shared/ProgressTemplate';
import { AppDispatchContext, AppStateContext } from '../../context/AppContext';
import CardEnrollLayout from '../shared/CardEnrollLayout';
import { useDidMount } from '../../utils/hooks';


const ENROLL_PIV = 'enrollPiv';

const AssociatePivEnroll = ({ mgmt, reader, associateUser }) => {
    const { t } = useTranslation();
    const dispatch = useContext(AppDispatchContext);
    const { webpcscMode } = useContext(AppStateContext);

    const [, setPivResult] = useState({});
    const [showNext, setShowNext] = useState(false);
    const [stage, setStage] = useState(t('pivActionInitialState'));

    useDidMount(() => {
        const setEnrollProgress = (hook, data) => {
            if (hook === UCMS.stage) {
                setStage(data);
            }
        };

        const setPivEnrollResult = async (json) => {
            setPivResult({ ...json });
            setShowNext(true);
            if (mgmt && json.step === UCMS.done) {
                setTimeout(mgmt.next, PROGRESS_BAR_DONE_DELAY);
            }
        };
        const { pinForm } = mgmt.formValues;
        postAssociateAction(
            setPivEnrollResult,
            setEnrollProgress,
            dispatch,
            {
                pin: pinForm,
                user: associateUser,
                action: ENROLL_PIV,
                reader,
            },
            webpcscMode,
        );
    });

    const renderer = ({seconds }) => {
        return <p>{t('instructionMessages$touchFeitian', { seconds })}</p>;
    };
    
    return (
        <CardEnrollLayout
            cardTitle={t('cardTitles$associateSupport')}
            title={t('updateProgress')}
            alert={t('enrollTemplateAlertDefault')}
            showNext={false}
            mgmt={mgmt}
        >
            {/* Onlye feitian token required a countdown. */}
            {stage === 'reset_containers' && reader.subType === 'feitian' && <Countdown date={Date.now() + 30000} renderer={renderer} />}
            <ProgressTemplate
                isFake
                isDone={showNext}
                stage={`${t('pivActionPrefix')} ${stage}`}
            />
        </CardEnrollLayout>
    );
};

AssociatePivEnroll.propTypes = {
    reader: PropTypes.object.isRequired,
    associateUser: PropTypes.string.isRequired,
    mgmt: PropTypes.object.isRequired,
};

export default AssociatePivEnroll;
