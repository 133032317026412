import React, { useRef } from 'react';
import { PropTypes } from 'prop-types';
import { Col, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import SecureEntryIcon from '../../shared/SecureEntryIcon';
import { createQuestionFormId } from '../../../constants';
import {
    StyledInput,
    StyledNavIcon,
    StyledGap,
    StyledButtonMarginAfter,
    PaddingButton,
} from '../../../styles/common';
import { useDidMount } from '../../../utils/hooks';

const CreateQuestionWithData = ({
    clearText,
    clearTextSwitch,
    questionNumber,
    questions,
    minEnroll,
    removeQuestion,
    questionForm,
    updateForm,
    validity,
    addQuestion,
    maxQuestions,
}) => {
    const { t } = useTranslation();
    const questionRef = useRef();
    const answerRef = useRef();
    const confirmAnswerRef = useRef();
    useDidMount(() => {
        if (!questionForm[questionNumber].question)
            questionRef.current.value = '';
        if (!questionForm[questionNumber].answer)
            answerRef.current.value = '';
        if (!questionForm[questionNumber].confirmAnswer)
            confirmAnswerRef.current.value = '';
    });

    const getInput = () => {
        const commonProps = {
            ref: questionRef,
            value: questionForm[questionNumber].question,
            name: 'question',
            placeholder: t('formLabels$question$placeholder'),
            bsSize: 'sm',
            maxLength: '100',
            autoComplete: 'off',
            onChange: (event) => {
                updateForm(event.target.value, 'question', questionNumber);
            },
        };

        if (!questions.length) {
            return <StyledInput type="input" {...commonProps} />;
        }
        return (
            <StyledInput
                valid={validity[questionNumber].question === true}
                invalid={validity[questionNumber].question === false}
                type="select"
                {...commonProps}
            >
                {questions.map((question) =>
                    questionForm[questionNumber].question !== question &&
                    Object.values(questionForm).some(
                        (value) => value.question === question,
                    ) ? null : (
                        // eslint-disable-next-line react/jsx-indent
                            <option key={question} value={question}>
                                {question}
                            </option>
                        ),
                )}
            </StyledInput>
        );
    };
    return (
        <div data-test-id={createQuestionFormId}>
            <FormGroup row autoComplete="off">
                <Col xs="2">
                    <Label for="question">
                        {t('formLabels$question$formLabel', {
                            NUMBER: questionNumber + 1,
                        })}
                    </Label>
                </Col>
                <Col xs="4">{getInput()}</Col>
                <Col xs="6">
                    <StyledButtonMarginAfter
                        outline
                        color="primary"
                        onClick={removeQuestion}
                        disabled={Object.keys(questionForm).length <= minEnroll}
                        size="sm"
                    >
                        <StyledNavIcon icon="times-circle" />
                        {t('formLabels$question$delete')}
                    </StyledButtonMarginAfter>
                    {questionNumber ===
                        Object.keys(questionForm).length - 1 && (
                        <PaddingButton
                            outline
                            color="primary"
                            onClick={addQuestion}
                            disabled={
                                Object.keys(questionForm).length ===
                                maxQuestions
                            }
                            size="sm"
                        >
                            <StyledNavIcon icon="plus-circle" />
                            {t('formLabels$question$add')}
                        </PaddingButton>
                    )}
                </Col>
            </FormGroup>
            <FormGroup row autoComplete="off">
                <Col xs="2" />
                <Col xs="4">
                    <StyledInput
                        ref={answerRef}
                        valid={validity[questionNumber].answer === true}
                        invalid={validity[questionNumber].answer === false}
                        type={clearText ? 'text' : 'password'}
                        name="answer"
                        placeholder={t('formLabels$answer$placeholder')}
                        bsSize="sm"
                        maxlength="100"
                        value={questionForm[questionNumber].answer}
                        onChange={(event) => {
                            updateForm(
                                event.target.value,
                                'answer',
                                questionNumber,
                            );
                        }}
                    />
                </Col>
                <Col xs="3">
                    <StyledInput
                        ref={confirmAnswerRef}
                        valid={
                            validity[questionNumber].confirmAnswer === true
                        }
                        invalid={
                            validity[questionNumber].confirmAnswer === false
                        }
                        type={clearText ? 'text' : 'password'}
                        name="confirmAnswer"
                        placeholder={t('formLabels$confirmAnswer$placeholder')}
                        bsSize="sm"
                        maxlength="100"
                        value={questionForm[questionNumber].confirmAnswer}
                        onChange={(event) => {
                            updateForm(
                                event.target.value,
                                'confirmAnswer',
                                questionNumber,
                            );
                        }}
                    />
                </Col>
                <Col xs="0">
                    <SecureEntryIcon
                        isActive={clearText}
                        onMouseDown={clearTextSwitch}
                    />
                </Col>
            </FormGroup>
            {questionNumber !== Object.keys(questionForm).length - 1 && (
                <StyledGap />
            )}
        </div>
    );
};

CreateQuestionWithData.propTypes = {
    clearText: PropTypes.bool.isRequired,
    clearTextSwitch: PropTypes.func.isRequired,
    questionNumber: PropTypes.number.isRequired,
    questions: PropTypes.array.isRequired,
};

export default CreateQuestionWithData;
