import React, { useEffect, useContext } from 'react';
import { loginURL } from '../../constants';
import { AppStateContext } from '../../context/AppContext';
import LoadingSpinner from '../shared/LoadingSpinner';

const NotAuthenticated = () => {
    const { authenticated } = useContext(AppStateContext);
    const goToLogin = () => (window.location.href = loginURL);

    useEffect(() => {
        if (authenticated === false) {
            goToLogin();
        }
    }, [authenticated]);

    return <LoadingSpinner />;
};

export default NotAuthenticated;
