/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Col, Input, NavLink, NavItem, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

export const mInput = React.forwardRef((props, ref) => {
    const { className, ...rest } = props;
    return <Input className={className} {...rest} innerRef={ref} />;
});

export const mCol = ({ className, children }) => (
    <Col className={className}>{children}</Col>
);

export const mLink = ({ className, children, ...rest }) => (
    <Link className={`${className} nav-link`} {...rest}>
        {children}
    </Link>
);

export const mNavLink = ({ className, children, ...rest }) => (
    <NavLink className={className} {...rest}>
        {children}
    </NavLink>
);

export const mNavItem = ({ className, children }) => (
    <NavItem className={className}>{children}</NavItem>
);

export const mRow = ({ className, children }) => (
    <Row className={className}>{children}</Row>
);

export const mA = ({ className, children, ...rest }) => (
    <a className={`${className} nav-link`} {...rest}>
        {children}
    </a>
);
