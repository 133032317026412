import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { NoPaddingContainer } from '../../../../styles/common';
import { useFetchData } from '../../../../utils/hooks';

import UserInfoRows from './UserInfoRows';
import DevicesRows from './DevicesRows';
import EmergencyInfoRows from './EmergencyInfoRows';

import { FullHeightCol } from './styles';

const UserDetails = () => {
    const { username } = useParams();
    // fetch data from /user api
    const [userLoading, userData] = useFetchData({
        endpoint: `/user/${username}`,
    });

    return (
        <NoPaddingContainer>
            <Row>
                <FullHeightCol sm="12" md="12" $reduceSpace="none">
                    <UserInfoRows userLoading={userLoading} user={userData} />
                </FullHeightCol>
            </Row>
            <Row>
                <Col>
                    <DevicesRows userData={userData} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <EmergencyInfoRows userData={userData} />
                </Col>
            </Row>
        </NoPaddingContainer>
    );
};

export default UserDetails;
