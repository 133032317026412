import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import {
    Container,
    Col,
    DropdownToggle,
    FormGroup,
    Alert,
    Input,
    Button,
    CustomInput,
    FormText,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Modal,
    ModalHeader,
    ModalFooter,
    Badge,
    Tooltip,
    ModalBody,
    Card,
    CardBody,
    Spinner,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mA, mInput, mLink, mNavItem, mRow } from './modstrap';

const sharedAnchorStyle = css`
    color: ${({ theme }) => theme.typography.anchor} !important;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    font-family: Open Sans, san-serif !important;
    margin: -4px -22px -4px -22px;
    &:hover {
        color: ${({ theme }) => theme.typography.anchorHover} !important;
        text-decoration: none;
    }
`;

export const PortalContainer = styled(Container)`
    text-align: left;
    max-width: 100% !important;
`;

export const StyledFlexbox = styled.div`
    height: '15px';
    display: flex;
    flex-direction: ${({ direction }) =>
        direction}; /* direction: [column|row] */
    align-items: ${({ alignment }) =>
        alignment}; /* alignment: [center|left|right] */
    justify-content: ${({ justification }) =>
        justification}; /* justification: [center|flex-end|flex-start] */
`;

export const StyledIcon = styled.img`
    height: 16px;
    margin-right: ${({ left }) => (left ? '10px' : '0px')};
`;

export const StyledInput = styled(mInput)`
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    color: ${({ theme }) => theme.typography.text} !important;
    background-color: ${({ theme }) => theme.colors.foreground} !important;
    width: 80%;
    &:focus {
        background: ${({ theme }) => theme.colors.background};
    }
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const FullWidthInput = styled(StyledInput)`
    width: 100% !important;
`;

export const StyledLink = styled(mLink)`
    color: ${({ theme }) => theme.typography.anchor} !important;
    text-decoration: none;
    font-size: 14px;
`;

export const NoHoverLink = styled(mLink)`
    ${sharedAnchorStyle}
`;

export const StyledAnchor = styled(mA)`
    ${sharedAnchorStyle}
    cursor: pointer;
`;

export const StyledNavIcon = styled(FontAwesomeIcon)`
    font-size: 1em;
    font-weight: ${({ active }) => (active ? 'bold' : 'default')};
    margin-right: 10px;
`;

export const StyledNavIconNoMarginRight = styled(StyledNavIcon)`
    margin-right: 0;
`;

export const StyledNavLink = styled(mLink)`
    color: ${({ theme }) => theme.typography.anchor} !important;
    font-weight: ${({ active }) => (active ? 'bold' : 'default')};
    text-decoration: none !important;
    text-align: left;
    padding-left: 0.15rem !important;
    padding-right: 0.15rem !important;
`;

export const StyledNavItem = styled(mNavItem)`
    color: ${({ active, theme }) =>
        active ? theme.colors.primary : 'inherit'} !important;
`;

export const TitleContainer = styled.div`
    padding-top: 15px;
    padding-bottom: 15px;
`;

export const TitleRow = styled(mRow)`
    padding-top: 2.25rem;
    padding-bottom: 0.375rem;
`;

export const SpinnerContainer = styled(Col)`
    text-align: center;
`;

export const BoldCol = styled(Col)`
    text-transform: uppercase;
`;

export const SpinnerIcon = styled(FontAwesomeIcon)`
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
`;

export const CardHeader = styled.h2`
    padding-bottom: 16px;
    font-weight: normal;
    margin-bottom: 0;
    font-size: 1rem;
`;

export const StyledHeaderButton = styled(Button)`
    background-color: white !important;
    border-color: ${({ theme, disabled }) =>
        disabled ? '#666' : theme.colors.accent} !important;
    color: ${({ theme, disabled }) =>
        disabled ? '#666' : theme.colors.accent} !important;
    :hover {
        color: ${({ disabled }) => (disabled ? '#666' : 'white')} !important;
        background-color: ${({ theme, disabled }) =>
        disabled ? 'transparent' : theme.colors.accent} !important;
        cursor: ${({ disabled }) =>
        disabled ? 'default' : 'pointer'} !important;
    }
    padding-top: 6px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    padding-bottom: 6px !important;
    ::after {
        margin-left: 8px !important;
    }
`;

export const StyledDropdown = styled(Dropdown)`
    margin-top: 12px;
    margin-bottom: 16px;
`;

export const ActionDropdown = styled(Dropdown)`
    display: flex;
`;

export const TableActionWrapper = styled.div`
    align-items: stretch;
    display: flex;
`

export const StyledDropdownToggle = styled(DropdownToggle)`
    background-color: white !important;
    border-color: ${({ theme, disabled }) =>
        disabled ? '#666' : theme.colors.accent} !important;
    color: ${({ theme, disabled }) =>
        disabled ? '#666' : theme.colors.accent} !important;
    :hover {
        color: ${({ disabled }) => (disabled ? '#666' : 'white')} !important;
        background-color: ${({ theme, disabled }) =>
        disabled ? 'transparent' : theme.colors.accent} !important;
        cursor: ${({ disabled }) =>
        disabled ? 'default' : 'pointer'} !important;
    }
    padding-top: 6px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    padding-bottom: 6px !important;
    ::after {
        margin-left: 8px !important;
    }
`;

export const StyledDropdownItem = styled(DropdownItem)`
    display: block !important;
    width: 100% !important;
    clear: both !important;
    color: #212529 !important;
    text-align: inherit !important;
    white-space: nowrap !important;
    border: 0 !important;
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
    position: absolute;
    top: -3px;
    left: 0px;
`;

export const StyledFormGroup = styled(FormGroup)`
    height: 3rem;
    padding-top: 6px;
`;

export const StyledFormGroupPaddingTop = styled(FormGroup)`
    height: 3rem;
    padding-top: 6px;
`;

const StyledTableRowCommon = styled.div`
    display: flex;
    line-height: 2rem;
    padding: 0.75rem;
    border-bottom: 1px solid rgb(241, 241, 241);
    width: fit-content;
    min-width: 100%;
`;
export const StyledTableHeadRow = styled(StyledTableRowCommon)``;

export const StyledTableRow = styled(StyledTableRowCommon)`
    width: auto;
    :hover {
        background-color: rgba(0, 0, 0, 0.04) !important;
    }
`;

export const StyledAlert = styled(Alert)``;

export const StyledAlertMarginTop = styled(Alert)`
    margin-top: 8px;
`;

export const FormComponentWrapper = styled.div`
    padding-top: 6px;
    padding-bottom: 6px;
`;

export const PaddingDivider = styled.div`
    padding-top: 24px;
`;

export const SelectInput = styled(Input)`
    width: 50%;
    float: left;
    margin-right: 1rem;
`;

export const FullWidthSelectInput = styled(SelectInput)`
    width: 100%;
`;

export const SyncIcon = styled(FontAwesomeIcon)`
    margin-top: 0.45rem;
    opacity: ${(props) => (props.$syncing ? 1 : 0)};
`;

export const PaddingButton = styled(Button)`
    padding-top: 6px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    padding-bottom: 6px !important;
`;

export const StyledButton = styled(PaddingButton)`
    margin-top: 12px;
    margin-bottom: 16px;
`;

export const StyledTable = styled.div`
    color: ${({ theme }) => theme.typography.text} !important;
    background-color: ${({ theme }) => theme.colors.foreground} !important;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
`;

export const StyledTableHead = styled.div`
    border-top: 0 !important;
    white-space: nowrap !important;
    flex-basis: fit-content;
`;

export const StyledTableBody = styled.div`
    color: #44484c;
    letter-spacing: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow: overlay;
    flex-basis: auto;
    width: 100%;
    min-width: fit-content;
    padding-bottom: 1.5rem;
`;

export const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
`;

export const AccentIcon = styled(StyledNavIcon)`
    color: ${({ theme }) => theme.colors.accent};
    ${({ disabled, theme }) => css`
        color: ${disabled ? 'grey' : theme.colors.accent};
    `} 
`;

export const AccentButton = styled(StyledButton)`
    border-color: ${({ theme }) => theme.colors.accent} !important;
    color: ${({ theme, outline }) =>
        outline ? theme.colors.accent : theme.typography.light} !important;
    :hover {
        color: white !important;
        background-color: ${({ theme }) => theme.colors.accent} !important;
    }
    opacity: ${(props) => (props['aria-disabled'] ? 0.5 : 1)};
`;

export const StyledRadioButton = styled(CustomInput)`
    display: inherit !important;
    margin-left: -20px;
    > .custom-control-label {
        ::before {
            background-color: ${({ theme, checked }) =>
        checked ? theme.colors.accent : 'transparent'} !important;
            border-color: #d8d8d8 !important;
        }
        ::after {
            content: '' !important;
        }
    }
`;

export const StyledFormText = styled(FormText)`
    margin-left: 6px;
    color: ${({ $color, theme }) =>
        $color ? '#6c757d' : theme.colors.accent} !important;
`;

export const StyledCheckbox = styled.input`
    margin-top: 12px !important;
    margin-left: 1px !important;
`;

export const StyledTableSortIcon = styled(FontAwesomeIcon)`
    font-size: 1em;
    margin-left: 12px;
    cursor: pointer;
    opacity: ${({ hide }) => (hide ? 0 : 1)};
`;

export const StyledColumnHeader = styled.span`
    text-transform: uppercase;
    font-weight: normal;
    vertical-align: middle;
`;

export const StyledGap = styled.div`
    margin-bottom: 50px;
`;

export const StyledButtonMarginAfter = styled(PaddingButton)`
    margin-right: 8px;
`;

export const StyledButtonMarginBefore = styled(PaddingButton)`
    margin-left: 8px;
`;

export const VerticalLine = styled.div`
    border-left: 1px solid #dddddd;
    height: 32px;
    display: inline-block;
    margin-bottom: -10px;
    margin-left: 10px;
`;

export const StyledModalHeader = styled(ModalHeader)`
    border-width: 0px !important;
`;

export const StyledModalFooter = styled(ModalFooter)`
    border-width: 0px !important;
`;

export const StyledModal = styled(Modal)`
    .modal-content {
        padding: 10px;
    }
`;

export const StyledModalInfo = styled(Alert)`
    padding-bottom: 15px;
`;
export const StyledDefaultModalHeader = styled(StyledModalHeader)`
    background-color: ${(props) => props.theme.colors.foreground};
    color: ${(props) => props.theme.typography.text};
    padding-bottom: 0px !important;
    & * {
        font-weight: bold !important;
    }
`;
export const StyledDefaultModalBody = styled(ModalBody)`
    background-color: ${(props) => props.theme.colors.foreground};
    color: ${(props) => props.theme.typography.text};
    padding-bottom: 0px !important;
`;
export const StyledDefaultModalFooter = styled(StyledModalFooter)`
    background-color: ${(props) => props.theme.colors.foreground};
    color: ${(props) => props.theme.typography.text};
    padding-bottom: 0px !important;
`;

export const DeviceStatusContainer = styled(Badge)`
    text-transform: uppercase;
    color: ${({ status: { text } }) => text} !important;
    background-color: ${({ status: { bg } }) => bg} !important;
    padding: 0.55rem !important;
    letter-spacing: 0.06em;
    font-size: 80%;
    font-weight: 700 !important;
    & * {
        text-transform: uppercase;
        color: ${({ status: { text } }) => text} !important;
        letter-spacing: 0.06em;
        font-size: 80%;
        font-weight: 700 !important;
    }
`;

export const EmergencyActionButtonWrapper = styled(DeviceStatusContainer)`
    cursor: pointer;
    background-color: ${({ $useDefaultBg }) =>
        $useDefaultBg ? 'inherit' : 'transparent !important'};
    border: 0.125rem solid ${({ status: { bg } }) => bg} !important;
    &:hover {
        background-color: ${({ status: { bg } }) => `${bg}80`} !important;
    }
`;

export const DeviceActionButtonWrapper = styled.div`
    display: flex;
`;

export const DeviceActionButton = styled(DeviceStatusContainer)`
    cursor: pointer;
    margin: 0 0.125rem;
    min-width: 4rem;
    background-color: ${({ $useDefaultBg }) =>
        $useDefaultBg ? 'red' : 'transparent !important'};
    border: 0.125rem solid ${({ status: { bg } }) => bg} !important;
    &:hover {
        background-color: ${({ status: { bg } }) => `${bg}80`} !important;
    }
`;

export const StyledActionDropdownToggle = styled(DropdownToggle)`
    ${({ disabled }) => disabled && css`
        cursor: no-drop;
        pointer-events: auto !important;
    `} 
    text-decoration: none !important;
    vertical-align: inherit;
    padding: 0 0.5rem;
    background-color: #f7f7f7;
    border: 0.125rem solid #c9c9c9 !important;
    &:hover, &:focus {
        background-color: #d6d6d6 !important;
    }
    & svg {
        fill: #465765;
    }
`;

export const StyledActionTooltip = styled(Tooltip).attrs({
    autohide: false,
})`
    .tooltip-inner {
        border: 1px solid rgba(0, 0, 0, 0.125) !important;
        background-color: ${({ theme }) => theme.colors.foreground} !important;
        height: auto !important;
        max-height: 60vh !important;
        overflow-y: auto !important;
        padding: 0.5rem 0 0.5rem 0 !important;
        width: 158px !important;
    }
    .arrow::before {
        border-left-color: rgba(0, 0, 0, 0.125) !important;
    }
    .tooltip.show {
        opacity: 1 !important;
    }
`;

export const NoPaddingContainer = styled(Container)`
    padding: 0 !important;
`;

export const StyledQueryCard = styled(Card)`
    min-height: 35px !important;
    width: 100% !important;
    background: #f8f8f8 !important;
    margin: 12px 0 16px 0 !important;
    // border-right-width: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
`;

export const StyledQueryCardBody = styled(CardBody)`
    padding: 5px 10px 5px 10px !important;
`;

export const AccentButtonMarginAfter = styled(AccentButton)`
    margin-right: 8px !important;
`;

export const ColAlignRight = styled(Col)`
    text-align: right !important;
`;

export const StyledRefreshBtn = styled(Button)`
    position: absolute;
    margin-left: -50px;
    margin-top: 8px;
    height: 38px;
    width: auto;
    background-color: ${({ theme }) => theme.colors.background} !important;
    color: ${({ theme }) => theme.colors.dark} !important;
    border-color: #d1d1d1 !important;
    right: 15px;
    border-radius: 0 4px 4px 0 !important;
    &:hover {
        opacity: 0.675;
    }
    &:focus {
        box-shadow: none !important;
    }
`;

export const StyledRefreshReportsBtn = styled(Button)`
    margin: 12px 0 16px 0 !important;
    height: 38px;
    width: 42px;
    background-color: ${({ theme }) => theme.colors.background} !important;
    color: ${({ theme }) => theme.colors.dark} !important;
    border-color: #d1d1d1 !important;
    border-left-width: 0 !important;
    border-radius: 0px !important;
    border-top-right-radius: ${(props) =>
        props.$last ? '4px' : '0'} !important;
    border-bottom-right-radius: ${(props) =>
        props.$last ? '4px' : '0'} !important;
    &:hover {
        opacity: 0.675;
    }
    &:focus {
        box-shadow: none !important;
    }
`;

export const ColFlex = styled(Col)`
    display: flex !important;
`;

export const ColAlignCenter = styled(Col)`
    text-align: center !important;
`;

export const HoverButton = styled(PaddingButton)`
    opacity: 0.5;
    &:hover {
        opacity: 1;
        // background-color: #eef1f3 !important;
    }
    color: #343a40 !important;
    background-color: #f8f9fa !important;
    border: none !important;
`;

export const DropdownToggleNoStyle = styled(DropdownToggle)`
    padding: 0 !important;
    border: 0 !important;
    background: none !important;
`;

export const StyledDropdownMenuSelect = styled(DropdownMenu)`
    font: inherit !important;
`;

export const StyledDropdownItemSelect = styled(DropdownItem)``;

export const SpinnerPaddingRight = styled(Spinner)`
    margin-right: 8px;
`;

export const AccentLink = styled(Link)`
    color: ${({ theme }) => theme.colors.accent};
`;

export const TableHeaderRow = styled(Container)`
    ${({ $hideTableHeader })=> css`display: ${$hideTableHeader ? 'none' : 'block' };`}
    min-height: 3rem;
    padding: 0;
`;

export const TableCellWrapper = styled.div`
    display: flex;
    align-items: center;
    word-break: break-word;
`;

export const TableRowWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TableRowDrawer = styled.div`
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    box-shadow: inset 0 0 2px 0 #ebebeb;
    ${({ isExpanded }) =>
        isExpanded
            ? css`
                  padding-top: 1rem;
                  padding-bottom: 1rem;
              `
            : `padding-top: 0 padding-bottom: 0`};
    background-color: #f5f5f5;
    ${({ isExpanded, maxHeight }) =>
        isExpanded
            ? css`
                  max-height: ${maxHeight || '15rem'};
              `
            : css`
                  max-height: 0;
              `}
    & ${StyledTableHeadRow} {
        font-size: 92%;
    }
    transition-property: max-height, padding-top, padding-bottom;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.77, 0.35, 0.46, 1.04);
`;

export const Title = styled.h1`
    font-weight: 600;
    font-size: 1.5rem;
    text-align: left;
    text-transform: capitalize;
`;
export const SubTitle = styled.h2`
    font-weight: 400;
    font-size: 0.8125rem;
    text-align: left;
    padding-bottom: 15px;
    opacity: 0.8;
    color: #333333;
`;

export const StyledImage = styled.img`
    height: 1.25rem;
    width: 1.25rem;
    margin-left: 0.5rem;
    text-align: center;
`;

export const CopyInfo = styled.span`
    color: ${({ failed }) => (failed ? '#842029' : '#146e43')};
    & svg {
        fill: ${({ failed }) => (failed ? '#842029' : '#146e43')};
        width: ${({iconWidth}) => css`${iconWidth}`}
    }
`;
