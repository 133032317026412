import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Form, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
    PortalContainer,
    StyledAlert,
    CardHeader,
    StyledRadioButton,
    StyledFormText,
} from '../../../styles/common';
import { AppDispatchContext, AppStateContext } from '../../../context/AppContext';
import DeviceAssociate from '../../elementLibrary/DeviceAssociate';
import UserAssociate from '../../elementLibrary/UserAssociate';
import { EnrollingDispatchContext } from '../../../context/EnrollingContext';

const AssociateDeviceWithData = ({
    associateUser,
    handleRadioClick,
    fieldset,
    // not yet removed because the dragon might use this for now...
    nextShow,
    setNextShow,
    setReader,
}) => {
    const { t } = useTranslation();
    const appDispatch = useContext(AppDispatchContext);
    const { user } = useContext(AppStateContext);
    const enrollingDispatch = useContext(EnrollingDispatchContext);

    const displayFields = Object.keys(fieldset).filter(
        (key) => fieldset[key].display === true,
    );
    return (
        <PortalContainer>
            <CardHeader>{t('associateUserLabels$stateless$h6')}</CardHeader>
            <StyledAlert color="info">
                {t('instructionMessages$delegationSelectIdentity')}
            </StyledAlert>
            <UserAssociate
                nextShow={nextShow}
                setNextShow={setNextShow}
                serverComponent={{
                    type: 'userid',
                    details: { value: associateUser },
                }}
            />
            <p>{t('associateUserLabels$stateless$selectADevice')}</p>
            {displayFields.length > 0 && <br />}
            <Form tag="fieldset" autoComplete="off">
                {displayFields.map(
                    (key) =>
                        fieldset[key].display && (
                            <FormGroup
                                className="form-check"
                                key={t(
                                    `issuanceTypeCardLabels$issuanceTypes$${key}`,
                                )}
                            >
                                <StyledRadioButton
                                    id={key}
                                    checked={fieldset[key].selected}
                                    value={key}
                                    type="radio"
                                    name="issuance"
                                    onChange={handleRadioClick}
                                    label={t(
                                        `issuanceTypeCardLabels$issuanceTypes$${key}`,
                                    )}
                                />
                                <StyledFormText $color={!fieldset[key].selected}>
                                    {t(
                                        `associateUserLabels$stateless$deviceTypes$${key}_desc`,
                                    )}
                                </StyledFormText>
                                {fieldset[key].buttonType === "dropdown" && (
                                    <DeviceAssociate
                                        setReader={setReader}
                                        associateUser={associateUser}
                                        appDispatch={appDispatch}
                                        enrollingDispatch={enrollingDispatch}
                                        display={
                                            fieldset[key].selected
                                        }
                                        isHost={user.username === associateUser}
                                    />
                                )}
                            </FormGroup>
                        ),
                )}
            </Form>
            {displayFields.length === 0 && (
                <StyledAlert color="info">
                    {t('deviceAssociateNoDevices')}
                </StyledAlert>
            )}
        </PortalContainer>
    );
};

AssociateDeviceWithData.propTypes = {
    fieldset: PropTypes.objectOf(PropTypes.object).isRequired,
    handleRadioClick: PropTypes.func.isRequired,
    associateUser: PropTypes.string.isRequired,
    nextShow: PropTypes.bool,
    setNextShow: PropTypes.func,
    setReader: PropTypes.func.isRequired,
};

export default AssociateDeviceWithData;
