import React, { useContext, useReducer, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { componentKeys, paths, KIOSK_MODE } from '../../constants';
import UserEntryCard from '../cardLibrary/UserEntry';
import TerminusCard from '../cardLibrary/Terminus';
import LoadingSpinner from '../shared/LoadingSpinner';
import EnrollQuestionCard from '../cardLibrary/EnrollQuestion';
import { EnrollingDispatchContext } from '../../context/EnrollingContext';
import { useFetchData } from '../../utils/hooks';
import { AppStateContext } from '../../context/AppContext';
import ValidateQuestionCard from '../cardLibrary/ValidateQuestion';

const { TERMINUS, USER_ENTRY } = componentKeys;

function reducer(state, action) {
    switch (action.type) {
        case 'SET_SECURITY_WORKFLOW':
            return {
                ...state,
                workflow: action.payload,
                fetching: false,
            };
        case 'SET_NEXT_STEP':
            return {
                ...state,
                step: state.step + 1,
            };
        case 'SET_BACK_STEP':
            return {
                ...state,
                step: state.step - 1,
            };
        case 'GO_TO_STEP':
            return {
                ...state,
                step: action.payload,
            };
        case 'SET_CURRENT_ANSWER':
            return {
                ...state,
                challenges: {
                    ...state.challenges,
                    [action.session]: action.answer,
                },
            };
        default:
            return state;
    }
}

const UpdateSecurity = () => {
    const { errorMsg: errorMessage, user, viewMode } = useContext(
        AppStateContext,
    );
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const { t } = useTranslation();
    const history = useHistory();
    const [state, dispatch] = useReducer(reducer, {
        workflow: [
            {
                type: 'loadingSpinner',
            },
            {
                type: TERMINUS,
            },
        ],
        fetching: false,
        step: 0,
    });

    const setSecurityWorkflow = (walkway) => {
        const workflow = [...walkway];
        dispatch({
            type: 'SET_SECURITY_WORKFLOW',
            payload: [
                ...workflow,
                {
                    type: TERMINUS,
                },
            ],
        });
        enrollingDispatch({
            type: 'SET_CREDENTIAL_FLOW',
            credentialFlow: walkway,
        });
    };

    useEffect(() => {
        enrollingDispatch({
            type: 'SET_USERNAME',
            username: user.username,
        });
        return () => {
            enrollingDispatch({
                type: 'RESET_FLOWS',
            });
        };
    },[]);

    useFetchData({
        endpoint: '/user/security/question',
        onSuccess: (data) => setSecurityWorkflow(data),
        queryParams: { user: user.username },
    });

    const setUserEntryEnrolling = (step) => {
        const { type } = state.workflow[step];
        if (type !== 'userEntry') {
            return;
        }
        enrollingDispatch({
            type: 'SET_USER_ENTRY',
            userEntry: state.workflow[step],
        });
    };

    const homeBack = () => {
        history.push(paths.account);
    };

    const next = () => {
        if (state.step < state.workflow.length) {
            setUserEntryEnrolling(state.step + 1);
            dispatch({
                type: 'SET_NEXT_STEP',
            });
        }
    };

    const goToQuestionEnroll = () => {
        const stepNumber = state.workflow
            .map((s) => s.type === 'questionEnroll')
            .indexOf(true);
        if (stepNumber >= 0) {
            dispatch({
                type: 'GO_TO_STEP',
                payload: stepNumber,
            });
        }
    };

    const goToResetQuestion = () => {
        const stepNumber = state.workflow
            .map(
                (s) =>
                    s.type === 'userEntry' &&
                    s.components.some((c) => c.type === 'question'),
            )
            .indexOf(true);
        if (stepNumber >= 0) {
            setUserEntryEnrolling(stepNumber);
            dispatch({
                type: 'GO_TO_STEP',
                payload: stepNumber,
            });
        }
    };

    const setCurrentAnswer = (answer, session) => {
        dispatch({
            type: 'SET_CURRENT_ANSWER',
            answer,
            session,
        });
    };

    const components = {
        /* eslint-disable react/prop-types */
        loadingSpinner: ({ title }, mgmt) => (
            <LoadingSpinner title={title} mgmt={mgmt} flowButtons />
        ),
        userEntry: (thisProps, mgmt) => (
            <UserEntryCard
                mgmt={mgmt}
                goToResetQuestion={goToResetQuestion}
                setCurrentAnswer={setCurrentAnswer}
            />
        ),
        questionValidate: (thisProps, mgmt) => (
            <ValidateQuestionCard mgmt={mgmt} deviceUser={user.username} />
        ),
        questionEnroll: (thisProps, mgmt) => <EnrollQuestionCard mgmt={mgmt} />,
        terminus: ({ title, errorMsg }) => (
            <TerminusCard
                title={title}
                errorMsg={errorMsg}
                msg={t(
                    'securityUpdateSuccessMsg'.concat(
                        viewMode === KIOSK_MODE ? '$kiosk' : '',
                    ),
                )}
            />
        ),
    }; /* eslint-enable react/prop-types */

    const stepComp = state.workflow[state.step];
    if (state.fetching) {
        return <LoadingSpinner />;
    }

    const isQuestionUpdateOrReset = (step) => {
        return (
            state.workflow[step].type === 'userEntry' &&
            state.workflow[step].components.some((c) =>
                ['question', 'updateQuestion'].includes(c.type),
            )
        );
    };

    return components[state.workflow[state.step].type](
        {
            // props
            errorMsg: errorMessage,
            title: t('cardTitles$securityInfo'),
            userEntry: stepComp.type === USER_ENTRY ? stepComp : {},
        },
        {
            // state
            workflow: state.workflow,
            fetching: state.fetching,
            step: state.step,
            next: isQuestionUpdateOrReset(state.step)
                ? goToQuestionEnroll
                : next,
            showNext: false,
            showBack: state.step === 0,
            back: homeBack,
            setWorkflow: setSecurityWorkflow,
            homeBack,
            challenges: state.challenges,
        },
    );
};

export default UpdateSecurity;
