import React from 'react';
import TableChevron from './TableChevron';
import { TableRowExpantionButton } from './styles';

const TableRowExpantionColumn = ({ setClickedRowIndex, row, isExpanded }) => (
    <TableRowExpantionButton
        type="button"
        onClick={() => {
            if (isExpanded) {
                setClickedRowIndex(null);
            } else {
                setClickedRowIndex(row.index);
            }
        }}
    >
        <TableChevron isExpanded={isExpanded} />
    </TableRowExpantionButton>
);

export default TableRowExpantionColumn;
