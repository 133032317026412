import i18next from 'i18next';
import u2f from './yubico-u2f-lib';

/* eslint-disable */
export async function u2fRegister(req) {
    const E = (m) => new Error(m);
    console.debug('u2fRegister req:', req);
    try {
        return new Promise((resolve, reject) => {
            const t = setTimeout(
                () => reject(E(i18next.t('errorMsgs$u2f_timeoutExternal'))),
                305000,
            );
            u2f.register(
                req.appId,
                [{ challenge: req.challenge, version: req.version }],
                [],
                (res) => {
                    clearTimeout(t);
                    switch (res.errorCode) {
                        case 1:
                            return reject(
                                E(i18next.t('errorMsgs$u2f_unknown')),
                            );
                        case 2:
                            return reject(
                                E(i18next.t('errorMsgs$u2f_badRequest')),
                            );
                        case 3:
                            return reject(
                                E(i18next.t('errorMsgs$u2f_configUnsupported')),
                            );
                        case 4:
                            return reject(
                                E(t('errorMsgs$u2f_deviceIneligible')),
                            );
                        case 5:
                            return reject(
                                E(t('errorMsgs$u2f_timeoutInternal')),
                            );
                    }
                    resolve(res);
                },
                300,
            );
        });
    } catch (e) {
        console.error('u2f register:', e);
        throw e;
    }
}

export async function u2fSign(req) {
    const E = (m) => new Error(m);

    return new Promise((resolve, reject) => {
        const t = setTimeout(
            () => reject(E(i18next.t('errorMsgs$u2f_timeoutExternal'))),
            305000,
        );

        // NOTE: This usage might be wrong, but it works.
        u2f.sign(
            req.appId,
            req,
            [req],
            (res) => {
                clearTimeout(t);
                switch (res.errorCode) {
                    case 1:
                        return reject(E(i18next.t('errorMsgs$u2f_unknown')));
                    case 2:
                        return reject(E(i18next.t('errorMsgs$u2f_badRequest')));
                    case 3:
                        return reject(
                            E(i18next.t('errorMsgs$u2f_configUnsupported')),
                        );
                    case 4:
                        return reject(
                            E(i18next.t('errorMsgs$u2f_deviceIneligible')),
                        );
                    case 5:
                        return reject(
                            E(i18next.t('errorMsgs$u2f_timeoutInternal')),
                        );
                }
                resolve(res);
            },
            300,
        );
    });
}
