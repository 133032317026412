import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import { useHistory } from 'react-router-dom';
import TableCore from '../TableCore';
import DeviceDetailsModal from './DeviceDetailsModal';
import { TableRowDrawer } from '../../../styles/common';
import { createColumnsData, createRowDeviceDetails } from './utils';
import { tableType, apiPath } from '../../../constants';
import {
    AppDispatchContext,
} from '../../../context/AppContext';
import { HelpDeskDispatchContext } from '../../../context/HelpDeskContext';
import { useLazyPatchData } from '../../../utils/hooks';

const DevicesTable = ({
    data: devicesData,
    columnsName = [],
    activeColumns = {},
    hideCheckboxes = false,
    hidePagination = false,
    onSelectedRow = () => {},
    actions = true,
    enableDeviceDetails = false,
    cardType,
    ...otherProps
}) => {
    const history = useHistory();
    const { location } = history || {};
    const { pathname } = location || {};
    const dispatch = useContext(AppDispatchContext);
    const helpDeskDispatch = useContext(HelpDeskDispatchContext);
    const { t } = useTranslation();
    const [clickedRowIndex, setClickedRowIndex] = useState();
    // store current selected details data of the credentials
    const [deviceDetailsData, setDetailsData] = useState({});
    // toggle details modal
    const [isOpen, setIsOpen] = useState(false);
    const onCurrentPageChange = () => {
        setClickedRowIndex(null);
    };

    const devicesRef = useRef(devicesData);

    const handleSuccess = async (data) => {
        // update device table
        const currentDevices = [...devicesRef.current];

        const { index, currentDevice } = getDeviceFromCredential(data);
        if (!currentDevice) return; 
        await fetch(`${apiPath}/device/${currentDevice.type}/${currentDevice.serial}`, {
            method: 'GET',
            timeout: 5000,
        })
            .then((res) => res.json())
            .then((res) => {
                if(res.length > 0){
                    currentDevices[index] = res[0];
                } else {
                    currentDevices.splice(index, 1)
                }
            })
            .catch((err) => console.error(err));
        dispatch({ type: 'SET_DEVICES', devices: currentDevices });
        helpDeskDispatch({ type: 'SET_SEARCH_DATA', payload: currentDevices });
        dispatch({ type: 'REMOVE_LOADING_CREDENTIAL_ID', id: data.identifier });
    };

    const handleCredentialActionError = (error) => {
        const endpointArr = error.endpoint.split('/');
        const identifier = endpointArr[endpointArr.length -1]; 
        dispatch({ type: 'REMOVE_LOADING_CREDENTIAL_ID', id: identifier });
    }

    useEffect(() => {
        devicesRef.current = devicesData;
    }, [devicesData]);

    const [patchCallback] = useLazyPatchData({
        onSuccess: handleSuccess,
        onError: handleCredentialActionError
    });

    const redirectionCallback = (href, credential) => {
        const { currentDevice } = getDeviceFromCredential(credential);
        if (!currentDevice) return; 
        dispatch({
            type: 'SET_DEVICE',
            device: currentDevice,
            goBackLink: pathname,
        });
        history.push(href);
    }

    const getDeviceFromCredential = (credential) => {
        const currentDevices = [...devicesRef.current];
        for(const [index, currentDevice] of currentDevices.entries()) {
            const { credentials = [] } = currentDevice;
            for(const cred of credentials) {
                if (cred.identifier === credential.identifier) {
                    return {index, currentDevice};
                }
            };
        };
        return undefined;
    }

    // create column definations for each row
    const columnsData = createColumnsData({
        columnsName,
        actions,
        t,
        clickedRowIndex,
        enableDeviceDetails,
        setClickedRowIndex,
        pathname,
        dispatch,
        history,
        cardType,
    });
    const hideColumn = [];
    columnsName.forEach((name) => {
        if (!activeColumns[name]) {
            hideColumn.push(name);
        }
    });

    const getColumnWidth = (width) => {
        if (!width) return {};
        return {
            style: {
                width,
            },
        };
    };

    const setWidthForStatusAndActionColumn = (column) => {
        let res;
        switch (column.id) {
            case 'status':
                res = getColumnWidth('6rem');
                res.style.flexGrow = 0;
                break;
            case 'user':
                res = getColumnWidth('10rem');
                res.style.marginRight ='1rem';
                break;
            case 'friendlyName':
                res = getColumnWidth('15rem');
                break;
            case 'action':
                res = getColumnWidth('15rem');
                break;
            case 'expander':
                res = getColumnWidth('4rem');
                res.style.flexGrow = 0;
                break;
            case 'logo':
                res = getColumnWidth('3rem');
                break;
            case 'type':
                res = getColumnWidth('4rem');
                res.style.flexGrow = 0;
                break;
            case 'selection':
                res = getColumnWidth('2rem');
                break;
            case 'data':
                res = getColumnWidth('4rem');
                res.style.flexGrow = 50;
                break;
            case 'provider':
                res = getColumnWidth('4rem');
                res.style.flexGrow = 50;
                break;
            default:
                res = {};
        }
        return res;
    };

    const setDetailsModalData = (data) => {
        setIsOpen(true);
        setDetailsData(data);
    };

    const getRowDetails = (row) => {
        if (!row || !row.original) return null;
        const { credentials = [], keyHistoryCredentials = [], subType, user } = row.original;
        // put active credentials at the begining.
        const credentialsData = credentials.concat(keyHistoryCredentials);
        if (credentials.length === 0) {
            return <></>;
        }
        let showActionCol = false;
        const credData = credentialsData.map((data) => {
            if (
                showActionCol === false &&
                !!data.actions &&
                data.actions.length > 0
            ) {
                showActionCol = true;
            }
            const d = {...data}
            d.subType = subType
            return d
        });
        // create column names
        const columnSet = new Set();
        credentials.forEach((cred) => {
            Object.keys(cred).forEach(item => columnSet.add(item));
        })
        const columnsName = Array.from(columnSet) || [];
        // JIRA: PM-2432 : Add name as 2nd column to credential sub - table
        columnsName.splice(1, 0, 'name')
        const columnsData = createRowDeviceDetails({
            columnsName,
            setDetailsModalData,
            showActionCol,
            pathname,
            dispatch,
            patchCallback,
            t,
            redirectionCallback,
            deviceUser: user
        });

        return (
            <TableRowDrawer
                maxHeight="17rem"
                isExpanded={clickedRowIndex === row.index}
            >
                <TableCore
                    tableType={tableType.rowDetailes}
                    data={!isEmpty(credData) ? credData : []}
                    columnsData={columnsData}
                    hideCheckboxes
                    hidePagination
                    hideTableHeader
                    columnsName={columnsName}
                    setColumnStyle={setWidthForStatusAndActionColumn}
                    hideColumn={['deviceType', 'value']}
                />
            </TableRowDrawer>
        );
    };

    return (
        <>
            {!!deviceDetailsData.identifier && (
                <DeviceDetailsModal
                    data={deviceDetailsData}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                />
            )}
            <TableCore
                tableType={tableType.device}
                data={!isEmpty(devicesData) ? devicesData : []}
                columnsData={columnsData}
                onSelectedRow={onSelectedRow}
                hideCheckboxes={hideCheckboxes}
                hidePagination={hidePagination}
                columnsName={columnsName}
                hideColumn={hideColumn}
                setColumnStyle={setWidthForStatusAndActionColumn}
                onPageChange={onCurrentPageChange}
                rowDetails={enableDeviceDetails && getRowDetails}
                {...otherProps}
            />
        </>
    );
};

export default DevicesTable;
