import React, { useContext } from 'react';
import { Nav, Navbar } from 'reactstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AppStateContext } from '../../context/AppContext';

const Label = styled.li`
    margin-top: 15px;
    text-align: center;
    font-size: 13px;
    color: ${(props) => props.theme.typography.footer} !important;
    margin-bottom: 15px;
`;
const FooterBar = styled(Navbar)`
    color: ${(props) => props.theme.typography.footer} !important;
    background-color: ${(props) => props.theme.colors.foreground} !important;
`;

const Footer = () => {
    const { settings } = useContext(AppStateContext);
    const { t } = useTranslation();
    return (
        <footer role="contentinfo">
            <FooterBar fixed="bottom" expand="md">
                <Nav className="mx-auto footer-navbar" aria-label="footer">
                    <Label>
                        {`${t('scaffoldingLabels$footer$copyright', {
                            YEAR: new Date().getFullYear(),
                        })} ${settings.version}`}
                    </Label>
                </Nav>
            </FooterBar>
        </footer>
    );
};

export default Footer;
