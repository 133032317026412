import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { EmergencyActionButtonWrapper } from '../../../styles/common';
import { severeColor, infoColor } from '../../../constants';
import { helpDeskActionTypes } from '../../../utils/mappings';

const TPAActionButton = ({
    onClick = () => { },
    data,
    revokeFailed,
    isRevoking,
    revokedDevice
}) => {
    const { t } = useTranslation();
    const isTpaRevokeFailed = data.type === 'tmpPwd' && revokeFailed;
    const getStatusColor = () => {
        if (data.actions[0] === 'revoke' || isTpaRevokeFailed) {
            return severeColor;
        }
        return infoColor;
    };

    if (!data || !data.actions || data.actions.length === 0) return null;
    if ((data.type === 'tmpPwd' && revokedDevice === undefined && isRevoking) || (data.serial === revokedDevice && isRevoking)) {
        return (
            <LoadingSpinner
                noContainer
                spinnerStyle={{ width: '1rem', height: '1rem' }}
            />
        );
    }
    const handleInteraction = !isTpaRevokeFailed && data.type === 'tmpPwd' ? onClick : () => { onClick(data) }
    
    const handleKeyDown = (e) => {
        if(e.keyCode === 13) { 
            handleInteraction(e)
        }
    }

    const getButtonLabel = () => {
        if(data?.actions[0]=== 'REVOKED') return t(`mappingsLabels$actionTypes$revokeSecurityQuestion$text`)
        return t(helpDeskActionTypes[data.actions[0]].text)
    }

    return (
        <EmergencyActionButtonWrapper
            $useDefaultBg={isTpaRevokeFailed}
            onClick={handleInteraction}
            onKeyDown={handleKeyDown}
            id={`status_${data.type}`}
            status={{ ...getStatusColor() }}
            tabIndex={0}
        >
            {isTpaRevokeFailed
                ? t(`helpDesk$action$revokeFailed`)
                : getButtonLabel()}
        </EmergencyActionButtonWrapper>
    );
};

export default TPAActionButton;
