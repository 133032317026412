import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import AutoSuggestWithData from './AutoSuggestWithData';
import { useDidMount, useFetchData } from '../../../utils/hooks';
import LoadingSpinner from '../../shared/LoadingSpinner';

const AutoSuggest = ({ registerForm, serverComponent, setNextShow }) => {
    const { t } = useTranslation();
    const [placeholder, setPlaceholder] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [userListLoading, userListData] = useFetchData({
        endpoint: '/delegation/associate',
    });

    const validator = (value) =>
        suggestions.includes(value) ? true : t('errorMsgs$userId');

    useDidMount(() => {
        if (registerForm) {
            registerForm({
                autoSuggest: validator,
            });
        }
        setPlaceholder(t('formLabels$autoSuggest$placeholderEmpty'));
    });

    useEffect(() => {
        if (userListData && userListData.length > 0) {
            setSuggestions(userListData);
            setPlaceholder(t('formLabels$autoSuggest$placeholder'));
        }
    }, [userListData, t]);

    if (userListLoading) {
        return <LoadingSpinner />;
    }
    return (
        <AutoSuggestWithData
            serverComponent={serverComponent}
            suggestions={suggestions}
            validator={validator}
            placeholder={placeholder}
            setNextShow={setNextShow}
        />
    );
};

AutoSuggest.propTypes = {
    registerForm: PropTypes.func.isRequired,
    serverComponent: PropTypes.object,
};

AutoSuggest.defaultProps = {
    serverComponent: undefined,
};

export default AutoSuggest;
