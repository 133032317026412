import React, { useEffect, useState, useRef, forwardRef } from 'react';
import {
    useTable,
    useRowSelect,
    useSortBy,
    useRowState,
    usePagination,
    useFlexLayout,
} from 'react-table';
import uniqueId from 'lodash.uniqueid';
import styled from 'styled-components';
import {
    StyledTableRow,
    StyledTable,
    StyledTableHead,
    StyledTableHeadRow,
    StyledTableBody,
    StyledColumnHeader,
    TableWrapper,
    VerticalLine,
    StyledCheckbox,
} from '../../../styles/common';
import SortIcon from '../../shared/SortIcon';
import TablePagination from '../../shared/TablePagination';
import TableSettings from '../../shared/TableSettings';
import { renderCell } from '../../../utils/generic';

const TableSettingsWrapper = styled.div`
    text-align: right;
`;

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <StyledCheckbox type="checkbox" ref={resolvedRef} {...rest} />;
});

const ReportsTable = ({
    data = [{}],
    columnsName = [''],
    columnsData = [{}],
    selectedColumnsKey = '',
    hidePagination = false,
    hideCheckboxes = false,
    onColumnsChange = () => {},
}) => {
    const [isCheckAllClicked, setIsCheckAllClicked] = useState(false);
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headers,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        rows,
        selectedFlatRows,
    } = useTable(
        {
            columns: columnsData,
            data,
            initialState: { 
                pageIndex: 0,
                hiddenColumns: columnsData
                    .filter(column => !column.isVisible)
                    .map(column => column.accessor),
            },
        },
        useSortBy,
        useFlexLayout,
        usePagination,
        useRowSelect,
        useRowState,
        ({ flatColumns }) => {
            !hideCheckboxes &&
                flatColumns &&
                flatColumns.push((columns) => [
                    {
                        id: 'selection',
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <IndeterminateCheckbox
                                onClick={() => setIsCheckAllClicked(true)}
                                {...getToggleAllRowsSelectedProps()}
                            />
                        ),
                        Cell: ({ row: { getToggleRowSelectedProps } }) => (
                            <IndeterminateCheckbox
                                onClick={() => setIsCheckAllClicked(false)}
                                {...getToggleRowSelectedProps()}
                            />
                        ),
                    },
                    ...columns,
                ]);
        },
    );

    useEffect(() => {
        if (isCheckAllClicked) {
            const pageRows = selectedFlatRows.filter((a) =>
                page.some((b) => a.id === b.id),
            );
            // eslint-disable-next-line array-callback-return
            rows.map((row) => {
                prepareRow(row);
                if (!pageRows.includes(row)) {
                    row.toggleRowSelected(false);
                }
            });
            setIsCheckAllClicked(false);
        }
    }, [page, selectedFlatRows, isCheckAllClicked, rows, prepareRow]);

    const onPageSizeChange = (value) => value && setPageSize(value);

    return (
        <>
            <TableWrapper>
                <StyledTable
                    hover
                    borderless
                    className="mTable"
                    {...getTableProps()}
                >
                    <StyledTableHead>
                        <StyledTableHeadRow>
                            {headers.map(
                                (column) =>
                                    column.isVisible && (
                                        <div
                                            key={uniqueId('usersTableHeader_')}
                                            id={`usersTableHeader_${column.id}`}
                                            name={column.id}
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps(),
                                            )}
                                        >
                                            <StyledColumnHeader>
                                                {column.render('Header')}
                                            </StyledColumnHeader>
                                            <SortIcon
                                                isSorted={column.isSorted}
                                                isSortedDesc={
                                                    column.isSortedDesc
                                                }
                                                hide={column.id === 'actions'}
                                            />
                                        </div>
                                    ),
                            )}
                        </StyledTableHeadRow>
                    </StyledTableHead>
                    <StyledTableBody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <StyledTableRow
                                    key={uniqueId('userRow_')}
                                    id={`userRow_${row.id}`}
                                    {...row.getRowProps()}
                                >
                                    {row.cells.map((cell) => (
                                        <div
                                            key={uniqueId('userCell_')}
                                            id={`userCell_${cell.column.id}-${row.id}`}
                                            {...cell.getCellProps()}
                                        >
                                            {renderCell(cell)}
                                        </div>
                                    ))}
                                </StyledTableRow>
                            );
                        })}
                    </StyledTableBody>
                </StyledTable>
            </TableWrapper>
            <TableSettingsWrapper>
                <>
                    <TablePagination
                        gotoPage={gotoPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        pageIndex={pageIndex}
                        pageCount={pageCount}
                        totalCount={data.length}
                    />
                    <VerticalLine />
                </>
                <TableSettings
                    onPageSizeChange={onPageSizeChange}
                    onColumnsChange={onColumnsChange}
                    selectedColumnsKey={selectedColumnsKey}
                    columnsName={columnsName}
                    pageSize={pageSize}
                    hidePagination={hidePagination}
                />
            </TableSettingsWrapper>
        </>
    );
};

export default ReportsTable;
