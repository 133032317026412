import React, { useState, useContext, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PROGRESS_BAR_DONE_DELAY, vscPlaceholder } from '../../constants';
import CardTemplate from '../shared/CardTemplate';
import ProgressTemplate from '../shared/ProgressTemplate';
import EnrollTemplate from '../shared/EnrollTemplate';
import FlowButtons from '../elementLibrary/FlowButtons';
import { EnrollingStateContext } from '../../context/EnrollingContext';
import VscCreationSelection from '../elementLibrary/VscCreationSelection';

const EnrollVscCard = ({ mgmt = null }) => {
    const { t } = useTranslation();
    const { reader, username } = useContext(EnrollingStateContext);
    const [showNext, setShowNext] = useState(false);

    useEffect(() => {
        if (reader && reader.serial !== vscPlaceholder.serial && username) {
            setShowNext(true);
            setTimeout(mgmt.next, PROGRESS_BAR_DONE_DELAY);
        }
    }, [reader, username, mgmt]);

    return (
        <CardTemplate title={t('cardTitles$issueIdentity')} noFade>
            <EnrollTemplate
                title={t('issuanceProgress')}
                alert={t('enrollTemplateAlertDefault')}
            >
                <ProgressTemplate
                    isFake
                    isDone={showNext}
                    stage={t('deviceActionsStage$vsc')}
                />
            </EnrollTemplate>
            <FlowButtons
                mgmt={{
                    next: mgmt ? mgmt.next : '',
                    showNext,
                    back: mgmt ? mgmt.back : '',
                    showBack: false,
                }}
            />
            <VscCreationSelection username={username} />
        </CardTemplate>
    );
};

EnrollVscCard.propTypes = {
    mgmt: PropTypes.object,
};

EnrollVscCard.defaultProps = {
    mgmt: null,
};

export default EnrollVscCard;
