import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AccentButton,
    StyledDefaultModalHeader,
    StyledDefaultModalBody,
    StyledDefaultModalFooter,
    StyledAlert,
} from '../../styles/common';
import { useHandleEnter, useDeleteData } from '../../utils/hooks';
import { AppDispatchContext } from '../../context/AppContext';
import { ReportsStateContext, ReportsDispatchContext } from '../../context/ReportContext';

const DeleteReport = () => {
    const dispatch = useContext(AppDispatchContext);
    const { currentReport } = useContext(ReportsStateContext);
    const reportsDispatch = useContext(ReportsDispatchContext);
    const { t } = useTranslation();
    const [deleteCallback, { data }] = useDeleteData({
        endpoint: '/setting/config/reports',
    });

    const deleteReport = () => {
        deleteCallback(
            currentReport.name,
        );
    };

    useEffect(() => {
        if (data) {
            reportsDispatch({
                type: 'TRIGGER_INVENTORY_FETCH',
                payload: true,
            });
            dispatch({
                type: 'CLEAR_MODAL',
            });
        }
    }, [data, dispatch, reportsDispatch]);

    useHandleEnter({ action: deleteReport });

    return (
        <>
            <StyledDefaultModalHeader>
                {t(`modalLabels$deleteReport$title`)}: {currentReport.name}
            </StyledDefaultModalHeader>
            <StyledDefaultModalBody>
                <StyledAlert color="info">
                    {t(`modalLabels$deleteReport$info`)}: {currentReport.name}
                </StyledAlert>
            </StyledDefaultModalBody>
            <StyledDefaultModalFooter>
                <AccentButton
                    outline
                    color="primary"
                    size="sm"
                    onClick={deleteReport}
                >
                    {t('modalLabels$deleteReport$submit')}
                </AccentButton>
                <AccentButton
                    outline
                    color="primary"
                    size="sm"
                    onClick={() =>
                        dispatch({
                            type: 'CLEAR_MODAL',
                        })
                    }
                >
                    {t('modalElementLabels$details$modal$button')}
                </AccentButton>
            </StyledDefaultModalFooter>
        </>
    );
};

export default DeleteReport;
