import React, {
    useContext,
    useEffect,
    useReducer,
    useRef,
    useState,
} from 'react';
import { Col, FormGroup, Input } from 'reactstrap';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    searchBarFormId,
    searchBarId,
    searchBarInputId,
    searchBarBtnId,
} from '../../../constants';
import { StyledRefreshBtn } from '../../../styles/common';
import { BrandingStateContext } from '../../../context/BrandingContext';
import { useDidMount, usePostData } from '../../../utils/hooks';
import { sleep } from '../../../utils/generic';

const StyledSearchBar = styled(CreatableSelect)`
    padding-top: 8px !important;
    margin-right: 41px !important;
    [class*='-placeholder'] {
        color: #666666;
    }
`;

const StyledKeywordLabel = styled.div`
    display: inline;
    border-radius: 2px;
    color: hsl(0, 0%, 20%);
    font-size: 85%;
    overflow: hidden;
    padding: 3px;
    padding-left: 6px;
    display: inline;
    font-weight: 600;
`;

const KeywordsList = styled.div`
    margin-top: 0px !important;
    top: 100%;
    background-color: hsl(0, 0%, 100%);
    border-radius: 0;
    box-shadow: 0 0 0 0px hsla(0, 0%, 0%, 0.1), 0 3px 10px hsla(0, 0%, 0%, 0.1);
    margin-bottom: 8px;
    position: absolute;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
`;

const KeywordsListBody = styled.div`
    text-align: left;
    margin-top: 4px;
    margin-bottom: 4px;
`;

const KeywordsListHeader = styled.div`
    background-color: #f9f9f9 !important;
    color: #121212;
    font-weight: 500 !important;
    font-size: 14px !important;
    padding: 8px 8px;
`;

const KeywordsListRow = styled.span`
    display: block;
    &:hover {
        background-color: #e5edff;
        font-weight: 500;
        cursor: pointer;
    }
    background-color: transparent;
    color: inherit;
    cursor: default;
    display: block;
    font-size: inherit;
    padding: 8px 16px;
    width: 100%;
`;

const StyledInput = styled(Input)`
    font-size: inherit !important;
    height: 25px !important;
    padding-left: 5px !important;
    text-align: center !important;
    border: 1px solid #d8d8d8 !important;
    border-radius: 4px !important;
    box-shadow: none !important;
`;

const parseInputValue = (value) => {
    const tokens = value.trim().split(':');
    if (tokens.length !== 2) return {};
    return {
        keyword: tokens[0].trim(),
        value: tokens[1].trim(),
    };
};

const customStyles = (colors) => ({
    option: (provided) => ({
        ...provided,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 8,
    }),
    control: (provided) => ({
        ...provided,
        // none of react-select's styles are passed to <Control />
        width: 'auto',
        display: 'flex',
        border: '1px solid #d8d8d8',
        boxShadow: colors.accent,
        '&:hover': {
            border: '1px solid #d8d8d8',
        },
        borderRadius: '4px 0 0 4px !important',
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: 13,
    }),
    menu: (provided) => ({
        ...provided,
        display: 'contents',
    }),
    menuList: (provided) => ({
        ...provided,
        width: '100%',
        maxHeight: 250,
        backgroundColor: '#fefefe',
        zIndex: '100 !important',
        position: 'absolute',
        boxShadow:
            '0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1) !important',
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#f1f1f1',
        zIndex: '100 !important',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        paddingLeft: 6,
        display: 'inline',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        height: 21,
    }),
    crossIcon: (provided) => ({
        ...provided,
        marginTop: 4,
        marginBottom: 4,
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        display: 'none',
    }),
});

const initialState = {
    searchInput: '',
    isKeywordListOpen: false,
    isDefaultSearch: false,
};

const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'CLEAR_INPUT':
            return { ...state, searchInput: '' };
        case 'SEARCH_INPUT':
            return { ...state, searchInput: payload, isDefaultSearch: false };
        case 'DEFAULT_SEARCH_CLICK':
            return {
                ...state,
                searchInput: payload,
                isDefaultSearch: false,
            };
        case 'TYPE_INPUT':
            return {
                ...state,
                searchInput: payload.value,
                isDefaultSearch: payload.defaultSearch,
            };
        case 'LIST_KEYWORDS':
            return { ...state, isKeywordListOpen: payload };
        case 'RESET_STATE':
            return { ...initialState };
        case 'SET_EDITING_FILTER':
            return { ...state, editingFilter: payload };
        default:
            return { ...state };
    }
};

const MultiValueRemove = (props) => {
    const {
        data,
        selectProps: { editingFilter },
    } = props;
    if (editingFilter && data && editingFilter.value === data.value)
        return <></>;
    return <components.MultiValueRemove {...props} />;
};

const MultiValueLabel = (props) => {
    const {
        data,
        selectProps: {
            onClickLabel,
            setEditingFilter,
            handleEditLabel,
            loading,
            filterOption,
        },
    } = props;
    const { value: dataValue } = data;
    const { keyword, value } = parseInputValue(dataValue);
    const [editMode, setEditMode] = useState(false);
    const [inputValue, setInputValue] = useState();
    const inputRef = useRef();

    useEffect(() => {
        if (editMode) {
            setInputValue(dataValue);
            inputRef.current.focus();
        }
    }, [editMode, dataValue]);

    const onInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleExitEditMode = () => {
        handleEditLabel({
            data,
            newLabel: inputValue,
        });
        setEditMode(false);
    };

    const handleInputKeyDown = (e) => {
        e.stopPropagation();
        if (e.keyCode === 13) {
            handleExitEditMode();
        }
    };

    const handleBlur = () => {
        handleExitEditMode();
    };

    if (editMode)
        return (
            <div onClick={onClickLabel}>
                <StyledInput
                    innerRef={inputRef}
                    value={inputValue}
                    onChange={onInputChange}
                    onKeyDown={handleInputKeyDown}
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                    onBlur={handleBlur}
                />
            </div>
        );

    return (
        <div
            onClick={() => {
                onClickLabel();
            }}
            onDoubleClick={() => {
                if (loading) return;
                setEditingFilter(data);
                setEditMode(true);
            }}
        >
            {filterOption([], dataValue) ? (
                <>
                    <StyledKeywordLabel>{`${keyword}: `}</StyledKeywordLabel>
                    <span>{value}</span>
                </>
            ) : (
                <StyledKeywordLabel>{dataValue}</StyledKeywordLabel>
            )}
        </div>
    );
};

const Control = (props) => {
    const {
        selectProps: {
            keywords,
            isDropdownOpen,
            searchBarRef,
            onSelect,
            isDefaultSearch,
            inputValue,
            editingFilter,
            handleClickSearch,
            isKeywordListOpen,
        },
    } = props;
    const { t } = useTranslation();
    const defaultKeyword = t(`searchBar$keyword$${keywords[0]}`) || keywords[0];
    const { keyword, value } = parseInputValue(inputValue);
    const searchMessage = `${t('searchBar$searchByMsg')}${
        isDefaultSearch
            ? ` ${defaultKeyword}: ${inputValue}`
            : ` ${keyword}: ${value}`
    }`;

    const onClickItem = async (keyword = '', value = '') => {
        onSelect(`${keyword}:${value}`);
        await sleep(50);
        searchBarRef.current.select.focus();
    };

    return (
        <>
            <components.Control {...props} />
            {!editingFilter && (
                <KeywordsList>
                    {inputValue && isKeywordListOpen && (
                        <KeywordsListBody>
                            <KeywordsListRow
                                onMouseDown={() =>
                                    handleClickSearch(inputValue)
                                }
                            >
                                {searchMessage}
                            </KeywordsListRow>
                        </KeywordsListBody>
                    )}
                    {isDropdownOpen && (
                        <>
                            <KeywordsListHeader>
                                {`${t('searchBar$searchByMsg')}:`}
                            </KeywordsListHeader>
                            <KeywordsListBody>
                                {keywords &&
                                    keywords.map((keyword) => (
                                        <KeywordsListRow
                                            id={keyword}
                                            key={keyword}
                                            onMouseDown={() =>
                                                onClickItem(keyword)
                                            }
                                        >
                                            {t(
                                                `searchBar$keyword$${keyword}`,
                                            ) || keyword}
                                        </KeywordsListRow>
                                    ))}
                            </KeywordsListBody>
                        </>
                    )}
                </KeywordsList>
            )}
        </>
    );
};

const InputComponent = (props) => (
    <components.Input {...props} id={searchBarInputId} />
);

const SearchBarWithData = ({
    endpoint = '',
    keywords = [],
    onLoading = () => {},
    onSearchData = () => {},
    onClearData = () => {},
    onFiltersChange = () => {},
    onSelect = () => {},
    defaultValue = [],
    searchFilter = {},
    graphQLBody = null,
    queryParams = {},
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        theme: { colors },
    } = useContext(BrandingStateContext);
    const searchBarRef = useRef();
    const { t } = useTranslation();
    const [searchCallback, { loading, data }] = usePostData({
        endpoint,
        graphQL: !!graphQLBody,
        queryParams
    });
    const loadingRef = useRef(null);
    const dataRef = useRef(null);

    const styles = customStyles(colors);

    const { searchInput, isKeywordListOpen, isDefaultSearch, editingFilter } =
        state;

    useEffect(() => {
        if (loadingRef.current !== loading) {
            loadingRef.current = loading;
            onLoading(loading);
        }
    }, [loading, onLoading]);

    useEffect(() => {
        if (dataRef.current !== data) {
            dataRef.current = data;
            const searchData = graphQLBody
                ? data && data.data && data.data.deviceInfo
                : data;
            onSearchData(searchData);
        }
    }, [data, onSearchData, graphQLBody]);

    const onSearch = (search) => {
        const filters = [];
        Object.keys(search).forEach((key) => {
            filters.push({
                name: key,
                values: search[key],
            });
        });
        searchCallback(graphQLBody ? graphQLBody(filters) : { filters });
    };

    const toggleKeywordList = () =>
        dispatch({ type: 'LIST_KEYWORDS', payload: !isKeywordListOpen });

    const filterOption = (option, inputValue) => {
        const { keyword, value } = parseInputValue(inputValue);
        return value && keyword && keywords.includes(keyword);
    };

    const formatCreateLabel = () => {
        const { value } = parseInputValue(searchInput);
        return t('searchBar$searchForMsg', { VALUE: value });
    };

    const handleOnSelect = (options) => {
        onSelect(options);
        if (options === null) {
            onClearData();
            return;
        }
        const filters = {};
        if (options && options.length > 0) {
            options
                .filter((option) => filterOption([], option.value))
                .forEach((option) => {
                    const { keyword, value } = parseInputValue(option.value);
                    if (filters[keyword]) {
                        if (filters[keyword].includes(value)) return;
                        filters[keyword].push(value);
                    } else {
                        filters[keyword] = [value];
                    }
                });
        }
        onFiltersChange(filters);
        if (Object.keys(filters).length > 0 && !loading) {
            onSearch(filters);
        } else {
            onSearchData([]);
        }
        dispatch({ type: 'LIST_KEYWORDS', payload: false });
    };

    useDidMount(() => {
        onFiltersChange({});
        handleOnSelect(defaultValue);
    });

    const handleInputChange = (value, params) => {
        const { action } = params || {};
        const defaultSearch = value !== '' && value.indexOf(':') === -1;
        if (action !== 'input-blur' && action !== 'menu-close') {
            dispatch({ type: 'TYPE_INPUT', payload: { value, defaultSearch } });
        }
    };

    const handleClickSearch = (event, skipDefault = false) => {
        let modifiedSearchInput = searchInput;
        // for ui, remove 'default search' dropdown
        if (!skipDefault && isDefaultSearch && keywords) {
            modifiedSearchInput = `${keywords[0]}:${modifiedSearchInput}`;
            dispatch({
                type: 'DEFAULT_SEARCH_CLICK',
                payload: modifiedSearchInput,
            });
        }
        // add previously created search filters to a search list, which gets used if a new search filter is added
        const search = defaultValue ? [...defaultValue] : [];
        // see if the user input warrants a new search
        // else fire a search if filters already exist
        const { keyword, value } = parseInputValue(modifiedSearchInput);
        if (
            filterOption([], modifiedSearchInput) &&
            (!searchFilter[keyword] || !searchFilter[keyword].includes(value))
        ) {
            search.push({
                label: `${keyword}:${value}`,
                value: `${keyword}:${value}`,
                __isNew__: true,
            });
            searchBarRef.current.select.onChange(search, {
                action: 'create-option',
                name: 'search',
            });
            dispatch({ type: 'CLEAR_INPUT' });
        } else if (defaultValue !== null && defaultValue.length > 0) {
            handleOnSelect(defaultValue);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleClickSearch();
        }
    };

    const handleBlur = () =>
        dispatch({ type: 'LIST_KEYWORDS', payload: false });

    const handleEditLabel = ({ data, newLabel }) => {
        dispatch({ type: 'SET_EDITING_FILTER', payload: null });
        if (data.value === newLabel) return;
        const optionSet = new Set();
        const newDefaultValue = [...defaultValue]
            .map((option) => {
                if (option.value === data.value) {
                    if (filterOption([], newLabel)) {
                        const { keyword, value } = parseInputValue(newLabel);
                        return {
                            label: `${keyword}:${value}`,
                            value: `${keyword}:${value}`,
                            __isNew__: true,
                        };
                    }
                    return {
                        label: newLabel,
                        value: newLabel,
                        __isNew__: true,
                    };
                }
                return option;
            })
            .filter((option) => {
                if (optionSet.has(option.value) || !option.value.trim()) {
                    return false;
                }
                return optionSet.add(option.value);
            });
        searchBarRef.current.select.onChange(newDefaultValue, {
            action: 'create-option',
            name: 'search',
        });
    };

    const setEditingFilter = (option) => {
        dispatch({ type: 'SET_EDITING_FILTER', payload: option });
    };

    return (
        <div data-test-id={searchBarFormId}>
            <FormGroup row autoComplete="off">
                <Col>
                    <StyledRefreshBtn
                        onMouseDown={handleClickSearch}
                        id={searchBarBtnId}
                        aria-label="Refresh Search"
                    >
                        <FontAwesomeIcon icon="search" />
                    </StyledRefreshBtn>
                    <div
                        onClick={toggleKeywordList}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                    >
                        <StyledSearchBar
                            autoFocus
                            autoBlur
                            isMulti
                            isClearable
                            isSearchable
                            id={searchBarId}
                            ref={searchBarRef}
                            name="search"
                            placeholder={t('searchBar$placeholder')}
                            inputValue={searchInput}
                            onInputChange={handleInputChange}
                            onChange={handleOnSelect}
                            noOptionsMessage={() => null}
                            formatCreateLabel={formatCreateLabel}
                            components={{
                                MultiValueLabel,
                                Control,
                                Input: InputComponent,
                                MultiValueRemove,
                            }}
                            handleClickSearch={handleClickSearch}
                            styles={styles}
                            filterOption={filterOption}
                            isValidNewOption={() => false}
                            defaultValue={defaultValue}
                            // custom props
                            onClickLabel={handleBlur}
                            keywords={keywords}
                            isKeywordListOpen={isKeywordListOpen}
                            isDropdownOpen={
                                searchInput === '' && isKeywordListOpen
                            }
                            isDefaultSearch={isDefaultSearch}
                            editingFilter={editingFilter}
                            loading={loading}
                            onSelect={handleInputChange}
                            handleEditLabel={handleEditLabel}
                            setEditingFilter={setEditingFilter}
                            searchBarRef={searchBarRef}
                            aria-label="Search"
                        />
                    </div>
                </Col>
            </FormGroup>
        </div>
    );
};

export default SearchBarWithData;
