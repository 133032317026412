import React from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import TableCore from '../TableCore';
import UserAction from './UserAction';
import UserLink from './UserLink';
import { sortString } from '../../../utils/generic';
import { tableType } from '../../../constants';

const cellTypeSelect = (name, data, t, rowId) => {
    switch (name) {
        case 'actions':
            return <UserAction data={{ ...data, rowId }} />;
        case 'username':
            return <UserLink data={data} />;
        default: {
            let displayedData = data[name];
            if (!displayedData && data.customAttributes) {
                const customAttribute =
                    data.customAttributes.find((attr) => attr.key === name) ||
                    {};
                displayedData = customAttribute.value;
            }
            if (Array.isArray(displayedData)) {
                return displayedData.join(', ') || ' ';
            }
            if (typeof displayedData === 'boolean') {
                return t(
                    `hideColumns$boolType$${displayedData ? 'true' : 'false'}`,
                );
            }
            return displayedData || ' ';
        }
    }
};

const createColumnsData = (columnsName, activeColumns, t) => {
    const parse = [];
    columnsName.forEach((name) => {
        parse.push({
            Header:
                t(`usersTable$column$${name}`) ||
                name[0].toUpperCase() + name.slice(1),
            accessor: name,
            Cell: ({ row }) => cellTypeSelect(name, row.original, t),
            isVisible: !!activeColumns[name],
            sortType: sortString,
        });
    });
    // Set actions column independently
    parse.push({
        Header: t(`usersTable$column$actions`),
        accessor: 'actions',
        Cell: ({ row }) => cellTypeSelect('actions', row.original, t, row.id),
        disableSortBy: true,
        isVisible: true,
    });
    return parse;
};

const UsersTable = ({
    data,
    columnsName = [],
    activeColumns = {},
    selectedColumnsKey = '',
    hidePagination = false,
    onColumnsChange = () => {},
    ...otherProps
}) => {
    const { t } = useTranslation();
    const hideColumn = [];
    columnsName.forEach((name) => {
        if (!activeColumns[name]) {
            hideColumn.push(name);
        }
    });
    const columnsData = createColumnsData(columnsName, activeColumns, t);
    const setWidthForColumns = (column) => {
        if (column.id === 'username' || column.id === 'email' || column.id === 'upn') {
            return {
                style: {
                    marginRight:'1rem'
                },
            };
        }
        return {};
    };

    return (
        <TableCore
            tableType={tableType.user}
            data={!isEmpty(data) ? data : []}
            columnsData={columnsData}
            columnsName={columnsName}
            hidePagination={hidePagination}
            selectedColumnsKey={selectedColumnsKey}
            onColumnsChange={onColumnsChange}
            setColumnStyle={setWidthForColumns}
            hideColumn={hideColumn}
            hideCheckboxes
            {...otherProps}
        />
    );
};

export default UsersTable;
