import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as log from 'loglevel';
import App from './components/App/App.jsx';
import { AppProvider } from './context/AppContext';
import { BrandingProvider } from './context/BrandingContext';
import { EnrollingProvider } from './context/EnrollingContext';
import { HelpDeskProvider } from './context/HelpDeskContext';
import { TabsProvider } from './context/TabsContext';
import { rootPath, LOGLEVEL_KEY } from './constants';
import { extractLoglevel } from './utils/generic';
import GlobalStyles from './styles/global';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/fonts.css';
import './index.css';
import './i18n';
import { ReportsProvider } from './context/ReportContext.jsx';

log.setDefaultLevel('SILENT');
log.setLevel(extractLoglevel(JSON.parse(sessionStorage.getItem(LOGLEVEL_KEY))));

if (log.getLevel() === log.levels.SILENT) {
    console.debug = () => {};
}

const Providers = ({ children }) => (
    <AppProvider>
        <TabsProvider>
            <EnrollingProvider>
                <HelpDeskProvider>
                    <ReportsProvider>
                        <BrandingProvider>{children}</BrandingProvider>
                    </ReportsProvider>
                </HelpDeskProvider>
            </EnrollingProvider>
        </TabsProvider>
    </AppProvider>
);

ReactDOM.render(
    <BrowserRouter basename={rootPath}>
        <Suspense fallback={<div />}>
            <Providers>
                <GlobalStyles />
                <App />
            </Providers>
        </Suspense>
    </BrowserRouter>,
    document.getElementById('root'),
);
