import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Spinner } from 'reactstrap';
import { BrandingStateContext } from '../../context/BrandingContext';

const StyledContainer = styled.div`
    padding: 12px;
    text-align: center;
`;
const StyledSpinner = styled(Spinner)`
    color: ${({ color }) => color} !important;
`;

const LoadingSpinner = ({ noContainer = false, spinnerStyle = {} }) => {
    const {
        theme: {
            colors: { primary },
        },
    } = useContext(BrandingStateContext);

    return noContainer ? (
        <StyledSpinner color={primary} style={spinnerStyle} />
    ) : (
        <StyledContainer className="loading-spinner">
            <StyledSpinner color={primary} style={spinnerStyle} />
        </StyledContainer>
    );
};

LoadingSpinner.propTypes = {
    color: PropTypes.string,
};

LoadingSpinner.defaultProps = {
    color: 'primary',
};

export default LoadingSpinner;
