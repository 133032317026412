import React, { useEffect, useContext, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MessageCard from '../cardLibrary/MessageCard';
import TerminusCard from '../cardLibrary/Terminus';
import ChangePinCard from '../cardLibrary/ChangePin';
import UserEntryCard from '../cardLibrary/UserEntry';
import ChangePinPivCard from '../cardLibrary/ChangePinPiv';
import FlowButtons from '../elementLibrary/FlowButtons';
import DeviceDetection from '../elementLibrary/DeviceDetection'; // eslint-disable-line
import LoadingSpinner from '../shared/LoadingSpinner';
import { AppDispatchContext, AppStateContext } from '../../context/AppContext';
import {
    EnrollingDispatchContext,
    EnrollingStateContext,
} from '../../context/EnrollingContext';
import { useLazyFetchData } from '../../utils/hooks';
import { PaddingDivider } from '../../styles/common';
import { getParentRoute } from '../../utils/generic';
import ValidateQuestionCard from '../cardLibrary/ValidateQuestion';
import { KIOSK_MODE } from '../../constants';
import DeviceNotFound from '../cardLibrary/DeviceNotFound';
import EnrollQuestionCard from '../cardLibrary/EnrollQuestion';

function reducer(state, action) {
    switch (action.type) {
        case 'SET_CHANGE_PIN_WORKFLOW':
            return {
                ...state,
                workflow: action.payload,
                fetching: false,
            };
        case 'SET_BACK_STEP':
            return {
                ...state,
                step: state.step - 1,
                detected: false,
            };
        case 'SET_NEXT_STEP':
            return {
                ...state,
                step: state.step + 1,
            };
        case 'SET_DETECTED_TRUE':
            return {
                ...state,
                detected: true,
            };
        case 'SET_CURRENT_PIN':
            return {
                ...state,
                device: {
                    ...state.device,
                    oldPin: action.payload,
                },
            };
        case 'SET_DEVICE':
            return {
                ...state,
                device: action.payload,
            };
        case 'SET_PASS':
            return {
                ...state,
                device: {
                    ...state.device,
                    pass: action.payload,
                },
            };
        case 'APPEND_DEVICE':
            return {
                ...state,
                device: {
                    ...state.device,
                    ...action.payload,
                },
            };
        case 'SET_READER':
            return {
                ...state,
                reader: action.payload,
            };
        case 'SET_CURRENT_ANSWER':
            return {
                ...state,
                challenges: {
                    ...state.challenges,
                    [action.payload.session]: action.payload.answer,
                },
            };
        default:
            return state;
    }
}

const ChangePin = () => {
    const appDispatch = useContext(AppDispatchContext);
    const {
        errorMsg: errorMessage,
        device,
        viewMode,
    } = useContext(AppStateContext);
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const { form, device: enrollingDevice } = useContext(EnrollingStateContext);
    const { t } = useTranslation();
    const history = useHistory();
    const { location } = history || {};

    const [state, dispatch] = useReducer(reducer, {
        workflow: [],
        fetching: true,
        step: 0,
        device,
        reader: '',
        detected: false,
    });

    const { type, serial, group, subType, user } = device || {};
    const { pathname } = location || {};
    const isKiosk = viewMode === KIOSK_MODE;

    const setChangePinWorkflow = (walkway) => {
        if (device) {
            const hasX509 = device.credentials.some(
                (credential) => {
                    return credential.type.toLowerCase() === 'x509' || credential.type === 'whfb'
                }
            );
            const currentWalkway = hasX509 
                ? walkway.filter(
                    (elem) => !elem.state || elem.state === 'hasX509',
                )
                : walkway.filter(
                    (elem) => !elem.state || elem.state === 'notHasX509',
                );

            const pivWorkflow = [
                {
                    type: 'messageCard',
                },
                ...currentWalkway,
            ];
            const piWorkflow = [
                ...currentWalkway,
                {
                    type: 'changePin',
                },
            ];

            // WHFB will re-use pivWorkflow, whose messageCard to detect device and pivChangePin to perform the cmd
            const updatedWorkflow = hasX509 ? pivWorkflow : piWorkflow;

            dispatch({
                type: 'SET_CHANGE_PIN_WORKFLOW',
                payload: [
                    ...updatedWorkflow,
                    {
                        type: 'terminus',
                    },
                ],
            });

            enrollingDispatch({
                type: 'SET_CREDENTIAL_FLOW',
                credentialFlow: currentWalkway,
            });
        }
    };

    const [fetchChangePinWalkway] = useLazyFetchData({
        endpoint: `/device/changePin/${type}/${serial}`,
        onSuccess: (res) => setChangePinWorkflow(res),
    });

    useEffect(() => {
        if (device) {
            const queryParams = group ? { group } : {};
            queryParams.subType = subType || undefined;
            queryParams.user = user || undefined;
            fetchChangePinWalkway(queryParams);
        }
        return () => {
            enrollingDispatch({
                type: 'RESET_FLOWS',
            });
            appDispatch({
                type: 'RESET_FLOWS',
            });
        };
    },[]);

    useEffect(() => {
        enrollingDispatch({
            type: 'SET_USERNAME',
            username: user,
        });
    }, [user, enrollingDispatch]);

    const deviceNotFound = () =>
        dispatch({
            type: 'SET_CHANGE_PIN_WORKFLOW',
            payload: [{ type: 'deviceNotFound' }],
        });

    const components = {
        /* eslint-disable react/prop-types */
        loadingSpinner: ({ title }, mgmt) => (
            <LoadingSpinner title={title} mgmt={mgmt} flowButtons />
        ),
        userEntry: ({ setPass, setCurrentPin, setCurrentAnswer }, mgmt) => (
            <UserEntryCard
                setPass={setPass}
                setCurrentPin={setCurrentPin}
                setCurrentAnswer={setCurrentAnswer}
                mgmt={{ ...mgmt, next: mgmt.uen, back: mgmt.homeBack }}
                device={device}
            />
        ),
        questionValidate: (thisProps, mgmt) => (
            <ValidateQuestionCard mgmt={mgmt} deviceUser={user} />
        ),
        changePin: ({ title, reader, device }, mgmt) => (
            <ChangePinCard
                title={title}
                reader={reader}
                device={device}
                mgmt={mgmt}
            />
        ),
        pivChangePin: ({ title, reader, device }, mgmt) => (
            <ChangePinPivCard
                title={title}
                reader={reader}
                device={device}
                mgmt={mgmt}
            />
        ),
        terminus: ({ title, errorMsg }) => {
            return (
                <TerminusCard
                    title={title}
                    errorMsg={errorMsg}
                    msg={t(
                        'changePinSuccessMsg'.concat(isKiosk ? '$kiosk' : ''),
                    )}
                />
            );
        },
        messageCard: ({ msg, title, alertStyle, device, setReader }, mgmt) => (
            <MessageCard
                msg={msg}
                title={title}
                alertStyle={alertStyle}
                mgmt={mgmt}
            >
                <DeviceDetection
                    device={device}
                    appDispatch={appDispatch}
                    enrollingDispatch={enrollingDispatch}
                    mgmt={mgmt}
                    setReader={setReader}
                    handleNotFound={deviceNotFound}
                />
                <PaddingDivider />
                <FlowButtons
                    mgmt={{
                        next: mgmt.next,
                        back: mgmt.back,
                        showBack: true,
                        showNext: false,
                    }}
                />
            </MessageCard>
        ),
        deviceNotFound: ({ title }) => <DeviceNotFound title={title} />,
        questionEnroll: (thisProps, mgmt) => (
            <EnrollQuestionCard stepId='questionEnroll' mgmt={mgmt} />
        ),
    };

    const homeBack = () => {
        const parentRoute = getParentRoute(pathname);
        history.push(parentRoute);
    };

    const setUserEntryEnrolling = (step) => {
        const { type } = state.workflow[step];
        if (type !== 'userEntry') {
            return;
        }
        enrollingDispatch({
            type: 'SET_USER_ENTRY',
            userEntry: state.workflow[step],
        });
    };

    const next = () => {
        if (state.step < state.workflow.length) {
            setUserEntryEnrolling(state.step + 1);
            dispatch({ type: 'SET_NEXT_STEP' });
        }
    };

    const back = () => {
        if (state.step > 0) {
            setUserEntryEnrolling(state.step - 1);
            dispatch({ type: 'SET_BACK_STEP' });
        }
    };

    const userEntryNext = () => {
        dispatch({
            type: 'APPEND_DEVICE',
            payload: {
                oldPin: form.values.currentPin,
                newPin: form.values.pin,
                newPinForm: (enrollingDevice || {}).pin,
            },
        });
        next();
    };

    const stepComp = state.workflow[state.step];

    if (!device) {
        const parentRoute = getParentRoute(pathname);
        history.push(parentRoute);
        return null;
    }
    return (
        <>
            {state.fetching ? (
                <LoadingSpinner />
            ) : (
                components[state.workflow[state.step].type](
                    {
                        errMsg: errorMessage,
                        title: t('cardTitles$changePin'),
                        device: state.device,
                        setCurrentAnswer: (answer, session) => {
                            dispatch({
                                type: 'SET_CURRENT_ANSWER',
                                payload: {
                                    answer,
                                    session,
                                },
                            });
                        },
                        setCurrentPin: (oldPin) =>
                            dispatch({
                                type: 'SET_CURRENT_PIN',
                                payload: oldPin,
                            }),
                        setPass: (pass) =>
                            dispatch({
                                type: 'SET_PASS',
                                payload: pass,
                            }),
                        setReader: (payload) =>
                            dispatch({
                                type: 'SET_READER',
                                payload,
                            }),
                        reader: state.reader,
                        userEntry:
                            stepComp.type === 'userEntry' ? stepComp : {},
                    },
                    {
                        workflow: state.workflow,
                        fetching: state.fetching,
                        step: state.step,
                        device: state.device,
                        reader: state.reader,
                        detected: state.detected,
                        challenges: state.challenges,
                        next,
                        showNext: false,
                        showBack: state.step === 0,
                        back: state.step === 0 ? homeBack : back,
                        setWorkflow: setChangePinWorkflow,
                        setDetected: async () =>
                            dispatch({ type: 'SET_DETECTED_TRUE' }),
                        uen: userEntryNext,
                        homeBack,
                    },
                )
            )}
        </>
    );
};

export default ChangePin;
