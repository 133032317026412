import React from 'react';
import { Badge } from 'reactstrap';
import styled from 'styled-components';

const StatusContainer = styled(Badge)`
    text-transform: uppercase;
    color: ${({ $color: { text } }) => text} !important;
    background-color: ${({ $color: { bg } }) => bg} !important;
    padding: 0.55rem !important;
    letter-spacing: 0.06em;
    font-size: 10px !important;
    font-weight: 700 !important;
`;

const labelColors = {
    yellow: {
        bg: '#feebc5',
        text: '#42526d',
    },
    green: {
        bg: '#e3fcef',
        text: '#14892c',
    },
    gray: {
        bg: '#ebecef',
        text: '#42526d',
    },
    red: {
        bg: '#fce3f0',
        text: '#892c14',
    },
};

const StatusLabel = ({ color = 'gray', label = '' }) => {
    return (
        <StatusContainer $color={labelColors[color]}>{label}</StatusContainer>
    );
};

export default StatusLabel;
