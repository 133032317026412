import React, { useContext, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { AppStateContext, AppDispatchContext } from '../../context/AppContext';
import { StyledModal } from '../../styles/common';

const PortalModal = ({ className, children }) => {
    const { modal } = useContext(AppStateContext);
    const dispatch = useContext(AppDispatchContext);

    const { show, type, closeAction, staticBackdrop } = modal;

    const clearModal = () => dispatch({ type: 'CLEAR_MODAL', modal });

    const onToggle = () => {
        closeAction && closeAction();
        clearModal();
    };

    const modalComponents = useMemo(() => {
        const ret = {};

        React.Children.forEach(children, (child) => {
            const { modalId } = child.props;
            if (!modalId || typeof modalId !== 'string') {
                throw new Error(
                    `Modal: component '${child.type.name}' has \
                    a modalId="${modalId}", all children of Modal \
                    must have a modalId prop that is a valid string`,
                );
            }
            ret[child.props.modalId] = child;
        });
        return ret;
    }, [children]);

    return (
        <StyledModal
            centered
            size="lg"
            isOpen={show}
            toggle={onToggle}
            className={className}
            backdrop={staticBackdrop ? 'static' : true}
        >
            {modalComponents[type]}
        </StyledModal>
    );
};

PortalModal.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

PortalModal.defaultProps = {
    className: '',
};

export default PortalModal;
