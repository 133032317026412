import React, { useContext } from 'react';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { identitiesCardId, userEndpoint } from '../../../constants';
import CardTemplate from '../../shared/CardTemplate';
import { useFetchData } from '../../../utils/hooks';
import {
    AppDispatchContext,
    AppStateContext,
} from '../../../context/AppContext';
import IdentitiesCardWithData from './IdentitiesCardWithData';

const IdentitiesCard = () => {
    const { user, devices } = useContext(AppStateContext);
    const dispatch = useContext(AppDispatchContext);

    const [loading] = useFetchData({
        endpoint: `${userEndpoint}/${user.username}/device`,
        onSuccess: (d) => {
            dispatch({ type: 'SET_DEVICES', devices: d });
        },
    });
    return (
        <CardTemplate testId={identitiesCardId}>
            {loading ? (
                <LoadingSpinner />
            ) : (
                devices && <IdentitiesCardWithData data={devices} loading={loading} />
            )}
        </CardTemplate>
    );
};

export default IdentitiesCard;
