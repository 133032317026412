import React, { useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Countdown from 'react-countdown';
import { postRecyclePiv } from '../../utils/walkways';
import {
    ucmsConstants as UCMS,
    PROGRESS_BAR_DONE_DELAY,
} from '../../constants';
import ProgressTemplate from '../shared/ProgressTemplate';
import { AppDispatchContext, AppStateContext } from '../../context/AppContext';
import CardEnrollLayout from '../shared/CardEnrollLayout';
import { useDidMount } from '../../utils/hooks';

const RecyclePivCard = ({ device, mgmt = null, reader }) => {
    const dispatch = useContext(AppDispatchContext);
    const { webpcscMode } = useContext(AppStateContext);
    const { t } = useTranslation();

    const [, setResult] = useState({});
    const [showNext, setShowNext] = useState(false);
    const [stage, setStage] = useState(t('pivActionInitialState'));

    useDidMount(() => {
        const setProgress = (hook, data) => {
            if (hook === 'stage') {
                setStage(data);
            }
        };

        const setPivResult = async (json) => {
            setResult({ ...json });
            setShowNext(true);
            if (mgmt && json.step === UCMS.done) {
                // ucms
                setTimeout(mgmt.next, PROGRESS_BAR_DONE_DELAY);
            }
        };

        postRecyclePiv(
            setPivResult,
            setProgress,
            dispatch,
            {
                ...device,
                reader,
            },
            webpcscMode,
        );
    });

    const renderer = ({seconds }) => {
        return <p>{t('instructionMessages$touchFeitian', { seconds })}</p>;
    };

    return (
        <CardEnrollLayout
            cardTitle={t('cardTitles$recycleDevice')}
            title={t('recycleProgress')}
            alert={t('enrollTemplateAlertDefault')}
            mgmt={mgmt}
            showNext={showNext}
        >
            {/* Onlye feitian token required a countdown. */}
            {stage === 'reset_containers' && device.subType === 'feitian' && <Countdown date={Date.now() + 30000} renderer={renderer} />}
            <ProgressTemplate
                isFake
                isDone={showNext}
                stage={`${t('pivActionPrefix')} ${stage}`}
            />
        </CardEnrollLayout>
    );
};

RecyclePivCard.propTypes = {
    mgmt: PropTypes.object,
    device: PropTypes.object.isRequired,
    reader: PropTypes.object.isRequired,
};

RecyclePivCard.defaultProps = {
    mgmt: null,
};

export default RecyclePivCard;
