import fetchIntercept from 'fetch-intercept';

const HttpInterceptor = () => {
    fetchIntercept.register({
        request (url, config) {
            // Modify the url or config here
            return [url, config];
        },

        requestError (error) {
            // Called when an error occured during another 'request' interceptor call
            return Promise.reject(error);
        },

        response (response) {
            // Modify the reponse object
            if(response.redirected) {
                window.location.href=`${window.location.origin}/user/saml/login`;
            }

            return response;
        },

        responseError (error) {
            // Handle an fetch error
            return Promise.reject(error);
        },
    });
};

export default HttpInterceptor