import React from 'react';
import { PropTypes } from 'prop-types';
import { Col, Row } from 'reactstrap';
import { PortalContainer, CardHeader, StyledAlert } from '../../styles/common';

const EnrollTemplate = ({ title, alert, children }) => (
    <PortalContainer>
        <Row>
            <Col sm="12">
                <CardHeader>{title}</CardHeader>
            </Col>
            <Col sm="12">
                <StyledAlert color="info" fade={false}>
                    {alert}
                </StyledAlert>
            </Col>
        </Row>
        {children}
    </PortalContainer>
);

EnrollTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    alert: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default EnrollTemplate;
