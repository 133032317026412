import React, { useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { postChangePin } from '../../utils/walkways';
import {
    serverConstants as SERVER,
    PROGRESS_BAR_DONE_DELAY,
} from '../../constants';
import ProgressTemplate from '../shared/ProgressTemplate';
import { AppDispatchContext } from '../../context/AppContext';
import CardEnrollLayout from '../shared/CardEnrollLayout';
import { useDidMount } from '../../utils/hooks';

const ChangePin = ({ title, reader, mgmt, device }) => {
    const dispatch = useContext(AppDispatchContext);
    const { t } = useTranslation();
    const [showNext, setShowNext] = useState(false);
    const cardTitle = title || t('cardTitles$updateDevice');

    const setResult = async (json) => {
        setShowNext(true);
        if (json.result === SERVER.success) {
            setTimeout(mgmt.next, PROGRESS_BAR_DONE_DELAY);
        }
    };

    useDidMount(() => {
        const { pass, oldPin, newPin, user } = device;
        let { ...data } = device;
        if (oldPin && newPin) {
            data = {
                ...device,
                oldPin,
                newPin,
                reader,
            };
        }
        if (pass && newPin) {
            data = {
                ...device,
                pass,
                newPin,
            };
        }
        data.user = user || undefined;
        postChangePin(setResult, null, dispatch, data);
    });

    const alertProp = device.pass
        ? t('softwareChangePinDefault')
        : t('enrollTemplateAlertDefault');

    return (
        <CardEnrollLayout
            cardTitle={cardTitle}
            title={t('updateProgress')}
            alert={alertProp}
            showNext={showNext}
            mgmt={mgmt}
        >
            <ProgressTemplate
                isFake
                isDone={showNext}
                stage={t('deviceActionsStage$changePin')}
            />
        </CardEnrollLayout>
    );
};

ChangePin.propTypes = {
    title: PropTypes.string,
    mgmt: PropTypes.object,
    device: PropTypes.object.isRequired,
    reader: PropTypes.object,
};

ChangePin.defaultProps = {
    mgmt: null,
    reader: null,
    title: null,
};

export default ChangePin;
