import React, { useContext, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { postData } from '../../../utils/postData';
import StepWizard from '../../shared/StepWizard';
import GoBackRedirect from '../../shared/GoBackRedirect';
import { ResyncOtpContext, ResyncOtpDispatchContext } from './context';
import ResyncOtpCard from '../../cardLibrary/ResyncOtp';
import PerformAction from '../../cardLibrary/PerformAction';
import {
    AppDispatchContext,
    AppStateContext,
} from '../../../context/AppContext';
import Status from '../../cardLibrary/Status';
import {
    serverConstants,
    paths,
    PROGRESS_BAR_DONE_DELAY,
} from '../../../constants';
import { HelpDeskDispatchContext } from '../../../context/HelpDeskContext';

const RESYNC_OTP = 'resyncOtp';
const PERFORM_ACTION = 'performAction';
const STATUS = 'status';
const HOME = 'home';

function reducer(state, action) {
    switch (action.type) {
        case 'DONE':
            return { step: HOME };
        case 'SET_OTP1':
            return {
                ...state,
                otp1: action.payload,
            };
        case 'SET_OTP2':
            return {
                ...state,
                otp2: action.payload,
            };
        case 'ON_SUCCESS':
            return {
                ...state,
                statusMessage: 'resyncOtpSuccessMsg',
                errorMessage: false,
                loading: false,
                step: STATUS,
            };
        case 'ON_FAILURE':
            return {
                ...state,
                loading: false,
                statusMessage: action.payload,
                errorMessage: true,
                step: STATUS,
            };
        case 'GO_BACK':
            if (state.step === RESYNC_OTP) {
                return { ...state, step: HOME };
            }
            return state;
        case 'GO_NEXT':
            if (state.step === RESYNC_OTP) {
                return { ...state, step: PERFORM_ACTION, loading: true };
            }
            return state;
        case 'PROGRESS_BAR_DONE':
            return { ...state, isDone: true };
        default:
            throw new Error('Disable Device Reducer: invalid action');
    }
}

const ResyncOtp = () => {
    const appDispatch = useContext(AppDispatchContext);
    const { device, goBackLink } = useContext(AppStateContext);
    const helpDeskDispatch = useContext(HelpDeskDispatchContext);
    const [state, dispatch] = useReducer(reducer, {
        step: RESYNC_OTP,
        nextStep: PERFORM_ACTION,
        prevStep: HOME,
        otp1: '',
        otp2: '',
        statusMessage: null,
        errorMessage: false,
        device,
        loading: false,
        isDone: false,
    });
    const history = useHistory();
    const { t } = useTranslation();

    const { location } = history || {};
    const { pathname } = location || {};
    const isHelpDesk = pathname.includes(paths.helpDesk);
    const returnPath =
        goBackLink || (isHelpDesk ? paths.helpDesk : paths.identities);

    useEffect(() => {
        const performAction = async () => {
            const body = { otp1: state.otp1, otp2: state.otp2 };
            body.user = state.device.user ? state.device.user : undefined;
            const queryParams = state.device.subType ? {subType: state.device.subType} : {};
            const res = await postData({
                body,
                endpoint: `/device/resyncOtp/${state.device.type}/${state.device.serial}`,
                appDispatch,
                queryParams
            });
            const content = await res.clone().text();
            if (content) {
                const json = await res.json();
                if (json.result === serverConstants.success) {
                    dispatch({ type: 'PROGRESS_BAR_DONE' });
                    setTimeout(
                        () => dispatch({ type: 'ON_SUCCESS', payload: null }),
                        PROGRESS_BAR_DONE_DELAY,
                    );
                } else {
                    dispatch({ type: 'PROGRESS_BAR_DONE' });
                    setTimeout(
                        () =>
                            dispatch({
                                type: 'ON_FAILURE',
                                payload:
                                    t(`errorMsgs$${json.message}`) ||
                                    json.message,
                            }),
                        PROGRESS_BAR_DONE_DELAY,
                    );
                }
            } else {
                dispatch({ type: 'PROGRESS_BAR_DONE' });
                setTimeout(
                    () =>
                        dispatch({
                            type: 'ON_FAILURE',
                            payload: t('errorMsgs$serverDefault'),
                        }),
                    PROGRESS_BAR_DONE_DELAY,
                );
            }
            helpDeskDispatch({ type: 'RESET_CONTEXT' });
        };
        if (state.step === PERFORM_ACTION) {
            performAction();
        }
    }, [
        state.step,
        state.device,
        state.otp1,
        state.otp2,
        appDispatch,
        history,
        helpDeskDispatch,
        t
    ]);
    
    useEffect(() => {
        return () => {
            helpDeskDispatch({ type: 'RESET_CONTEXT' });
            appDispatch({ type: 'RESET_FLOWS' });
        };
    },[]);

    const areFieldsEmpty = () => state.otp1 === '' || state.otp2 === '';

    if (!device) {
        history.push(returnPath);
        return null;
    }
    return (
        <ResyncOtpContext.Provider value={state}>
            <ResyncOtpDispatchContext.Provider value={dispatch}>
                <StepWizard
                    step={state.step}
                    title={t('cardTitles$resyncOtp')}
                    dispatch={dispatch}
                    state={state}
                >
                    <GoBackRedirect
                        path={returnPath}
                        stepId={HOME}
                        history={history}
                    />
                    <ResyncOtpCard
                        stepId={RESYNC_OTP}
                        dispatch={dispatch}
                        disableNext={areFieldsEmpty()}
                        device={device}
                    />
                    <PerformAction
                        stepId={PERFORM_ACTION}
                        isDone={state.isDone}
                    />
                    <Status path={returnPath} stepId={STATUS} />
                </StepWizard>
            </ResyncOtpDispatchContext.Provider>
        </ResyncOtpContext.Provider>
    );
};

export default ResyncOtp;
