import React, { useEffect, useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    enrollPivCardId,
    PROGRESS_BAR_DONE_DELAY,
} from '../../constants';
import { postFido2Enroll } from '../../utils/credentialing';
import ProgressTemplate from '../shared/ProgressTemplate';
import { AppDispatchContext, AppStateContext } from '../../context/AppContext';
import CardEnrollLayout from '../shared/CardEnrollLayout';
import { EnrollingStateContext } from '../../context/EnrollingContext';

const EnrollFido2BoundCard = ({ mgmt }) => {
    const { reader, username } = useContext(EnrollingStateContext);
    const dispatch = useContext(AppDispatchContext);
    const { webpcscMode } = useContext(AppStateContext);
    const { t } = useTranslation();
    const [, setResult] = useState({});
    const [showNext, setShowNext] = useState(false);
    const [, setStage] = useState(t('pivActionInitialState'));

    const setProgress = (hook, data) => {
        if (hook === 'stage') {
            setStage(data);
        }
    };


    useEffect(() => {
        const setFido2Result = async (json) => {
            setResult({ ...json });
            setShowNext(true);
            if (mgmt && json.step === 'done') {
                setTimeout(mgmt.next, PROGRESS_BAR_DONE_DELAY);
            }
        };

        const deviceData = {
            device: {
                type: 'ctap',
            },
            group: reader.groups[0],
            type: 'windows_ctap',
        };
        postFido2Enroll(
            'fido2BoundEnroll',
            setFido2Result,
            setProgress,
            dispatch,
            deviceData,
            username,
        );
    }, [username, dispatch, reader.groups, webpcscMode, mgmt]);

    return (
        <CardEnrollLayout
            cardTitle={t('cardTitles$issueFido2')}
            testId={enrollPivCardId}
            title={t('issuanceProgress')}
            alert={t('enrollTemplateAlertDefault')}
            showNext={showNext}
            mgmt={mgmt}
        >
            <ProgressTemplate
                isFake
                isDone={showNext}
                stage={t('deviceActionsStage$fido2')}
            />
        </CardEnrollLayout>
    );
};

EnrollFido2BoundCard.propTypes = {
    mgmt: PropTypes.object,
};

EnrollFido2BoundCard.defaultProps = {
    mgmt: null,
};

export default EnrollFido2BoundCard;
