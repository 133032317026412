import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import isEmpty from 'lodash.isempty';
import { AppDispatchContext } from '../context/AppContext';
import { errorFactory, declareError } from './errors';
import { apiPath, serverConstants, defaultPageTitle } from '../constants';
import { postData, isJson } from './postData';
import { patchData } from './patchData';
import { deleteData } from './deleteData';
import { updateIdleTimeoutDate, queryParamsToQueryString } from './generic';

import { HelpDeskStateContext } from '../context/HelpDeskContext';
import { TabsDispatchContext } from '../context/TabsContext';
import i18n from '../i18n';

const FETCH_TIMEOUT = 120000;
export const AUTHENTICATION_ERROR_CODES = [402, 403];

/**
 * custom useDidMount hook
 * Calls a function on mount
 */
export const useDidMount = (callback = () => { }) => {
    useEffect(() => {
        if (typeof callback === 'function') {
            callback();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

const fetchData = async ({
    endpoint,
    onSuccess,
    onError,
    appDispatch,
    setLoading,
    setData,
    setError,
    options,
    didCancel = false,
    queryParams = null,
}) => {
    setLoading(true);
    setData(null);
    setError(null);
    try {
        const queryString = queryParamsToQueryString(queryParams);
        const res = await Promise.race([
            fetch(`${apiPath}${endpoint}${queryString}`, options),
            new Promise((_, reject) => {
                setTimeout(
                    () =>
                        // eslint-disable-next-line implicit-arrow-linebreak
                        reject(errorFactory.timeout()),
                    FETCH_TIMEOUT,
                );
            }),
        ]);
        if (AUTHENTICATION_ERROR_CODES.includes(res.status)) {
            setLoading(false);
            appDispatch({
                type: 'SET_AUTHENTICATED',
                authenticated: false,
            });
            setError(res.status);
            onError({ error: res.status });
            throw new Error('Not authenticated');
        }
        updateIdleTimeoutDate();
        if (!res.ok) {
            setLoading(false);
            setError(res.statusText);
            onError({ error: res.statusText });
            appDispatch({
                type: 'SET_ERROR_STATUS',
                errorStatus: res.status,
            });
            throw errorFactory.err(`${res.statusText}`);
        }
        if (!didCancel) {
            const json = await res.json();
            if (json.result === serverConstants.failure) {
                throw errorFactory.server(null, null, json);
            }
            setData(json);
            setLoading(false);
            onSuccess(json);
        }
    } catch (err) {
        setLoading(false);
        setError(err);
        onError({ error: err });
        declareError(appDispatch, err);
    }
};

export const useFetchData = ({
    endpoint,
    body = null,
    onSuccess = () => { },
    onError = () => { },
    queryParams = null,
    skip = false,
}) => {
    const appDispatch = useContext(AppDispatchContext);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const options = {
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
        method: body === null ? 'GET' : 'POST',
        body: isJson(body) ? body : JSON.stringify(body),
    };

    const fetchNewData = (didCancel = false) =>
        fetchData({
            endpoint,
            onSuccess,
            onError,
            appDispatch,
            setLoading,
            setData,
            setError,
            options,
            didCancel,
            queryParams,
        });

    useEffect(() => {
        let didCancel = false;
        if (!skip) {
            fetchNewData(didCancel);
        }

        return () => {
            didCancel = true;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return [loading, data, error, fetchNewData];
};

export const useLazyFetchData = ({
    endpoint,
    body = null,
    onSuccess = () => { },
    onError = () => { },
    skip = false,
}) => {
    const appDispatch = useContext(AppDispatchContext);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const options = {
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
        method: body === null ? 'GET' : 'POST',
        body: isJson(body) ? body : JSON.stringify(body),
    };

    const callback = useCallback(
        (queryParams = null) =>
            !skip &&
            fetchData({
                endpoint,
                onSuccess,
                onError,
                appDispatch,
                setLoading,
                setData,
                setError,
                options,
                queryParams,
            }),
        [], // eslint-disable-line react-hooks/exhaustive-deps
    );

    return [callback, { loading, data, error }];
};

export const useLazyPatchData = ({ onSuccess = () => { }, onError = () => { }, skip = false }) => {
    const appDispatch = useContext(AppDispatchContext);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);


    const callback = useCallback(
        ({ queryParams = null, endpoint, body = {} }) => {
            const options = {
                credentials: 'same-origin',
                headers: { 'Content-Type': 'application/json' },
                method: 'PUT',
                body: isJson(body) ? body : JSON.stringify(body),
            };
            const handleError = (error) => {
                onError({ ...error, endpoint, body })
            }
            if (!skip) {
                fetchData({
                    endpoint,
                    onSuccess,
                    onError: handleError,
                    appDispatch,
                    setLoading,
                    setData,
                    setError,
                    options,
                    queryParams,
                });
            }
        },
        [], // eslint-disable-line react-hooks/exhaustive-deps
    );

    return [callback, { loading, data, error }];
};

export const usePostData = ({ endpoint, queryParams, onSuccess = () => { }, graphQL }) => {
    const dispatch = useContext(AppDispatchContext);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    // TODO how to do did cancel ?

    const callback = useCallback(
        async (body) => {
            setLoading(true);
            setData(null);
            setError(null);
            try {
                const res = await postData({
                    endpoint,
                    body,
                    graphQL,
                    onAuthError: () => {
                        setLoading(false);
                        dispatch({
                            type: 'SET_AUTHENTICATED',
                            authenticated: false,
                        });
                        throw new Error('Not authenticated');
                    },
                    queryParams
                });
                console.log(res.ok)
                if (!res.ok) {
                    setLoading(false);
                    setError(res.statusText);
                    dispatch({
                        type: 'SET_ERROR_STATUS',
                        errorStatus: res.status,
                    });
                    throw errorFactory.err(`${res.statusText}`);
                }
                const json = await res.json();
                if (json.result === serverConstants.failure) {
                    throw errorFactory.server(null, null, json);
                }
                setData(json);
                onSuccess(json);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                setError(err);
                declareError(dispatch, err);
            }
        },
        [endpoint, dispatch], // eslint-disable-line react-hooks/exhaustive-deps
    );

    return [callback, { loading, data, error }];
};

export const usePatchData = ({ endpoint, queryParams, onSuccess = () => { } }) => {
    const dispatch = useContext(AppDispatchContext);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    // TODO how to do did cancel ?

    const callback = useCallback(
        async (body) => {
            setLoading(true);
            setData(null);
            setError(null);
            try {
                const res = await patchData({
                    endpoint,
                    body,
                    queryParams
                });
                if (!res.ok) {
                    setLoading(false);
                    setError(res.statusText);
                    dispatch({
                        type: 'SET_ERROR_STATUS',
                        errorStatus: res.status,
                    });
                    throw errorFactory.err(`${res.statusText}`);
                }
                const json = await res.json();
                if (json.result === serverConstants.failure) {
                    throw errorFactory.server(null, null, json);
                }
                setData(json);
                onSuccess(json);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                setError(err);
                declareError(dispatch, err);
            }
        },
        [endpoint, dispatch], // eslint-disable-line react-hooks/exhaustive-deps
    );

    return [callback, { loading, data, error }];
};

export const useDeleteData = ({ endpoint, onSuccess = () => { } }) => {
    const dispatch = useContext(AppDispatchContext);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    // TODO how to do did cancel ?

    const callback = useCallback(
        async (reportName) => {
            setLoading(true);
            setData(null);
            setError(null);
            const url = `${endpoint}/${reportName}`;
            try {
                const res = await deleteData({
                    endpoint: url,
                });
                if (!res.ok) {
                    setLoading(false);
                    setError(res.statusText);
                    dispatch({
                        type: 'SET_ERROR_STATUS',
                        errorStatus: res.status,
                    });
                    throw errorFactory.err(`${res.statusText}`);
                }
                const json = await res.json();
                if (json.result === serverConstants.failure) {
                    throw errorFactory.server(null, null, json);
                }
                setData(json);
                onSuccess(json);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                setError(err);
                declareError(dispatch, err);
            }
        },
        [endpoint, dispatch], // eslint-disable-line react-hooks/exhaustive-deps
    );

    return [callback, { loading, data, error }];
};

export const useLocalStorageState = (
    key,
    initialState = {},
    enableLocalStorage = true,
) => {
    const localState = JSON.parse(localStorage.getItem(key));
    const newLocalState =
        localState &&
            Object.keys(initialState).every((col) =>
                Object.keys(localState).includes(col),
            ) &&
            enableLocalStorage
            ? localState
            : initialState;

    const [value, setValue] = useState(newLocalState);

    useEffect(() => {
        if (enableLocalStorage) {
            localStorage.setItem(key, JSON.stringify(value));
        }
    }, [value]);

    return [value, setValue];
};

export const useSortColumn = ({
    data = {},
    columns = [],
    activeColumns = {},
}) => {
    const [isSorted, setIsSorted] = useState(false);

    useEffect(() => {
        const sortColumn = () => {
            if (!isEmpty(data)) {
                let index = 0;
                while (index < columns.length) {
                    if (activeColumns[columns[index]]) break;
                    index += 1;
                }
                data.sort((a, b) => {
                    const str1 =
                        a[columns[index]] && a[columns[index]].toUpperCase();
                    const str2 =
                        b[columns[index]] && b[columns[index]].toUpperCase();
                    return str1 < str2 ? -1 : +(str1 > str2);
                });
                return true;
            }
            return false;
        };
        if (!isSorted) {
            const sortedStatus = sortColumn();
            setIsSorted(sortedStatus);
        } else if (!data) {
            setIsSorted(false);
        }
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    return [data, isSorted];
};

export const useTabNav = ({
    tabs = [],
    path = null,
    changeTab = undefined,
}) => {
    const dispatch = useContext(TabsDispatchContext);

    useEffect(() => {
        dispatch({
            type: 'ACTIVATE_TAB_NAV',
            payload: { tabs, changeTab },
        });
        return () => {
            const {
                location: { href },
            } = window;
            if (!href.includes(path)) {
                dispatch({ type: 'RESET_CONTEXT' });
            }
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => htmlElRef.current && htmlElRef.current.focus();

    return [htmlElRef, setFocus];
};

export const useSearch = () => {
    const [user, setUser] = useState(null);
    const [serial, setSerial] = useState(null);
    const { searchFilter } = useContext(HelpDeskStateContext);
    useEffect(() => {
        const { user: searchUser, serial: searchSerial } = searchFilter || {};
        setUser(searchUser);
        setSerial(searchSerial);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return { user, serial };
};

export const usePrevious = (state) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = state;
    }, [state]);

    return ref.current;
};

export const useBoundingBox = () => {
    const ref = useRef();
    const [bbox, setBbox] = useState({});

    const set = () =>
        setBbox(ref && ref.current ? ref.current.getBoundingClientRect() : {});

    useEffect(() => {
        set();
        window.addEventListener('resize', set);
        return () => window.removeEventListener('resize', set);
    }, []);

    return [bbox, ref];
};

export const useHandleEnter = ({ action }) => {
    const actionRef = useRef(action);
    actionRef.current = action;

    useEffect(() => {
        const handleEnter = (e) => {
            if (e && e.keyCode === 13) {
                actionRef.current();
            }
        };
        document.addEventListener('keyup', handleEnter);
        return () => {
            document.removeEventListener('keyup', handleEnter);
        };
    }, []);
};

export const usePageTitle = ({ title }) => {
    useEffect(() => {
        if (i18n.exists(title)) {
            document.title = `${defaultPageTitle} - ${i18n.t(title)}`;
        }
        return () => {
            document.title = defaultPageTitle;
        };
    }, [title]);
};

export const useLocalStorage = ({ key, value }) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : value;
        } catch (error) {
            return value;
        }
    });

    const setValue = (c) => {
        try {
            const valueToStore = c instanceof Function ? value(storedValue) : c;
            setStoredValue(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error('useLocalStorage [setValue]: ', error);
        }
    };

    const removeValue = () => {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.error('useLocalStorage [removeValue]: ', error);
        }
    };

    return [storedValue, setValue, removeValue];
};
