import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import UserIdWithData from './UserIdWithData';
import { useDidMount } from '../../../utils/hooks';

const UserId = ({ registerForm, serverComponent, validUser, loading }) => {
    const { t } = useTranslation();

    const validator = (value) =>
        value && value.trim() ? true : t('errorMsgs$userId');

    useDidMount(() => {
        if (registerForm) {
            registerForm({
                userId: validator,
            });
        }
    });

    return (
        <UserIdWithData
            serverComponent={serverComponent}
            validUser={validUser}
            validator={validator}
            loading={loading}
        />
    );
};

UserId.propTypes = {
    registerForm: PropTypes.func.isRequired,
    serverComponent: PropTypes.object,
};

UserId.defaultProps = {
    serverComponent: undefined,
};

export default UserId;
