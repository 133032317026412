import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AccentButton, StyledModal } from '../../../../styles/common';
import { formatDate } from '../../../../utils/generic';
import {
    TPADetailsBody,
    TPAContent,
    TPATitle,
    StyledHeader,
    StyledSubtitle,
    StyledBody,
    StyledFooter,
} from './styles';

import CopyButton from '../../../shared/CopyButton';

const numberRegex = /^[0-9]/g;
const symbolRegex = /^[a-zA-Z0-9]+$/g;
const BLUE = '#5671d6';
const RED = '#db4d4d';
const BLACK = '#000';

const TPADetailsModal = ({ isOpen, toggleModal, tpaData }) => {
    const { value, validityStart, validityEnd } = tpaData || {};
    const { t } = useTranslation();
    const closeBtnRef = useRef();

    useEffect(() => {
        setTimeout(() => {
            if (isOpen && closeBtnRef.current) {
                closeBtnRef.current.focus();
            }
        });
    }, [isOpen]);

    if (!tpaData) return null;

    const applyColorToTpa = (value) => {
        if (typeof value === 'string' && value.length > 0) {
            return value.split('').map((c,i) => {
                let colorCode = BLACK;
                if (c.match(numberRegex)) {
                    colorCode = BLUE;
                }
                if (!c.match(symbolRegex)) {
                    colorCode = RED;
                }
                // eslint-disable-next-line react/no-array-index-key
                return <span style={{ color: colorCode, fontFamily: 'Monaco', fontSize:'28px'}} key={i}>{c}</span>;
            });
        }
        return 'N/A';
    };

    return (
        <StyledModal centered size="lg" isOpen={isOpen}>
            <StyledHeader>{t('usersDetails$TPATitle')}</StyledHeader>
            <StyledSubtitle>{`${t('usersDetails$TPAWarning')}`}</StyledSubtitle>
            <StyledBody>
                <TPADetailsBody>
                    <TPATitle>{`${t('usersDetails$TPAValue')}:`}</TPATitle>
                    <TPAContent style={{ fontWeight: '600' }}>
                        {applyColorToTpa(value)}{' '}
                        <CopyButton
                            iconWidth="20px"
                            style={{ margin: '0 0.5rem' }}
                            value={value}
                        />
                    </TPAContent>
                    <TPATitle>{`${t('usersDetails$TPA$validityStart')}:`}</TPATitle>
                    <TPAContent>{formatDate(validityStart)}</TPAContent>
                    <TPATitle>{`${t('usersDetails$TPA$validityEnd')}:`}</TPATitle>
                    <TPAContent>{formatDate(validityEnd)}</TPAContent>
                </TPADetailsBody>
            </StyledBody>
            <StyledFooter>
                <AccentButton
                    innerRef={closeBtnRef}
                    outline
                    color="primary"
                    size="sm"
                    onClick={() => toggleModal(false)}
                >
                    {t('modalLabels$close')}
                </AccentButton>
            </StyledFooter>
        </StyledModal>
    );
};
export default TPADetailsModal;
