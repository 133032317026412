import styled from 'styled-components';
import { Badge } from 'reactstrap';

export const DeviceDetailsField = styled.div`
    text-transform: capitalize;
    padding: 0.25rem 0;
    font-weight: 700;
`;
export const DeviceDetailsValue = styled.div`
    word-break: break-word;
    padding: 0.25rem 0;
`;
export const DeviceDetailsButton = styled.button`
    outline: none;
    border: none;
    background-color: transparent;
    & svg {
        fill: #465765;
    }
`;

export const DeviceDetailsTableWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: minmax(6.25rem, 10rem) auto;
    grid-column-gap: 1rem;
`;

export const DetailsButtonWrapper = styled.div`
    padding: 0.25rem 0.5rem;
    cursor: pointer;
`;

export const FailcountBadge = styled(Badge)`
    color: white;
    font-size: 90%;
    font-weight: bold;
    width: fit-content;
    margin: 0.25rem 0;
    line-height: normal;
`;

export const TableCellHover = styled.div`
display: block;
align-items: center;
word-break: break-word;
overflow:hidden;
white-space: nowrap;
text-overflow: ellipsis;
width:120px;
cursor:pointer;
&:hover {
    overflow: visible; 
    white-space: normal;
    height:auto;
}
`;


