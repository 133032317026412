import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import CardTemplate from './CardTemplate';
import EnrollTemplate from './EnrollTemplate';
import FlowButtons from '../elementLibrary/FlowButtons';

const EnrollTemplateContainer = styled.div`
    padding-bottom: 12px;
`;

const CardEnrollLayout = ({
    children,
    cardTitle = '',
    title = '',
    alert,
    showNext,
    showBack = false,
    displayNext = true,
    mgmt,
    flowButtonProps = {},
    testId = null,
}) => {
    return (
        <CardTemplate title={cardTitle} testId={testId} noFade>
            <EnrollTemplateContainer>
                <EnrollTemplate title={title} alert={alert}>
                    {children}
                </EnrollTemplate>
            </EnrollTemplateContainer>
            <FlowButtons
                mgmt={{
                    next: mgmt ? mgmt.next : '',
                    showNext,
                    displayNext,
                    back: mgmt ? mgmt.back : '',
                    showBack,
                }}
                {...flowButtonProps}
            />
        </CardTemplate>
    );
};

CardEnrollLayout.propTypes = {
    children: PropTypes.node.isRequired,
    cardTitle: PropTypes.string,
    title: PropTypes.string,
    alert: PropTypes.string.isRequired,
    showNext: PropTypes.bool.isRequired,
    showBack: PropTypes.bool,
    mgmt: PropTypes.object,
    testId: PropTypes.string,
};

CardEnrollLayout.defaultProps = {
    cardTitle: '',
    title: '',
    showBack: false,
    testId: null,
};

export default CardEnrollLayout;
