import React, { useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { u2fRegister } from '../../../utils/u2f';
import {
    serverConstants as SERVER,
    PROGRESS_BAR_DONE_DELAY,
} from '../../../constants';
import CardTemplate from '../../shared/CardTemplate';
import ProgressTemplate from '../../shared/ProgressTemplate';
import EnrollTemplate from '../../shared/EnrollTemplate';
import EnrollU2fWithData from './AssociateEnrollU2fWithData';
import FlowButtons from '../../elementLibrary/FlowButtons';
import { AppDispatchContext } from '../../../context/AppContext';
import { EnrollingStateContext } from '../../../context/EnrollingContext';
import {
    postAssociateEnrollU2fRequest,
    fetchAssociateEnrollU2fRequest,
} from '../../../utils/walkways';
import { useDidMount } from '../../../utils/hooks';

const TAG = 'EnrollU2fCard'; // eslint-disable-line no-unused-vars

const EnrollU2fCard = ({ mgmt = null, associateUser, reader }) => {
    const { t } = useTranslation();
    const dispatch = useContext(AppDispatchContext);
    const { device } = useContext(EnrollingStateContext);
    const [, setRequest] = useState({});
    const [requestSigned, setRequestSigned] = useState(false);
    const [, setResult] = useState({});
    const [, setData] = useState({});
    const [showNext, setShowNext] = useState(false);

    const setEnrollU2fRequest = async (json) => {
        setRequest({ ...json });
    };

    const setU2fResult = async (json) => {
        setResult({ ...json });
        setShowNext(true);
        if (mgmt && json.result === SERVER.success) {
            // server
            setTimeout(mgmt.next, PROGRESS_BAR_DONE_DELAY);
        }
    };

    const register = async (request) => {
        try {
            const thisResult = await u2fRegister(request);
            setRequestSigned(true);
            setData(thisResult);
            setRequest(request);
            postAssociateEnrollU2fRequest(
                setU2fResult,
                dispatch,
                {
                    action: 'enrollU2f',
                    u2fData: thisResult.clientData,
                    u2fRegistration: thisResult.registrationData,
                },
                {
                    user: associateUser,
                    type: reader.subType,
                    serial: reader.serial,
                },
            );
        } catch (e) {
            console.error(TAG, 'register:', e);
        }
    };

    useDidMount(() => {
        fetchAssociateEnrollU2fRequest(
            setEnrollU2fRequest,
            dispatch,
            register,
            {
                action: 'enrollU2f',
                pin: device.pin,
                type: reader.subType,
            },
            {
                user: associateUser,
                serial: reader.serial,
                type: reader.type,
                subType: reader.subType,
            },
        );
    });

    return (
        <CardTemplate title={t('cardTitles$issueIdentity')} noFade>
            {/* todo: get title from props */}
            {requestSigned ? (
                <>
                    <EnrollTemplate
                        title={t('issuanceProgress')}
                        alert={t('enrollTemplateAlertDefault')}
                    >
                        <ProgressTemplate
                            isFake
                            isDone={showNext}
                            stage={t('deviceActionsStage$u2f')}
                        />
                    </EnrollTemplate>
                    <FlowButtons
                        mgmt={{
                            next: mgmt ? mgmt.next : '',
                            showNext,
                            back: mgmt ? mgmt.back : '',
                            showBack: false,
                        }}
                    />
                </>
            ) : (
                <EnrollU2fWithData />
            )}
        </CardTemplate>
    );
};

EnrollU2fCard.propTypes = {
    mgmt: PropTypes.object,
};

EnrollU2fCard.defaultProps = {
    mgmt: null,
};

export default EnrollU2fCard;
