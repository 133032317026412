import React from 'react';
import i18next from 'i18next';
import PCSC from '../../../utils/pcsc';
import UCMS from '../../../utils/ucms';
import { errorFactory, declareError } from '../../../utils/errors';
import { fetchDiagnosis } from '../../../utils/diagnosis';
import { deriveUrl, sleep } from '../../../utils/generic';
import {
    webpcscNoService,
    apiPath,
    ucmsConstants,
    buildDeviceDropdownDisplaySuccess,
    buildDeviceDropdownDisplayFailure,
    vscPlaceholder,
    atrCases
} from '../../../constants';
import { fetchDeviceAssociateQuery } from '../../../utils/walkways';
import DeviceAssociateWithData from './DeviceAssociateWithData';

/* *****************************************************************************************
  Warning: this file is a dragon. test carefully after any change.
    the main loop or engine of this component is the handlePcscOnChange function
    be careful while using state, with this component it is very easy
    for weird state changes to conflict or occur out of order
****************************************************************************************** */

const TAG = 'DeviceAssociate';

function deviceQuery(props, state, reader) {
    return reader.status === 'available' || reader.status === 'assigned'
        ? true
        : i18next.t(`errorMsgs$deviceSelection_status_${reader.status}`);
}

function displayOption(data) {
    return data.issuable === true
        ? buildDeviceDropdownDisplaySuccess(
              data,
              i18next.t('genericDeviceDropdownLabels$serialNo'),
          )
        : buildDeviceDropdownDisplayFailure(data);
}
class DeviceAssociate extends React.Component {
    state = {
        selected: '',
        readers: {},
        interval: null,
        syncing: false,
        operable: [],
        inoperable: [],
    };

    componentDidMount() {
        this.pcscOnChange();
    }

    componentWillUnmount() {
        if (this.state.interval) {
            this.state.interval.stop();
            this.setState({ ...this.state, interval: null });
        }
    }

    setReader = (value) => {
        const empty =
            value === i18next.t('genericDeviceDropdownLabels$defaultOption') ||
            value === i18next.t('genericDeviceDropdownLabels$noDevicesOption');
        this.setState({
            ...this.state,
            selected: empty ? {} : value,
        });
        if (this.props.setReader) {
            this.props.setReader(this.state.readers[value]);
        }
    };

    setDeviceAssociateQuery = (json) => {
        const { readers } = this.state;
        Object.keys(readers).forEach((readerName) => {
            const reader = readers[readerName];
            json.forEach((device) => {
                if (device.serial === reader.serial) {
                    readers[readerName] = {
                        ...reader,
                        ...device,
                        groups: device.groups,
                        changed: false,
                    };
                    if (device.serial !== vscPlaceholder.serial) {
                        // -------- for vsc "null" CAN BE MADE CLEANER
                        readers[readerName].display = displayOption({
                            ...reader,
                            ...device,
                        });
                    }
                }
            });
        });
        this.setState({
            ...this.state,
            readers: {
                ...this.state.readers,
                ...readers,
            },
        });
        this.filterDevices(deviceQuery);
    };

    setDetectReaders = (added, modified) => {
        // eslint-disable-next-line max-len, no-return-assign, no-param-reassign
        added.forEach(
            (reader) =>
                (modified[reader] =
                    modified[reader] === undefined ? null : modified[reader]),
        );
        const readers = {};
        Object.keys(modified).forEach((reader) => {
            readers[reader] = {
                ...[reader],
                issuable: i18next.t(atrCases[modified[reader]]) || true,
                atr: modified[reader],
                readerName: reader,
                label: reader,
                changed: true,
            };
        });
        this.setState({
            ...this.state,
            readers: {
                ...this.state.readers,
                ...readers,
            },
        });
    };

    filterDevices = (check) => {
        const { readers } = this.state;
        Object.keys(readers).forEach((readerName) => {
            const reader = readers[readerName];
            if (
                reader.issuable === true ||
                reader.issuable === i18next.t('errorMsgs$unissuable')
            ) {
                reader.issuable = check(null, this.state, reader);
            }
        });
        console.debug(TAG, 'filterDevices readers:', readers);
        this.setState({
            ...this.state,
            ...readers,
        });
    };

    removeReaders = (removed) => {
        const { readers } = this.state;
        removed.forEach((readerName) => {
            delete readers[readerName];
        });
        this.setState({
            ...this.state,
            selected: removed.includes(this.state.selected)
                ? ''
                : this.state.selected,
            ...readers,
        });
        this.props.enrollingDispatch({
            type: 'SET_SELECTED_READER',
            reader: removed.includes(this.state.selected)
                ? null
                : this.state.readers[this.state.selected],
        });
    };

    handlePcscOnChange = async (added, removed, modified) => {
        console.debug('handlePcscOnChange state:', this.state);
        this.setState({ ...this.state, syncing: true });
        if (removed.length > 0) {
            this.removeReaders(removed);
            // eslint-disable-next-line no-param-reassign
            removed.forEach((reader) => delete modified[reader]);
        }
        this.setDetectReaders(added, modified);
        await this.identifyDevices();
        await this.queryDevices();
        if (this.props.isHost) {
            await this.handleVsc();
        }
        console.log(this.state.readers); // FOR QA, remove after
        this.setState({
            ...this.state,
            syncing: false,
        });
    };

    handleVsc = async () => {
        const { readers } = this.state;
        // disable this code for now to show the placeholder all the time.
        // Enable it once we can differentiate the VSC created by us
        // if (
        //     Object.keys(readers).some(
        //         (reader) =>
        //             readers[reader].subType === vscPlaceholder.subType &&
        //             readers[reader].status === 'available',
        //     )
        // )
        //     return;
        if (await PCSC.supports(vscPlaceholder.subType)) {
            vscPlaceholder.display =
                i18next.t('mappingsLabels$deviceSubTypes$vsc$label') ||
                vscPlaceholder.display;
            this.setState({
                ...this.state,
                readers: {
                    ...this.state.readers,
                    vsc: vscPlaceholder,
                },
            });
            await fetchDeviceAssociateQuery(
                this.setDeviceAssociateQuery,
                this.props.appDispatch,
                [vscPlaceholder.device],
                this.props.associateUser,
            );
        }
    };

    pcscOnChange = async () => {
        try {
            await fetchDiagnosis(this.props.appDispatch);
            await PCSC.ready;
            const interval = await PCSC.onChange(
                this.handlePcscOnChange,
                true,
                1500,
            );
            this.setState({
                ...this.state,
                interval,
            });
        } catch (error) {
            console.error(
                TAG,
                'device selection identifyDevices error:',
                error,
            );
            declareError(
                this.props.appDispatch,
                errorFactory.err(`${webpcscNoService}_ERROR`),
            );
        }
    };

    // open questions
    identifyDevices = async () => {
        // eslint-disable-line consistent-return
        const { readers } = this.state;
        await sleep(1000);
        for (const readerName of Object.keys(readers)) {
            // Skip whfb if it is not same user
            if (readerName.includes('Windows Hello for Business') && (!this.props.isHost)) {
                continue;
            }
            // eslint-disable-line no-restricted-syntax, max-len
            if (readers[readerName].changed === false) {
                continue; // eslint-disable-line no-continue
            }
            let result = null;
            let reader = null;
            if (readers[readerName].issuable !== true) {
                continue; // eslint-disable-line no-continue
            }
            try {
                reader = await PCSC.connect(readerName); // eslint-disable-line no-await-in-loop
                var atr = readers[readerName].atr;
                // Hard coded the atr to 'axiad.whfb' for whfb
                if (readerName.includes('Windows Hello for Business')) {
                    atr = 'axiad.whfb';
                }
                result = await UCMS.apdu.run(
                    // eslint-disable-line no-await-in-loop
                    reader,
                    ucmsConstants.actions.identify,
                    atr,
                    () => {},
                    deriveUrl(apiPath),
                );
            } catch (error) {
                console.error('device selection identifyDevices error:', error);
                if (error.msg === 'E_SHARING_VIOLATION') {
                    readers[readerName].issuable = i18next.t(
                        'errorMsgs$atr_inUse',
                    );
                } else if (error.msg === 'Unsupported card') {
                    readers[readerName].issuable = i18next.t(
                        'errorMsgs$unsupportedDevice',
                    );
                } else {
                    readers[readerName].issuable = i18next.t(
                        'errorMsgs$unidentifiable',
                    );
                }
            } finally {
                if (reader) await reader.disconnect(); // eslint-disable-line no-await-in-loop
            }
            let card = {}
            if (result !== null) {
                card = result.entries[0]?.result;
            }
            console.debug(TAG, 'identifyDevices card:', card);
            readers[readerName] = {
                ...readers[readerName],
                ...card,
                device: card,
                label: displayOption({ ...card, ...readers[readerName] }),
            };
        }
        this.setState({
            ...this.state,
            readers: {
                ...this.state.readers,
                ...readers,
            },
        });
    };

    queryDevices = async () => {
        console.debug(TAG, 'queryDevices state:', this.state);
        const data = [];
        for (const readerName of Object.keys(this.state.readers)) {
            // eslint-disable-line no-restricted-syntax, max-len
            const reader = this.state.readers[readerName];
            if (
                reader.issuable === true &&
                reader.changed === true &&
                reader.device
            ) {
                data.push(reader.device);
            }
        }
        if (data.length > 0) {
            await fetchDeviceAssociateQuery(
                this.setDeviceAssociateQuery,
                this.props.appDispatch,
                data,
                this.props.associateUser,
            );
        }
    };

    render() {
        const { display } = this.props;
        return (
            <DeviceAssociateWithData
                display={display}
                readers={this.state.readers}
                defaultOption={i18next.t(
                    'genericDeviceDropdownLabels$defaultOption',
                )}
                setReader={this.setReader}
                syncing={this.state.syncing}
            />
        );
    }
}

export default DeviceAssociate;
