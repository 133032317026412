import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
    createPinFormId,
    createPinFormWeakPinCheckId,
    createPinFormMinMaxLengthId,
    createPinFormConstraintId,
    keyboardLayouts,
} from '../../../constants';
import { StyledInput } from '../../../styles/common';
import {
    EnrollingDispatchContext,
    EnrollingStateContext,
} from '../../../context/EnrollingContext';
import SecureEntryIcon from '../../shared/SecureEntryIcon';
import CheckPinIcon from '../../shared/CheckPinIcon';

const CreatePinWithData = ({
    checked,
    pinPolicy,
    pinRef,
    confirmPinRef,
    clearText,
    pinValidator,
    confirmPinBridge,
    clearTextSwitch,
    confirmPinValidator,
    keyboardLayout,
    setKeyboardLayout,
}) => {
    const { t } = useTranslation();
    const {
        form: { errors },
    } = useContext(EnrollingStateContext);
    const enrollingDispatch = useContext(EnrollingDispatchContext);

    const pinLabel = pinPolicy.id.includes('otp.slot') ? 
        t('formLabels$createPin$formLabel$password') : t('formLabels$createPin$formLabel$pin');
    const confirmPinLabel = pinPolicy.id.includes('otp.slot') ? 
        t('formLabels$createPin$formLabel$confirmPassword') : t('formLabels$createPin$formLabel$confirmPin');
    const pinMatchLabel = pinPolicy.id.includes('otp.slot') ? 
        t('formLabels$createPin$policyFlags$matchPassword') : t('formLabels$createPin$policyFlags$match');
    const confirmPinPlaceholderLabel = pinPolicy.id.includes('otp.slot') ? 
        t('formLabels$createPin$placeholder$confirmPassword') : t('formLabels$createPin$placeholder$confirmPin');
    const pinPlaceholderLabel = pinPolicy.id.includes('otp.slot') ? 
        t('formLabels$createPin$placeholder$password') : t('formLabels$createPin$placeholder$pin');
    const pinLenthAlert = pinPolicy.id.includes('otp.slot') ? 
        t('formLabels$createPin$policyFlags$passwordLen', {
            MINLEN: pinPolicy.minLength,
            MAXLEN: pinPolicy.maxLength,
        }) :
        t('formLabels$createPin$policyFlags$len', {
            MINLEN: pinPolicy.minLength,
            MAXLEN: pinPolicy.maxLength,
        });
    const policyLabel = pinPolicy.id.includes('otp.slot') ? 
        t('formLabels$createPin$formLabel$passwordPolicy') : t('formLabels$createPin$formLabel$pinPolicy');
    
    return (
        <div data-test-id={createPinFormId}>
            <FormGroup row autoComplete="off">
                <Col xs="3">{pinLabel}</Col>
                <Col xs="4">
                    <StyledInput
                        ref={pinRef}
                        valid={!errors.pin && errors.pin !== undefined}
                        invalid={
                            errors.pin !== undefined && errors.pin !== false
                        }
                        type={clearText ? 'text' : 'password'}
                        name="pin"
                        placeholder={pinPlaceholderLabel}
                        maxLength={pinPolicy.maxLength}
                        bsSize="sm"
                        onChange={(event) => {
                            enrollingDispatch({
                                type: 'SET_EXTRACT_VALUE',
                                name: 'pin',
                                value: event.target.value,
                                validator: pinValidator,
                            });
                            enrollingDispatch({
                                type: 'ADD_PIN',
                                id: pinPolicy.id,
                                value: event.target.value,
                                constraint: pinPolicy.constraint,
                                keyboardLayout,
                            });
                            confirmPinBridge(event.target.value);
                        }}
                    />
                </Col>
                <Col xs="0">
                    <SecureEntryIcon
                        isActive={clearText}
                        onMouseDown={clearTextSwitch}
                    />
                </Col>
            </FormGroup>
            <FormGroup row autoComplete="off">
                <Col xs="3">{confirmPinLabel}</Col>
                <Col xs="4">
                    <StyledInput
                        ref={confirmPinRef}
                        valid={
                            !errors.confirmPin &&
                            errors.confirmPin !== undefined
                        }
                        invalid={
                            errors.confirmPin !== undefined &&
                            checked.match === false
                        }
                        type={clearText ? 'text' : 'password'}
                        name="confirmPin"
                        placeholder={confirmPinPlaceholderLabel}
                        maxLength={pinPolicy.maxLength}
                        bsSize="sm"
                        onChange={(event) => {
                            enrollingDispatch({
                                type: 'SET_VALUE',
                                name: 'confirmPin',
                                value: event.target.value,
                                validator: confirmPinValidator,
                            });
                        }}
                    />
                </Col>
            </FormGroup>
            {pinPolicy.id.includes('otp.slot') && (
                <FormGroup row autoComplete="off">
                    <Col xs="3">
                        <Label for="keyboardLayout">
                            {t('formLabels$createPin$formLabel$keyboardLayout')}
                        </Label>
                    </Col>
                    <Col xs="4">
                        <StyledInput
                            type="select"
                            bsSize="sm"
                            value={keyboardLayout}
                            name="keyboardLayout"
                            onChange={(event) => {
                                setKeyboardLayout(event.target.value);
                                enrollingDispatch({
                                    type: 'ADD_PIN',
                                    id: pinPolicy.id,
                                    value: pinRef.current.value,
                                    constraint: pinPolicy.constraint,
                                    keyboardLayout: event.target.value,
                                });
                            }}
                        >
                            {keyboardLayouts.map((op) => (
                                <option value={op} key={op}>
                                    {t(
                                        `formLabels$createPin$formLabel$keyboardLayout$${op}`,
                                    ) || op}
                                </option>
                            ))}
                        </StyledInput>
                    </Col>
                </FormGroup>
            )}
            <Row>
                <Col xs="3">
                    <Label>{policyLabel}</Label>
                </Col>
                <Col>
                    <ul className="pin-checks">
                        <li
                            className="check"
                            data-test-id={createPinFormMinMaxLengthId}
                        >
                            <CheckPinIcon valid={checked.len} />
                            {pinLenthAlert}
                        </li>
                        {!['any', 'utf8', 'scancode'].includes(
                            pinPolicy.constraint,
                        ) && (
                            <li
                                className="check"
                                data-test-id={createPinFormConstraintId}
                            >
                                <CheckPinIcon valid={checked.constraint} />
                                {t(
                                    'formLabels$createPin$policyFlags$constraint',
                                    {
                                        CONSTRAINT: pinPolicy.constraint,
                                    },
                                )}
                            </li>
                        )}
                        {checked.weakPin !== undefined ? (
                            <li
                                className="check"
                                data-test-id={createPinFormWeakPinCheckId}
                            >
                                <CheckPinIcon valid={checked.weakPin} />
                                {t('formLabels$createPin$policyFlags$weakPin')}
                            </li>
                        ) : null}
                        <li className="check">
                            <CheckPinIcon valid={checked.match} />
                            {pinMatchLabel}
                        </li>
                    </ul>
                </Col>
            </Row>
        </div>
    );
};

CreatePinWithData.propTypes = {
    checked: PropTypes.objectOf(PropTypes.bool).isRequired,
    pinRef: PropTypes.object.isRequired,
    pinValidator: PropTypes.func.isRequired,
    confirmPinValidator: PropTypes.func.isRequired,
    confirmPinBridge: PropTypes.func.isRequired,
    clearText: PropTypes.bool.isRequired,
    clearTextSwitch: PropTypes.func.isRequired,
    pinPolicy: PropTypes.object.isRequired,
};

export default CreatePinWithData;
