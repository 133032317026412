import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label } from 'reactstrap';
import { StyledInput } from '../../../styles/common';
import {
    EnrollingDispatchContext,
    EnrollingStateContext,
} from '../../../context/EnrollingContext';

const SerialWithData = ({ validator }) => {
    const { t } = useTranslation();
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const {
        form: { errors },
        device,
    } = useContext(EnrollingStateContext);

    return (
        <FormGroup row autoComplete="off">
            <Col xs="3">
                <Label for="serial">{t('formLabels$serial$formLabel')}</Label>
            </Col>
            <Col xs="4">
                <StyledInput
                    name="serial"
                    bsSize="sm"
                    placeholder={t('formLabels$serial$placeholder')}
                    valid={!errors.form && errors.form !== undefined}
                    invalid={errors.form !== undefined && errors.form !== false}
                    onChange={(event) => {
                        enrollingDispatch({
                            type: 'SET_EXTRACT_VALUE',
                            name: 'serial',
                            value: event.target.value,
                            validator,
                            extra: {
                                device: {
                                    ...device,
                                    serial: event.target.value,
                                },
                            },
                        });
                    }}
                />
            </Col>
        </FormGroup>
    );
};

const { func } = PropTypes;

SerialWithData.propTypes = {
    validator: func.isRequired,
};

export default SerialWithData;
