import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { PropTypes } from 'prop-types';
import CardTemplate from './CardTemplate';
import FlowButtons from '../elementLibrary/FlowButtons';
import AssuranceAcknowledge from '../cardLibrary/IssuanceType/AssuranceAcknowledge';

const CardLayout = ({
    children,
    title,
    subtitle,
    showNext,
    showBack = true,
    mgmt,
    testId = null,
    skipEnabled = false,
    clickSkip = () => {},
    assuranceAckType = null,
}) => {
    const { t } = useTranslation();

    return (
        <CardTemplate title={title} subtitle={subtitle} testId={testId} noFade>
            <Row>
                <Col
                    xs={
                        t(`assuranceAcknowledge$${assuranceAckType}`) !== '' &&
                        'auto'
                    }
                >
                    {children}
                    <FlowButtons
                        mgmt={{
                            next: mgmt ? mgmt.next : '',
                            showNext,
                            back: mgmt ? mgmt.back : '',
                            showBack,
                            skipEnabled,
                            clickSkip,
                        }}
                    />
                </Col>

                {/* Put the Assurance Acknowledge as the same level of next button */}
                {t(`assuranceAcknowledge$${assuranceAckType}`) !== '' && (
                    <Col align="left">
                        <AssuranceAcknowledge type={assuranceAckType} />
                    </Col>
                )}

                {/* Add a right spacing for Assurance Acknowledge */}
                {t(`assuranceAcknowledge$${assuranceAckType}`) !== '' && (
                    <Col sm="1" />
                )}
            </Row>
        </CardTemplate>
    );
};

CardLayout.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    showNext: PropTypes.bool.isRequired,
    showBack: PropTypes.bool,
    mgmt: PropTypes.object.isRequired,
    testId: PropTypes.string,
    subtitle: PropTypes.string,
};

CardLayout.defaultProps = {
    showBack: true,
    testId: null,
    title: '',
    subtitle: '',
};

export default CardLayout;
