import React from 'react';
import { PropTypes } from 'prop-types';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { selectReasonCardId } from '../../constants';
import NewCardLayout from '../shared/NewCardLayout';
import {
    PortalContainer,
    CardHeader,
    StyledAlert,
    StyledRadioButton,
} from '../../styles/common';
import { deviceTypes } from '../../utils/mappings';

const reasons = ['lost', 'stolen', 'damaged', 'forgotten', 'other'];

const SelectReason = ({
    title,
    subtitle = '',
    dispatch,
    state: { disableBack, disableNext, reason },
    instructions,
    isHelpDesk,
    device,
}) => {
    const { t } = useTranslation();
    const { serial, type, label } = device || {};
    const selectedValue = reason || reasons[0];

    const serialConfirmation = t('walkways$serialConfirmation', {
        SERIAL: (type === 'unqualified_ctap' || type === 'windows_ctap') ? '' : serial,
        TYPE: label || t(type ? deviceTypes[type].label : 'unknownDevice'),
    });

    const handleRadioClick = (proxy) => {
        dispatch({ type: 'SET_REASON', payload: proxy.target.value });
    };

    return (
        <NewCardLayout
            title={title}
            id={selectReasonCardId}
            dispatch={dispatch}
            disableBack={disableBack}
            disableNext={disableNext}
        >
            <PortalContainer>
                <Row>
                    <Col sm="12">
                        <CardHeader>
                            {subtitle || serialConfirmation}
                        </CardHeader>
                    </Col>
                </Row>
                <StyledAlert color="info">{instructions}</StyledAlert>
                <FormGroup tag="fieldset" autoComplete="off">
                    {reasons.map((reason) => (
                        <FormGroup
                            className="form-check"
                            key={t(`reasonType$${reason}`)}
                        >
                            <Label className="form-check-label">
                                <StyledRadioButton
                                    id={reason}
                                    checked={reason === selectedValue}
                                    value={reason}
                                    type="radio"
                                    name="disable"
                                    onChange={handleRadioClick}
                                />
                                {isHelpDesk
                                    ? t(`helpDesk$reasonType$${reason}`)
                                    : t(`reasonType$${reason}`)}
                            </Label>
                        </FormGroup>
                    ))}
                </FormGroup>
            </PortalContainer>
        </NewCardLayout>
    );
};

SelectReason.propTypes = {
    title: PropTypes.string,
    header: PropTypes.string,
    instructions: PropTypes.string,
    dispatch: PropTypes.func,
    state: PropTypes.object,
    isHelpdesk: PropTypes.bool,
};

SelectReason.defaultProps = {
    title: '',
    header: '',
    instructions: '',
    isHelpdesk: false,
};

export default SelectReason;
