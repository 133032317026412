/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postResetFailCounter } from '../../utils/walkways';
import { AppDispatchContext, AppStateContext } from '../../context/AppContext';
import TerminusCard from '../cardLibrary/Terminus';
import ResetFailCounterCard from '../cardLibrary/ResetFailCounterCard';
import { PROGRESS_BAR_DONE_DELAY } from '../../constants';

const workflow = ['resetFailCounterCard', 'terminus'];

const ResetFailcounter = () => {
    const { t } = useTranslation();
    const dispatch = useContext(AppDispatchContext);
    const { device, user, goBackLink, errorMsg } = useContext(AppStateContext);
    const [isDone, setIsDone] = useState(false);
    const [step, setStep] = useState(0);

    const components = {
        /* eslint-disable react/prop-types */
        resetFailCounterCard: (props) => <ResetFailCounterCard {...props} />,
        terminus: () => (
            <TerminusCard
                title={t('cardTitles$ResetFailcounter')}
                errorMsg={errorMsg}
                msg={t('resetFailcounterMsg')}
                path={goBackLink}
            />
        ),
    };

    const setResult = async () => {
        setIsDone(true);
        setTimeout(() => setStep(step + 1), PROGRESS_BAR_DONE_DELAY);
    };

    useEffect(() => {
        postResetFailCounter(setResult, dispatch, {
            user: { username: device.user },
            type: device.type,
            serial: device.serial,
            subType: device.subType,
        });
    }, []);

    return (
        <>
            {components[workflow[step]]({
                isDone,
            })}
        </>
    );
};

export default ResetFailcounter;
