import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import {
    PortalContainer,
    StyledAlert,
    StyledNavIcon,
    AccentButton,
} from '../../../../styles/common';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import ReportsTable from '../../../elementLibrary/ReportsTable';
import {
    AppStateContext,
    AppDispatchContext,
} from '../../../../context/AppContext';
import {
    emptyDevicesLabelId,
    reportDevicesTableColumnsSettings,
    reportsPaths,
} from '../../../../constants';
import { useSortColumn, useLazyFetchData, useDidMount } from '../../../../utils/hooks';
import { ReportsDispatchContext, ReportsStateContext } from '../../../../context/ReportContext';
import {
    isAllowed,
    reportsModifyPrivileges,
} from '../../../../utils/authorization';

const InventoryHeader = () => {
    const { t } = useTranslation();
    const {
        user: { privileges },
    } = useContext(AppStateContext);

    const modifySearchAllowed = isAllowed({
        allowedPermissions: reportsModifyPrivileges,
        permissions: privileges,
    });

    return (
        <Row>
            <Col>
                {modifySearchAllowed && (
                    <Link to={reportsPaths.reportNew}>
                        <AccentButton outline size="sm" color="primary">
                            <StyledNavIcon icon="plus-circle" />
                            {t('reports$inventory$addReportBtn')}
                        </AccentButton>
                    </Link>
                )}
            </Col>
        </Row>
    );
};

const Inventory = () => {
    const {
        admin: { metadata },
        activeReportsColumns,
    } = useContext(AppStateContext);
    const { triggerInventoryFetch } = useContext(ReportsStateContext);
    const reportsDispatch = useContext(ReportsDispatchContext);
    const appDispatch = useContext(AppDispatchContext);
    const { t } = useTranslation();
    const [fetchedReportsData, setFetchedReportsData] = useState([]);

    const [
        triggerInventoryFetchCallback,
        { loading: reportsLoading },
    ] = useLazyFetchData({
        endpoint: '/setting/config/reports',
        onSuccess: (data) => {
            setFetchedReportsData(data);
            reportsDispatch({
                type: 'SET_CUSTOM_REPORTS_LIST',
                reports: data,
            });
        },
    });

    const { reports: reportHomeMetadata } = metadata || {};
    const { columns } = reportHomeMetadata || {};

    useDidMount(() => {
        triggerInventoryFetchCallback();
    });

    useEffect(() => {
        if (triggerInventoryFetch && !reportsLoading) {
            triggerInventoryFetchCallback();
            reportsDispatch({
                type: 'TRIGGER_INVENTORY_FETCH',
                payload: false,
            });
        }
    }, [triggerInventoryFetch, reportsLoading, triggerInventoryFetchCallback, reportsDispatch]);

    useSortColumn({
        data: fetchedReportsData,
        columns,
        activeColumns: activeReportsColumns,
    });

    const onColumnsChange = useCallback((value) => {
        appDispatch({ type: 'SET_ACTIVE_REPORTS_COLUMNS', payload: value });
    }, [appDispatch]);

    return (
        <PortalContainer>
            <InventoryHeader />
            {!reportsLoading && isEmpty(fetchedReportsData) && (
                <StyledAlert id={emptyDevicesLabelId} color="info" fade={false}>
                    {t('helpDesk$devices$emptyDeviceTable')}
                </StyledAlert>
            )}
            <ReportsTable
                data={fetchedReportsData}
                columnsName={columns}
                activeColumns={activeReportsColumns}
                onColumnsChange={onColumnsChange}
                selectedColumnsKey={`${reportDevicesTableColumnsSettings}forReportCreationPage`}
                actions={false}
                hideCheckboxes={true}
            />
            {reportsLoading && <LoadingSpinner />}
        </PortalContainer>
    );
};

export default Inventory;
