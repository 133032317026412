import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Col, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { resyncOtpFormId } from '../../../constants';
import { StyledInput } from '../../../styles/common';
import SecureEntryIcon from '../../shared/SecureEntryIcon';

const ResyncOtpWithData = ({ dispatch }) => {
    const { t } = useTranslation();
    const [clearText, setClearText] = useState(false);

    return (
        <div data-test-id={resyncOtpFormId}>
            <FormGroup row autoComplete="off">
                <Col xs="3">
                    <Label for="otp1">
                        {t('formLabels$resyncOtp$formLabel1')}
                    </Label>
                </Col>
                <Col xs="5">
                    <StyledInput
                        type={clearText ? 'text' : 'password'}
                        name="otp1"
                        placeholder={t('formLabels$resyncOtp$placeholder1')}
                        bsSize="sm"
                        onChange={({ target }) => {
                            dispatch({
                                type: 'SET_OTP1',
                                payload: target.value,
                            });
                        }}
                    />
                </Col>
                <Col xs="0">
                    <SecureEntryIcon
                        isActive={clearText}
                        onMouseDown={() => setClearText(!clearText)}
                    />
                </Col>
            </FormGroup>
            <FormGroup row autoComplete="off">
                <Col xs="3">
                    <Label for="otp2">
                        {t('formLabels$resyncOtp$formLabel2')}
                    </Label>
                </Col>
                <Col xs="5">
                    <StyledInput
                        type={clearText ? 'text' : 'password'}
                        name="otp2"
                        placeholder={t('formLabels$resyncOtp$placeholder2')}
                        bsSize="sm"
                        onChange={({ target }) => {
                            dispatch({
                                type: 'SET_OTP2',
                                payload: target.value,
                            });
                        }}
                    />
                </Col>
            </FormGroup>
        </div>
    );
};

ResyncOtpWithData.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default ResyncOtpWithData;
