import React, { useContext, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserEntryCard from '../cardLibrary/UserEntry';
import TerminusCard from '../cardLibrary/Terminus';
import MessageCard from '../cardLibrary/MessageCard';
import ResetPinCard from '../cardLibrary/ResetPin';
import DeviceDetection from '../elementLibrary/DeviceDetection'; // eslint-disable-line
import FlowButtons from '../elementLibrary/FlowButtons';
import { AppDispatchContext, AppStateContext } from '../../context/AppContext';
import LoadingSpinner from '../shared/LoadingSpinner';
import {
    EnrollingDispatchContext,
    EnrollingStateContext,
} from '../../context/EnrollingContext';
import { useFetchData } from '../../utils/hooks';
import { PaddingDivider } from '../../styles/common';
import { HelpDeskDispatchContext } from '../../context/HelpDeskContext';
import ValidateQuestionCard from '../cardLibrary/ValidateQuestion';
import { paths, KIOSK_MODE } from '../../constants';
import DeviceNotFound from '../cardLibrary/DeviceNotFound';
import EnrollQuestionCard from '../cardLibrary/EnrollQuestion';

function reducer(state, action) {
    switch (action.type) {
        case 'SET_RESET_PIN_WORKFLOW':
            return {
                ...state,
                workflow: action.payload,
                fetching: false,
            };
        case 'SET_NEXT_STEP':
            return {
                ...state,
                step: state.step + 1,
            };
        case 'SET_BACK_STEP':
            return {
                ...state,
                step: state.step - 1,
                detected: false,
            };
        case 'SET_DEVICE':
            return {
                ...state,
                device: action.payload,
            };
        case 'ADD_PIN':
            return {
                ...state,
                device: {
                    ...state.device,
                    pin: action.pin,
                    user: action.user,
                },
            };
        case 'SET_READER':
            return {
                ...state,
                reader: action.payload,
            };
        case 'SET_DETECTED':
            return {
                ...state,
                detected: true,
            };
        case 'CLEAR_DETECTED':
            return {
                ...state,
                detected: false,
            };
        case 'SET_CURRENT_ANSWER':
            return {
                ...state,
                challenges: {
                    ...state.challenges,
                    [action.payload.session]: action.payload.answer,
                },
            };
        default:
            return state;
    }
}

const ResetPin = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const appDispatch = useContext(AppDispatchContext);
    const { errorMsg, device, goBackLink, viewMode } =
        useContext(AppStateContext);
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const { device: enrollingDevice } = useContext(EnrollingStateContext);
    const helpDeskDispatch = useContext(HelpDeskDispatchContext);
    const [state, dispatch] = useReducer(reducer, {
        workflow: [],
        fetching: true,
        step: 0,
        device,
        reader: '',
        detected: false,
    });

    const { location } = history || {};
    const { pathname } = location || {};
    const isHelpDesk = pathname ? pathname.includes(paths.helpDesk) : false;
    const returnPath =
        goBackLink || (isHelpDesk ? paths.helpDesk : paths.identities);
    const isKiosk = viewMode === KIOSK_MODE;

    useEffect(() => {
        return () => {
            enrollingDispatch({ type: 'RESET_FLOWS' });
            helpDeskDispatch({ type: 'RESET_CONTEXT' });
            appDispatch({ type: 'RESET_FLOWS' });
        };
    },[]);

    const setResetPinWorkflow = (walkway) => {
        if (device) {
            const hasX509 = device.credentials.some(
                (credential) => credential.type.toLowerCase() === 'x509' || credential.type === 'whfb',
            );
            const newWalkway = hasX509
                ? walkway.filter(
                    (elem) => !elem.state || elem.state === 'hasX509',
                )
                : walkway.filter(
                    (elem) => !elem.state || elem.state === 'notHasX509',
                );
            const workflow = hasX509
                ? [{ type: 'detection' }, ...newWalkway]
                : [...newWalkway, { type: 'resetPin' }];
            dispatch({
                type: 'SET_RESET_PIN_WORKFLOW',
                payload: [
                    ...workflow,
                    {
                        type: 'terminus',
                    },
                ],
            });
            enrollingDispatch({
                type: 'SET_CREDENTIAL_FLOW',
                credentialFlow: newWalkway,
            });
        }
    };
    const { type, serial, group, subType, user } = device || {};
    const queryParams = group ? { group } : {};
    queryParams.subType = subType || undefined;
    queryParams.user = user || undefined;
    useFetchData({
        skip: !type || !serial,
        endpoint: `/device/resetPin/${type}/${serial}`,
        onSuccess: (res) => setResetPinWorkflow(res),
        queryParams,
    });

    useEffect(() => {
        enrollingDispatch({
            type: 'SET_USERNAME',
            username: user,
        });
    }, [user, enrollingDispatch]);

    const deviceNotFound = () =>
        dispatch({
            type: 'SET_RESET_PIN_WORKFLOW',
            payload: [{ type: 'deviceNotFound' }],
        });

    const components = {
        loadingSpinner: ({ title }, mgmt) => (
            <LoadingSpinner title={title} mgmt={mgmt} flowButtons />
        ),
        userEntry: ({ setPass, setCurrentPin, setCurrentAnswer }, mgmt) => (
            <UserEntryCard
                setPass={setPass}
                setCurrentPin={setCurrentPin}
                setCurrentAnswer={setCurrentAnswer}
                mgmt={{ ...mgmt, next: mgmt.uen, back: mgmt.homeBack }}
                device={device}
            />
        ),
        questionValidate: (thisProps, mgmt) => (
            <ValidateQuestionCard mgmt={mgmt} deviceUser={user} />
        ),
        resetPin: ({ device, reader }, mgmt) => (
            <ResetPinCard device={device} reader={reader} mgmt={mgmt} />
        ),
        pivReset: ({ device, reader }, mgmt) => (
            <ResetPinCard device={device} reader={reader} mgmt={mgmt} />
        ),
        terminus: (
            { title, errorMsg },
            mgmt, // eslint-disable-line no-unused-vars
        ) => (
            <TerminusCard
                title={title}
                errorMsg={errorMsg}
                msg={t('resetPinSuccessMsg'.concat(isKiosk ? '$kiosk' : ''))}
                path={returnPath}
            />
        ),
        detection: ({ msg, title, alertStyle, device, setReader }, mgmt) => (
            <>
                <MessageCard
                    msg={msg}
                    title={title}
                    alertStyle={alertStyle}
                    mgmt={mgmt}
                >
                    <DeviceDetection
                        device={device}
                        mgmt={mgmt}
                        appDispatch={appDispatch}
                        enrollingDispatch={enrollingDispatch}
                        setReader={setReader}
                        handleNotFound={deviceNotFound}
                    />
                    <PaddingDivider />
                    <FlowButtons
                        mgmt={{
                            next: mgmt.next,
                            back: mgmt.back,
                            showBack: true,
                            showNext: false,
                        }}
                    />
                </MessageCard>
            </>
        ),
        deviceNotFound: ({ title }) => <DeviceNotFound title={title} />,
        questionEnroll: (thisProps, mgmt) => (
            <EnrollQuestionCard stepId='questionEnroll' mgmt={mgmt} />
        ),
    };

    const homeBack = () => {
        history.push(returnPath);
    };

    const setUserEntryEnrolling = (step) => {
        const { type } = state.workflow[step];
        if (type !== 'userEntry') {
            return;
        }
        enrollingDispatch({
            type: 'SET_USER_ENTRY',
            userEntry: state.workflow[step],
        });
    };

    const next = () => {
        if (state.step < state.workflow.length) {
            setUserEntryEnrolling(state.step + 1);
            dispatch({
                type: 'SET_NEXT_STEP',
            });
        }
    };

    const back = () => {
        if (state.step > 0) {
            setUserEntryEnrolling(state.step - 1);
            dispatch({
                type: 'SET_BACK_STEP',
            });
        }
    };

    const userEntryNext = () => {
        const { pin } = enrollingDevice || {};
        dispatch({
            type: 'ADD_PIN',
            pin,
            user,
        });
        next();
    };

    const stepComp = state.workflow[state.step];

    if (!device) {
        history.push(returnPath);
        return null;
    }
    return (
        <>
            {state.fetching ? (
                <LoadingSpinner />
            ) : (
                components[state.workflow[state.step].type](
                    {
                        // props
                        errorMsg,
                        title: t('cardTitles$resetPin'),
                        device: state.device,
                        setCurrentAnswer: (answer, session) => {
                            dispatch({
                                type: 'SET_CURRENT_ANSWER',
                                payload: {
                                    answer,
                                    session,
                                },
                            });
                        },
                        setCurrentPin: (oldPin) =>
                            dispatch({
                                type: 'SET_DEVICE',
                                payload: {
                                    ...state.device,
                                    oldPin,
                                },
                            }),
                        setPass: (pass) =>
                            dispatch({
                                type: 'SET_DEVICE',
                                payload: {
                                    ...state.device,
                                    pass,
                                },
                            }),
                        setReader: (reader) =>
                            dispatch({
                                type: 'SET_READER',
                                payload: reader,
                            }),
                        reader: state.reader,
                        userEntry:
                            stepComp.type === 'userEntry' ? stepComp : {},
                    },
                    {
                        // state
                        workflow: state.workflow,
                        fetching: state.fetching,
                        step: state.step,
                        device: state.device,
                        reader: state.reader,
                        detected: state.detected,
                        challenges: state.challenges,
                        next,
                        showNext: false,
                        showBack: state.step === 0,
                        back: state.step === 0 ? homeBack : back,
                        setWorkflow: setResetPinWorkflow,
                        setDetected: async () =>
                            dispatch({
                                type: 'SET_DETECTED',
                            }),
                        uen: userEntryNext,
                        homeBack,
                    },
                )
            )}
        </>
    );
};

export default ResetPin;
