import React from 'react';
import { Col } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    PortalContainer,
    StyledFormGroup,
    SelectInput,
    SyncIcon,
} from '../../../styles/common';

const displayDeviceOption = (devices, readers) => {
    return devices.map((readerName) => {
        const reader = readers[readerName];

        return reader.issuable === true ? (
            <option
                key={`${reader.label}${reader.serial}`}
                value={reader.readerName}
                data-test-id={`${reader.serial}`}
            >
                {reader.display}
            </option>
        ) : (
            <option
                key={`${reader.readerName}`}
                disabled
                value={reader.readerName}
            >
                {reader.display
                    ? `${reader.display}`
                    : `${reader.readerName}: ${reader.issuable}`}
            </option>
        );
    });
};

const DeviceSelectionWithData = ({
    readers,
    defaultOption,
    display,
    setReader,
    syncing,
}) => {
    const { t } = useTranslation();
    const operable = Object.keys(readers).filter(
        (readerName) =>
            readers[readerName].issuable === true &&
            readers[readerName].display,
    );
    const inoperable = Object.keys(readers).filter(
        (readerName) =>
            readers[readerName].issuable !== true &&
            (readers[readerName].display || readers[readerName].readerName) &&
            !['assigned','otherAssigned'].includes(readers[readerName].status)
    );
    const assigned = Object.keys(readers).filter(
        (readerName) =>
            readers[readerName].issuable !== true &&
            (readers[readerName].display || readers[readerName].readerName) &&
            ['assigned','otherAssigned'].includes(readers[readerName].status ),
    );
    return (
        <PortalContainer>
            <StyledFormGroup>
                <Col sm="9">
                    <SelectInput
                        disabled={!display}
                        type="select"
                        name="select"
                        aria-label="select"
                        onChange={(event) => {
                            setReader(event.target.value);
                        }}
                    >
                        <option>
                            {operable.length === 0 &&
                            inoperable.length === 0 &&
                            assigned.length === 0
                                ? t(
                                    'genericDeviceDropdownLabels$noDevicesOption',
                                )
                                : defaultOption}
                        </option>
                        {operable.length > 0 && (
                            <optgroup label={t('deviceSelection$available')}>
                                {displayDeviceOption(operable, readers)}
                            </optgroup>
                        )}
                        {inoperable.length > 0 && (
                            <optgroup label={t('deviceSelection$ineligible')}>
                                {displayDeviceOption(inoperable, readers)}
                            </optgroup>
                        )}
                        {assigned.length > 0 && (
                            <optgroup label={t('deviceSelection$assigned')}>
                                {displayDeviceOption(assigned, readers)}
                            </optgroup>
                        )}
                    </SelectInput>
                </Col>
                <SyncIcon icon="sync" size="lg" spin $syncing={syncing} />
            </StyledFormGroup>
        </PortalContainer>
    );
};

DeviceSelectionWithData.propTypes = {
    readers: PropTypes.object.isRequired,
    display: PropTypes.bool,
    setReader: PropTypes.func.isRequired,
    defaultOption: PropTypes.string.isRequired,
};

DeviceSelectionWithData.defaultProps = {
    display: false,
};

export default DeviceSelectionWithData;
