import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import CurrentQuestionWithData from './CurrentQuestionWithData';
import { useDidMount } from '../../../utils/hooks';

const CurrentQuestion = ({
    registerForm,
    serverComponent,
    setCurrentAnswer,
}) => {
    const { questions } = serverComponent.details;
    const validator = (value) => (value ? true : 'Error Current Answer');
    const initialClearText = {};
    const registerFormObject = {};
    for (let i = 0; i < questions.length; i++) {
        initialClearText[i] = false;
        registerFormObject[`currentQuestion${i}`] = validator;
    }
    const [clearText, setClearText] = useState(initialClearText);

    useDidMount(() => {
        if (registerForm) {
            registerForm(registerFormObject);
        }
    });

    const clearTextSwitch = (questionNumber) => {
        setClearText({
            ...clearText,
            [questionNumber]: !clearText[questionNumber],
        });
    };

    return (
        <>
            {questions.map((question, i) => (
                <CurrentQuestionWithData
                    validator={validator}
                    setCurrentAnswer={setCurrentAnswer}
                    clearText={clearText[i]}
                    clearTextSwitch={() => clearTextSwitch(i)}
                    question={question}
                    questionNumber={i}
                    questionLength={questions.length}
                    key={question}
                />
            ))}
        </>
    );
};

CurrentQuestion.propTypes = {
    registerForm: PropTypes.func.isRequired,
    serverComponent: PropTypes.object.isRequired,
    setCurrentAnswer: PropTypes.func.isRequired,
};

export default CurrentQuestion;
