import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import {
    paths,
    KIOSK_MODE,
    identitiesTableColumnsSettings,
} from '../../../constants';
import { camelCaseToSentence } from '../../../utils/generic';
import { actionTypes, kioskActionToDeviceAction } from '../../../utils/mappings';
import {
    StyledNavIcon,
    PortalContainer,
    StyledAlert,
    AccentButton,
    Title,
    SubTitle,
} from '../../../styles/common';
import {
    AppStateContext,
    AppDispatchContext,
} from '../../../context/AppContext';
import LoadingSpinner from '../../shared/LoadingSpinner';
import DevicesTable from '../../elementLibrary/DevicesTable';

// Hidding the clear filter button for now
const hideClearFilterButton = true;

const IdentitiesHeaderRow = ({
    kioskMode = false,
    kioskAction = null,
    kioskDeviceId = null,
    location,
}) => {
    const { t } = useTranslation();
    const dispatch = useContext(AppDispatchContext);
    const history = useHistory();
    const showAddIdentityButton = !(kioskMode && !!kioskAction);
    const { pathname } = location || {};

    let filterDisplay;
    if (kioskAction) {
        filterDisplay = `${t(
            'identitiesCardLabels$stateless$action',
        )}: ${camelCaseToSentence(kioskAction)}`;

        if (kioskDeviceId)
            filterDisplay = `${t(
                'identitiesCardLabels$stateless$action',
            )}: ${camelCaseToSentence(kioskAction)} / ${kioskDeviceId}`;
    }

    const saveBackLink = () =>
        dispatch({ type: 'SET_GO_BACK_LINK', payload: pathname });

    return (
        <>
            <Col>
                <Row>
                    <Title style={{ margin: '0.75rem' }}>
                        {`${t('cardTitles$identities')}`}
                    </Title>
                    {showAddIdentityButton && (
                        <Link to={paths.credentialing} onClick={saveBackLink}>
                            <AccentButton
                                outline
                                size="sm"
                                id="add-identity"
                                color="primary"
                            >
                                <StyledNavIcon icon="plus-circle" />
                                {t('identitiesCardLabels$stateless$button')}
                            </AccentButton>
                        </Link>
                    )}
                    {!hideClearFilterButton && kioskAction && (
                        <Button
                            outline
                            size="sm"
                            id="remove-action"
                            color="primary"
                            onClick={() => {
                                dispatch({ type: 'CLEAR_KIOSK_ACTION' });
                                history.push('/');
                            }}
                        >
                            <StyledNavIcon icon="times-circle" />
                            {filterDisplay}
                        </Button>
                    )}
                </Row>
                <Row>
                    <SubTitle style={{ margin: ' 0 0.75rem' }}>
                        {t('bannerLabels$identities$info')}
                    </SubTitle>
                </Row>
            </Col>
        </>
    );
};

IdentitiesHeaderRow.propTypes = {
    kioskMode: PropTypes.bool.isRequired,
    kioskAction: PropTypes.string,
    kioskDeviceId: PropTypes.string,
};

const IdentitiesCardWithData = ({ data = [], loading = true }) => {
    const { t } = useTranslation();
    const {
        admin: { metadata },
        viewMode,
        kioskAction,
        kioskDeviceId,
        activeDevicesColumns,
        settings,
    } = useContext(AppStateContext);
    const defaultDeviceColumns =
        settings.defaultDeviceColumns === ''
            ? []
            : settings.defaultDeviceColumns.split(',');
    const dispatch = useContext(AppDispatchContext);
    const history = useHistory();

    const { devices } = metadata || {};
    const { columns } = devices || {};
    const {
        location: { pathname },
    } = history || {};

    useEffect(
        () => {
            if (viewMode === KIOSK_MODE) {
                // clear cached action and device
                dispatch({ type: 'SET_KIOSK_MODE', payload: { action:"", deviceId:"" } });
            }
        }, [])

    const onColumnsChange = (value) => {
        dispatch({
            type: 'SET_ACTIVE_DEVICES_COLUMNS',
            payload: value,
        });
    };

    if (kioskAction === 'credentialing') {
        history.replace(paths.credentialing);
        return null;
    } 
    
    if (kioskAction === 'updateQA') {
        history.replace(paths.updateSecurity);
        return null;
    }
    
    const filteredDevice =
        (!isEmpty(data) &&
            data.filter((d) => {
                if (!kioskDeviceId) return true;
                return d.serial === kioskDeviceId;
            })) ||
        [];
    const deviceAction = kioskActionToDeviceAction[kioskAction] ? kioskActionToDeviceAction[kioskAction] : kioskAction;
    if (filteredDevice && filteredDevice.length === 1 && deviceAction) {
        if (filteredDevice[0].actions.includes(deviceAction)) {
            dispatch({
                type: 'SET_DEVICE',
                device: filteredDevice[0],
                goBackLink: pathname,
            });
            history.push(actionTypes[deviceAction].href);
            return <LoadingSpinner />;
        }
    }

    if (
        filteredDevice &&
        filteredDevice.length === 0 &&
        viewMode === KIOSK_MODE
    ) {
        history.push(paths.credentialing);
        return <LoadingSpinner />;
    }
    if (deviceAction && actionTypes[deviceAction] === undefined)
        return (
            <PortalContainer>
                <StyledAlert
                    id="invalid-action-alert"
                    color="danger"
                    fade={false}
                >
                    {t('invalidAction')}
                </StyledAlert>
            </PortalContainer>
        );

    const getTableHeader = (settingsIconCol) => (
        <>
            <Row>
                <IdentitiesHeaderRow
                    kioskMode={viewMode === KIOSK_MODE}
                    kioskAction={deviceAction}
                    kioskDeviceId={kioskDeviceId}
                />
                {settingsIconCol}
            </Row>
        </>
    );
    return (
        <>
            {metadata.devices.columns ? (
                <PortalContainer>
                    <DevicesTable
                        data={filteredDevice}
                        columnsName={columns}
                        activeColumns={activeDevicesColumns}
                        onColumnsChange={onColumnsChange}
                        selectedColumnsKey={identitiesTableColumnsSettings}
                        defaultColumns={defaultDeviceColumns}
                        getTableHeader={getTableHeader}
                        hideCheckboxes
                        enableDeviceDetails
                    />
                    {loading && <LoadingSpinner />}
                </PortalContainer>
            ) : (
                <LoadingSpinner />
            )}
        </>
    );
};

IdentitiesCardWithData.propTypes = {
    devices: PropTypes.arrayOf(PropTypes.object),
};

IdentitiesCardWithData.defaultProps = {
    devices: [],
};

export default IdentitiesCardWithData;
