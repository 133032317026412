import React, { useContext, useEffect, useReducer } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserEntryCard from '../cardLibrary/UserEntry';
import EnrollU2fCard from '../cardLibrary/EnrollU2f';
import EnrollOtpCard from '../cardLibrary/EnrollOtp';
import EnrollPivCard from '../cardLibrary/EnrollPiv';
import EnrollIdWallet from '../cardLibrary/EnrollIdWallet';
import EnrollFido2BoundCard from '../cardLibrary/EnrollFido2BoundCard';
import EnrollFido2UnboundCard from '../cardLibrary/EnrollFido2UnboundCard';
import EnrollMobileOTP from '../cardLibrary/EnrollMobileOTP';
import TerminusCard from '../cardLibrary/Terminus';
import IssuanceTypeCard from '../cardLibrary/IssuanceType';
import EnrollQuestionCard from '../cardLibrary/EnrollQuestion';
import ValidateQuestionCard from '../cardLibrary/ValidateQuestion';
import { AppStateContext, AppDispatchContext } from '../../context/AppContext';
import { EnrollingDispatchContext } from '../../context/EnrollingContext';
import { paths, KIOSK_MODE } from '../../constants';
import EnrollVscCard from '../cardLibrary/EnrollVsc';

const DEVICE_SELECTION = 'deviceSelection';
const TERMINUS = 'terminus';

function reducer(state, action) {
    switch (action.type) {
        case 'SET_NEXT_STEP':
            return {
                ...state,
                step: state.step + 1,
            };
        case 'SET_BACK_STEP':
            return {
                ...state,
                step: state.step - 1,
            };
        case 'RESET_STATE':
            return {
                ...state,
                workflow: [
                    {
                        type: DEVICE_SELECTION,
                    },
                    {
                        type: TERMINUS,
                    },
                ],
                challenges: null,
                step: 0,
            };
        case 'SET_CREDENTIALING_WORKFLOW':
            return {
                ...state,
                workflow: [
                    state.workflow[0],
                    ...action.payload,
                    state.workflow[state.workflow.length - 1],
                ],
            };
        case 'SET_CURRENT_ANSWER':
            return {
                ...state,
                challenges: {
                    ...state.challenges,
                    [action.payload.session]: action.payload.answer,
                },
            };
        case 'SET_TERMINUS_INFO':
            return {
                ...state,
                terminusInfo: action.terminusInfo
            }
        default:
            return state;
    }
}

const Credentialing = () => {
    const { t } = useTranslation();
    const {
        errorMsg: errorMessage,
        user,
        goBackLink,
        viewMode,
        device,
    } = useContext(AppStateContext);
    const appDispatch = useContext(AppDispatchContext);
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const { username } = useParams();

    const history = useHistory();
    const { location } = history || {};
    const { pathname } = location || {};
    const isHelpDesk = pathname ? pathname.includes(paths.helpDesk) : false;
    const returnPath =
        goBackLink || (isHelpDesk ? paths.helpDesk : paths.identities);

    useEffect(() => {
        enrollingDispatch({
            type: 'SET_USERNAME',
            username: user.username,
        });
    }, [user, enrollingDispatch]);

    const [state, dispatch] = useReducer(reducer, {
        step: 0,
        workflow: [
            {
                type: DEVICE_SELECTION,
            },
            {
                type: TERMINUS,
            },
        ],
        answer: null,
        session: null,
        terminusInfo: null,
    });

    const components = {
        /* eslint-disable react/prop-types */
        deviceSelection: (thisProps, mgmt) => (
            <IssuanceTypeCard mgmt={mgmt} path={returnPath} />
        ),
        userEntry: ({ setCurrentPin, setCurrentAnswer }, mgmt) => (
            <UserEntryCard
                setCurrentAnswer={setCurrentAnswer}
                setCurrentPin={setCurrentPin}
                mgmt={{
                    ...mgmt,
                    back: mgmt.resetMgmtState,
                }}
            />
        ),
        fido2BoundEnroll: (thisProps, mgmt) => <EnrollFido2BoundCard mgmt={mgmt} />,
        fido2UnboundEnroll: (thisProps, mgmt) => <EnrollFido2UnboundCard mgmt={mgmt} />,
        u2fEnroll: (thisProps, mgmt) => <EnrollU2fCard mgmt={mgmt} />,
        otpEnroll: (thisProps, mgmt) => <EnrollOtpCard mgmt={mgmt} />,
        pivEnroll: (thisProps, mgmt) => <EnrollPivCard mgmt={mgmt} />,
        vscEnroll: (thisProps, mgmt) => <EnrollVscCard mgmt={mgmt} />,
        idWalletEnroll: (thisProps, mgmt) => <EnrollIdWallet mgmt={mgmt} />,
        mobileOtpEnroll: (thisProps, mgmt) => <EnrollMobileOTP mgmt={mgmt} />,
        questionEnroll: (thisProps, mgmt) => <EnrollQuestionCard mgmt={mgmt} />,
        questionValidate: (thisProps, mgmt) => (
            <ValidateQuestionCard mgmt={mgmt} />
        ),
        terminus: ({ title, errorMsg }) => {
            const { terminusInfo } = state;
            return (
                <TerminusCard
                    title={title}
                    errorMsg={errorMsg}
                    msg={t(
                        'issuanceSuccessMsg'.concat(
                            viewMode === KIOSK_MODE ? '$kiosk' : '',
                        ),
                    )}
                    info={terminusInfo}
                    path={returnPath}
                />
            )
        },
    };

    useEffect(() => {
        return () => {
            enrollingDispatch({
                type: 'RESET_FLOWS',
            });
            if (device !== null) {
                appDispatch({
                    type: 'RESET_FLOWS',
                });
            }
        };
    },[]);

    useEffect(() => {
        if (!!username) {
            enrollingDispatch({ type: 'SET_USERNAME', username });
        }
    }, [username, enrollingDispatch]);

    const setUserEntryEnrolling = (step) => {
        const { type } = state.workflow[step];
        if (type !== 'userEntry') {
            return;
        }
        enrollingDispatch({
            type: 'SET_USER_ENTRY',
            userEntry: state.workflow[step],
        });
    };

    const next = () => {
        if (state.step < state.workflow.length) {
            setUserEntryEnrolling(state.step + 1);
            dispatch({ type: 'SET_NEXT_STEP' });
        }
    };

    const back = () => {
        if (state.step > 0) {
            setUserEntryEnrolling(state.step - 1);
            dispatch({ type: 'SET_BACK_STEP' });
        }
    };

    return (
        <>
            {components[state.workflow[state.step].type](
                {
                    errMsg: errorMessage,
                    title: t('cardTitles$issueIdentity'),
                    setCurrentAnswer: (answer, session) => {
                        dispatch({
                            type: 'SET_CURRENT_ANSWER',
                            payload: {
                                answer,
                                session,
                            },
                        });
                    },
                    setCurrentPin: (oldPin) => {
                        // Hard coded the policy id as piv.pin, because currenly enrolling POSSESSED device only requires piv.pin
                        return enrollingDispatch({
                            type: 'ADD_PIN',
                            id: 'piv.pin',
                            value: oldPin,
                        });
                    },
                },
                {
                    workflow: state.workflow,
                    step: state.step,
                    answer: state.answer,
                    session: state.session,
                    challenges: state.challenges,
                    setTerminusInfo: (info) => {
                        dispatch({
                            type:'SET_TERMINUS_INFO',
                            terminusInfo: info,
                        })
                    },
                    next,
                    showNext: false,
                    showBack: false,
                    back,
                    setWorkflow: (credentialFlow) =>
                        dispatch({
                            type: 'SET_CREDENTIALING_WORKFLOW',
                            payload: credentialFlow,
                        }),
                    resetMgmtState: () =>
                        dispatch({
                            type: 'RESET_STATE',
                        }),
                },
            )}
        </>
    );
};

export default Credentialing;
