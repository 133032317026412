import React, { useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import { BrandingStateContext } from '../../context/BrandingContext';
import { useDidMount } from '../../utils/hooks';

const starClassName = 'star';

const StyledStar = styled.span`
    color: ${({ theme }) => theme.colors.inactive};
    cursor: pointer;
`;

const Star = ({ numberOfStars, onClick, onMouseOver }) =>
    [...Array(numberOfStars).keys()].map((n) => (
        <StyledStar
            className={starClassName}
            key={n + 1}
            data-value={n + 1}
            data-testid={n + 1}
            onMouseOver={onMouseOver}
            onClick={onClick}
        >
            &#9733;
        </StyledStar>
    ));

const StarRating = ({ onClick = () => {}, numberOfStars = 5 }) => {
    const [rating, setRating] = useState(0);
    const {
        theme: {
            colors: { primary, inactive },
        },
    } = useContext(BrandingStateContext);
    const rateRef = useRef();

    const hoverHandler = ({ target }) => {
        const stars =  Array.from(target.parentElement.getElementsByClassName(
            starClassName,
        ));
        const hoverValue = target.dataset.value;
        stars.forEach((star, idx) => {
            stars[idx].style.color =
                hoverValue >= star.dataset.value ? primary : inactive;
        });
    };

    const setRate = () => {
        const stars =  Array.from(rateRef.current.getElementsByClassName(starClassName));
        Array.from(stars).forEach((star, idx) => {
            stars[idx].style.color =
                rating >= star.dataset.value ? primary : inactive;
        });
    };

    const starClickHandler = ({ target }) => {
        const rate = target.dataset.value;
        setRating(rate);
        onClick(rate);
    };

    useDidMount(() => {
        setRate();
    });

    return (
        <div ref={rateRef} onMouseOut={setRate}>
            <Star
                numberOfStars={numberOfStars}
                onMouseOver={hoverHandler}
                onClick={starClickHandler}
            />
        </div>
    );
};

export default StarRating;
