// npm dependencies
import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { selectDelegateMessage } from '../../../utils/generic';
import UserId from '../../formLibrary/UserId';
import CreatePin from '../../formLibrary/CreatePin';
import CurrentPin from '../../formLibrary/CurrentPin';
import Pass from '../../formLibrary/Pass';
import Serial from '../../formLibrary/Serial';
import CreateQuestion from '../../formLibrary/CreateQuestion';
import CurrentQuestion from '../../formLibrary/CurrentQuestion';
import AutoSuggest from '../../formLibrary/AutoSuggest';
import { EnrollingStateContext } from '../../../context/EnrollingContext';
import {
    PortalContainer,
    CardHeader,
    StyledAlert,
    FormComponentWrapper,
} from '../../../styles/common';

const userEntryAlert = {
    update: 'delegationWalkwayLabels$userEntryAlert$update',
    reset: 'delegationWalkwayLabels$userEntryAlert$reset',
    resetUpdate: 'delegationWalkwayLabels$userEntryAlert$resetUpdate',
    replace: 'delegationWalkwayLabels$userEntryAlert$replace',
};

const displayFormComponent = (properties, components) => {
    const formComponents = {
        pass: ({ registerForm, setPass }, component) => (
            <Pass
                registerForm={registerForm}
                setPass={setPass}
                serverComponent={component}
            />
        ),
        pin: (
            {
                registerForm,
                enableSkip,
                disableSkip,
                skipClicked,
                mgmt,
                diableShowNext,
            },
            component,
        ) => (
            <CreatePin
                registerForm={registerForm}
                serverComponent={component}
                enableSkip={enableSkip}
                disableSkip={disableSkip}
                skipClicked={skipClicked}
                diableShowNext={diableShowNext}
                mgmt={mgmt}
            />
        ),
        serial: ({ registerForm }, component) => (
            <Serial registerForm={registerForm} serverComponent={component} />
        ),
        currentPin: ({ registerForm, setCurrentPin, forgotPin }, component) => (
            <CurrentPin
                registerForm={registerForm}
                setCurrentPin={setCurrentPin}
                forgotPin={forgotPin}
                serverComponent={component}
            />
        ),
        userid: ({ registerForm }, component) => (
            <UserId registerForm={registerForm} serverComponent={component} />
        ),
        autoSuggest: ({ registerForm }, component) => (
            <AutoSuggest
                registerForm={registerForm}
                serverComponent={component}
            />
        ),
        question: ({ registerForm }, component) => (
            <CreateQuestion
                registerForm={registerForm}
                serverComponent={component}
            />
        ),
        currentQuestion: ({ registerForm, setCurrentAnswer }, component) => (
            <CurrentQuestion
                setCurrentAnswer={setCurrentAnswer}
                registerForm={registerForm}
                serverComponent={component}
            />
        ),
        instruction: (
            {}, // eslint-disable-line no-empty-pattern
            component,
        ) => {
            return (
                <div>
                    { 
                        component?.details?.warning && 
                        (
                            <StyledAlert color="warning">
                                {i18next.t(
                                    `instructionMessages$${component.details.warning}`,
                                )}
                            </StyledAlert>
                        )
                    }
                    <StyledAlert color="info">
                        {i18next.t(
                            `instructionMessages$${component.details.message}$${component.details.extra}`,
                        ) ||
                            i18next.t(
                                `instructionMessages$${component.details.message}`,
                            )}
                    </StyledAlert>
                </div>
            );
        },
    };
    return components.map((component) => {
        if (
            component.state === undefined ||
            component.state === properties.mgmt.flowState
        ) {
            return formComponents[component.type](properties, component);
        }
        return '';
    });
};

const alertMessage = ({ mgmt, delegationOperation }) => {
    return selectDelegateMessage(
        mgmt.flowState,
        delegationOperation,
        userEntryAlert,
    );
};

const UserEntryDelegateWithData = ({
    mgmt,
    delegationOperation,
    registerForm,
    setPass,
    forgotPin,
    setCurrentPin,
    setCurrentAnswer,
    enableSkip = () => {},
    disableSkip = () => {},
    skipClicked = false,
    diableShowNext = () => {},
}) => {
    const { t } = useTranslation();
    const {
        userEntry: { components },
    } = useContext(EnrollingStateContext);
    return (
        <PortalContainer>
            <CardHeader>{t('associateUserLabels$stateless$h6')}</CardHeader>
            {components &&
                components.some((c) => c.type === 'instruction') ? null : (
                    <StyledAlert color="info">
                        {t(alertMessage({ mgmt, delegationOperation }))}
                    </StyledAlert>
                )
            }
            <FormComponentWrapper>
                {displayFormComponent(
                    {
                        registerForm,
                        setPass,
                        forgotPin,
                        setCurrentAnswer,
                        setCurrentPin,
                        mgmt,
                        enableSkip,
                        disableSkip,
                        skipClicked,
                        diableShowNext,
                    },
                    components,
                )}
            </FormComponentWrapper>
        </PortalContainer>
    );
};

UserEntryDelegateWithData.propTypes = {
    userEntry: PropTypes.shape({
        components: PropTypes.arrayOf(PropTypes.object),
    }),
    mgmt: PropTypes.object,
    delegationOperation: PropTypes.string,
    registerForm: PropTypes.func,
    setPass: PropTypes.func,
    forgotPin: PropTypes.func,
    setCurrentPin: PropTypes.func,
    setCurrentAnswer: PropTypes.func,
};

UserEntryDelegateWithData.defaultProps = {
    mgmt: null,
    delegationOperation: '',
    registerForm: null,
    setPass: null,
    forgotPin: null,
    setCurrentPin: null,
    setCurrentAnswer: null,
};

export default UserEntryDelegateWithData;
