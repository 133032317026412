import React from 'react';
import { PropTypes } from 'prop-types';
import { Col, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import SecureEntryIcon from '../../shared/SecureEntryIcon';
import { createQuestionFormId } from '../../../constants';
import { StyledInput, StyledGap } from '../../../styles/common';

const UpdateQuestionWithData = ({
    clearText,
    clearTextSwitch,
    questionNumber,
    questions,
    questionForm,
    updateForm,
    validity,
}) => {
    const { t } = useTranslation();
    return (
        <div data-test-id={createQuestionFormId}>
            <FormGroup row autoComplete="off">
                <Col xs="2">
                    <Label for="question">
                        {t('formLabels$question$formLabel', {
                            NUMBER: questionNumber + 1,
                        })}
                    </Label>
                </Col>
                <Col xs="4">{questions[questionNumber]}</Col>
            </FormGroup>
            <FormGroup row autoComplete="off">
                <Col xs="2" />
                <Col xs="4">
                    <StyledInput
                        valid={validity[questionNumber].answer === true}
                        invalid={validity[questionNumber].answer === false}
                        type={clearText ? 'text' : 'password'}
                        name="answer"
                        placeholder={t('formLabels$answer$placeholder')}
                        bsSize="sm"
                        maxlength="100"
                        value={questionForm[questionNumber].answer}
                        onChange={(event) => {
                            updateForm(
                                event.target.value,
                                'answer',
                                questionNumber,
                            );
                        }}
                    />
                </Col>
                <Col xs="3">
                    <StyledInput
                        valid={
                            validity[questionNumber].confirmAnswer === true
                        }
                        invalid={
                            validity[questionNumber].confirmAnswer === false
                        }
                        type={clearText ? 'text' : 'password'}
                        name="confirmAnswer"
                        placeholder={t('formLabels$confirmAnswer$placeholder')}
                        bsSize="sm"
                        maxlength="100"
                        value={questionForm[questionNumber].confirmAnswer}
                        onChange={(event) => {
                            updateForm(
                                event.target.value,
                                'confirmAnswer',
                                questionNumber,
                            );
                        }}
                    />
                </Col>
                <Col xs="0">
                    <SecureEntryIcon
                        isActive={clearText}
                        onMouseDown={clearTextSwitch}
                    />
                </Col>
            </FormGroup>
            {questionNumber !== questions.length - 1 && <StyledGap />}
        </div>
    );
};

UpdateQuestionWithData.propTypes = {
    checked: PropTypes.objectOf(PropTypes.bool).isRequired,
    questionRef: PropTypes.object.isRequired,
    questionAnswerValidator: PropTypes.func.isRequired,
    confirmAnswerValidator: PropTypes.func.isRequired,
    confirmAnswerBridge: PropTypes.func.isRequired,
    clearText: PropTypes.bool.isRequired,
    clearTextSwitch: PropTypes.func.isRequired,
    answerRef: PropTypes.object.isRequired,
    questionNumber: PropTypes.object.isRequired,
    questions: PropTypes.object.isRequired,
};

export default UpdateQuestionWithData;
