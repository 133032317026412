import React from 'react';
import { Link } from 'react-router-dom';
import { subTabsPaths } from '../../../constants';

const {
    reports: { view },
} = subTabsPaths;

const ReportsLink = ({ data }) => (
    <Link to={{ pathname: `${view}/${data.name}` }}>{data.name}</Link>
);

export default ReportsLink;
