import React, { useContext, useReducer, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { componentKeys } from '../../constants';
import { selectDelegateMessage } from '../../utils/generic';
import { AppStateContext } from '../../context/AppContext';
import FlowButtons from '../elementLibrary/FlowButtons';
import CheckPivLocked from '../elementLibrary/CheckPivLocked';
import CardTemplate from '../shared/CardTemplate';
import EnrollTemplate from '../shared/EnrollTemplate';
import LoadingSpinner from '../shared/LoadingSpinner';
import CardNotification from '../shared/CardNotification';
import TerminusCard from '../cardLibrary/Terminus';
import AssociateUserCard from '../cardLibrary/AssociateUser';
import AssociateDeviceCard from '../cardLibrary/AssociateDevice';
import AssociatePivEnrollCard from '../cardLibrary/AssociatePivEnroll';
import AssociatePivResetCard from '../cardLibrary/AssociatePivReset';
import AssociateU2fEnrollCard from '../cardLibrary/AssociateEnrollU2f';
import AssociateOtpEnrollCard from '../cardLibrary/AssociateEnrollOtp';
import AssociateVscEnrollCard from '../cardLibrary/AssociateEnrollVsc';
import UserEntryDelegateCard from '../cardLibrary/UserEntryDelegate';
import AssociatePivUpdateCard from '../cardLibrary/AssociatePivUpdate';
import ValidateQuestionCard from '../cardLibrary/ValidateQuestion';
import {
    EnrollingDispatchContext,
    EnrollingStateContext,
} from '../../context/EnrollingContext';
import { PaddingDivider } from '../../styles/common';
import AssociateEnrollFido2Card from '../cardLibrary/AssociateEnrollFido2Card';

const { TERMINUS, ASSOCIATE_USER, ASSOCIATE_DEVICE, ASSOCIATE_PIV_ENROLL, ASSOCIATE_PIV_UPDATE, FIDO2_ENROLL } = componentKeys;
const CHECK_LOCKED_PIV = 'checkLockedPiv';

const delegationSuccessMsg = {
    update: 'delegationSuccessMsg$update',
    reset: 'delegationSuccessMsg$reset',
    resetUpdate: 'delegationSuccessMsg$resetUpdate',
    replace: 'delegationSuccessMsg$replace',
};

const terminusMessage = (state) => {
    return selectDelegateMessage(
        state.flowState,
        state.delegationOperation,
        delegationSuccessMsg,
    );
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_DELEGATION_WORKFLOW':
            return {
                ...state,
                ...action.payload,
            };
        case 'SET_STATE_RESET':
            return {
                ...state,
                workflow: [
                    {
                        type: ASSOCIATE_USER,
                    },
                    {
                        type: ASSOCIATE_DEVICE,
                    },
                    {
                        type: CHECK_LOCKED_PIV,
                    },
                    {
                        type: TERMINUS,
                    },
                ],
                step: action.payload,
                flowState: 'notLocked',
                formValues: {},
                reader: '',
                associateUser: action.payload === 0 ? '' : state.associateUser,
                delegationOperation: false,
                answer: null,
                session: null,
                detected: null,
                challenges: null,
            };
        case 'SET_NEXT_STEP':
            return {
                ...state,
                step: state.step + 1,
            };
        case 'SET_BACK_STEP':
            return {
                ...state,
                step: state.step - 1,
                detected: false,
            };
        case 'ASSOCIATE_DEVICE_BACK':
            return {
                ...state,
                step: 1,
            };
        case 'SET_FLOW_STATE':
            return {
                ...state,
                flowState: action.payload,
            };
        case 'SET_READER':
            return {
                ...state,
                reader: action.payload,
            };
        case 'SET_FORM_VALUES':
            return {
                ...state,
                formValues: action.payload,
            };
        case 'SET_ASSOCIATE_USER':
            return {
                ...state,
                associateUser: action.payload,
            };
        case 'SET_CURRENT_ANSWER':
            return {
                ...state,
                challenges: {
                    ...state.challenges,
                    [action.payload.session]: action.payload.answer,
                },
            };
        case 'SET_DETECTED':
            return {
                ...state,
                detected: action.payload,
            };
        default:
            return state;
    }
}

const Delegation = () => {
    const { t } = useTranslation();
    const { errorMsg } = useContext(AppStateContext);
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const { form, device } = useContext(EnrollingStateContext);
    const history = useHistory();

    const [state, dispatch] = useReducer(reducer, {
        workflow: [
            {
                type: 'associateUser',
            },
            {
                type: 'associateDevice',
            },
            {
                type: 'checkLockedPiv',
            },
            {
                type: 'terminus',
            },
        ],
        fetching: false,
        step: 0,
        flowState: 'notLocked',
        reader: {},
        formValues: {},
        delegationOperation: '',
        associateUser: '',
        answer: null,
        session: null,
        detected: null,
    });

    const components = {
        /* eslint-disable react/prop-types */
        loadingSpinner: ({ title }, mgmt) => (
            <LoadingSpinner title={title} mgmt={mgmt} flowButtons />
        ),
        userEntry: (
            { delegationOperation, setCurrentPin, setCurrentAnswer, forgotPin },
            mgmt,
        ) => (
            <UserEntryDelegateCard
                delegationOperation={delegationOperation}
                setCurrentPin={setCurrentPin}
                setCurrentAnswer={setCurrentAnswer}
                forgotPin={forgotPin}
                mgmt={{ ...mgmt, next: mgmt.uen, back: mgmt.adb }}
            />
        ),
        terminus: ({ title, errorMsg }, mgmt) => (
            <TerminusCard
                title={title}
                errorMsg={errorMsg}
                msg={t(terminusMessage(mgmt))}
            />
        ),
        associateDevice: (
            { associateUser, reader, setReader, nextShow, setNextShow },
            mgmt,
        ) => (
            <AssociateDeviceCard
                associateUser={associateUser}
                reader={reader}
                setReader={setReader}
                nextShow={nextShow}
                setNextShow={setNextShow}
                mgmt={mgmt}
            />
        ),
        associatePivReset: ({ reader, associateUser }, mgmt) => (
            <AssociatePivResetCard
                reader={reader}
                associateUser={associateUser}
                mgmt={mgmt}
            />
        ),
        associatePivUpdate: ({ reader, associateUser }, mgmt) => (
            <AssociatePivUpdateCard
                reader={reader}
                associateUser={associateUser}
                mgmt={mgmt}
            />
        ),
        associatePivEnroll: ({ reader, associateUser }, mgmt) => (
            <AssociatePivEnrollCard
                reader={reader}
                associateUser={associateUser}
                mgmt={mgmt}
            />
        ),
        associateU2fEnroll: ({ reader, associateUser }, mgmt) => (
            <AssociateU2fEnrollCard
                reader={reader}
                associateUser={associateUser}
                mgmt={mgmt}
            />
        ),
        associateOtpEnroll: ({ reader, associateUser }, mgmt) => (
            <AssociateOtpEnrollCard
                reader={reader}
                associateUser={associateUser}
                mgmt={mgmt}
            />
        ),
        associateVscEnroll: ({ reader, setReader, associateUser }, mgmt) => (
            <AssociateVscEnrollCard
                reader={reader}
                associateUser={associateUser}
                setReader={setReader}
                mgmt={mgmt}
            />
        ),
        associateUser: ({ associateUser }, mgmt) => (
            <>
                <AssociateUserCard
                    mgmt={{
                        ...mgmt,
                        next: mgmt.aun,
                        back: mgmt.homeBack,
                    }}
                    associateUser={associateUser}
                />
                {t('associateUserLabels$helperText') !== '' && (
                    <CardNotification>
                        {t('associateUserLabels$helperText')}
                    </CardNotification>
                )}
            </>
        ),
        checkLockedPiv: ({ reader }, mgmt) => (
            <CardTemplate
                title={t('cardTitles$associateSupport')}
                subtitle={t('bannerLabels$delegations$info')}
            >
                <EnrollTemplate
                    title={t('delegationWalkwayLabels$checkPivLocked$title')}
                    alert={t('enrollTemplateAlertDefault')}
                >
                    <CheckPivLocked
                        reader={reader}
                        mgmt={mgmt}
                    />
                </EnrollTemplate>
                <PaddingDivider />
                <FlowButtons
                    mgmt={{
                        next: mgmt ? mgmt.next : '',
                        showNext: false,
                        back: mgmt ? mgmt.back : '',
                        showBack: false,
                    }}
                />
            </CardTemplate>
        ),
        questionValidate: ({ associateUser }, mgmt) => (
            <ValidateQuestionCard deviceUser={associateUser} mgmt={mgmt} />
        ),
        fido2UnboundEnroll: (thisProps, mgmt) => <AssociateEnrollFido2Card mgmt={mgmt} />,
    }; /* eslint-enable react/prop-types */

    useEffect(() => {
        return () => {
            enrollingDispatch({
                type: 'RESET_FLOWS',
            });
        };
    },[]);

    const getDelegationOperation = (flow) => {
        const operations = [ASSOCIATE_PIV_ENROLL, ASSOCIATE_PIV_UPDATE, FIDO2_ENROLL];
        // eslint-disable-next-line no-restricted-syntax
        for (const operation of operations) {
            if (flow.some((elem) => elem.type === operation)) {
                return operation;
            }
        }
        return '';
    };

    const setDelegationWorkflow = (flow) => {
        const workflow = Object.assign(state.workflow);
        const delegationOperation = getDelegationOperation(flow);
        workflow.splice(3, 0, ...flow);
        dispatch({
            type: 'SET_DELEGATION_WORKFLOW',
            payload: {
                workflow,
                delegationOperation,
            },
        });

        enrollingDispatch({
            type: 'SET_CREDENTIAL_FLOW',
            credentialFlow: flow,
        });
    };

    const setUserEntryData = (values) => {
        dispatch({
            type: 'SET_FORM_VALUES',
            payload: {
                ...values,
            },
        });
    };

    const homeBack = () => {
        history.goBack();
    };

    const setUserEntryEnrolling = (step) => {
        const { type } = state.workflow[step];
        if (type !== 'userEntry') {
            return;
        }
        enrollingDispatch({
            type: 'SET_USER_ENTRY',
            userEntry: state.workflow[step],
        });
    };

    const next = () => {
        if (state.step < state.workflow.length) {
            setUserEntryEnrolling(state.step + 1);
            dispatch({
                type: 'SET_NEXT_STEP',
            });
        }
    };

    const back = () => {
        if (state.step > 0) {
            setUserEntryEnrolling(state.step - 1);
            dispatch({
                type: 'SET_BACK_STEP',
            });
        }
    };

    const associateUserNext = () => {
        const { userId } = form.values;
        dispatch({
            type: 'SET_ASSOCIATE_USER',
            payload: userId,
        });
        next();
    };

    const userEntryNext = () => {
        setUserEntryData({
            ...form.values,
            pinForm: (device || {}).pin,
        });
        next();
    };

    if (
        state.workflow[state.step].state !== state.flowState &&
        state.workflow[state.step].state !== undefined
    ) {
        next();
        return <div />;
    }
    return (
        <>
            {state.fetching ? (
                <LoadingSpinner />
            ) : (
                components[state.workflow[state.step].type](
                    {
                        // props
                        errorMsg,
                        title: t('cardTitles$associateSupport'),
                        setReader: (reader) =>
                            dispatch({
                                type: 'SET_READER',
                                payload: reader,
                            }),
                        setCurrentAnswer: (answer, session) =>
                            dispatch({
                                type: 'SET_CURRENT_ANSWER',
                                payload: {
                                    answer,
                                    session,
                                },
                            }),
                        setCurrentPin: (currentPin) => {
                            // Hard coded the policy id as piv.pin, because currenly enrolling POSSESSED device only requires piv.pin
                            enrollingDispatch({
                                type: 'ADD_PIN',
                                id: 'piv.pin',
                                value: currentPin,
                            });

                            return dispatch({
                                type: 'SET_FORM_VALUES',
                                payload: {
                                    ...state.formValues,
                                    currentPin,
                                },
                            });
                        },
                        reader: state.reader,
                        delegationOperation: state.delegationOperation,
                        associateUser: state.associateUser,
                        forgotPin: () =>
                            dispatch({
                                type: 'SET_FLOW_STATE',
                                payload: 'locked',
                            }),
                    },
                    {
                        // state
                        workflow: state.workflow,
                        fetching: state.fetching,
                        step: state.step,
                        flowState: state.flowState,
                        reader: state.reader,
                        formValues: state.formValues,
                        delegationOperation: state.delegationOperation,
                        associateUser: state.associateUser,
                        answer: state.answer,
                        session: state.session,
                        challenges: state.challenges,
                        detected: state.detected,
                        next,
                        showNext: false,
                        showBack: state.step === 0,
                        back: state.step === 0 ? homeBack : back,
                        setWorkflow: setDelegationWorkflow,
                        setDetected: (detected) =>
                            dispatch({
                                type: 'SET_DETECTED',
                                payload: detected,
                            }),
                        resetMgmtState: (step) =>
                            dispatch({
                                type: 'SET_STATE_RESET',
                                payload: step,
                            }),
                        aun: associateUserNext,
                        uen: userEntryNext,
                        adb: () =>
                            dispatch({
                                type: 'ASSOCIATE_DEVICE_BACK',
                            }),
                        homeBack,
                    },
                )
            )}
        </>
    );
};

export default Delegation;
