import React from 'react';
import { PropTypes } from 'prop-types';
import Loader from '../shared/Loader';

const PerformAction = ({ state, title, isDone }) => {
    const { loading } = state;
    return loading && <Loader title={title} isDone={isDone} />;
};

PerformAction.propTypes = {
    state: PropTypes.object,
    title: PropTypes.string,
};

export default PerformAction;
