import React from 'react';
import { useTranslation } from 'react-i18next';
import { deviceTypes, deviceSubTypes } from '../../../utils/mappings';
import unknownCredential from '../../../images/lock.svg';
import { StyledImage } from '../../../styles/common';

const DeviceType = ({ device }) => {
    const { t } = useTranslation();
    const { type, serial } = device || {};

    if (!type || !serial) {
        return null;
    }

    const deviceMapping = {
        ...deviceTypes[type],
        ...deviceSubTypes[device.subType],
    };
    const deviceString = `d_${type.replace(/[^a-zA-Z0-9_-]/g, '')}-${serial}`;
    const imageTarget = `${deviceString}-img`;
    const { imgLink, imgAlt } = deviceMapping || {};
    const srcImg = imgLink || unknownCredential;
    const altImg = t(imgAlt || 'unknownDevice');

    return <StyledImage src={srcImg} id={imageTarget} alt={altImg} />;
};

export default DeviceType;
