import React, { useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Label, FormGroup } from 'reactstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { autoSuggestFormId, autoSuggestId } from '../../../constants';
import { BrandingStateContext } from '../../../context/BrandingContext';
import {
    EnrollingStateContext,
    EnrollingDispatchContext,
} from '../../../context/EnrollingContext';

const customStyles = (colors) => ({
    option: (provided) => ({
        ...provided,
        paddingLeft: 16,
        paddingTop: 8,
        paddingBottom: 8,
    }),
    control: (provided) => ({
        ...provided,
        // none of react-select's styles are passed to <Control />
        width: 'auto',
        display: 'flex',
        border: '1px solid #d8d8d8',
        boxShadow: colors.accent,
        '&:hover': {
            borderColor: colors.accent,
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: 13,
    }),
    menu: (provided) => ({
        ...provided,
        display: 'contents',
    }),
    menuList: (provided) => ({
        ...provided,
        width: '100%',
        maxHeight: 250,
        boxShadow:
            '0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1) !important',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        position: 'relative',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        return { ...provided, opacity, transition };
    },
});
const AutoSuggestWithData = ({
    serverComponent,
    validator,
    suggestions,
    placeholder,
    setNextShow,
}) => {
    const { t } = useTranslation();
    const {
        theme: { colors },
    } = useContext(BrandingStateContext);
    const dispatch = useContext(EnrollingDispatchContext);
    const {
        form: { values },
    } = useContext(EnrollingStateContext);
    const options = suggestions.map((suggestion) => ({
        value: suggestion,
        label: suggestion,
    }));
    const form = 'userId';

    const validSuggestion = useCallback((value) => {
        const valid = suggestions.includes(value);
        setNextShow(valid);
        return valid;
    }, [setNextShow, suggestions]);

    const onChange = useCallback(({ value }) => {
        if (validSuggestion(value)) {
            dispatch({
                type: 'SET_VALUE',
                name: form,
                value,
                validator,
            });
        }
    }, [dispatch, validSuggestion, validator]);

    useEffect(() => {
        if (suggestions.length === 1 && !serverComponent.details.value) {
            onChange({ value: suggestions[0] });
        }
    }, [suggestions, serverComponent, onChange]);

    return (
        <div data-test-id={autoSuggestFormId}>
            <FormGroup row autoComplete="off">
                <Col xs="3">
                    <Label for={form}>
                        {t('formLabels$autoSuggest$formLabel')}
                    </Label>
                </Col>
                <Col xs="4">
                    {serverComponent.details.value ? (
                        <div>{serverComponent.details.value}</div>
                    ) : (
                        <Select
                            id={autoSuggestId}
                            autoFocus
                            isSearchable={suggestions.length > 0}
                            name={form}
                            placeholder={placeholder}
                            options={options}
                            onChange={onChange}
                            styles={customStyles(colors)}
                            value={
                                suggestions.length === 1
                                    ? {
                                        label: values[form],
                                        value: values[form],
                                    }
                                    : undefined
                            }
                            noOptionsMessage={() =>
                                t('formLabels$autoSuggest$emptyOptions')
                            }
                            openMenuOnClick={suggestions.length > 0}
                        />
                    )}
                </Col>
            </FormGroup>
        </div>
    );
};

AutoSuggestWithData.propTypes = {
    serverComponent: PropTypes.object,
    validator: PropTypes.func.isRequired,
    suggestions: PropTypes.array,
    placeholder: PropTypes.string,
    setNextShow: PropTypes.func.isRequired,
};

AutoSuggestWithData.defaultProps = {
    serverComponent: {
        details: {
            value: false,
        },
    },
    suggestions: [],
    placeholder: '',
};

export default AutoSuggestWithData;
