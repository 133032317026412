import React, { useEffect, useState, useContext, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { emptyObject } from '../../../utils/generic';
import { fetchDelegationFlow, fetchNormalDelegationFlow } from '../../../utils/walkways';
import {
    associateDeviceCardId,
} from '../../../constants';
import AssociateDeviceWithData from './AssociateDeviceWithData';
import { AppDispatchContext } from '../../../context/AppContext';
import CardLayout from '../../shared/CardLayout';
import { EnrollingDispatchContext } from '../../../context/EnrollingContext';
import { useFetchData, useDidMount } from '../../../utils/hooks';
import { parseFieldSet } from '../IssuanceType';

const AssociateDevice = ({
    associateUser,
    mgmt,
    reader,
    setReader,
    nextShow,
    setNextShow,
}) => {
    const { t } = useTranslation();
    const dispatch = useContext(AppDispatchContext);
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const [showNext, setShowNext] = useState(false);
    const [fieldset, setFieldset] = useState(null);
    const nextClickedRef = useRef(false);

    const setDevices = (json) => {
        const newFieldSet = parseFieldSet(json, fieldset);
        Object.keys(json).forEach((key) => {
            newFieldSet[key].display = json[key] != null;
        });
        setFieldset(newFieldSet);
    };

    useFetchData({
        endpoint: `/delegation/associate/${associateUser}`,
        onSuccess: (data) => setDevices(data),
    });

    useDidMount(() => {
        const resetMgmtState = () => {
            if (mgmt !== undefined && mgmt.resetMgmtState !== undefined) {
                mgmt.resetMgmtState(1);
            }
        };
        resetMgmtState();
    });

    useEffect(() => {
        const isRadioButtonSelected = () => {
            let res = false;
            Object.keys(fieldset).forEach((key) => {
                if (!res) {
                    const obj = fieldset[key];
                    const dropDownSelected =
                        obj.buttonType === 'dropdown' &&
                        obj.selected &&
                        !emptyObject(reader);
                    const normalSelected =
                        obj.buttonType === 'normal' && obj.selected;
                    if (normalSelected || dropDownSelected) {
                        res = true;
                    }
                }
            });
            return res;
        };
        const enableNext = () => {
            if (nextClickedRef.current === true || fieldset === null) {
                return false;
            }

            return isRadioButtonSelected();
        };
        setShowNext(enableNext());
    }, [reader, fieldset]);

    const handleRadioClick = (proxy) => {
        const newFieldset = { ...fieldset };
        Object.keys(newFieldset).forEach((key) => {
            newFieldset[key].selected = false;
            if (key === proxy.target.value) {
                newFieldset[key].selected = true;
            }
        });
        setFieldset(newFieldset);
    };

    useEffect(() => {
        enrollingDispatch({
            type: 'SET_USERNAME',
            username: associateUser,
        });
    }, [associateUser, enrollingDispatch]);

    const next = async () => {
        nextClickedRef.current = true;
        setShowNext(false);
        Object.keys(fieldset).forEach(async (key) => {
            const obj = fieldset[key];
            if (obj.selected) {
                // Todo: refactor when more rideo button have "dropdown" buttonType besides insertedDevice
                if (obj.buttonType === 'dropdown') {
                    enrollingDispatch({
                        type: 'SET_SELECTED_READER',
                        reader,
                    });
                    if (mgmt) {
                        await fetchDelegationFlow(
                            mgmt.setWorkflow,
                            dispatch,
                            {
                                subType: reader.subType,
                                type: reader.type,
                                serial: reader.serial,
                                user: associateUser,
                            },
                            {
                                group: reader.groups[0],
                                subType: reader.subType,
                                prestate: reader.prestate,
                            },
                        );
                        mgmt.next();
                    }
                } else {
                    // currently my circle only allow insertedDevice.
                    enrollingDispatch({
                        type: 'SET_SELECTED_READER',
                        reader: {groups: obj.group && [obj.group]},
                    });
                    if (mgmt && associateUser) {
                        await fetchNormalDelegationFlow(
                            mgmt.setWorkflow,
                            dispatch,
                            {
                                user: associateUser,
                                type: key,
                            },
                            []
                        );
                        mgmt.next();
                    }
                }
            }
        })
    };

    return (
        <CardLayout
            title={t('cardTitles$associateSupport')}
            subtitle={t('bannerLabels$delegations$info')}
            testId={associateDeviceCardId}
            showNext={showNext}
            showBack
            mgmt={{
                ...mgmt,
                next,
            }}
        >
            {fieldset !== null && (
                <AssociateDeviceWithData
                    fieldset={fieldset}
                    handleRadioClick={handleRadioClick}
                    setReader={setReader}
                    nextShow={nextShow}
                    setNextShow={setNextShow}
                    associateUser={associateUser}
                />
            )}
        </CardLayout>
    );
};

AssociateDevice.propTypes = {
    reader: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    associateUser: PropTypes.string.isRequired,
    mgmt: PropTypes.object.isRequired,
    setReader: PropTypes.func.isRequired,
    nextShow: PropTypes.bool,
    setNextShow: PropTypes.func,
};

export default AssociateDevice;
