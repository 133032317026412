import React, { useReducer, createContext } from 'react';
import { PropTypes } from 'prop-types';
import { USER_MODE, KIOSK_MODE } from '../constants';

const initialState = {
    devices: null,
    device: null,
    credential: null,
    loadingCredentialIds: [],
    user: {},
    currDevice: {},
    settings: {},
    webpcscInfo: {},
    authenticated: null,
    modal: {
        title: '',
        info: '',
        show: false,
        type: '',
    },
    hasError: false,
    errorType: null,
    errorMsg: '',
    errorStatus: '',
    viewMode: sessionStorage.getItem('mode') || USER_MODE,
    kioskAction: sessionStorage.getItem('action'),
    kioskDeviceId: sessionStorage.getItem('deviceId'),
    admin: {
        metadata: {
            devices: {
                search: null,
            },
            users: {},
        },
    },
    activeUsersColumns: {},
    activeDevicesColumns: {},
    activeEmergencyColumns: {},
    activeReportsColumns: {},
    goBackLink: '',
    webpcscMode: sessionStorage.getItem('webpcscMode') || 'smart',
    wideMode: sessionStorage.getItem('wideMode') === 'true' || false,
};

const reducer = (state, action) => {
    const { type, payload } = action || {};
    switch (type) {
        case 'SET_GO_BACK_LINK':
            return { ...state, goBackLink: action.payload };
        case 'SET_ACTIVE_DEVICES_COLUMNS':
            return { ...state, activeDevicesColumns: payload };
        case 'SET_ACTIVE_EMERGENCY_COLUMNS':
            return { ...state, activeEmergencyColumns: payload };
        case 'SET_ACTIVE_USERS_COLUMNS':
            return { ...state, activeUsersColumns: payload };
        case 'SET_ACTIVE_REPORTS_COLUMNS':
            return { ...state, activeReportsColumns: payload };
        case 'SET_METADATA':
            return { ...state, admin: { metadata: action.metadata } };
        case 'SET_AUTHENTICATED':
            return { ...state, authenticated: action.authenticated };
        case 'SET_ERROR_STATUS':
            return { ...state, errorStatus: action.status };
        case 'SET_DEVICES':
            return { ...state, devices: action.devices };
        case 'SET_DEVICE':
            return {
                ...state,
                device: action.device,
                goBackLink: action.goBackLink || '',
            };
        case 'SET_CREDENTIAL':
            return {
                ...state,
                credential: action.credential,
                goBackLink: action.goBackLink || '',
            };
        case 'ADD_LOADING_CREDENTIAL_ID':
            return {
                ...state,
                loadingCredentialIds: [ ...state.loadingCredentialIds, action.id],
                goBackLink: action.goBackLink || '',
            };
        case 'REMOVE_LOADING_CREDENTIAL_ID':
            return {
                ...state,
                loadingCredentialIds: state.loadingCredentialIds.filter(id => id !== action.id),
                goBackLink: action.goBackLink || '',
            };
        case 'RESET_FLOWS':
            return {
                ...state,
                device: null,
                goBackLink: '',
            };
        case 'SET_SECURITY':
            return { ...state, security: action.security };
        case 'SET_SETTINGS':
            return {
                ...state,
                settings: action.settings,
            };
        case 'SET_WEBPCSC_INFO':
            return { ...state, webpcscInfo: action.webpcscInfo };
        case 'SET_USER':
            return {
                ...state,
                user: action.user,
                authenticated: true,
                permissions: action.permissions,
            };
        case 'CLEAR_MODAL':
            return { ...state, modal: { show: false } };
        case 'SHOW_MODAL':
            return {
                ...state,
                device: action.device,
                report: action.report,
                modal: { ...action.modal, show: true },
            };
        case 'SET_CUSTOM_MODAL_PROPERTIES':
            return {
                ...state,
                modal: { ...state.modal, ...action.modal },
            };
        case 'ADD_ERROR':
            return {
                ...state,
                hasError: true,
                errorType: action.errorType,
                errorMsg: action.message,
            };
        case 'CLEAR_ERROR':
            return {
                ...state,
                hasError: false,
                errorType: null,
                errorMsg: '',
                errorStaus: '',
            };
        case 'SET_USER_MODE':
            sessionStorage.setItem('mode', USER_MODE);
            sessionStorage.removeItem('action');
            sessionStorage.removeItem('deviceId');
            return {
                ...state,
                viewMode: USER_MODE,
                kioskAction: undefined,
                kioskDeviceId: undefined,
            };
        case 'SET_KIOSK_MODE':
            sessionStorage.setItem('mode', KIOSK_MODE);
            if (action.payload.action)
                sessionStorage.setItem('action', action.payload.action);
            if (action.payload.deviceId)
                sessionStorage.setItem('deviceId', action.payload.deviceId);
            return {
                ...state,
                viewMode: KIOSK_MODE,
                kioskAction: action.payload.action,
                kioskDeviceId: action.payload.deviceId,
            };
        case 'CLEAR_KIOSK_ACTION':
            sessionStorage.removeItem('action');
            sessionStorage.removeItem('deviceId');
            return {
                ...state,
                kioskAction: undefined,
                kioskDeviceId: undefined,
            };
        case 'SET_TABNAV_INDEX':
            sessionStorage.setItem(
                'activeIndex',
                JSON.stringify(action.activeIndex),
            );
            return {
                ...state,
                tabNav: {
                    tabs: [...state.tabNav.tabs],
                    activeIndex: action.activeIndex,
                    expand: true,
                },
            };
        case 'MODIFY_METADATA':
            return {
                ...state,
                admin: {
                    ...state.admin,
                    metadata: {
                        ...state.admin.metadata,
                        ...action.payload,
                    },
                },
            };
        case 'SET_WEBPCSC_MODE':
            sessionStorage.setItem('webpcscMode', action.webpcscMode);
            return {
                ...state,
                webpcscMode: action.webpcscMode,
            };
        case 'TOGGLE_WIDE_MODE':
            sessionStorage.setItem('wideMode', !state.wideMode);
            return {
                ...state,
                wideMode: !state.wideMode,
            };
        default:
            return state;
    }
};

export const AppStateContext = createContext();
export const AppDispatchContext = createContext();

export const AppProvider = ({ children, appState = initialState }) => {
    const [state, dispatch] = useReducer(reducer, appState);

    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>
                {children}
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    );
};

AppProvider.propTypes = {
    children: PropTypes.node.isRequired,
    appState: PropTypes.object,
};

AppProvider.defaultProps = {
    appState: initialState,
};
