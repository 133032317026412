import { apiPath } from '../constants';
import { errorFactory } from './errors';
import { updateIdleTimeoutDate } from './generic';

const AUTHENTICATION_ERROR_CODES = [400, 403, 404];
const DELETE_TIMEOUT = 12000; // 12s

export const deleteData = async ({ endpoint }) => {
    const postEndpoint = `${apiPath}${endpoint}`;
    const res = await Promise.race([
        fetch(postEndpoint, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
            },
        }),
        new Promise((_, reject) => {
            setTimeout(
                () =>
                    // eslint-disable-next-line implicit-arrow-linebreak
                    reject(errorFactory.timeout()),
                DELETE_TIMEOUT,
            );
        }),
    ]);
    if (AUTHENTICATION_ERROR_CODES.includes(res.status)) {
        console.error(
            `There has been an error with the request: ${res.status}`,
        );
    }
    updateIdleTimeoutDate();
    return res;
};
