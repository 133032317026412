import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import isEmpty from 'lodash.isempty';
import {
    helpdeskEmergencyTableColumnsSettings,
    userEndpoint,
    emergencyInfoCardId,
    credentialEndpoint,
} from '../../../../constants';
import { formatDate } from '../../../../utils/generic';
import { postEnrollTPA } from '../../../../utils/credentialing';
import { useFetchData } from '../../../../utils/hooks';
import { deleteData } from '../../../../utils/deleteData';
import { helpdeskUserResetPrivileges, isAllowed } from '../../../../utils/authorization';
import EmergencyInfoTable from '../../../elementLibrary/EmergencyInfoTable';
import {
    AppDispatchContext,
    AppStateContext,
} from '../../../../context/AppContext';
import CardTemplate from '../../../shared/CardTemplate';

import LoadingSpinner from '../../../shared/LoadingSpinner';
import TPADetailsModal from './TPADetailsModal';
import RevokeSecurityQuestionModal from './RevokeSecurityQuestionModal';
import { DevicesTableWrapper, Title } from './styles';
import { patchData } from '../../../../utils/patchData';
import { errorFactory, declareError } from '../../../../utils/errors';

const EmergencyInfoRows = ({ userData }) => {
    const { username } = useParams();
    const { user, activeEmergencyColumns } = useContext(AppStateContext);
    // eslint-disable-next-line no-unused-vars
    const [securityLoading, securityData, error, fetchData] = useFetchData({
        endpoint: `/user/${username}/security`,
    });
    const { t } = useTranslation();
    const dispatch = useContext(AppDispatchContext);

    const [isRevoking, setIsRevoking] = useState(false);
    const [revokedDevice, setRevokedDevice] = useState('');
    const [revokeFailed, setRevokeFailed] = useState(false);
    const [isTPAModalOpen, setIsTPAModalOpen] = useState(false);
    const [TPAData, setTPAData] = useState();
    const [isSecurityModalOpen, setIsSecurityModalOpen] = useState(false);
    if (securityData === null) return null;
    const hasResetSecurityPrivilege = isAllowed({
        allowedPermissions: helpdeskUserResetPrivileges,
        permissions: user.privileges,
    });

    const enrollTPA = (e) => {
        e.preventDefault();

        postEnrollTPA(
            (json) => {
                setTPAData(json.details);
                setIsTPAModalOpen(true);
                fetchData();
            },
            dispatch,
            {},
            username,
        );
    };

    if (securityLoading) {
        return <LoadingSpinner />;
    }

    const TPAStatus = (item) => {
        if (!item.enrolled) return 'DISABLED';
        const start = new Date(item.validityStart).getTime();
        const end = new Date(item.validityEnd).getTime();
        const current = new Date().getTime();
        if (current < start && (end === 0 || current < end)) {
            return 'DISABLED';
        }
        if ((start === 0 || current > start) && (end === 0 || current < end)) {
            return 'ACTIVE';
        }
        return 'EXPIRED';
    }
    const getTPAAction = (item) => {
        if (userData !== null && userData.status !== 'Disabled') {
            return TPAStatus(item) === 'ACTIVE' ? ['REVOKED'] : ['generate'];
        }
        return [];
    };

    const revokeTPA = async () => {
        const res = await deleteData({
            endpoint: `${userEndpoint}/${userData.username}/security/tmpPwd`,
        });
        setIsRevoking(true);
        setRevokedDevice(undefined)
        const json = await res.json();

        if (json.result === 'failure') {
            setRevokeFailed(true);
            setTimeout(() => setRevokeFailed(false), 3000);
        } else {
            fetchData();
        }
        setIsRevoking(false);
    };

    const onColumnsChange = (value) => {
        if (activeEmergencyColumns !== value) {
            dispatch({
                type: 'SET_ACTIVE_EMERGENCY_COLUMNS',
                payload: value,
            });
        }
    };

    const constructData = () => {
        const data = [];
        securityData.forEach(function (item) {
            if (item.type === 'tmpPwd') {
                data.push({
                    actions: getTPAAction(item),
                    onClick: TPAStatus(item) === 'ACTIVE' ? revokeTPA : enrollTPA,
                    status: TPAStatus(item),
                    type: 'tmpPwd',
                    validityStart: item.enrolled
                        ? formatDate(item.validityStart)
                        : '',
                    validityEnd: item.enrolled ? formatDate(item.validityEnd) : '',
                });
            } else if (hasResetSecurityPrivilege && item.type === 'securityQuestion') {
                data.push({
                    actions: item.enrolled ? ['REVOKED'] : [],
                    onClick: () => setIsSecurityModalOpen(true),
                    status: item.enrolled ? 'ACTIVE' : 'NOTSET',
                    type: 'securityQuestion',
                    validityStart: '',
                    validityEnd: '',
                });
            } else {
                data.push({
                    actions: item.actions,
                    onClick: credentialAction,
                    status: item.enrolled ? 'ACTIVE' : 'REVOKED',
                    type: item.type,
                    serial: item.serial,
                    provider: item.provider,
                    validityStart: '',
                    validityEnd: '',
                })
            }
        })
        return data;
    };

    const credentialAction = async (item) => {
        setIsRevoking(true);
        setRevokedDevice(item.serial)
        const res = await patchData({
            endpoint: `${credentialEndpoint}/${item.type}/${item.serial}`,
            body: {
                user: username,
                status: item.actions[0],
                provider: item.provider
            }
        });
        if (res.result === 'failure') {
            declareError(dispatch, errorFactory.err(res.message));
        } else {
            for (const [index, data] of securityData.entries()) {
                if (data.serial === item.serial) {
                    securityData[index] = res;
                }
            }
            setIsRevoking(false);
        }
    }

    const getColumnNames = () => {
        return ['type', 'label', 'validityStart', 'validityEnd', 'status'];
    };

    const getTableHeader = (settinsIconCol) => (
        <>
            <Row>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                    <Title>{`${t('cardTitles$emergency')}`}</Title>
                </Col>
                {settinsIconCol}
            </Row>
        </>
    );

    const data = constructData();
    return (
        (!isEmpty(data) && (
            <CardTemplate testId={emergencyInfoCardId}>
                <TPADetailsModal
                    isOpen={isTPAModalOpen}
                    toggleModal={(open) => setIsTPAModalOpen(open)}
                    tpaData={TPAData}
                />
                <RevokeSecurityQuestionModal
                    isOpen={isSecurityModalOpen}
                    setIsOpen={setIsSecurityModalOpen}
                    revokeCallback={() => {
                        fetchData();
                    }}
                />
                <DevicesTableWrapper>
                    <EmergencyInfoTable
                        revokeFailed={revokeFailed}
                        isRevoking={isRevoking}
                        revokedDevice={revokedDevice}
                        hideCheckboxes
                        hidePagination
                        data={data}
                        columnsName={getColumnNames()}
                        activeColumns={activeEmergencyColumns}
                        selectedColumnsKey={helpdeskEmergencyTableColumnsSettings}
                        getTableHeader={getTableHeader}
                        onColumnsChange={onColumnsChange}
                    />
                </DevicesTableWrapper>
            </CardTemplate>
        ))
    );
};

export default EmergencyInfoRows;
