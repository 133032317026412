import React from 'react';
import AdvancedSearchBarWithData from './AdvancedSearchBarWithData';
import LoadingSpinner from '../../shared/LoadingSpinner';

const AdvancedSearchBar = ({
    filters,
    modifySearchAllowed,
    alwaysModifiableIds,
    valueValidator,
}) => {
    if (!filters) {
        return <LoadingSpinner />;
    }
    return (
        <AdvancedSearchBarWithData
            filters={filters}
            modifySearchAllowed={modifySearchAllowed}
            alwaysModifiableIds={alwaysModifiableIds}
            valueValidator={valueValidator}
        />
    );
};

export default AdvancedSearchBar;
