import React from 'react';
import { PropTypes } from 'prop-types';

import { Button } from 'reactstrap';
import styled from 'styled-components';

import { StyledNavIcon } from '../../../styles/common';

const StyledButton = styled(Button)`
    opacity: ${(props) => (props['aria-disabled'] ? 0.5 : 1)};
`;

const ActionButton = ({
    title,
    size = 'sm',
    icon,
    disabled = false,
    id,
    onClick,
}) => (
    <StyledButton
        disabled={disabled}
        outline
        color="primary"
        onClick={onClick}
        size={size}
    >
        <span data-test-id={id}>
            <StyledNavIcon pad="start" icon={icon} />
            {title}
        </span>
    </StyledButton>
);

ActionButton.propTypes = {
    title: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default ActionButton;
