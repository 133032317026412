import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import CreatePinWithData from './CreatePinWithData';
import { EnrollingDispatchContext } from '../../../context/EnrollingContext';
import { keyboardLayouts } from '../../../constants';

const getPinPolicyInit = (minlen = 6, maxlen = 8, constraint = 'digit') => {
    const constrainPattern = {
        digit: '^[0-9]+$',
        alpha: '^[a-zA-Z]+$',
        alphanumeric: '^[0-9a-zA-Z]+$',
        any: '^.+$',
        utf8: '^.+$',
        scancode: '^.+$',
    };
    const policy = {
        constraint: {
            regex: new RegExp(constrainPattern[constraint]),
            valid: false,
        },
        len: {
            regex: new RegExp(`^.{${minlen},${maxlen}}$`),
            valid: false,
        },
        repeat: {
            regex: /^.*(.)\1{3,}.*$/,
            valid: false,
        },
        sequence: {
            regex: /^.*(0123|1234|2345|3456|4567|5678|6789|9876|8765|7654|6543|5432|4321|3210).*$/,
            valid: false,
        },
    };
    return policy;
};

const CreatePin = ({
    serverComponent,
    registerForm,
    enableSkip = () => {},
    disableSkip = () => {},
    skipClicked = false,
    mgmt = {},
    diableShowNext = () => {},
}) => {
    const [policyLen, setPolicyLen] = useState(false);
    const [policyConstraint, setPolicyConstraint] = useState(false);
    const [policyWeakPin, setPolicyWeakPin] = useState(false);
    const [match, setMatch] = useState(false);
    const [clearText, setClearText] = useState(false);
    const [confirmPin, setConfirmPin] = useState('');
    const [keyboardLayout, setKeyboardLayout] = useState(keyboardLayouts[0]);
    const pinRef = useRef();
    const confirmPinRef = useRef();
    const pinPolicyIdRef = useRef();
    const pinPolicy = serverComponent.details;
    const enrollingDispatch = useContext(EnrollingDispatchContext);

    const pinValidator = useCallback((value) => {
        const policyState = getPinPolicyInit(
            pinPolicy.minLength,
            pinPolicy.maxLength,
            pinPolicy.constraint,
        );
        policyState.constraint.valid = policyState.constraint.regex.test(value);
        policyState.len.valid = policyState.len.regex.test(value);
        policyState.repeat.valid = !policyState.repeat.regex.test(value);
        policyState.sequence.valid = !policyState.sequence.regex.test(value);

        setPolicyConstraint(policyState.constraint.valid);
        setPolicyLen(policyState.len.valid);
        setPolicyWeakPin(
            policyState.repeat.valid && policyState.sequence.valid,
        );

        return policyState.constraint.valid &&
            policyState.len.valid &&
            (!pinPolicy.weakPinCheck ||
                (policyState.repeat.valid && policyState.sequence.valid))
            ? true
            : '';
    },[pinPolicy]);

    const confirmPinBridge = (matchValue) =>
        setMatch(confirmPin === matchValue);

    const confirmPinValidator = (value) => {
        if (value === pinRef.current.value) {
            setConfirmPin(value);
            setMatch(true);
            return true;
        }
        setConfirmPin(value);
        setMatch(false);
        return '';
    };

    const clearTextSwitch = () => {
        setClearText(!clearText);
    };

    useEffect(() => {
        return () => {
            disableSkip();
        };
    },[]);

    useEffect(() => {
        if (skipClicked) {
            enrollingDispatch({
                type: 'DELETE_PIN',
                id: pinPolicy.id,
            });
            disableSkip();
            mgmt && mgmt.next();
        }
    }, [skipClicked, disableSkip, enrollingDispatch, mgmt, pinPolicy.id]);

    useEffect(() => {
        if (pinPolicy.id !== pinPolicyIdRef.current) {
            pinPolicyIdRef.current = pinPolicy.id;
            if (registerForm) {
                registerForm({
                    pin: pinValidator,
                    confirmPin: confirmPinValidator,
                });
            }
            enrollingDispatch({
                type: 'RESET_FORM',
            });
            setPolicyLen(false);
            setPolicyConstraint(false);
            setPolicyWeakPin(false);
            setMatch(false);
            diableShowNext();
            pinRef.current.value = '';
            confirmPinRef.current.value = '';
            if (pinPolicy.isOptional) enableSkip();
        }
    }, [pinPolicy.id, diableShowNext, enableSkip, enrollingDispatch, pinPolicy.isOptional, pinValidator, registerForm]);

    return (
        <CreatePinWithData
            pinValidator={pinValidator}
            confirmPinValidator={confirmPinValidator}
            confirmPinBridge={confirmPinBridge}
            checked={{
                constraint: policyConstraint,
                len: policyLen,
                weakPin: pinPolicy.weakPinCheck ? policyWeakPin : undefined,
                match,
            }}
            clearText={clearText}
            clearTextSwitch={clearTextSwitch}
            pinRef={pinRef}
            confirmPinRef={confirmPinRef}
            pinPolicy={pinPolicy}
            keyboardLayout={keyboardLayout}
            setKeyboardLayout={setKeyboardLayout}
        />
    );
};

CreatePin.propTypes = {
    serverComponent: PropTypes.object.isRequired,
    registerForm: PropTypes.func.isRequired,
};

export default CreatePin;
