import React, { useState, useContext } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { Dropdown } from 'reactstrap';
import uniqueId from 'lodash.uniqueid';
import { useTranslation } from 'react-i18next';
import { stringToHash } from '../../../utils/stringUtil';
import {
    NoHoverLink,
    StyledFlexbox,
    AccentIcon,
    StyledActionTooltip,
    StyledActionDropdownToggle,
} from '../../../styles/common';
import { helpDeskActionTypes } from '../../../utils/mappings';
import { AppDispatchContext } from '../../../context/AppContext';

const DropdownUserActions = ({ data = {}, options = [], disabled = false }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const appDispatch = useContext(AppDispatchContext);
    const { location } = useHistory();
    const { pathname } = location || {};
    const { username, rowId } = data || {};

    const toggle = (event) => {
        if (event.target.closest(`#tooltip_${rowId}`)) return;
        setDropdownOpen(!dropdownOpen);
    };


    if (!username) {
        return null;
    }
    return (
        <div id={`dropdown_${stringToHash(username)}`}>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <StyledActionDropdownToggle
                    disabled={disabled}
                    color="link"
                    size="sm"
                >
                    <AccentIcon icon="cog" />
                </StyledActionDropdownToggle>
            </Dropdown>
            <StyledActionTooltip
                id={`tooltip_${rowId}`}
                placement="auto"
                isOpen={dropdownOpen}
                target={`dropdown_${stringToHash(username)}`}
                fade={false}
            >
                {options.length > 0 &&
                    options.map((option) => (
                        <div
                            key={uniqueId('dropdownItem_')}
                            className="dropdown-item"
                            id={`${stringToHash(username)}_${option.text}`}
                        >
                            <NoHoverLink
                                onClick={() =>
                                    appDispatch({
                                        type: 'SET_GO_BACK_LINK',
                                        payload: pathname,
                                    })
                                }
                                to={{
                                    pathname: generatePath(option.href, {
                                        username,
                                    }),
                                }}
                            >
                                <StyledFlexbox
                                    key={uniqueId('userAction_')}
                                    direction="row"
                                    alignment="center"
                                >
                                    {option.component}
                                </StyledFlexbox>
                            </NoHoverLink>
                        </div>
                    ))}
            </StyledActionTooltip>
        </div>
    );
};

const UserAction = ({ data }) => {
    const { t } = useTranslation();
    const history = useHistory();
    // Mock of actions
    const mockActions = ['usersDetails'];
    if (data.status !== 'DISABLED') {
        mockActions.push('addDevice');
    }
    const actionMapping = mockActions.map((actionType) => {
        const action = helpDeskActionTypes[actionType];
        return {
            component: (
                <StyledFlexbox
                    key={uniqueId('userAction_')}
                    direction="row"
                    alignment="center"
                >
                    {t(action.text)}
                </StyledFlexbox>
            ),
            text: actionType,
            effect: action.effect,
            href: action.href,
        };
    });

    return (
        <DropdownUserActions
            data={data}
            history={history}
            options={actionMapping}
        />
    );
};

export default UserAction;
