import React, { useEffect, useState, useContext, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    fetchEnrollU2fRequest,
    postEnrollU2fRequest,
} from '../../../utils/credentialing';
import { u2fRegister } from '../../../utils/u2f';
import {
    serverConstants as SERVER,
    PROGRESS_BAR_DONE_DELAY,
} from '../../../constants';
import CardTemplate from '../../shared/CardTemplate';
import ProgressTemplate from '../../shared/ProgressTemplate';
import EnrollTemplate from '../../shared/EnrollTemplate';
import EnrollU2fWithData from './EnrollU2fWithData';
import FlowButtons from '../../elementLibrary/FlowButtons';
import { AppDispatchContext } from '../../../context/AppContext';
import { EnrollingStateContext } from '../../../context/EnrollingContext';

const TAG = 'EnrollU2fCard';

// necessary comment to access global.chrome if present
// global chrome

const EnrollU2fCard = ({ mgmt = null }) => {
    const { t } = useTranslation();
    const dispatch = useContext(AppDispatchContext);
    const { reader, device, username } = useContext(EnrollingStateContext);
    const [, setRequest] = useState({});
    const [requestSigned, setRequestSigned] = useState(false);
    const [, setResult] = useState({});
    const [, setData] = useState({});
    const [showNext, setShowNext] = useState(false);
    const usernameRef = useRef(null);

    const setEnrollU2fRequest = async (json) => {
        setRequest({ ...json });
    };

    useEffect(() => {
        if (usernameRef.current !== username) {
            usernameRef.current = username;

            const setU2fResult = async (json) => {
                setResult({ ...json });
                setShowNext(true);
                if (mgmt && json.result === SERVER.success) {
                    // server
                    setTimeout(mgmt.next, PROGRESS_BAR_DONE_DELAY);
                }
            };
            const register = async (request) => {
                if (username) {
                    try {
                        const thisResult = await u2fRegister(request);
                        setRequestSigned(true);
                        setData(thisResult);
                        setRequest(request);
                        postEnrollU2fRequest(
                            setU2fResult,
                            dispatch,
                            {
                                serial: reader.serial,
                                u2fData: thisResult.clientData,
                                u2fRegistration: thisResult.registrationData,
                                type: reader.subType,
                            },
                            username,
                        );
                    } catch (e) {
                        console.error(TAG, 'register:', e);
                    }
                }
            };

            if (typeof chrome !== 'undefined' && chrome.runtime) {
                fetchEnrollU2fRequest(
                    setEnrollU2fRequest,
                    dispatch,
                    register,
                    {
                        pin: device.pin,
                        serial: reader.serial,
                        type: reader.subType,
                    },
                    username,
                );
            } else {
                mgmt.next();
            }
        }
    }, [username, device, dispatch, mgmt, reader]);

    return (
        <CardTemplate title={t('cardTitles$issueIdentity')} noFade>
            {/* todo: get title from props */}
            {requestSigned ? (
                <>
                    <EnrollTemplate
                        title={t('issuanceProgress')}
                        alert={t('enrollTemplateAlertDefault')}
                    >
                        <ProgressTemplate
                            isFake
                            isDone={showNext}
                            stage={t('deviceActionsStage$u2f')}
                        />
                    </EnrollTemplate>
                    <FlowButtons
                        mgmt={{
                            next: mgmt ? mgmt.next : '',
                            showNext,
                            back: mgmt ? mgmt.back : '',
                            showBack: false,
                        }}
                    />
                </>
            ) : (
                <EnrollU2fWithData />
            )}
        </CardTemplate>
    );
};

EnrollU2fCard.propTypes = {
    mgmt: PropTypes.object,
};

EnrollU2fCard.defaultProps = {
    mgmt: null,
};

export default EnrollU2fCard;
