import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { fetchDiagnosis } from '../../../utils/diagnosis';
import { localSettingsCardId } from '../../../constants';
import { errorFactory, declareError } from '../../../utils/errors';
import PCSC from '../../../utils/pcsc';
import CardTemplate from '../../shared/CardTemplate';
import LocalSettingsWithData from './LocalSettingsWithData';
import { AppDispatchContext } from '../../../context/AppContext';
import AdvancedSettings from '../AdvancedSettings';
import { useDidMount } from '../../../utils/hooks';

const browserDetection = () => {
    // eslint-disable-next-line no-undef
    const ua = navigator.userAgent;
    let tem;
    let M =
        ua.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
        ) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return `'Internet Explorer' ${tem[1] || ''}`;
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem !== null) {
            return `${tem[1].replace('OPR', 'Opera')} ${tem[2]}`;
        }
    }
    // eslint-disable-next-line no-undef
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    // eslint-disable-next-line no-cond-assign
    if ((tem = ua.match(/version\/(\d+)/i)) !== null) {
        M.splice(1, 1, tem[1]);
    }
    return `${M[0]} ${M[1]}`;
};

const LocalSettings = () => {
    const dispatch = useContext(AppDispatchContext);
    const { t } = useTranslation();
    const [tableValues, setTableValues] = useState([]);
    const [success, setSuccess] = useState(false);

    const checkDiag = async () => {
        setSuccess(false);
        try {
            const result = await fetchDiagnosis(dispatch);
            if (result === true) {
                setSuccess(true);
                return true;
            }
        } catch (error) {
            if (error.msg === 'Bad command!') {
                declareError(
                    dispatch,
                    errorFactory.webpcsc(i18next.t('errorMsgs$ver_app')),
                );
            } else {
                declareError(
                    dispatch,
                    errorFactory.webpcsc(error.code, error.msg, error),
                );
            }
        }
        return false;
    };

    useDidMount(() => {
        const addWebpcscAndBrowserDataToTable = async () => {
            try {
                await checkDiag();
                const ver = await PCSC.version();
                setTableValues([
                    [
                        t('localSettingsCardLabels$rows$browser'),
                        browserDetection(),
                    ],
                    [t('localSettingsCardLabels$rows$webpcsc'), ver.app],
                    [t('localSettingsCardLabels$rows$webpcscBridge'), ver.ext],
                ]);
            } catch (error) {
                console.error(error)
            }
        };

        addWebpcscAndBrowserDataToTable();
    });

    return (
        <>
            <CardTemplate
                title={t('cardTitles$localSettings')}
                testId={localSettingsCardId}
            >
                <LocalSettingsWithData
                    tableValues={tableValues}
                    showAlert={success}
                    checkDiagnosis={checkDiag}
                />
            </CardTemplate>
            <CardTemplate title={t('cardTitles$advancedSettings')}>
                <AdvancedSettings />
            </CardTemplate>
        </>
    );
};

export default LocalSettings;
