import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { postData } from '../../../utils/postData';
import { getSerialsFromBulk } from '../../../utils/generic';
import GoBackRedirect from '../../shared/GoBackRedirect';
import StepWizard from '../../shared/StepWizard';
import {
    UnassignDeviceContext,
    UnassignDeviceDispatchContext,
} from './context';
import SelectReason from '../../cardLibrary/SelectReason';
import PerformAction from '../../cardLibrary/PerformAction';
import {
    AppDispatchContext,
    AppStateContext,
} from '../../../context/AppContext';
import Status from '../../cardLibrary/Status';
import {
    serverConstants,
    paths,
    unassignDeviceEndpoint,
    PROGRESS_BAR_DONE_DELAY,
} from '../../../constants';
import {
    HelpDeskStateContext,
    HelpDeskDispatchContext,
} from '../../../context/HelpDeskContext';

const SELECT_REASON = 'selectReason';
const PERFORM_ACTION = 'performAction';
const STATUS = 'status';
const HOME = 'home';

function reducer(state, action) {
    switch (action.type) {
        case 'DONE':
            return { step: HOME };
        case 'SET_REASON':
            return {
                ...state,
                reason: action.payload,
            };
        case 'ON_SUCCESS':
            return {
                ...state,
                statusMessage: 'unassignSuccessMsg',
                errorMessage: false,
                loading: false,
                step: STATUS,
            };
        case 'ON_FAILURE':
            return {
                ...state,
                loading: false,
                statusMessage: action.payload,
                errorMessage: true,
                step: STATUS,
            };
        case 'GO_BACK':
            if (state.step === SELECT_REASON) {
                return { ...state, step: HOME };
            }
            return state;
        case 'GO_NEXT':
            if (state.step === SELECT_REASON) {
                return { ...state, step: PERFORM_ACTION, loading: true };
            }
            return state;
        case 'PROGRESS_BAR_DONE':
            return { ...state, isDone: true };
        default:
            throw new Error('Disable Device Reducer: invalid action');
    }
}

const Unassign = () => {
    const { device, goBackLink } = useContext(AppStateContext);
    const appDispatch = useContext(AppDispatchContext);
    const helpDeskDispatch = useContext(HelpDeskDispatchContext);
    const { bulkActionDevices } = useContext(HelpDeskStateContext);
    const [state, dispatch] = useReducer(reducer, {
        step: SELECT_REASON,
        nextStep: PERFORM_ACTION,
        prevStep: HOME,
        reason: 'lost',
        statusMessage: null,
        errorMessage: null,
        loading: false,
        isDone: false,
    });
    const history = useHistory();
    const { t } = useTranslation();

    const { serial, type, subType, user } = device || {};
    const { location } = history || {};
    const { pathname } = location || {};
    const isHelpDesk = pathname ? pathname.includes(paths.helpDesk) : false;
    const returnPath =
        goBackLink || (isHelpDesk ? paths.helpDesk : paths.identities);
    const serialsConfirmation = getSerialsFromBulk(bulkActionDevices);

    const performAction =useCallback(async () => {
        let endpoint;
        const queryParams = {};
        const body = {
            reason: state.reason,
        };
        body.user = user || undefined;
        if (bulkActionDevices.length !== 0) {
            body.devices = bulkActionDevices;
            endpoint = unassignDeviceEndpoint;
        } else {
            endpoint = `${unassignDeviceEndpoint}/${type}/${serial}`;
            queryParams.subType = subType;
        }
        const res = await postData({
            body,
            endpoint,
            appDispatch,
        });
        const content = await res.clone().text();
        if (content) {
            const json = await res.json();
            if (json.result === serverConstants.success) {
                dispatch({ type: 'PROGRESS_BAR_DONE' });
                setTimeout(
                    () => dispatch({ type: 'ON_SUCCESS', payload: null }),
                    PROGRESS_BAR_DONE_DELAY,
                );
            } else {
                dispatch({ type: 'PROGRESS_BAR_DONE' });
                setTimeout(
                    () =>
                        dispatch({
                            type: 'ON_FAILURE',
                            payload:
                                t(`errorMsgs$${json.message}`) ||
                                json.message,
                        }),
                    PROGRESS_BAR_DONE_DELAY,
                );
            }
        } else {
            dispatch({ type: 'PROGRESS_BAR_DONE' });
            setTimeout(
                () =>
                    dispatch({
                        type: 'ON_FAILURE',
                        payload: t('errorMsgs$serverDefault'),
                    }),
                PROGRESS_BAR_DONE_DELAY,
            );
        }
    }, [state.reason, appDispatch, bulkActionDevices, serial, subType, t, type, user]);

    useEffect(() => {
        if (state.step === PERFORM_ACTION) {
            performAction();
        }
    }, [state.step, performAction]);

    useEffect(() => {
        return () => {
            helpDeskDispatch({ type: 'RESET_CONTEXT' });
            appDispatch({ type: 'RESET_FLOWS' });
        };
    }, []);

    if ((!serial || !type) && bulkActionDevices.length === 0) {
        history.push(returnPath);
        return null;
    }

    return (
        <UnassignDeviceContext.Provider value={state}>
            <UnassignDeviceDispatchContext.Provider value={dispatch}>
                <StepWizard
                    step={state.step}
                    title={t('cardTitles$unassignDevice')}
                    dispatch={dispatch}
                    state={state}
                >
                    <GoBackRedirect
                        path={returnPath}
                        stepId={HOME}
                        history={history}
                    />
                    <SelectReason
                        stepId={SELECT_REASON}
                        device={device}
                        instructions={t('instructionMessages$unassign')}
                        isHelpDesk={isHelpDesk}
                        subtitle={serialsConfirmation}
                    />
                    <PerformAction
                        stepId={PERFORM_ACTION}
                        isDone={state.isDone}
                    />
                    <Status path={returnPath} stepId={STATUS} />
                </StepWizard>
            </UnassignDeviceDispatchContext.Provider>
        </UnassignDeviceContext.Provider>
    );
};

export default Unassign;
