import React from 'react';
import { PropTypes } from 'prop-types';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CardTemplate from './CardTemplate';
import { PortalContainer, StyledAlert } from '../../styles/common';

const ErrorLabel = styled.span`
    font-weight: bold;
    padding-right: 4px;
`;

const ErrorMessage = ({ message }) => {
    const { t } = useTranslation();
    return (
        <div>
            <ErrorLabel>{t('sharedLabels$terminalError$issue')}</ErrorLabel>
            <span>{message}</span>
        </div>
    );
};

ErrorMessage.propTypes = {
    message: PropTypes.string.isRequired,
};

const TerminalError = ({ error, children }) => {
    const { t } = useTranslation();
    return (
        <CardTemplate title={t('cardTitles$error')}>
            <PortalContainer>
                <Row>
                    <Col sm="12">
                        <StyledAlert color="danger">
                            {!error ? (
                                t('errorDefault')
                            ) : (
                                <ErrorMessage message={error} />
                            )}
                        </StyledAlert>
                    </Col>
                </Row>
                {children}
            </PortalContainer>
        </CardTemplate>
    );
};

TerminalError.propTypes = {
    error: PropTypes.string.isRequired,
    children: PropTypes.node,
};

TerminalError.defaultProps = {
    children: null,
};

export default TerminalError;
