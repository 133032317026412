import React, { useReducer, createContext } from 'react';
import { PropTypes } from 'prop-types';
import pick from 'lodash.pick';

const initialState = {
    bulkActionDevices: [],
    selectedRows: [],
    searchFilter: {},
    searchData: [],
    searchLoading: false,
    usersDefaultValue: [],
    devicesDefaultValue: [],
    reportDevicesDefaultValue: [],
};

const reducer = (state, action) => {
    const { type, payload } = action || {};
    switch (type) {
        case 'SAVE_USERS_DEFAULT_VALUE':
            return {
                ...state,
                usersDefaultValue: payload,
            };
        case 'SAVE_DEVICES_DEFAULT_VALUE':
            return {
                ...state,
                devicesDefaultValue: payload,
            };
        case 'SAVE_REPORT_DEVICES_DEFAULT_VALUE':
            return {
                ...state,
                reportDevicesDefaultValue: payload,
            };
        case 'SET_SELECTED_ROWS':
            return {
                ...state,
                selectedRows: payload,
            };
        case 'SET_BULK_ACTIONS': {
            const keysToKeep = ['user', 'serial', 'type'];
            const devicesArray = payload.map((device) =>
                pick(device, keysToKeep),
            );
            return {
                ...state,
                bulkActionDevices: devicesArray,
                selectedRows: payload,
            };
        }
        case 'RESET_BULK_ACTIONS':
            return {
                ...state,
                bulkActionDevices: [],
                selectedRows: [],
            };
        case 'SET_SEARCH_FILTER':
            return {
                ...state,
                searchFilter: payload,
            };
        case 'SET_SEARCH_LOADING':
            return { ...state, searchLoading: payload };
        case 'SET_SEARCH_DATA':
            return { ...state, searchData: payload };
        case 'RESET_SEARCH_DATA':
            return {
                ...state,
                searchData: [],
                searchFilter: {},
            };
        case 'RESET_CONTEXT':
            return {
                ...initialState,
                devicesDefaultValue: state.devicesDefaultValue,
                usersDefaultValue: state.usersDefaultValue,
                reportDevicesDefaultValue: state.reportDevicesDefaultValue,
            };
        default:
            return state;
    }
};

export const HelpDeskStateContext = createContext();
export const HelpDeskDispatchContext = createContext();

export const HelpDeskProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <HelpDeskStateContext.Provider value={state}>
            <HelpDeskDispatchContext.Provider value={dispatch}>
                {children}
            </HelpDeskDispatchContext.Provider>
        </HelpDeskStateContext.Provider>
    );
};

HelpDeskProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
