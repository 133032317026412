import React from 'react';
import { DropdownItem, Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import StatusLabel from '../../shared/StatusLabel';
import NotificationAction from './NotificationAction';

const NotificationContainer = styled(Row)`
    max-width: 500px;
`;

const NotificationMessage = styled.p`
    font-size: 0.85em;
    display: inline;
`;

const NotificationItem = styled(DropdownItem)`
    width: 500px;
    &:hover {
        cursor: regular;
    }
`;

const MessageContainer = styled(Col)`
    white-space: break-spaces;
    overflow: visible;
`;

const LabelContainer = styled(Col)`
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: end;
`;

const NotificationsList = ({ items = [] }) => {
    const { t } = useTranslation();

    const priorityColors = {
        critical: 'red',
        severe: 'red',
        warn: 'yellow',
        info: 'gray',
        default: 'gray',
    };

    return items.map(
        ({
            objectId,
            priority,
            message: { code = '', info = '', parameters = [] },
            actions,
            objectType,
            objectName,
            objectSubType,
        }) => {
            let msg = t(`notifications$${code}`, {
                ...parameters || 0,
            }) || info;
            if (code === 'renewSevere' && parameters.length === 0) {
                msg = t(`notifications$renewExpired`) || info;
            }
            return (
                (code || info) && (
                    <NotificationItem
                        key={`notification-item-${objectId}`}
                        toggle={false}
                    >
                        <NotificationContainer key={`notification-${objectId}`}>
                            <MessageContainer md="9" sm="8" xs="7">
                                <NotificationMessage>{msg}</NotificationMessage>
                                <NotificationAction
                                    actions={actions}
                                    serial={objectId}
                                    type={objectType}
                                    deviceType={objectName}
                                    subType={objectSubType}
                                />
                            </MessageContainer>
                            <LabelContainer md="3" sm="4" xs="5">
                                <StatusLabel
                                    color={priorityColors[priority] || 'gray'}
                                    label={t(priority) || t('info')}
                                />
                            </LabelContainer>
                        </NotificationContainer>
                    </NotificationItem>
                )
            );
        },
    );
};

export default NotificationsList;
