import React, { useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    enrollOtpCardId,
    serverConstants as SERVER,
    PROGRESS_BAR_DONE_DELAY,
} from '../../constants';
import ProgressTemplate from '../shared/ProgressTemplate';
import { AppDispatchContext } from '../../context/AppContext';
import CardEnrollLayout from '../shared/CardEnrollLayout';
import { EnrollingStateContext } from '../../context/EnrollingContext';
import { postAssociateEnrollOtpAssign } from '../../utils/walkways';
import { useDidMount } from '../../utils/hooks';

const EnrollOtpCard = ({ mgmt, associateUser, reader }) => {
    const { device } = useContext(EnrollingStateContext);
    const dispatch = useContext(AppDispatchContext);
    const { t } = useTranslation();
    const [, setResult] = useState({});
    const [showNext, setShowNext] = useState(false);

    const setOtpResult = async (json) => {
        setResult({ ...json });
        setShowNext(true);
        if (mgmt && json.result === SERVER.success) {
            setTimeout(mgmt.next, PROGRESS_BAR_DONE_DELAY);
        }
    };

    useDidMount(() => {
        const otpData = {
            action: 'enrollOtp',
            pin: device.pin,
            type: reader.subType,
        };
        postAssociateEnrollOtpAssign(setOtpResult, dispatch, otpData, {
            user: associateUser,
            serial: reader.serial,
            type: reader.type,
            subType: reader.subType,
        });
    });
    return (
        <CardEnrollLayout
            cardTitle={t('cardTitles$issueIdentity')}
            testId={enrollOtpCardId}
            title={t('issuanceProgress')}
            alert={t('enrollTemplateAlertDefault')}
            showNext={false}
            mgmt={mgmt}
        >
            <ProgressTemplate
                isFake
                isDone={showNext}
                stage={t('deviceActionsStage$otp')}
            />
        </CardEnrollLayout>
    );
};

EnrollOtpCard.propTypes = {
    mgmt: PropTypes.object,
};

EnrollOtpCard.defaultProps = {
    mgmt: null,
};

export default EnrollOtpCard;
