import React from 'react';

import { useTranslation } from 'react-i18next';

import { Row, Col } from 'reactstrap';
import { userInfoRowContainerId, userInfoCardId } from '../../../../constants';
import CardTemplate from '../../../shared/CardTemplate';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import {
    ScrollInfoContainer,
    UserLabel,
    UserValue,
    UserInfoColName,
    UserInfoColValue,
    Title,
    CardTitleRow,
} from './styles';

const UserInfoRows = ({ user, userLoading }) => {
    const { t } = useTranslation();

    if (!user) {
        return null;
    }

    const attributes = {};
    const rightContent = ['roles', 'groups'];
    attributes.fullName =
        [user.firstName, user.lastName].filter((name) => name).join(' ') || '';
    ['username', 'email', 'status', 'roles', 'groups'].forEach((attr) => {
        attributes[attr] = user[attr] || '';
    });
    user.customAttributes.forEach((attr) => {
        attributes[attr.key] = attr.value;
    });

    const renderRow = (attr) => {
        let value;
        if (attributes[attr] instanceof Array) {
            value = attributes[attr].join(', ');
        } else {
            value = attributes[attr];
        }
        const label =
            t(`userInfo$attributes$${attr}`) ||
            attr.charAt(0).toUpperCase() + attr.substring(1);
        return (
            <React.Fragment key={label}>
                <UserLabel md={4}>{`${label}:`}</UserLabel>
                <UserValue>{value}</UserValue>
            </React.Fragment>
        );
    };

    if (userLoading) return <LoadingSpinner />;
    return (
        <CardTemplate testId={userInfoCardId}>
            <ScrollInfoContainer data-test-id={userInfoRowContainerId}>
                <CardTitleRow>
                    <Col>
                        <Title>{`${t('cardTitles$userInfo')}`}</Title>
                    </Col>
                </CardTitleRow>
                <Row>
                    <UserInfoColName>
                        {Object.keys(attributes)
                            .filter((attr) => rightContent.indexOf(attr) === -1)
                            .map((attr) => renderRow(attr))}
                    </UserInfoColName>
                    <UserInfoColValue lg={7} style={{ overflowY: 'auto' }}>
                        {Object.keys(attributes)
                            .filter((attr) => rightContent.indexOf(attr) !== -1)
                            .map((attr) => renderRow(attr))}
                    </UserInfoColValue>
                </Row>
            </ScrollInfoContainer>
        </CardTemplate>
    );
};

export default UserInfoRows;
