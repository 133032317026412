import React, { useReducer, createContext } from 'react';
import { PropTypes } from 'prop-types';
import { homePath } from '../constants';

const initialState = {
    tabs: [],
    activeIndex: 0,
    currentParent: '',
    homePath,
};

const reducer = (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case 'SET_HOME_PATH':
            return { ...state, homePath: payload };
        case 'SET_TABS_LIST':
            return {
                ...state,
                tabs: payload.tabs,
                activeIndex: payload.activeIndex,
                currentParent: payload.currentParent,
            };
        case 'SET_ACTIVE_INDEX':
            return { ...state, activeIndex: payload };
        case 'RESET_CONTEXT':
            return { ...initialState };
        default:
            return state;
    }
};

export const TabsStateContext = createContext();
export const TabsDispatchContext = createContext();

export const TabsProvider = ({ children, tabsState = initialState }) => {
    const [state, dispatch] = useReducer(reducer, tabsState);

    return (
        <TabsStateContext.Provider value={state}>
            <TabsDispatchContext.Provider value={dispatch}>
                {children}
            </TabsDispatchContext.Provider>
        </TabsStateContext.Provider>
    );
};

TabsProvider.propTypes = {
    children: PropTypes.node.isRequired,
    tabsState: PropTypes.object,
};

TabsProvider.defaultProps = {
    tabsState: initialState,
};
