import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import isEmpty from 'lodash.isempty';
import { terminusCardId, KIOSK_MODE, paths } from '../../constants';
import CardTemplate from '../shared/CardTemplate';
import { BrandingStateContext } from '../../context/BrandingContext';
import { EnrollingDispatchContext } from '../../context/EnrollingContext';
import { PortalContainer, CardHeader, StyledAlert } from '../../styles/common';
import { AppStateContext, AppDispatchContext } from '../../context/AppContext';
import GoBackButton from '../shared/GoBackButton';
import { useDidMount } from '../../utils/hooks';

const BodyContainer = styled.div`
    margin-left: 0rem;
    margin-right: 0rem;
`;

const DeviceNotFound = ({ title, path }) => {
    const { t } = useTranslation();
    const enrollingDispatch = useContext(EnrollingDispatchContext);
    const dispatch = useContext(AppDispatchContext);
    const { viewMode, goBackLink } = useContext(AppStateContext);
    const { kiosk } = useContext(BrandingStateContext);
    const history = useHistory();

    const { location } = history || {};
    const { pathname } = location || {};
    const isKiosk = viewMode === KIOSK_MODE;
    const hideBackBtn =
        isKiosk && !isEmpty(kiosk) && kiosk.terminusSuccessButton === 'false';
    const isHelpDesk = pathname.includes(paths.helpDesk);
    const returnPath =
        goBackLink || (isHelpDesk ? paths.helpDesk : paths.identities);

    useDidMount(() => {
        enrollingDispatch({
            type: 'RESET_CONTEXT',
        });
    });

    const clearKioskAction = () => {
        dispatch({ type: 'CLEAR_KIOSK_ACTION' });
        history.push(returnPath);
    };

    return (
        <CardTemplate title={title} testId={terminusCardId}>
            <PortalContainer data-testid="terminus">
                <BodyContainer>
                    <CardHeader>
                        {t('terminusCardLabels$stateless$h6')}
                    </CardHeader>
                    <StyledAlert color="info">
                        {t('deviceNotFoundMsg'.concat(isKiosk ? '$kiosk' : ''))}
                    </StyledAlert>
                    {!hideBackBtn && (
                        <GoBackButton onClick={clearKioskAction} path={path} />
                    )}
                </BodyContainer>
            </PortalContainer>
        </CardTemplate>
    );
};

export default DeviceNotFound;
